import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'times',
})
export class HourMinuteSecondsPipe implements PipeTransform {
  transform(seconds: number): string {
    let hour = '';
    const h = Math.floor(seconds / 3600);
    h < 10 ? (hour = '0' + h) : (hour = h.toString());
    let min = '';
    const m = Math.floor((seconds % 3600) / 60);
    m < 10 ? (min = '0' + m) : (min = m.toString());
    let sec = '';
    const s = Math.floor((seconds % 3600) % 60);
    s < 10 ? (sec = '0' + s) : (sec = s.toString());
    return hour + ':' + min + ':' + sec;
  }
}
