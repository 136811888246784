import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PremiumStateService } from '@betrail-libs/auth-state';
import { IPremiumRefPrice, IPremiumSubData } from '@betrail-libs/shared/interfaces/premium.model';

@Component({
  selector: 'app-premium-change-subscription-dialog',
  templateUrl: './premium-change-subscription-dialog.component.html',
  styleUrls: ['./premium-change-subscription-dialog.component.scss'],
})
export class PremiumChangeSubscriptionDialogComponent {
  #premiumState = inject(PremiumStateService);

  prices$ = this.#premiumState.premiumPrices$;
  selected?: IPremiumRefPrice;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { subscription: IPremiumSubData },
    private dialogRef: MatDialogRef<PremiumChangeSubscriptionDialogComponent>,
  ) {}

  closeDialog(price?: IPremiumRefPrice) {
    this.dialogRef.close(price);
  }
}
