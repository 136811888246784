import { inject, Injectable } from '@angular/core';
import { SetUserParams } from '@betrail-libs/app-params-state';
import { IBasket, IUserState } from '@betrail-libs/shared/interfaces/auth.model';
import { INotif } from '@betrail-libs/shared/interfaces/interfaces';
import { IPremiumRefPrice, IPremiumSubData } from '@betrail-libs/shared/interfaces/premium.model';
import { PlatformServiceService } from '@betrail/ux/platform-service.service';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PremiumApiService } from '../api/premium-api.service';
import { UsersService } from '../users';
import {
  CancelSubscriptionAutoRenewal,
  ChangePremiumSubscriptionType,
  CompleteAuthRunnerData,
  CreateUserAction,
  CreateUserFailedAction,
  LoadPublicPremiumPrices,
  LoadUserNotificationsAction,
  LoadUserPremiumData,
  LoginAction,
  LoginFailedAction,
  LogoutAction,
  MasqueradeAction,
  RetrieveAction,
  RetrieveFailedAction,
  RetrievePasswordAction,
  RetrieveSucceedAction,
  UpdateCartAction,
  UpdateNotificationStatusAction,
  UpdateUserAction,
  UpdateUserAddsPreferences,
  UpdateUserLanguage,
  UpdateUserMailPreferences,
  UpdateUserPassword,
  UpdateUserProfileData,
} from './auth.actions';
import { UserApiService } from '../api/user-api.service';
import { UseLang } from 'apps/betrail2/src/app/i18nState/i18n.action';

export interface AuthStateModel {
  loggedIn: boolean;
  loading: boolean;
  user: IUserState;
  basket: IBasket;
  allOrders: any[];
  notifs: { [id: number]: INotif };
  notifsStatus: { [id: number]: string };
  premiumData?: IPremiumSubData;
  premiumPrices: IPremiumRefPrice[];
}

@State<AuthStateModel>({
  name: 'ngxsAuthState',
  defaults: {
    loggedIn: undefined,
    loading: true,
    user: undefined,
    basket: undefined,
    allOrders: [],
    notifs: {},
    notifsStatus: {},
    premiumPrices: [],
  },
})
@Injectable()
export class NgxsAuthState implements NgxsOnInit {
  #platformService = inject(PlatformServiceService);
  #premiumApi = inject(PremiumApiService);
  #userApi = inject(UserApiService);
  usersService = inject(UsersService);

  @Selector()
  static authState(state: AuthStateModel): AuthStateModel {
    return state;
  }

  @Selector()
  static uid(state: AuthStateModel) {
    return state.user.uid;
  }

  @Selector()
  static allOrders(state: AuthStateModel) {
    return state.allOrders;
  }

  @Selector()
  static basket(state: AuthStateModel) {
    return state.basket;
  }

  @Selector()
  static runnerAlias(state: AuthStateModel): string {
    return state.user?.runner?.alias;
  }

  @Selector()
  static user(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  static notifs(state: AuthStateModel) {
    return Object.values(state.notifs);
  }

  @Selector()
  static notifsStatus(state: AuthStateModel) {
    return state.notifsStatus;
  }

  @Selector()
  static isLogged(state: AuthStateModel) {
    return state.loggedIn;
  }

  @Selector()
  static isLoading(state: AuthStateModel) {
    return state.loading;
  }

  @Selector()
  static premiumData(state: AuthStateModel) {
    return state.premiumData;
  }

  @Selector()
  static premiumPrices(state: AuthStateModel) {
    return state.premiumPrices;
  }

  ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch([new RetrieveAction(), new LoadPublicPremiumPrices()]);
  }

  @Action(LoginAction)
  authLogin(ctx: StateContext<AuthStateModel>, action: LoginAction) {
    ctx.setState(
      produce((draft: AuthStateModel) => {
        draft.loading = true;
        draft.loggedIn = false;
        draft.user = undefined;
      }),
    );
    return this.#userApi.login(action.loginCredential.user, action.loginCredential.password).pipe(
      // If successful, dispatch success action with result
      map(() => ctx.dispatch(new RetrieveAction(action.activateRunner))),
      // If request fails, dispatch failed action
      catchError(() => ctx.dispatch(new LoginFailedAction())),
    );
  }

  @Action(MasqueradeAction)
  masqueradeLogin(ctx: StateContext<AuthStateModel>, action: MasqueradeAction) {
    ctx.setState(
      produce((draft: AuthStateModel) => {
        draft.loading = true;
        draft.loggedIn = false;
        draft.user = undefined;
      }),
    );
    return this.#userApi.masquerade(action.uid).pipe(
      // If successful, dispatch success action with result
      map(() => ctx.dispatch(new RetrieveAction())),
      // If request fails, dispatch failed action
      catchError(() => ctx.dispatch(new LoginFailedAction())),
    );
  }

  @Action(LoginFailedAction)
  loginFailed(ctx: StateContext<AuthStateModel>, action: LoginFailedAction) {
    ctx.setState(
      produce((draft: AuthStateModel) => {
        draft.loading = false;
        draft.loggedIn = false;
        draft.user = undefined;
      }),
    );
  }

  @Action(LogoutAction)
  logout(ctx: StateContext<AuthStateModel>, action: LogoutAction) {
    return this.#userApi.logout().pipe(
      tap(() => {
        ctx.setState(
          produce((draft: AuthStateModel) => {
            draft.loading = false;
            draft.loggedIn = undefined;
            draft.user = undefined;
          }),
        );
        // ctx.dispatch([new SetCurrentUserRunnerId(0), new Navigate(['/'])]);
      }),
    );
  }

  @Action(RetrieveAction)
  fetchUser(ctx: StateContext<AuthStateModel>, action: RetrieveAction) {
    if (this.#platformService.isSsr()) {
      ctx.setState(
        produce((draft: AuthStateModel) => {
          draft.loading = false;
          draft.loggedIn = false;
        }),
      );
    } else {
      ctx.setState(
        produce((draft: AuthStateModel) => {
          draft.loading = true;
        }),
      );
      return this.#userApi.getUserFromSession(action.activateRunner).pipe(
        map((data: any) => ctx.dispatch(new RetrieveSucceedAction(data))),
        catchError((err, caught) => ctx.dispatch(new RetrieveFailedAction())),
      );
    }
  }

  @Action(RetrieveSucceedAction)
  retrieveUserSucceeded(ctx: StateContext<AuthStateModel>, action: RetrieveSucceedAction) {
    const user = this.addAuthAliases(action.payload);
    ctx.setState(
      produce((draft: AuthStateModel) => {
        draft.loading = false;
        draft.loggedIn = true;
        draft.user = user;
        draft.basket = user.basket;
      }),
    );
    ctx.dispatch([
      new LoadUserNotificationsAction('NO_NOTIFICATION_FOUND'),
      //  new loadUserRunnerRegistrations(),
      // new SetCurrentUserRunnerId(ctx.getState().user?.runner?.id, ctx.getState().user?.runner),
    ]);
    if (ctx.getState().user?.userDrupal?.userParams) {
      const userParams = JSON.parse(ctx.getState().user?.userDrupal?.userParams);
      return ctx.dispatch(new SetUserParams(userParams));
    }
  }

  @Action(RetrieveFailedAction)
  retrieveUserFailed(ctx: StateContext<AuthStateModel>, action: RetrieveFailedAction) {
    ctx.setState(
      produce((draft: AuthStateModel) => {
        draft.loading = false;
        draft.loggedIn = false;
      }),
    );
  }

  @Action(CreateUserAction)
  createUser(ctx: StateContext<AuthStateModel>, action: CreateUserAction) {
    return this.usersService.createUser(action.formData).pipe(
      map(ret => {
        return ctx.dispatch(new LoginAction({ user: action.formData.mail, password: action.formData.pass }));
      }),
      catchError(err => {
        return ctx.dispatch(new CreateUserFailedAction(err.error));
      }),
    );
  }

  // ??? goes nowhere but is used in login-dialog.service.ts & login-dialog.component.ts ???
  @Action(RetrievePasswordAction)
  retrievePassword(ctx: StateContext<AuthStateModel>, action: RetrievePasswordAction) {
    return this.usersService.retrievePassword(action.payload.name);
  }

  @Action(UpdateUserAction)
  updateUserState(ctx: StateContext<AuthStateModel>, action: UpdateUserAction) {
    ctx.setState(
      produce((draft: AuthStateModel) => {
        draft.user = { ...draft.user, ...action.payload.user };
      }),
    );
  }

  @Action(UpdateCartAction)
  @ImmutableContext()
  updateCart(ctx: StateContext<AuthStateModel>, action: UpdateCartAction) {
    ctx.setState(draft => {
      draft.basket.items = action.items;
      return draft;
    });
  }

  @Action(UpdateUserProfileData)
  updateUserProfileData(ctx: StateContext<AuthStateModel>, action: UpdateUserProfileData) {
    ctx.setState(
      produce((draft: AuthStateModel) => {
        draft.user.userProfile = action.userProfile;
      }),
    );
  }

  @Action(LoadUserNotificationsAction)
  loadUserNotifications(ctx: StateContext<AuthStateModel>, action: LoadUserNotificationsAction) {
    return this.usersService.getUserNotifications().pipe(
      tap(notifs => {
        notifs &&
          ctx.setState(
            produce((draft: AuthStateModel) => {
              notifs.map(notif => {
                switch (notif.type) {
                  case 'result_available':
                    notif = {
                      ...notif,
                      url: encodeURI('https://www.betrail.run/runner/' + notif.alias + '/results/' + notif.reid),
                    };
                    break;
                  case 'premium_payment_waiting':
                    notif = {
                      ...notif,
                      url: encodeURI('https://www.betrail.run/subscription/' + notif.target_id),
                    };
                    break;

                  default:
                    break;
                }

                draft.notifs[notif.id] = { ...notif, createdDate: new Date(notif.created) };
                draft.notifsStatus[notif.id] = notif.status;
              });
            }),
          );
      }),
    );
  }

  @Action(UpdateNotificationStatusAction)
  updateNotificationStatus(ctx: StateContext<AuthStateModel>, action: UpdateNotificationStatusAction) {
    return this.usersService.updateNotificationStatus(action.noid, action.status).pipe(
      map(xx => {
        ctx.setState(
          produce((draft: AuthStateModel) => {
            draft.notifsStatus[action.noid] = action.status;
          }),
        );
      }),
    );
  }

  @Action(CompleteAuthRunnerData)
  completeAuthRunnerData(ctx: StateContext<AuthStateModel>, action: CompleteAuthRunnerData) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      user: {
        ...state.user,
        runner: {
          ...state.user.runner,
          ...action.data,
        },
      },
    });
  }

  @Action(LoadPublicPremiumPrices)
  loadPublicPremiumPrices(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    if (state.premiumPrices.length === 0) {
      return this.#premiumApi.getPublicPremiumPrices().pipe(
        tap(data => {
          ctx.setState(
            produce((draft: AuthStateModel) => {
              draft.premiumPrices = data;
            }),
          );
        }),
      );
    } else {
      return of(state.premiumPrices);
    }
  }

  @Action(LoadUserPremiumData)
  loadUserPremiumData(ctx: StateContext<AuthStateModel>, action: LoadUserPremiumData) {
    return this.#premiumApi.getUserPremiumSubscriptionData(action.userId).pipe(
      tap(data => {
        ctx.setState(
          produce((draft: AuthStateModel) => {
            draft.premiumData = data;
          }),
        );
      }),
    );
  }

  @Action(ChangePremiumSubscriptionType)
  changePremiumSubscriptionType(ctx: StateContext<AuthStateModel>, action: ChangePremiumSubscriptionType) {
    return this.#premiumApi.changePremiumSubscriptionType(action.subscriptionId, action.newPrice).pipe(
      tap(data => {
        ctx.setState(
          produce((draft: AuthStateModel) => {
            draft.premiumData = data;
          }),
        );
      }),
    );
  }

  @Action(CancelSubscriptionAutoRenewal)
  cancelSubscriptionAutoRenewal(ctx: StateContext<AuthStateModel>, action: CancelSubscriptionAutoRenewal) {
    const state = ctx.getState();
    return this.#premiumApi.cancelSubscriptionAutoRenewal(action.subscriptionId).pipe(
      tap(data => {
        if (data.auto_renewal === 0) {
          ctx.setState(
            produce((draft: AuthStateModel) => {
              draft.premiumData = { ...state.premiumData, autoRenewal: false };
            }),
          );
        }
      }),
    );
  }

  @Action(UpdateUserAddsPreferences)
  updateUserAddsPreferences(ctx: StateContext<AuthStateModel>, action: UpdateUserAddsPreferences) {
    return this.#userApi.updateUserAddsPreferences(action.uid, action.hide_other_banner).pipe(
      tap(({ hide_other_banner }) => {
        ctx.setState(
          produce((draft: AuthStateModel) => {
            draft.user.userProfile.hide_other_banner = hide_other_banner;
          }),
        );
      }),
    );
  }

  @Action(UpdateUserLanguage)
  updateUserLanguage(ctx: StateContext<AuthStateModel>, action: UpdateUserLanguage) {
    return this.#userApi.updateUserLanguage(action.uid, action.language).pipe(
      tap(({ language }) => {
        ctx.dispatch(new UseLang(language));
        ctx.setState(
          produce((draft: AuthStateModel) => {
            draft.user.language = language;
          }),
        );
      }),
    );
  }

  @Action(UpdateUserMailPreferences)
  updateUserMailPreferences(ctx: StateContext<AuthStateModel>, action: UpdateUserMailPreferences) {
    return this.#userApi.updateUserMailPreferences(action.uid, action.data).pipe(
      tap((data) => {
        ctx.setState(
          produce((draft: AuthStateModel) => {
            draft.user.mail = data.mail;
            draft.user.userProfile.mail_newsletter = data.mail_newsletter;
            draft.user.userProfile.mail_notification = data.mail_notification;
          }),
        );
      }),
    );
  }

  @Action(UpdateUserPassword)
  updateUserPassword(ctx: StateContext<AuthStateModel>, { uid, current_pass, new_pass }: UpdateUserPassword) {
    return this.#userApi.updateUserPassword(uid, { current_pass, new_pass });
  }

  addAuthAliases(user): IUserState {
    let authAliases: string[] = [];
    user.authAliases = authAliases;
    if (user && user.organizations && user.organizations.length > 0) {
      user.organizations.map(o => {
        if (o.organization) {
          authAliases.push('' + o.organization.id);
          if (o.organization.trails) {
            o.organization.trails.map(t => {
              authAliases.push(t.alias);
            });
          }
        }
      });
      user.authAliases = authAliases;
    }
    if (user.runner?.avatar?.filename) {
      user.picture = 'https://dev-old.betrail.run/sites/default/files/' + user.runner.avatar.filename;
    }
    return user;
  }
}
