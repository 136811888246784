import { Component, OnInit } from '@angular/core';
declare var googletag: any;

@Component({
  selector: 'google-ad-banner',
  templateUrl: './google-ad-banner.component.html',
  styleUrls: ['./google-ad-banner.component.scss'],
})
export class GoogleAdBannerComponent {
  ngAfterViewInit() {
    //window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      googletag.display('div-gpt-ad-1585570068518-0');
    });
  }
}
