import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthStateService } from '@betrail-libs/auth-state';
import { EUserRole } from '@betrail-libs/shared/interfaces/interfaces';
import { ERaceTimingType, ERaceType } from '@betrail-libs/shared/interfaces/race.model';
import { hasRoleId } from '@betrail-libs/shared/utils';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

export type RaceForm = {
  race_name: FormControl<string>;
  date: FormControl<string>;
  canceled: FormControl<boolean>;
  distance: FormControl<number>;
  elevation: FormControl<number>;
  race_type: FormControl<ERaceType>;
  refreshments: FormControl<number>;
  departure_time: FormControl<string>;
  price: FormControl<number>;
  night: FormControl<boolean>;
  laps?: FormControl<string>;
  // --- <mat-divider> ---
  trailcup_only: FormControl<boolean>;
  offroad: FormControl<number>;
  trail: FormControl<number>;
  offroad_certified: FormControl<boolean>;
  altitude_min: FormControl<number>;
  altitude_max: FormControl<number>;
  time_barrier: FormControl<number>;
  onsite_price: FormControl<number>;
  max_runners: FormControl<number>;
  timing_type: FormControl<ERaceTimingType>;
  route_type: FormControl<number>; // = 0 = linear, 1 = loop, 2 or more = multiple loops
  official_ranking_url: FormControl<string>;
  comment: FormControl<string>;
  message?: FormControl<string>;
};

@UntilDestroy()
@Component({
  selector: 'app-bux-race-form',
  templateUrl: './race-form.component.html',
})
export class RaceFormComponent implements OnInit, OnChanges {
  #authState = inject(AuthStateService);
  #transloco = inject(TranslocoService);

  raceTypes = Object.values(ERaceType);
  availableDates: { displayValue: string; value: string }[] = [];
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#424242',
      buttonColor: '#fff',
    },
    dial: {
      dialBackgroundColor: '#555',
    },
    clockFace: {
      clockFaceBackgroundColor: '#555',
      clockHandColor: '#3ca896',
      clockFaceTimeInactiveColor: '#fff',
    },
  };
  toggleMore = false;
  selectedLoopType = 1;
  showNumberOfLoop = false;
  commentMaxLength = 250;

  isAdmin$ = this.#authState.hasRole(EUserRole.Administrator);
  isEncoder$ = this.#authState.hasRole(EUserRole.Encoder);
  showOrgaOptions$ = this.#authState.hasOrgaRight();
  user$ = this.#authState.getUser();

  @Input() formGroup: FormGroup<RaceForm>;
  @Input() datesInfos: { several_days: boolean; dates: number[] };
  @Input() verified = false;
  @Input() multiple = false;

  @Output() distanceChanged = new EventEmitter<number>();

  ngOnInit() {
    /*
    This form ca be used in 2 cases :

    - encapsulted in form array (ex: used in add to calendar feature)
    - stand alone formGroup in a popup for example

    In any case, the formGroup has to be build by BetrailUxFormsService.buildRaceForm(),
    and passed in the component through @Input() formGroup: FormGroup<RaceForm>;
    */

    // Handle the multiple & route_type values to handle linear, loop or multi loops races
    if (this.multiple) {
      this.formGroup.addControl('laps', new FormControl<string>(undefined, Validators.required));
      this.formGroup.removeControl('route_type');
    }
    if (this.formGroup.value?.route_type === 0) {
      this.selectedLoopType = 0;
    } else if (this.formGroup.value?.route_type > 1 || this.multiple) {
      this.selectedLoopType = 2;
    } else {
      this.selectedLoopType = this.formGroup.value?.route_type ? +this.formGroup.value.route_type : 1;
    }
    // Disable some fields if the race is verified by the Betrail team because those info can no longer be modified
    this.user$.pipe(untilDestroyed(this)).subscribe(user => {
      if (this.verified && !hasRoleId(user, EUserRole.Administrator)) {
        this.disableVerifiedFields();
      }
    });
    // Build available dates in the race's event is on more than one day
    if (this.datesInfos) {
      this.buildAvailableDays();
    }
    // If only one date is available, setting this date as default date value
    if (this.availableDates && this.availableDates.length > 0) {
      this.setDefaultDate();
    }
    // Listen to distance changes. If there is a modification, notify it to the parent form to be able to update title information
    this.formGroup
      .get('distance')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((distanceChanges: number) => {
        this.distanceChanged.emit(distanceChanges);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datesInfos && !changes.datesInfos.firstChange) {
      this.buildAvailableDays();
      this.setDefaultDate();
    }
  }

  test() {
    console.log(this.formGroup);
  }

  disableVerifiedFields() {
    this.formGroup.get('distance').disable();
    this.formGroup.get('refreshments').disable();
    this.formGroup.get('elevation').disable();
    this.formGroup.get('offroad').disable();
    this.formGroup.get('trail').disable();
  }

  buildAvailableDays() {
    this.availableDates = [];

    for (let i = 0, len = this.datesInfos.dates.length; i < len; i++) {
      const date = this.datesInfos.dates[i];

      moment.locale(this.#transloco.getActiveLang());

      this.availableDates.push({
        displayValue: moment(date).format('dddd DD/MM/YYYY'),
        value: moment(date).format('YYYY-MM-DD'),
      });
    }
    this.setDefaultDate();
  }

  setDefaultDate() {
    let currentDateIsInTheList = false;

    if (this.availableDates && this.availableDates.length > 0) {
      for (const d of this.availableDates) {
        // checking if current selected date is still in the list
        if (this.formGroup.get('date').value === d.value) {
          currentDateIsInTheList = true;
        }
      }
      // setting the default date
      if (!currentDateIsInTheList) {
        this.formGroup.patchValue({
          date: this.availableDates?.[0]?.value,
        });
      }
    }
  }

  handleNbOfLoops(type: 0 | 1 | 2) {
    if (type === 2) {
      this.formGroup.patchValue({ route_type: type });
      this.showNumberOfLoop = true;
    } else {
      this.formGroup.patchValue({ route_type: type });
      this.formGroup.markAsDirty();
    }
  }
}
