import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IPremiumSubscription } from '@betrail-libs/shared/interfaces/premium.model';
import { EventService } from '@betrail-libs/trail-data-state';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'premium-subscription-preview',
  templateUrl: './premium-subscription-preview.component.html',
  styleUrls: ['./premium-subscription-preview.component.scss'],
})
export class PremiumSubscriptionPreviewComponent {
  constructor(private eventService: EventService, private router: Router) {}

  @Input() subscription: IPremiumSubscription;
  @Input() options: any;

  @Output() subscriptionDeleted = new EventEmitter();
  @Output() goToPayment = new EventEmitter();
  @Output() remindLater = new EventEmitter();

  async deleteSubscription(suid) {
    const deletedSubscription = await firstValueFrom(this.eventService.deletePremiumSubscription(suid));

    if (deletedSubscription && deletedSubscription.status === 3) {
      this.subscription = deletedSubscription;
      this.subscriptionDeleted.emit(deletedSubscription);
    } else {
      alert('An error occurred');
    }
  }

  go(id) {
    this.router.navigateByUrl(`/premium/subscribe/${id}`);
    this.goToPayment.emit();
  }
}
