<div class="timerLogo">
  <img class="timerLogoImg" [src]="updatedImage.max_100_height || timer?.photo?.max_100_height | sanitizeUrl" />
  <div class="timerLogoButtons">
    <input #fileInput hidden type="file" (click)="$event.stopPropagation()" (change)="addImage(fileInput.files[0])" />
    <button
      class="addImageButton"
      tdFileDrop
      #addImageButton
      mat-raised-button
      (click)="fileInput.click(); $event.stopPropagation()"
      (fileDrop)="addImage($any($event))"
      [disabled]="addImageButtonDisabled"
    >
      <mat-icon>image</mat-icon>&nbsp;
      <ng-container *ngIf="timer?.photo?.max_100_height || updatedImage.max_100_height; else newImage">
        {{ 'CHOOSE_ANOTHER_LOGO' | transloco }}</ng-container
      >
      <ng-template #newImage>{{ 'ADD_A_LOGO' | transloco }}</ng-template>
    </button>
    <button *ngIf="updatedImage.medium_image" class="removeImageButton" mat-icon-button (click)="removeImage()">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>
