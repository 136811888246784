import { inject, Injectable } from '@angular/core';
import { ILoginCred, IUserState } from '@betrail-libs/shared/interfaces/auth.model';
import { hasRoleId, userIsOrganizerOfThisAlias } from '@betrail-libs/shared/utils';
import { RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { EUserRole } from '../../../../shared/interfaces/interfaces';
import {
  LoginAction,
  LogoutAction,
  MasqueradeAction,
  UpdateCartAction,
  UpdateUserAction,
  UpdateUserAddsPreferences,
  UpdateUserLanguage,
  UpdateUserMailPreferences,
  UpdateUserPassword,
} from '../ngxs/auth.actions';
import { AuthStateModel, NgxsAuthState } from '../ngxs/auth.state';

interface StateWithAuth {
  ngxsAuthState: AuthStateModel;
}

@Injectable({
  providedIn: 'root',
  deps: [Store],
})
export class AuthStateService {
  #store = inject(Store);

  authState$ = this.#store.select(NgxsAuthState.authState);

  isLoading$ = this.#store.select(NgxsAuthState.isLoading);

  isLoading = () => this.isLoading$;

  user$ = this.#store.select(NgxsAuthState.user);

  geUserAfterLoading = () =>
    this.isLoading$.pipe(
      filter(status => status === false),
      switchMap(() => this.user$),
    );

  getUser = () => this.geUserAfterLoading();

  getUserSnapshot = () => this.#store.selectSnapshot((state: StateWithAuth) => state.ngxsAuthState.user);

  logged$ = this.#store.select(NgxsAuthState.isLogged);

  isLogged = () => this.logged$;

  isLoggedInSnapshot = () => this.#store.selectSnapshot((state: StateWithAuth) => state.ngxsAuthState.loggedIn);

  isConnected = () => this.user$.pipe(map(user => user && user.runner && +user.runner.id > 0));

  uid$ = this.#store.select(NgxsAuthState.uid);

  getUid = () => this.uid$;

  getUidSnapshot = () => this.#store.selectSnapshot((state: StateWithAuth) => state.ngxsAuthState.user?.uid);

  runnerAlias$ = this.#store.select(NgxsAuthState.runnerAlias);

  getRunner = () => this.geUserAfterLoading().pipe(map(u => u?.runner));

  allOrders$ = this.#store.select(NgxsAuthState.allOrders);

  getAllOrders = () => this.allOrders$;

  notifs$ = this.#store.select(NgxsAuthState.notifs);

  getNotifs = () => this.notifs$;

  notifsStatus$ = this.#store.select(NgxsAuthState.notifsStatus);

  getNotifsStatus = () => this.notifsStatus$;

  basket$ = this.#store.select(NgxsAuthState.basket);

  url$ = this.#store.select(RouterState.url);

  login = (credential: ILoginCred) => this.#store.dispatch(new LoginAction(credential));

  logout = () => this.#store.dispatch(new LogoutAction());

  masquerade = (uid: number) => this.#store.dispatch(new MasqueradeAction(uid));

  hasRole = (role: EUserRole) => this.geUserAfterLoading().pipe(map(user => hasRoleId(user, role)));

  hasRoleSnapshot = (role: EUserRole) => hasRoleId(this.getUserSnapshot(), role);

  hasOneOfRoles = (roles: EUserRole[]) =>
    this.geUserAfterLoading().pipe(map(user => roles.some(role => hasRoleId(user, role))));

  hasOrgaRight = () =>
    combineLatest([this.geUserAfterLoading(), this.url$]).pipe(
      map(([user, url]) => hasRoleId(user, EUserRole.VerifiedOrganizer) && userIsOrganizerOfThisAlias(user, url)),
    );

  isAdminEncoderOrTheOrganizer = () => {
    return combineLatest([this.hasOneOfRoles([EUserRole.Administrator, EUserRole.Encoder]), this.hasOrgaRight()]).pipe(
      map(([isAdminOrEncoder, isTheOrga]) => isAdminOrEncoder || isTheOrga),
    );
  };

  isSuperAdmin = () => this.getUid().pipe(map((uid: number) => [3567, 6567, 78797].includes(uid)));

  showAdds = () =>
    this.geUserAfterLoading().pipe(
      map(user => user?.runner?.is_premium !== 1 || !user?.userProfile?.hide_other_banner),
    );

  emptyUserBasket = () => this.#store.dispatch(new UpdateCartAction([]));

  updateCart = (items: any[]) => this.#store.dispatch(new UpdateCartAction(items));

  updateUserAddsPreferences = (uid: number, hide_other_banner: boolean) =>
    this.#store.dispatch(new UpdateUserAddsPreferences(uid, hide_other_banner));

  updateUserLanguage = (uid: number, language: 'fr' | 'nl' | 'en') =>
    this.#store.dispatch(new UpdateUserLanguage(uid, language));

  updateUserMailPreferences = (uid: number, mail: string, mail_newsletter: boolean, mail_notification: boolean) =>
    this.#store.dispatch(new UpdateUserMailPreferences(uid, { mail, mail_newsletter, mail_notification }));

  updateUserPassword = (uid: number, current_pass: string, new_pass: string) =>
    this.#store.dispatch(new UpdateUserPassword(uid, current_pass, new_pass));

  updateUserState = (user: Partial<IUserState>) => this.#store.dispatch(new UpdateUserAction({ user }));
}
