import { Pipe } from '@angular/core';
import { IOtherResult, IResult } from '@betrail-libs/shared/interfaces/result.model';

@Pipe({
  name: 'nbResultByType',
})
export class NbResultByTypePipe {
  transform(results: (IResult & IOtherResult)[], type: 'ultra' | 'trailL' | 'trailS' | 'stt' | 'other'): number {
    let resultCopy = [...results];
    switch (type) {
      case 'ultra':
        resultCopy = resultCopy.filter(el => {
          if (el.run_type !== 'nature_race' && el.run_type !== 'road_race') {
            const distance = el.race
              ? el.race.flat_distance
                ? el.race.flat_distance
                : el.race.distance
              : el.distance + (el.height_difference ? Math.round(el.height_difference / 100) : 0);
            return distance >= 80 ? true : false;
          }
        });
        break;
      case 'trailL':
        resultCopy = resultCopy.filter(el => {
          if (el.run_type !== 'nature_race' && el.run_type !== 'road_race') {
            const distance = el.race
              ? el.race.flat_distance
                ? el.race.flat_distance
                : el.race.distance
              : el.distance + (el.height_difference ? Math.round(el.height_difference / 100) : 0);
            return distance < 80 && distance >= 42 ? true : false;
          }
        });
        break;
      case 'trailS':
        resultCopy = resultCopy.filter(el => {
          if (el.run_type !== 'nature_race' && el.run_type !== 'road_race') {
            const distance = el.race
              ? el.race.flat_distance
                ? el.race.flat_distance
                : el.race.distance
              : el.distance + (el.height_difference ? Math.round(el.height_difference / 100) : 0);
            return distance < 42 && distance >= 21 ? true : false;
          }
        });
        break;
      case 'stt':
        resultCopy = resultCopy.filter(el => {
          if (el.run_type !== 'nature_race' && el.run_type !== 'road_race') {
            const distance = el.race ? (el.race.flat_distance ? el.race.flat_distance : el.race.distance) : el.distance;
            return distance < 21 ? true : false;
          }
        });
        break;
      case 'other':
        resultCopy = resultCopy.filter(el => {
          if (el.run_type === 'nature_race' || el.run_type === 'road_race') {
            return true;
          } else return false;
        });
        break;
      default:
        resultCopy = resultCopy;
    }

    /* if (resultCopy.length === 0 && type === 'other') {
      this.otherStats = '';
      return 0;
    } else if (resultCopy.length === 1 && type === 'other') {
      this.otherStats = 'OTHER';
      return 1;
    } else if (resultCopy.length > 1 && type === 'other') {
      this.otherStats = 'OTHERS';
      return resultCopy.length;
    } else {
      return resultCopy.length;
    } */

    return resultCopy.length;
  }
}
