import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthStateService } from '@betrail-libs/auth-state';
import { IPath } from '@betrail-libs/shared/interfaces/path.model';
import { EventService } from '@betrail-libs/trail-data-state';
import { PathFormComponent } from '@betrail/ux/components/forms/path-form/path-form.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { DialogResult } from '../../common/dialog/dialogResult';
import { DialogResultStatus } from '../../common/dialog/dialogResultStatus';

@UntilDestroy()
@Component({
  selector: 'app-edit-path-dialog',
  templateUrl: './edit-path-dialog.component.html',
  styleUrls: ['./edit-path-dialog.component.scss'],
})
export class EditPathDialogComponent implements OnInit {
  /* Members  */
  pathForm: UntypedFormGroup;
  path: IPath;
  raid;
  loading$ = new BehaviorSubject<boolean>(false);
  user;
  formActivated = false;
  forcedType: any;

  /* ViewChilds */
  @ViewChild('pathFormComponent') pathFormComponent: PathFormComponent;

  constructor(
    private authState: AuthStateService,
    private formBuilder: UntypedFormBuilder,
    private eventService: EventService,
    private dialogRef: MatDialogRef<EditPathDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.path = this.data.path;
    this.pathForm = this.formBuilder.group({});
    this.authState
      .getUser()
      .pipe(untilDestroyed(this))
      .subscribe(u => {
        this.user = u;
        this.patchValues();
      });
  }

  ngOnInit() {
    this.raid = this.path.raid;
  }

  patchValues() {
    setTimeout(() => {
      this.pathFormComponent.patchFormValues(this.path);
      this.pathForm = this.pathFormComponent.formGroup;
      if (this.data.type) {
        this.forcedType = this.data.type;
        this.pathForm.controls['type'].setValue(this.data.type);
        this.pathForm.controls['type'].disable();
      }
    });
  }

  onOkClick() {
    const dialogResult = new DialogResult();
    this.loading$.next(true);

    // SAVE PATH HERE
    if (this.pathForm.valid === true) {
      this.eventService.sendPathModificationMail(this.pathForm.value);
      this.eventService
        .updateRaceTrack(this.path.id, this.pathForm.value, this.data.isNew)
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          dialogResult.status = DialogResultStatus.Ok;
          dialogResult.data = res;
          this.loading$.next(false);
          this.dialogRef.close(dialogResult);
        });
    }
  }

  onCancelClick() {
    const dialogResult = new DialogResult();
    dialogResult.status = DialogResultStatus.Cancel;
    this.dialogRef.close(dialogResult);
  }

  close() {
    this.dialogRef.close();
  }
}
