import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthStateService } from '@betrail-libs/auth-state';
import { IUserState } from '@betrail-libs/shared/interfaces/auth.model';
import { IEvent } from '@betrail-libs/shared/interfaces/event.model';
import { EUserRole, ITrail } from '@betrail-libs/shared/interfaces/interfaces';
import { formatDate, hasRoleId } from '@betrail-libs/shared/utils';
import { EventService, LoadTrailForAlias } from '@betrail-libs/trail-data-state';
import { EventFormComponent } from '@betrail/ux/components/forms/event-form/event-form.component';
import { TrailFormComponent } from '@betrail/ux/components/forms/trail-form/trail-form.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { DialogResult } from '../../../../common/dialog/dialogResult';
import { DialogResultStatus } from '../../../../common/dialog/dialogResultStatus';

@UntilDestroy()
@Component({
  selector: 'app-edit-trail-dialog',
  templateUrl: './edit-trail-dialog.component.html',
  styleUrls: ['./edit-trail-dialog.component.scss'],
})
export class EditTrailDialogComponent {
  trail: ITrail;
  event: IEvent;
  mainForm: UntypedFormGroup;
  user: IUserState;
  isOrga = false;
  loading$ = new BehaviorSubject<boolean>(false);
  formActivated = false;
  datesWarning = false;

  @ViewChild('trailForm') trailFormComponent: TrailFormComponent;
  @ViewChild('eventForm') eventFormComponent: EventFormComponent;

  constructor(
    public eventService: EventService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditTrailDialogComponent>,
    private store: Store,
    private authState: AuthStateService,
    @Inject(MAT_DIALOG_DATA) public data: IEvent,
  ) {
    this.trail = data.trail;
    this.event = data;
    combineLatest([this.authState.getUser(), this.authState.hasOrgaRight()])
      .pipe(untilDestroyed(this))
      .subscribe(([u, isOrga]) => {
        this.user = u;
        this.isOrga = isOrga;
        if (
          this.user &&
          this.user.roles &&
          (hasRoleId(u, EUserRole.Administrator) ||
            hasRoleId(u, EUserRole.Encoder) ||
            (hasRoleId(u, EUserRole.VerifiedOrganizer) && isOrga))
        ) {
          this.formActivated = true;
          this.patchValues();
        }
      });

    const messageNotRequired =
      this.isOrga || this.user?.roles.map(r => r.rid).includes(EUserRole.Encoder, EUserRole.Administrator);
    this.mainForm = this.formBuilder.group({});
    this.mainForm.addControl(
      'message',
      new UntypedFormControl(
        this.isOrga ? "Modification par l'organisateur" : '',
        messageNotRequired ? undefined : Validators.required,
      ),
    );
  }

  patchValues() {
    setTimeout(() => {
      this.trailFormComponent.patchFormValues(this.trail);
      this.mainForm.addControl('trail', this.trailFormComponent.trailFormGroup);

      this.eventFormComponent.patchFormValues(this.event);
      this.mainForm.addControl('event', this.eventFormComponent.eventFormGroup);
    });
  }

  watchEmail(email: string) {
    if (!email) return false;
    else if (!email.trim()) return false;
    else if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(email.trim())) return false;
    else return true;
  }

  goToForm() {
    this.formActivated = true;
    this.patchValues();
  }

  onOkClick() {
    const dialogResult = new DialogResult();
    this.loading$.next(true);

    if (this.mainForm.value.trail.organizer_email) {
      this.mainForm.value.trail.organizer_email = this.mainForm.value.trail.organizer_email.trim();
    }

    if (this.mainForm.value.trail.website && this.mainForm.value.trail.website !== '') {
      let website = this.mainForm.value.trail.website.trim();
      if (website.includes('fbclid')) {
        website = website.split('fbclid')[0].slice(0, -1);
        this.mainForm.value.trail.website = website;
      }
    }

    if (this.mainForm.value.event.rules_url && this.mainForm.value.event.rules_url !== '') {
      let rulesUrl = this.mainForm.value.event.rules_url.trim();
      if (rulesUrl.includes('fbclid')) {
        rulesUrl = rulesUrl.split('fbclid')[0].slice(0, -1);
        this.mainForm.value.event.rules_url = rulesUrl;
      }
    }

    const data = {
      message: this.mainForm.value.message,
      newData: this.mainForm.value,
      oldData: { trail: this.trail, event: this.event },
    };

    this.eventService.updateTrailData(data).subscribe({
      complete: () => {
        dialogResult.status = DialogResultStatus.Ok;
        this.store.dispatch(new LoadTrailForAlias(this.trail.alias));
        this.loading$.next(false);
        const oldDate = formatDate(new Date(data.oldData.event.date * 1000 + 12 * 60 * 60 * 1000));
        const oldEndDate = formatDate(new Date(data.oldData.event.end_date * 1000 + 12 * 60 * 60 * 1000));
        const newDate = formatDate(new Date(data.newData.event.date));
        const newEndDate = formatDate(new Date(data.newData.event.end_date));
        if ((oldDate !== newDate || oldEndDate !== newEndDate) && (oldDate !== oldEndDate || newDate !== newEndDate)) {
          this.datesWarning = true;
        } else {
          this.dialogRef.close(dialogResult);
        }
      },
      error: err => {
        alert('error : ' + JSON.stringify(err));
        this.loading$.next(false);
      },
    });
  }

  onCancelClick() {
    const dialogResult = new DialogResult();

    dialogResult.status = DialogResultStatus.Cancel;

    this.dialogRef.close(dialogResult);
  }

  close() {
    this.dialogRef.close();
  }
}
