<div
  *ngIf="mode === 'line'"
  class="last-result-container line bt-shadowed"
  fxLayout="row wrap"
  fxLayoutAlign="start start"
>
  <div
    class="image"
    fxFlex="25"
    fxFlex.xs="17"
    fxFlexAlign="stretch"
    [style.background-image]="event?.trail?.photo?.small_image | sanitizeStyleUrl"
  ></div>
  <div class="date-container">
    <div class="date">
      <bux-date-card [date]="event.date" [endDate]="event.end_date" [verticalDates]="true"></bux-date-card>
    </div>
  </div>
  <div class="result-info-container" fxFlex="70" fxFlex.xs="77">
    <div class="title">
      <country-flag class="flag" [country]="event.country" [size]="18"  class="relative mr-2"></country-flag>
      <h4 [class.canceled]="event.canceled == 1">{{ eventName }} {{ event?.date * 1000 | date: 'yyyy' }}</h4>
    </div>
    <div class="encoder">
      <ng-container *ngIf="event?.encoders && event?.encoders.length > 0">
        {{ 'ENCODED_BY' | transloco }}
        <ng-container *ngFor="let encoder of event?.encoders">
          <ng-container *ngIf="encoder">
            <a>
              <!--[routerLink]="['/', 'runner', encoder?.alias, 'overview']"-->
              {{ encoder?.nickname ? encoder?.nickname : encoder?.firstname ? encoder?.firstname :
              encoder?.display_title }}
              <mat-icon>security</mat-icon>
            </a>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="encodingDate && encodingTime">
          {{ 'ON' | transloco }} {{ encodingDate }} {{ 'AT' | transloco }} {{ encodingTime }}
        </ng-container>
      </ng-container>
    </div>
    <div class="races">
      {{ 'AVAILABLE_RESULTS' | transloco }} :
      <ng-container *ngFor="let race of encodedRaces; let i= index" class="race">
            <span *ngIf="i < 8">
              <a [routerLink]="['/', 'race', event?.trail?.alias, event?.alias, race?.alias]">{{ race.distance }}km</a>
            </span>
      </ng-container>
      <span *ngIf="encodedRaces.length > 8">...</span>
      </div>

      <div class="link">
        <a
          mat-button
          [routerLink]="['/', 'race', event.trail.alias, event.alias]"
          class="button"
          title="{{ 'RESULTS' | transloco }} {{ event?.trail?.title }}  {{ event?.date * 1000 | date: 'yyyy' }}"
          >{{ 'ALL_RANKINGS' | transloco }}</a
        >
      </div>
    </div>
  </div>

  <div *ngIf="mode === 'block'" class="last-result-container block bt-shadowed bt-rounded">
    <div class="header">
      <div class="image" [style.background-image]="event?.trail?.photo?.small_image | sanitizeStyleUrl"></div>
    </div>
    <div class="content" fxLayout="column" fxLayoutAlign="center start">
      <div class="date-container">
        <div class="date">
          <bux-date-card
            [date]="event.date"
            [endDate]="event.end_date"
            [verticalDates]="false"
            [forceHorizontal]="true"
          ></bux-date-card>
        </div>
      </div>
      <div class="result-info-container">
        <div class="title">
          <h4 [class.canceled]="event.canceled == 1">{{ eventName }} {{ event?.date * 1000 | date: 'yyyy' }}</h4>
          <span class="canceledInfo" *ngIf="event.canceled == 1">{{ 'CANCELED' | transloco }}</span>
        </div>
        <div class="encoder">
          <ng-container *ngIf="event?.encoders && event?.encoders.length > 0">
          {{ 'ENCODED_BY' | transloco }}
          <ng-container *ngFor="let encoder of event?.encoders">
            <ng-container *ngIf="encoder">
              <a>
                <!--[routerLink]="['/', 'runner', encoder?.alias, 'overview']"-->
                {{ encoder?.nickname ? encoder?.nickname : encoder?.firstname ? encoder?.firstname :
                encoder?.display_title }}
                <mat-icon>security</mat-icon>
              </a>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="races">
        <ng-container *ngFor="let race of event.races" class="race">
          <div *ngIf="race?.encoding_status == 4">
            <a [routerLink]="['/', 'race', event?.trail?.alias, event?.alias, race?.alias]"
              >{{ 'RESULTS' | transloco }} {{ race.distance }}km</a
            >
            <span class="official-link" *ngIf="race?.official_ranking_url">
              ·
              <a href="{{ race?.official_ranking_url }}" rel="noFollow" target="_blank"
                >{{ 'OFFICIAL_RANKING' | transloco }}</a
              >
            </span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="link">
      <a
        mat-button
        [routerLink]="['/', 'race', event.trail.alias, event.alias]"
        class="button"
        title="{{ 'RESULTS' | transloco }} {{ event?.trail?.title }}  {{ event?.date * 1000 | date: 'yyyy' }}"
        >{{ 'ALL_RANKINGS' | transloco }}</a
      >
    </div>
  </div>
</div>
