/**
 * Transforms the input string by :
 * - Converting the string to lowercase.
 * - Normalizing accented characters to their unaccented counterparts.
 * - Replacing whitespace, '-' and '&' with a specified replacement character.
 * - Removing all characters except letters and numbers.
 *
 * @param {string} input - The string to be transformed.
 * @param {string} [replacement='.'] - The character to replace whitespace, '-' and '&'. Defaults to '.'.
 * @returns {string} The transformed string.
 */
export function generateAlias(input: string, replacement: string = '.'): string {
  // Transform the input string to lowercase.
  const lowerCaseInput = input.toLowerCase();

  // Normalize the input string to decompose accented characters.
  // For example, "é" becomes "e" + "◌́" (combining diacritical mark).
  // The regex then removes the diacritical marks.
  const normalizedInput = lowerCaseInput.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  let output = '';

  // Iterate over each character in the normalized string.
  for (const char of normalizedInput) {
    // If the character is a whitespace, hyphen, or ampersand, append the replacement character.
    if (/\s/.test(char) || char === '-' || char === '&') {
      output += replacement;
    }
    // If the character is alphanumeric (a letter or a digit), append it as is.
    else if (/[a-zA-Z0-9]/.test(char)) {
      output += char;
    }
    // All other characters are ignored.
  }

  return output;
}
