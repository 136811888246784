<section *ngIf="raceForm" class="race-form-dialog">
  <header class="race-form-title">
    <h2>{{ data.race?.title || ((data.multiple ? 'CREATE_MULTIPLE_LOOPS' : 'CREATE_NEW_RACE') | transloco) }}</h2>
  </header>
  <ng-container *ngIf="isLogged$ | async; else pleaseLogin">
    <form *ngIf="formActivated; else warning" [formGroup]="raceForm">
      <div class="form-container" [class.loading]="loading$ | async">
        <app-bux-race-form
          [formGroup]="raceForm"
          [datesInfos]="datesInfos"
          [verified]="data.race && +data.race.verification_status === 4"
          [multiple]="data.multiple"
        >
        </app-bux-race-form>

        <div class="spinner-container" *ngIf="loading$ | async">
          <mat-spinner strokeWidth="3" class="spinner-indicator"></mat-spinner>
        </div>
      </div>
      <div class="actions-container">
        <div class="textarea-label" [class.red]="!raceForm.get('message').valid">{{ 'MODO_MESSAGE' | transloco }}</div>
        <textarea
          class="moderator-message"
          rows="2"
          matInput
          appearance="outline"
          formControlName="message"
          [placeholder]="'UPDATE_INFO_WHY' | transloco"
        ></textarea>
        <div
          style="display: flex; padding-top: 1em"
          [style.justify-content]="data.race && ((isAdmin$ | async) || (isEncoder$ | async) || (isOrganizer$ | async)) ? 'space-between' : 'flex-end'"
        >
          <button
            *ngIf="data.race && ((isAdmin$ | async) || (isEncoder$ | async) || (isOrganizer$ | async))"
            mat-button
            color="warn"
            (click)="removeDistance(data.race?.hasResults || data.race?.results?.length > 0)"
          >
            {{ 'DELETE_THIS_DISTANCE' | transloco }}
          </button>
          <div>
            <button mat-button [mat-dialog-close]="null">{{ 'CANCEL' | transloco }}</button>
            <button
              class="confirm-button"
              mat-raised-button
              [disabled]="(loading$ | async) || !raceForm.valid || !raceForm.dirty"
              (click)="saveDistance()"
            >
              {{ ((loading$ | async) ? 'SAVING' : 'SAVE') | transloco }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-template #pleaseLogin>
    <div class="message">
      <div class="login-msg">{{ 'PLEASE_LOGIN_FEATURE' | transloco }}</div>
      <div class="message-btn-container">
        <button mat-raised-button [mat-dialog-close]="null">{{ 'CLOSE' | transloco }}</button>
      </div>
    </div>
  </ng-template>

  <ng-template #warning>
    <div class="message">
      <div innerHTML="{{ 'FORMS_WARNING' | transloco }}"></div>
      <div class="message-btn-container">
        <button mat-raised-button color="primary" (click)="formActivated = true">{{ 'UNDERSTOOD' | transloco }}</button>
      </div>
    </div>
  </ng-template>
</section>
