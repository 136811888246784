import { Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
//import { EventService } from '@betrail-libs/trail-data-state';

@Pipe({
  name: 'toDrupalImageUrl',
})
export class ToDrupalImageUrlPipe implements PipeTransform {
  defaultImage = '';
  constructor( private sanitizer: DomSanitizer) {}
  transform(value: any, args?: any): any {
    return of('avatar.png');
    //return this.eventService.getImageStyleUrl(value || 4023, 'avatar').pipe(
    //  catchError(err => of(this.defaultImage)),
    //  map(url => this.sanitizer.bypassSecurityTrustUrl(url)),
    //);
  }
}
