<div *ngIf="type == 'button'">
  <button mat-button class="button {{ style }} {{ color }} {{ size }}">
    <ng-content></ng-content>
  </button>
</div>

<div *ngIf="type == 'link'">
  <a [routerLink]="href" mat-button class="button {{ style }} {{ color }} {{ size }}">
    <ng-content></ng-content>
  </a>
</div>
