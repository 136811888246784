import { Pipe, PipeTransform } from '@angular/core';
import { ERaceTimingType, ERaceType, IRace } from '@betrail-libs/shared/interfaces/race.model';

@Pipe({
  name: 'isRaceWithoutPodium',
})
export class IsRaceWithoutPodiumPipe implements PipeTransform {
  transform(value?: IRace): boolean {
    if (value) {
      return value.race_type === ERaceType.BACKYARD || value.race_type === ERaceType.RELAY || value.stopped === 1;
    }
    return true;
  }
}
