import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthStateService } from '@betrail-libs/auth-state';
import { EUserRole } from '@betrail-libs/shared/interfaces/interfaces';
import { IRunner, IRunnerEdition } from '@betrail-libs/shared/interfaces/runner.model';
import { DEFAULT_WELCOME_STEPS } from '@betrail-libs/shared/utils';
import { EventService, RunnerStateService } from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss'],
})
export class WelcomeDialogComponent implements OnInit {
  #authState = inject(AuthStateService);
  #runnerState = inject(RunnerStateService);
  #dialogRef = inject(MatDialogRef<WelcomeDialogComponent>);
  #fb = inject(FormBuilder);

  runnersToCheck: any[];
  newAccountValues: any = { place: '', birthdate: '', gender: '', firstname: '', lastname: '' };
  fullscreen = false;
  saveButtonDisabled: boolean;
  saveText: string = 'SAVE';
  welcomeSteps = DEFAULT_WELCOME_STEPS;
  welcomeForm?: FormGroup;
  welcomeStep: string = 'welcome';
  doneSteps: number = 0;
  error: string;
  isCreateAccount = false;
  isWelcome = false;
  formType: string = 'waiting';
  _runner: any;
  isLightUser = false;


  isLoadingUser$ = this.#authState.isLoading();
  user$ = this.#authState.getUser();

  @Input()
  set runner(runner: IRunner) {
    this.fillWelcomeForm(runner);
    this._runner = runner;
  }
  @Output() action = new EventEmitter();

  ngOnInit() {
    this.user$.pipe(untilDestroyed(this)).subscribe({
      next: u => {
        this.isLightUser = u?.roles.filter(role => role.rid === EUserRole.LightUser).length > 0;
        if (this.isLightUser) {
          delete this.welcomeSteps['resultsClaimingTuto'];
        }
        if (u && u.uid && +u.uid > 0) {
          if (this.formType != 'welcome') {
            this.formType = 'loggedIn';
            this.close();
          }
        } else {
          this.error = '';
        }
      },
      error: () => {
        this.error = 'ERROR_PLEASE_TRY_AGAIN';
      },
    });
  }

  getRunner() {
    return this._runner;
  }

  setWelcomeStatus(status) {
    if (status === 'done') {
      this.close();
    }
  }

  fillWelcomeForm(runner: IRunner) {
    this.welcomeForm = this.#fb.group({
      place: [runner && runner.place ? runner.place : this.newAccountValues.place || '', Validators.required],
      postal_code: [runner && runner.postal_code ? runner.postal_code : '', Validators.required],
      country: [runner && runner.country ? runner.country : '', Validators.required],
      nationality: [runner && runner.nationality ? runner.nationality : '', Validators.required],
      gender: [
        runner && runner.gender
          ? +runner.gender === 1
            ? '1'
            : '0'
          : this.newAccountValues.gender && this.newAccountValues.gender == 'FEMALE'
          ? '1'
          : '0',
        Validators.required,
      ],
      birthdate: [
        this.newAccountValues.birthdate
          ? this.newAccountValues.birthdate
          : runner && runner.birthdate
          ? runner.birthdate * 1000
          : '',
        Validators.required,
      ],
    });
  }

  nextStep(step: string = this.welcomeStep) {
    this.doneSteps++;
    if (this.welcomeSteps[step] && this.welcomeSteps[step].next !== false) {
      if (this.welcomeSteps[this.welcomeSteps[step].next].display === true) {
        this.welcomeStep = this.welcomeSteps[step].next;
        if (this.welcomeStep == 'done') {
          this.#dialogRef.disableClose = false;
        }
      } else {
        this.nextStep(this.welcomeSteps[step].next);
      }
    } else {
      this.close();
    }
  }

  submitWelcome() {
    if (this.welcomeForm.valid) {
      this.disableButton('save');
      let runner: IRunnerEdition = { ...this.welcomeForm.value };

      this.#runnerState
        .updateRunner(this._runner.nid || this._runner.id, { runner })
        .pipe(untilDestroyed(this))
        .subscribe({
          error: err => {
            alert(JSON.stringify(err));
          },
          complete: () => {
            this.nextStep();
          },
        });

      /* this.eventService
        .updateRunner(this._runner.nid, runner)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.nextStep();
        }); */
    }
  }

  enableButton(type: String) {
    if (type === 'save') {
      this.saveButtonDisabled = false;
      this.saveText = 'SAVE';
    }
  }

  disableButton(type: String) {
    if (type === 'save') {
      this.saveButtonDisabled = true;
      this.saveText = 'SAVING';
    }
  }

  close() {
    this.#dialogRef.close();
  }
}
