import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-range-slider',
  template: `
    <div class="flex items-center justify-between">
      <mat-form-field appearance="outline" class="w-36" [color]="color">
        <mat-label>Min</mat-label>
        <input matInput type="number" min="0" [max]="maxValue" [(ngModel)]="minSelected" (change)="emitValueChange()" />
      </mat-form-field>

      <mat-icon>double_arrow</mat-icon>

      <mat-form-field appearance="outline" class="w-36" [color]="color">
        <mat-label>Max</mat-label>
        <input matInput type="number" min="0" [max]="maxValue" [(ngModel)]="maxSelected" (change)="emitValueChange()" />
      </mat-form-field>
    </div>
  `,
})
export class CustomRangeSliderComponent {
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';
  @Input() maxValue = 250;
  @Input() set values(values: number[]) {
    if (values && values.length === 2) {
      this.minSelected = values[0];
      this.maxSelected = values[1];
    }
  }
  @Output() valueChange = new EventEmitter<number[]>();

  minSelected = 0;
  maxSelected = this.maxValue;

  emitValueChange() {
    this.valueChange.emit([this.minSelected, this.maxSelected]);
  }
}
