import { IRoute as SignupRoute } from '../../types';
import { IEvent } from './event.model';
import { IGameEvent } from './interfaces';
import { IPath } from './path.model';
import { IResult } from './result.model';

export interface IRace {
  id: number;
  title: string;
  date?: number;
  uid: number;
  status?: number; // always = 1
  encoding_status?: number; // TODO : create an enum for this
  description?: string;
  comment?: string;
  betrail_index: number;
  betrail_registration?: number;
  betrail_registration_system?: string;
  verification_status?: ERaceVerificationStatus;
  night?: 0 | 1;
  registration_start_date?: number;
  registration_end_date?: number;
  departure_time?: number;
  race_name?: string;
  distance: number;
  flat_distance?: number;
  elevation?: number;
  elevation_km?: number;
  length?: number;
  intensity?: number;
  offroad?: number;
  trail?: number;
  trailcup_only?: 0 | 1;
  offroad_certified?: number;
  top_level?: number;
  refreshments?: number;
  category?: ERaceCategory;
  route_type?: string | number; // 0 = linear, 1 = loop, 2 or more = multiple loops
  race_type?: ERaceType;
  timing_type?: ERaceTimingType;
  time_barrier?: number | null; // = timer barrier in seconds, will be converted to HH:mm string in the form
  utmb_points?: number;
  max_runners?: number;
  max_altitude?: number;
  min_altitude?: number;
  price?: number;
  onsite_price?: number;
  country?: string;
  alias?: string;
  short_message?: string;
  results?: IResult[];
  podium?: {
    woman: IResult[];
    man: IResult[];
  };
  evid: number;
  event?: IEvent;
  unvalid?: boolean;
  resultsLoaded?: number;
  updated?: number;
  signupData?: SignupRoute;
  official_ranking_url?: string;
  paths?: IPath[];
  weather_data?: string;
  permissions?: any;
  canceled?: 0 | 1;
  stopped?: 0 | 1;
  white?: 0 | 1;
  urban?: 0 | 1;
  has_children?: 0 | 1;
  child_order?: number | null;
  parent_raid?: number | null;
  hasGpx?: boolean;
  hasPastGpx?: boolean;
  hasResults?: boolean;
  finishers?: number;
  game_events?: IGameEvent[];
  estimatedTime?: number;
}

export enum ERaceCategory {
  OTHER = 'other',
  NEARLY_CRITERIA = 'nearly_criteria',
  NATURE = 'nature',
  START_TO_TRAIL = 'start_to_trail',
  NATURE_XL = 'nature_xl',
  TRAIL = 'trail',
  LABEL = 'label',
}

/**
 * @deprecated
 * @use ERaceCategory
 */
export enum ERaceCategoryOld {
  REFUSED = '0',
  OTHERS = '1',
  NATURE_XL = '2',
  START_TO_TRAIL = '3',
  TRAIL = '4',
  LABEL = '5',
  UNKNOWN = '6',
  NATURE = '7',
}

export enum ERaceTimingType {
  CHIPS = 'puces',
  GPS = 'suivi_gps',
  MANUAL = 'manuel',
  NONE = 'non',
  NO_RESULT = 'no_result',
  TRACKING = 'suivi',
}

export enum ERaceType {
  SOLO = 'solo',
  DUO = 'duo',
  RELAY = 'relay',
  BACKYARD = 'backyard',
  H_24 = '24h',
  H_12 = '12h',
  H_10 = '10h',
  H_6 = '6h',
  MULTISTAGE = 'multistage',
  MULTISTAGE_DUO = 'multistage_duo',
  MULTISTAGE_TEAM = 'multistage_team',
  TEAM_3 = 'team_3',
  TEAM_4_OR_MORE = 'team_4_or_more',
  WALK = 'walk',
  NORDIC_WALK = 'nordic_walking',
  CANICROSS = 'canicross',
  ORIENTEERING = 'orienteering',
  KIDS_RUN = 'kids_run',
  OTHER = 'other',
}

/**
 * @deprecated
 * @use ERaceType
 */
export enum ERaceTypeOld {
  SOLO = 'solo',
  RELAY = 'relay',
  CLOCK = 'clock',
  WALK = 'walk',
  NORDIC_WALK = 'nordic_walking',
  CANICROSS = 'canicross',
  STOPPED = 'stop',
  TEAM_2 = 'team_2',
  TEAM_3 = 'team_3',
  TEAM_4 = 'team_4',
  TEAM_5 = 'team_5',
  MULTISTAGE = 'multistage',
  MULTISTAGE_DUO = 'multistage_duo',
  MULTISTAGE_TEAM = 'multistage_team',
  DRINK_TICKETS = 'drink_tickets',
  MEAL = 'meal',
  T_SHIRT = 'tshirt',
}

export enum ERaceVerificationStatus {
  REFUSED = 0,
  PENDING = 1,
  TEMPORARILY_ACCEPTED = 2,
  ACCEPTED = 3,
  ACCEPTED_AND_VERIFIED = 4,
}
