import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { APP_CONFIG } from '@betrail-libs/auth-state';
import { IAppConfig } from '@betrail-libs/shared/interfaces/app-config.model';
import { IRace } from '@betrail-libs/shared/interfaces/race.model';
import { IRegistration } from '@betrail-libs/shared/interfaces/registration.model';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RegistrationApiService {
  #config = inject<IAppConfig>(APP_CONFIG);
  #http = inject(HttpClient);

  // * REGISTRATION = races or events subscribed by the user for his agenda (NOT signup)

  addRunnerRegistration = (race: IRace) =>
    this.#http.post<{ body: IRegistration }>(`${this.#config.nodejsApi}/registration/add`, { race: race }).pipe(map(r => r));

  cancelRunnerRegistration = (race: IRace) =>
    this.#http.post<any>(`${this.#config.nodejsApi}/registration/cancel`, { race: race }).pipe(map(r => r));

  getUserRunnerRegistrations = () =>
    this.#http.get<any>(`${this.#config.nodejsApi}/registration/runner/current`).pipe(map(r => r));

  getRunnerRegistrations = (ruid: number) =>
    this.#http
      .get<any>(`${this.#config.nodejsApi}/registration/runner/${ruid}`)
      .pipe(map(r => r.body))
      .pipe(map(reg => reg.sort((a, b) => a.race.date - b.race.date).filter(r => !!r.race)));

  getRaceRegistrations = (raid: number, offset = 0) =>
    this.#http.get<any>(`${this.#config.nodejsApi}/registration/race/${raid}/${offset}`).pipe(map(r => r.body));

  getEventRegistrations = (evid: number, offset = 0) =>
    this.#http.get<any>(`${this.#config.nodejsApi}/registration/event/${evid}/${offset}`).pipe(map(r => r.body));
}
