<div
  class="bt-simple-card bt-shadowed bt-rounded {{ classes }}"
  ngClass.gt-xs="large"
  [class.height100]="height && height == '100'"
>
  <ng-content select="[card-ribbon]"></ng-content>

  <div class="card-header" *ngIf="header === true">
    <h3>
      <ng-content select="[card-title]"></ng-content>
    </h3>
  </div>
  <div class="card-content">
    <ng-content select="[card-content]"></ng-content>
    <div class="card-footer">
      <ng-content select="[card-footer]"></ng-content>
    </div>
  </div>
</div>
