<div
  class="next-registration-event-block bt-rounded bt-shadowed"
  [routerLink]="['/', 'race', event?.trail?.alias, event?.alias, 'registration']"
>
  <div class="image" [style.background-image]="event?.trail?.photo?.medium_image | sanitizeStyleUrl">
    <div class="title">
      <h3><span class="date">{{ event?.date * 1000 | date: 'dd/MM' }}</span> {{ event?.trail?.title }}</h3>
    </div>
    <a mat-button>{{ 'I_REGISTER' | transloco }}</a>
  </div>
</div>
