import { Pipe, PipeTransform } from '@angular/core';
import { ERaceCategory, ERaceTimingType, ERaceType, IRace } from '@betrail-libs/shared/interfaces/race.model';

@Pipe({
  name: 'isRaceOutOfCriteria',
})
export class IsRaceOutOfCriteriaPipe implements PipeTransform {
  transform(value?: IRace): boolean {
    if (value) {
      return (
        value.category === ERaceCategory.OTHER ||
        value.category === ERaceCategory.NEARLY_CRITERIA ||
        value.timing_type === ERaceTimingType.NONE ||
        value.timing_type === ERaceTimingType.NO_RESULT ||
        value.race_type === ERaceType.WALK ||
        value.race_type === ERaceType.NORDIC_WALK ||
        value.race_type === ERaceType.CANICROSS
      );
    }
    return true;
  }
}
