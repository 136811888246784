<div class="quickFilters">
  <strong>{{ 'FILTER_QUICK_FILTERS' | transloco }}</strong>
  <ng-container *ngFor="let quickFilter of quickFilters">
    <span
      class="quickFilter"
      (click)="activeQuickFilter(quickFilter.values, quickFilter.title)"
      [class.active]="quickFilter.values[0] === (values | async)[0] && quickFilter.values[1] === (values | async)[1]"
      >{{ quickFilter.title | transloco }}</span
    >
  </ng-container>
</div>
<div class="fullFilter">
  <strong class="strong">{{ 'FILTER_CUSTOMIZE_FILTER' | transloco }}</strong>
  <em *ngIf="description && description !== ''">{{ description | transloco }}</em>
  <div class="slider-container">
    <app-custom-range-slider
      [maxValue]="sliderMaxVal"
      [values]="values | async"
      (valueChange)="changeValues($event)"
    ></app-custom-range-slider>
  </div>
</div>
