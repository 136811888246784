import { inject, Injectable } from '@angular/core';
import { ALL_CALENDAR_COUNTRIES, ALL_COUNTRIES_CODES, COVERED_COUNTRIES } from '@betrail-libs/shared/utils';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import produce from 'immer';
import { of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { TimerApiService } from './timer-api.service';
import {
  CreateNewTimer,
  LoadAllTimers,
  LoadTimerByAlias,
  LoadTimerById,
  SetDisplayMode,
  SetSelectedCountries,
  UpdateTimer,
} from './timers.action';
import { TimersModel } from './timers.model';

@State<TimersModel>({
  name: 'timers',
  defaults: {
    timers: {},
    selectedTimer: undefined,
    selectedCountries: ALL_CALENDAR_COUNTRIES,
    displayMode: 'list',
  },
})
@Injectable()
export class TimersState {
  #timerApi = inject(TimerApiService);

  @Selector()
  static allTimers(state: TimersModel) {
    return Object.values(state.timers).sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
  }

  @Selector()
  static filteredTimers(state: TimersModel) {
    return Object.values(state.timers)
      .filter(t => state.selectedCountries.indexOf(t.country) > -1)
      .sort((a, b) => {
        if (a.title.toUpperCase() < b.title.toUpperCase()) {
          return -1;
        }
        if (a.title.toUpperCase() > b.title.toUpperCase()) {
          return 1;
        }
        return 0;
      });
  }

  @Selector()
  static timer(state: TimersModel) {
    return (timerAlias: string) => {
      return state.timers[timerAlias];
    };
  }

  @Selector()
  static displayMode(state: TimersModel) {
    return state.displayMode;
  }

  @Selector()
  static selectedCountry(state: TimersModel) {
    const countries = state.selectedCountries;
    if (countries.length == 1) {
      return countries[0];
    } else if (countries.length === ALL_COUNTRIES_CODES.length) {
      return '';
    } else if (countries.length === ALL_COUNTRIES_CODES.filter(c => !COVERED_COUNTRIES.includes(c)).length) {
      return 'OTHERS';
    }
    return '';
  }

  @Selector()
  static selectedCountries(state: TimersModel) {
    return state.selectedCountries;
  }

  @Selector()
  static selectedTimer(state: TimersModel) {
    if (state.selectedTimer) {
      return state.timers[state.selectedTimer];
    }
  }

  @Action(LoadAllTimers)
  loadAllTimers(ctx: StateContext<TimersModel>, action: LoadAllTimers) {
    const state = ctx.getState();
    if (Object.keys(state.timers).length <= 1 || action.force) {
      return this.#timerApi.getAllTimers().pipe(
        first(),
        tap(timers => {
          ctx.setState(
            produce(draft => {
              timers.map(t => (draft.timers[t.alias] = t));
            }),
          );
        }),
      );
    } else {
      return of(Object.values(state.timers));
    }
  }

  @Action(LoadTimerByAlias)
  loadTimerByAlias(ctx: StateContext<TimersModel>, action: LoadTimerByAlias) {
    const state = ctx.getState();
    const timer = state.timers[action.alias];
    if (!timer || !timer.events || action.force) {
      return this.#timerApi.getTimerByAliasOrId(action.alias).pipe(
        first(),
        tap(t => {
          ctx.setState(
            produce(draft => {
              draft.timers[action.alias] = t;
              draft.selectedTimer = t.alias;
            }),
          );
        }),
      );
    } else {
      ctx.patchState({ selectedTimer: action.alias });
      return of(state.timers[action.alias]);
    }
  }

  @Action(LoadTimerById)
  loadTimerById(ctx: StateContext<TimersModel>, action: LoadTimerById) {
    const state = ctx.getState();
    const timer = Object.values(state.timers).find(t => t.id === action.id);
    if (!timer || !timer.events || action.force) {
      return this.#timerApi.getTimerByAliasOrId(action.id).pipe(
        first(),
        tap(t => {
          ctx.setState(
            produce(draft => {
              draft.timers[t.alias] = t;
              draft.selectedTimer = t.alias;
            }),
          );
        }),
      );
    } else {
      ctx.patchState({ selectedTimer: timer.alias });
      return of(timer);
    }
  }

  @Action(SetDisplayMode)
  setDisplayMode(ctx: StateContext<TimersModel>, { mode }: SetDisplayMode) {
    ctx.setState(
      produce(draft => {
        draft.displayMode = mode;
      }),
    );
  }

  @Action(SetSelectedCountries)
  setSelectedCountries(ctx: StateContext<TimersModel>, action: SetSelectedCountries) {
    ctx.setState(
      produce(draft => {
        draft.selectedCountries = action.countries;
      }),
    );
  }

  @Action(CreateNewTimer)
  createNewTimer(ctx: StateContext<TimersModel>, action: CreateNewTimer) {
    return this.#timerApi.createNewTimer(action.timer).pipe(
      first(),
      tap(t => {
        ctx.setState(
          produce(draft => {
            draft.timers[t.alias] = t;
          }),
        );
      }),
    );
  }

  @Action(UpdateTimer)
  updateTimer(ctx: StateContext<TimersModel>, action: UpdateTimer) {
    return this.#timerApi.updateTimer(action.timer).pipe(
      first(),
      tap(t => {
        ctx.setState(
          produce(draft => {
            draft.timers[t.alias] = t;
          }),
        );
      }),
    );
  }
}
