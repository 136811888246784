import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthStateService } from '@betrail-libs/auth-state';
import { ERaceType } from '@betrail-libs/shared/interfaces/race.model';
import { ALL_COUNTRIES_CODES, VALUES_RACE_TYPE, VALUES_RUN_TYPE } from '@betrail-libs/shared/utils';
import { EventService, loadOtherResultsForRunnerId, TrailDataState } from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-add-resulr-dialog',
  templateUrl: './result-add-other-result.component.html',
  styleUrls: ['./result-add-other-result.component.scss'],
})
export class AddResultDialogComponent implements OnInit {
  @ViewChild('runtypeinput') searchElement: ElementRef;
  @Select(TrailDataState.selectSelectedRunnerId) runner$!: Observable<any>;

  /* Members  */
  isFind = false;
  isToCreate = false;
  isEventSelected = false;
  isRaceSelected = false;
  isOtherEventSelected = false;
  isOtherRaceSelected = false;
  isOtherRunType = false;
  trail: any;
  events: any;
  eventsOptions: any;
  races: any;
  racesOptions: any;
  selectedTrailTitile = '';
  selectedEvent: any;
  selectedRace: any;
  isSaved = false;
  user;
  runnerId: any;
  loading = false;

  otherResultForm: UntypedFormGroup = this.resetForm();
  isDnf = false;
  isSpec = false;
  saveButtonDisabled = false;

  raceTypeValues = VALUES_RACE_TYPE;
  countries = ALL_COUNTRIES_CODES;
  runTypes = VALUES_RUN_TYPE;
  resultTypes = [0, 1, 2, 3];
  selectedEventDate = null;

  days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  years = [];

  now: number;

  constructor(
    private fb: UntypedFormBuilder,
    private eventService: EventService,
    private store: Store,
    private authState: AuthStateService,
    private dialogRef: MatDialogRef<AddResultDialogComponent>,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    this.loadUser();
    this.getNow();
    this.getYears();
    this.runner$.subscribe(val => {
      this.runnerId = val;
    });
  }

  resetForm() {
    return this.fb.group(
      {
        country: [''],
        zipcode: [''],
        location: [''],
        run_type: ['trail'],

        day: [''],
        month: [''],
        year: [''],

        distance: [''],
        height_difference: [''],
        race_type: ['solo'],
        night_race: [false],

        result_type: [0, Validators.required],
        position: [''],
        total_finishers: [''],
        hours: [''],
        minutes: [''],
        seconds: [''],
        distance_dnf: [''],
      },
      { validators: this.validateDistance() },
    );
  }

  getYears() {
    const y = new Date().getFullYear();
    for (let i = 1920; i <= y; i++) {
      this.years.push(i);
    }
    this.years.reverse();
  }

  async loadUser() {
    await this.authState
      .getUser()
      .pipe(untilDestroyed(this))
      .subscribe(u => {
        this.user = u;
        this.otherResultForm.patchValue({
          country: u?.runner?.nationality || '',
        });
      });
  }

  onTrailChoice(v) {
    this.isEventSelected = false;
    this.isOtherEventSelected = false;
    this.isOtherRaceSelected = false;
    this.isRaceSelected = false;
    //show create event form or create race form
    if (v.add_course) {
      this.isOtherRunType = false;
      this.isToCreate = true;
      this.selectedTrailTitile = v.trail_title;
    } else {
      this.selectedTrailTitile = v.trail_title_original;
      this.isToCreate = false;
      this.isEventSelected = false;
      this.isRaceSelected = false;
    }
    if (!this.isToCreate) {
      this.eventService.getTrail(v.trail_alias).subscribe(r => {
        this.trail = r;
        this.events = r.events;
        this.eventsOptions = this.events
          .filter(el => el.date <= this.now / 1000)
          .map(event => {
            return {
              label: event.alias,
              value: event.alias,
              disabled: event.canceled && event.canceled === 1 ? true : false,
              style: event.canceled && event.canceled === 1 ? 'crossed' : '',
            };
          });
        this.eventsOptions.push({
          label: 'OTHER',
          value: 'other',
        });
        //show next when find
        this.isFind = true;
      });
    } else {
      this.isEventSelected = true;
      this.isRaceSelected = true;
      //show next when find
      this.isFind = true;
    }
  }

  sortEventsOptions(array) {
    let sortedArr;
    if (array !== undefined) {
      sortedArr = array.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
    }
    return sortedArr;
  }

  onEventChoice(v) {
    this.isEventSelected = true;
    //check if other button pressed
    if (v == 'other') {
      this.isOtherEventSelected = true;
      this.isRaceSelected = true;
      this.resultTypes = [0, 1, 2, 3];
      this.otherResultForm.patchValue({ result_type: 0 });
      this.selectedEventDate = null;
    } else {
      this.isOtherEventSelected = false;
      this.isOtherRaceSelected = false;
      this.selectedEvent = this.events.find(e => e.alias == v);
      this.selectedEventDate = this.selectedEvent.date;
      this.races = this.selectedEvent.races;
      this.racesOptions = this.races
        .map(race => {
          const res = {
            label: this.removeIdFromLabel(race.alias),
            value: race.alias,
          };
          if (race.race_type !== ERaceType.SOLO) {
            res['info'] = 'RACE_TYPE_' + race.race_type;
          }
          return res;
        })
        .sort((a, b) => b.label.split('k')[0] - a.label.split('k')[0]);
      this.racesOptions.push({
        label: 'OTHER',
        value: 'other',
      });
    }
  }

  removeIdFromLabel(label) {
    if (label.includes('-')) {
      const arr = label.split('-');
      if (arr.length === 2) {
        if (/^\d+$/.test(arr[0])) {
          return arr[1];
        } else if (/^\d+$/.test(arr[1])) {
          return arr[0];
        } else return arr[0] + '-' + arr[1];
      } else if (arr.length === 3) return arr[0] + '-' + arr[2];
    } else return label;
  }

  onRaceChoice(v) {
    if (v === 'other') {
      this.isOtherRaceSelected = true;
      this.resultTypes = [0, 1, 2, 3];
      this.otherResultForm.patchValue({ result_type: 0 });
      this.isDnf = false;
      this.isSpec = false;
    } else {
      this.isOtherRaceSelected = false;
      this.selectedRace = this.races.find(e => e.alias == v);
      this.eventService.getRaceResultsCount(this.selectedRace.id).subscribe(r => {
        if (r.race === 0) {
          this.resultTypes = [0, 1, 2, 3];
          this.otherResultForm.patchValue({ result_type: 0 });
          this.isDnf = false;
        } else {
          this.resultTypes = [1, 2, 3];
          this.isDnf = true;
        }
      });
      if (this.otherResultForm.value.result_type === 0) this.otherResultForm.patchValue({ result_type: 1 });
    }
    this.isRaceSelected = true;
  }

  onResultTypeChoice(event) {
    if (event.value == 1) this.isDnf = true;
    else this.isDnf = false;
    if (event.value == 0) this.isSpec = false;
    else this.isSpec = true;
  }

  onOtherRunTypeChoice() {
    this.isOtherRunType = true;
    setTimeout(() => {
      // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  async findGeoloc(isToCreate, trail, loc, zipcode, country) {
    if (!isToCreate) {
      return new Promise((resolve, reject) => {
        resolve({ geo_lat: trail.man_lat || trail.geo_lat, geo_lon: trail.man_lon || trail.geo_lon });
      });
    } else {
      const geoloc = { geo_lat: null, geo_lon: null };
      const payload = {
        country: country,
        zipcode: zipcode,
        location: loc,
      };
      let result;

      return new Promise((resolve, reject) => {
        this.eventService.searchMatchPlaces(payload).subscribe(
          r => {
            result = r;
            const coords = result.geolocation.split(',').map(e => +e);
            resolve({
              geo_lat: coords[0],
              geo_lon: coords[1],
            });
          },
          r => {
            resolve(geoloc);
          },
        );
      });
    }
  }

  saveOtherResult() {
    if (!this.otherResultForm.invalid && (this.isRaceSelected || this.isToCreate)) {
      this.loading = true;
      const form = { ...this.otherResultForm.value };

      let geoLoc: any = { geo_lat: null, geo_lon: null };

      this.findGeoloc(this.isToCreate, this.trail, form.location, form.zipcode, form.country).then(geolocat => {
        geoLoc = geolocat;

        let time = null;
        if (form.hours !== '' || form.minutes !== '' || form.seconds !== '') {
          time = form.hours * 3600 + form.minutes * 60 + form.seconds;
        }
        let event_date = null;
        let event_year = null;
        if (this.selectedEventDate) {
          const e = new Date(this.selectedEventDate * 1000);
          const d = e.getDate() >= 10 ? e.getDate() : '0' + e.getDate();
          const m = e.getMonth() + 1 >= 10 ? e.getMonth() + 1 : '0' + (e.getMonth() + 1);
          event_year = e.getFullYear();
          event_date = d + '/' + m;
        } else if (this.isToCreate || this.isOtherEventSelected) {
          event_year = form.year;
          const d = form.day >= 10 ? form.day : '0' + form.day;
          const m = form.month >= 10 ? form.month : '0' + form.month;
          event_date = d + '/' + m;
        }

        const otherResult = {
          uid: this.user.uid,
          ruid: this.runnerId,
          trid: this.isToCreate ? null : this.trail.id,
          evid: this.isToCreate || this.isOtherEventSelected ? null : this.selectedEvent.id,
          raid: this.isToCreate || this.isOtherEventSelected || this.isOtherRaceSelected ? null : this.selectedRace.id,
          country: form.country,
          title: this.selectedTrailTitile,
          zipcode: form.zipcode === '' ? null : form.zipcode,
          location: form.location === '' ? null : form.location,
          ev_year: event_year,
          ev_date: event_date,
          distance: this.isToCreate || this.isOtherEventSelected || this.isOtherRaceSelected ? form.distance : null,
          height_difference:
            (this.isToCreate || this.isOtherEventSelected || this.isOtherRaceSelected) && form.height_difference !== ''
              ? form.height_difference
              : null,
          race_type:
            this.isToCreate || this.isOtherEventSelected || this.isOtherRaceSelected
              ? form.race_type
              : this.selectedRace.race_type,
          night_race: form.night_race,
          result_type: form.result_type,
          position: form.position === '' ? null : form.position,
          total_finishers: form.total_finishers === '' ? null : form.total_finishers,
          result_milliseconds: time * 1000,
          result_seconds: time,
          distance_dnf: form.distance_dnf === '' ? null : form.distance_dnf,
          date: this.selectedEventDate ? this.selectedEventDate : Date.UTC(form.year, form.month - 1, form.day) / 1000,
          run_type: form.run_type,
          geo_lat: geoLoc.geo_lat,
          geo_lon: geoLoc.geo_lon,
        };
        this.eventService.createOtherResult(otherResult).subscribe(r => {
          this.isSaved = true;
          this.store.dispatch(new loadOtherResultsForRunnerId(this.runnerId));
          this.closeDialog();
        });
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getNow() {
    this.now = Date.now();
  }

  validateDistance(this: AddResultDialogComponent): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error = [];

      const resultType = control.get('result_type').value;
      const hours = control.get('hours').value;
      const minutes = control.get('minutes').value;
      const seconds = control.get('seconds').value;
      //const time = control.get('time').value;
      //const date = control.get('date').value;
      const day = control.get('day').value;
      const month = control.get('month').value;
      const year = control.get('year').value;
      const distance = control.get('distance').value;
      const zipcode = control.get('zipcode').value;
      const location = control.get('location').value;
      const position = control.get('position').value;
      const totalFinishers = control.get('total_finishers').value;
      const runType = control.get('run_type').value;
      //general fields
      if (resultType === 0 && position !== '' && totalFinishers !== '') {
        if (parseInt(position) > parseInt(totalFinishers)) error.push('positionError');
      }
      if (resultType === 0 && (hours === '' || minutes === '' || seconds === '')) error.push('timeError');
      //event fields
      if (this.isToCreate || this.isOtherEventSelected) {
        if (day === '' || month === '' || year === '') error.push('dateError');
        if (Date.UTC(year, month - 1, day) >= this.now) error.push('futurDateError');
      }
      //race fields
      if (this.isToCreate || this.isOtherRaceSelected || this.isOtherEventSelected) {
        if (distance === '') error.push('distanceError');
        else if (distance === 0) error.push('distanceZeroError');
      }
      //trail fields
      if (this.isToCreate) {
        if (zipcode === '') error.push('zipcodeError');
        if (location === '') error.push('locationError');
        if (runType === '' || runType === undefined || runType === null) error.push('runTypeError');
      }
      //distance_dnf not grater than distance
      if (control && control.get('distance') && control.get('distance_dnf')) {
        const distance = parseInt(control.get('distance').value);
        const distanceDnf = control.get('distance_dnf').value;
        if (distanceDnf !== '') {
          if (parseInt(distanceDnf) > distance) error.push('scoreError');
        }
      }

      if (control && control.get('distance_dnf')) {
        if (this.selectedRace && this.selectedRace?.distance) {
          if (this.selectedRace?.distance < parseInt(control.get('distance_dnf').value)) {
            error.push('scoreError');
          }
        }
      }

      const errors = {};
      for (let i = 0; i < error.length; i++) {
        errors[error[i]] = true;
      }
      return error.length === 0 ? null : errors;
    };
  }
}
