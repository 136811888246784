<div class="singleDate" *ngIf="endDate === date">
  <div class="date">
    <div class="weekDay">{{ date | luxonDate: 'EEE' }}</div>
    <div class="day">{{ date | luxonDate: 'dd' }}</div>
    <div class="month">{{ date | luxonDate: 'LLL' }}</div>
  </div>
</div>
<div
  class="dates"
  [class.vertical]="verticalDates"
  [class.horizontal]="!verticalDates && !forceHorizontal"
  [class.force-horizontal]="forceHorizontal"
  *ngIf="endDate !== date"
>
  <div class="date">
    <div class="weekDay">{{ date | luxonDate: 'EEE' }}</div>
    <div class="day">{{ date | luxonDate: 'dd' }}</div>
    <div class="month">{{ date | luxonDate: 'LLL' }}</div>
  </div>
  <div class="separator">
    <mat-icon class="sepicon">keyboard_arrow_right</mat-icon>
  </div>
  <div class="date last">
    <div class="weekDay">{{ endDate | luxonDate: 'EEE' }}</div>
    <div class="day">{{ endDate | luxonDate: 'dd' }}</div>
    <div class="month">{{ endDate | luxonDate: 'LLL' }}</div>
  </div>
</div>
