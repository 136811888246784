import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@betrail-libs/auth-state';
import { IAppConfig } from '@betrail-libs/shared/interfaces/app-config.model';
import { ITimer } from '@betrail-libs/shared/interfaces/interfaces';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TimerApiService {
  #config = inject<IAppConfig>(APP_CONFIG);
  #http = inject(HttpClient);

  getAllTimers = () => this.#http.get<{ body: ITimer[] }>(`${this.#config.nodejsApi}/timer/all`).pipe(map(r => r.body));

  getTimerByAliasOrId = (aliasOrId: string | number) =>
    this.#http.get<{ body: ITimer }>(`${this.#config.nodejsApi}/timer/${aliasOrId}`).pipe(map(r => r.body));

  createNewTimer = (data: Omit<ITimer, 'id'>) =>
    this.#http
      .post<{ body: ITimer }>(`${this.#config.nodejsApi}/timer`, { data }, { withCredentials: true })
      .pipe(map(r => r.body));

  updateTimer = (data: ITimer) =>
    this.#http
      .put<{ body: ITimer }>(`${this.#config.nodejsApi}/timer`, { data }, { withCredentials: true })
      .pipe(map(r => r.body));
}
