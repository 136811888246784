import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@betrail-libs/auth-state';
import { IAppConfig } from '@betrail-libs/shared/interfaces/app-config.model';
import { IRace } from '@betrail-libs/shared/interfaces/race.model';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RaceApiService {
  #config = inject<IAppConfig>(APP_CONFIG);
  #http = inject(HttpClient);

  createNewDistance = (race: Partial<IRace>) =>
    this.#http.post<{ body: IRace }>(`${this.#config.nodejsApi}/race`, { race }).pipe(map(res => res.body));

  generateBackyardRaces = (data: { baseRace: Partial<IRace>; laps: number[] }) =>
    this.#http.post<{ body: IRace[] }>(`${this.#config.nodejsApi}/race/backyard`, { ...data }).pipe(map(r => r.body));

  editRaceData = (race: IRace) =>
    this.#http.put<{ body: IRace }>(`${this.#config.nodejsApi}/race`, { race }).pipe(map(res => res.body));

  deleteRaceData = (raceId: number) => this.#http.delete(`${this.#config.nodejsApi}/race/${raceId}`);
}
