import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthStateService } from '@betrail-libs/auth-state';
import { EUserRole } from '@betrail-libs/shared/interfaces/interfaces';
import { IPath } from '@betrail-libs/shared/interfaces/path.model';
import { EventService } from '@betrail-libs/trail-data-state';
import { BetrailUxFormsService } from '@betrail/ux/betrail-ux-forms.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterState } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ButtonsToggleOption } from '../../buttons-toggle/buttons-toggle.component';

@UntilDestroy()
@Component({
  selector: 'bux-path-form',
  templateUrl: './path-form.component.html',
  styleUrls: ['./path-form.component.scss', '../common-form.scss'],
})
export class PathFormComponent implements OnInit, OnDestroy {
  forceDownload: boolean;
  forceDisplay: boolean;
  constructor(
    private betrailUxFormsService: BetrailUxFormsService,
    private eventService: EventService,
    private authState: AuthStateService,
  ) {}

  @Input() formGroup: UntypedFormGroup;
  @Input() raid;

  @Select(RouterState.url) url$: Observable<string>;

  user$ = this.authState.getUser();
  isAdmin$ = this.authState.hasRole(EUserRole.Administrator);
  isEncoder$ = this.authState.hasRole(EUserRole.Encoder);
  gpxOption$: Observable<number>;
  isPassedRace = false;

  typeOptionsAdmin = [
    {
      label: 'PATH_TYPE_REAL',
      value: 'real',
      disabled: false,
    },
    {
      label: 'PATH_TYPE_PLANNED',
      value: 'planned',
    },
    {
      label: 'PATH_TYPE_TRAINING',
      value: 'training',
    },
  ];

  typeOptionsOrga = [
    {
      label: 'PATH_TYPE_PLANNED',
      value: 'planned',
    },
    {
      label: 'PATH_TYPE_TRAINING',
      value: 'training',
    },
  ];

  displayOptions: any[] = [
    {
      label: 'PATH_DISPLAY_ALL',
      value: 'all',
    },
    {
      label: 'PATH_DISPLAY_ONLY_ELEVATION',
      value: 'elevation_only',
    },
    {
      label: 'PATH_DISPLAY_ONLY_PATH',
      value: 'path_only',
    },
    {
      label: 'PATH_DISPLAY_NOTHING',
      value: 'none',
    },
  ];

  privateSectionsOptions = [
    {
      label: 'YES',
      value: 1,
    },
    {
      label: 'NO',
      value: 0,
    },
    {
      label: 'I_DONT_KNOW',
      value: 2,
    },
  ];

  toggleOptions: ButtonsToggleOption[] = [
    {
      label: 'YES',
      value: 1,
    },
    {
      label: 'NO',
      value: 0,
    },
  ];

  ngOnInit() {
    /*

    This form ca be used in 2 cases :

    - encapsulted in form array (ex: used in add to calendar feature)
    - stand alone formGroup in a popup for example

    if it's in stand alone mode, the formGroup has to be built.
    Otherwise, it's given by the form Array via @Input

    */

    let now = Date.now();

    this.url$.pipe(untilDestroyed(this)).subscribe(u => {
      let uArr = u.split('/');
      this.getTrailData(uArr[2]);
    });

    if (this.raid) {
      let race = this.eventService.getRace(this.raid);
      race.pipe(untilDestroyed(this)).subscribe(r => {
        if (r.date) {
          if (r.date <= now / 1000) {
            this.isPassedRace = true;
          } else {
            this.typeOptionsAdmin[0].disabled = true;
          }
        }
      });
    }

    if (!this.formGroup) {
      this.formGroup = this.betrailUxFormsService.buildPathForm();
    }
  }

  getTrailData(alias) {
    if (alias) {
      let trail = this.eventService.getTrail(alias);
      trail.pipe(untilDestroyed(this)).subscribe(t => {
        this.gpxOption$ = t.gpx_option;
        this.defineGpxOptions(t.gpx_option);
      });
    }
  }

  defineGpxOptions(option) {
    switch (option) {
      case 1:
        this.formGroup.patchValue({
          downloadable: 0,
        });
        break;
      case 2:
        this.formGroup.patchValue({
          downloadable: 0,
          private_sections: 1,
        });
        break;
      case 3:
        this.formGroup.patchValue({
          downloadable: 0,
          private_sections: 1,
          zoomable: 0,
        });
        break;
      case 4:
        this.formGroup.patchValue({
          downloadable: 0,
          private_sections: 1,
          zoomable: 0,
          show_background: 0,
        });
        break;
    }
  }

  patchFormValues(path: IPath) {
    if (path && this.isPassedRace) {
      this.setForceDisplay(true);
    }

    let privateSec = this.formGroup.patchValue({
      id: path.id,
      raid: path.raid,
      uid: path.uid,
      title: path.title,
      type: path.type ? path.type : undefined,
      private_sections:
        path.private_sections !== null && path.private_sections !== undefined ? path.private_sections : 1,
      marked: !!path.marked ? path.marked : 0,
      fallback: !!path.fallback ? path.fallback : 0,
      display: path.display ? path.display : 'all',
      downloadable: path.downloadable === 0 ? 0 : 1,
      zoomable: path.zoomable === 0 ? 0 : 1,
      show_background: path.show_background === 0 ? 0 : 1,
      distance_meters: path.distance_meters / 1000,
      pos_elevation: path.pos_elevation,
      neg_elevation: path.neg_elevation,
      min_altitude: path.min_altitude,
      max_altitude: path.max_altitude,
      path: path.path,
      elevation: path.elevation,
    });

    if (path.type === 'training') {
      this.setForceDownload(true);
    }
  }

  typeChanged(value) {
    this.setForceDownload(value == 'training');
  }

  setForceDownload(value: boolean) {
    this.forceDownload = value;
    if (value === true) {
      this.formGroup.controls['downloadable'].setValue(1);
      this.formGroup.controls['downloadable'].disable();
    } else {
      this.formGroup.controls['downloadable'].enable();
    }
  }

  setForceDisplay(value: boolean) {
    this.forceDisplay = value;
    if (value === true) {
      this.formGroup.controls['display'].setValue('all');
      this.formGroup.controls['display'].disable();
    } else {
      this.formGroup.controls['display'].enable();
    }
  }

  ngOnDestroy() {}
}
