<svg class="block" [attr.viewBox]="svgViewBox" version="1.1" xmlns="http://www.w3.org/2000/svg" class="svg">
  <path
    class="block"
    [attr.fill]="appearance.color"
    [attr.fill-opacity]="appearance.opacity"
    [attr.d]="svgPath"
    stroke="transparent"
    stroke-width="0.5"
  />
</svg>
