import { Component, Input, OnInit } from '@angular/core';
import { AppParamsState } from '@betrail-libs/app-params-state';
import { EventService } from '@betrail-libs/trail-data-state';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  slides$: Observable<{ lang: string; link: string; url: string; countries: string[] }[]>;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5500,
    arrows: false,
    vertical: true,
  };
  maxSlidesPerType = {
    home: 3,
    calendar: 4,
    runner: 3,
    race: 3,
  };
  autoPromoBanner = false;
  currentLang?: string;

  constructor(private eventService: EventService, public translation: TranslocoService) {}

  @Input() type: 'calendar' | 'race' | 'perso';
  @Input() language: string;

  @Select(AppParamsState['defaultCountry']) defaultCountry$!: Observable<string>;

  ngOnInit() {
    this.currentLang = this.translation.getActiveLang();
    this.slides$ = this.eventService.getBanners(this.type);
    this.slides$.pipe(untilDestroyed(this)).subscribe(slides => {
      if (slides.length < this.maxSlidesPerType[this.type]) {
        this.autoPromoBanner = true;
      }
    });
  }
}
