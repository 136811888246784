import { Component, Input, OnInit } from '@angular/core';
import { IRace } from '@betrail-libs/shared/interfaces/race.model';

@Component({
  selector: 'last-result-block',
  templateUrl: './last-result-block.component.html',
  styleUrls: ['./last-result-block.component.scss'],
})
export class LastResultBlockComponent implements OnInit {
  eventName: string;
  encodingDate: string;
  encodingTime: string;

  private _event: any;

  encodedRaces = [] as IRace[];
  @Input()
  get event(): any {
    return this._event;
  }
  set event(value: any) {
    this._event = value;
    this.encodedRaces = value?.races.filter(r => r.encoding_status === 4) || [];
  }

  @Input() mode = 'line';
  @Input() size = 'large';

  ngOnInit() {
    this.eventName = this.limitTextLength(this.event?.trail?.title);
    this.otherFunc();
  }

  otherFunc() {
    if (this.event?.encoders?.length > 0) {
      const last = this.event.encoders.length - 1;
      this.encodingDate = this.formatToDateOrTime(this.event.encoders[last].encoding_date, 'date');
      this.encodingTime = this.formatToDateOrTime(this.event.encoders[last].encoding_date, 'time');
    }
  }

  formatToDateOrTime(timeStampInSeconds: number, type: string) {
    const date = new Date(timeStampInSeconds * 1000);
    if (type === 'date') {
      return `${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}/${
        date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
      }`;
    }
    if (type === 'time') {
      return `${date.getHours() > 9 ? date.getHours() : '0' + date.getHours()}h${
        date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
      }`;
    }
  }

  limitTextLength(text: string) {
    if (this.size === 'narrow') {
      if (text.length > 30) {
        let t = text.substring(0, 30) + '...';
        return t;
      } else return text;
    } else return text;
  }
}
