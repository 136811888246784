import { Component, OnInit, Input } from '@angular/core';
import { BetrailUxFormsService } from '../../../betrail-ux-forms.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'banner-form',
  templateUrl: './banner-form.component.html',
  styleUrls: ['./banner-form.component.scss'],
})
export class BannerFormComponent implements OnInit {
  constructor(private betrailUxFormsService: BetrailUxFormsService) {}

  @Input() formGroup: UntypedFormGroup;

  bannerOptions = [
    {
      label: 'BANNER_HOME',
      value: 'home',
    },
    {
      label: 'BANNER_RUNNER',
      value: 'runner',
    },
    {
      label: 'BANNER_RACE',
      value: 'race',
    },
    {
      label: 'BANNER_CALENDAR',
      value: 'calendar',
    },
  ];

  ngOnInit() {
    if (!this.formGroup) {
      this.formGroup = this.betrailUxFormsService.buildBannerForm();
      /* Disable these fields if the race is verified by the Betrail team : this info can no longer be modified */
    }
  }
}
