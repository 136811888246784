import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { MetaService } from '../../meta.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'bt-page',
  templateUrl: './bt-page.component.html',
  styleUrls: ['./bt-page.component.scss'],
})
export class BtPageComponent implements OnInit {
  constructor(private metaService: MetaService, public sanitizer: DomSanitizer) {}

  @Input() headerBackgroundImage: SafeResourceUrl;
  @Input() metaTitle: string;
  @Input() metaDescription: string;
  @Input() header: boolean = true;
  @Input() params: any = { top: 12, bottom: 25, titleClass: 'light', additionalClass: '' };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.metaTitle || changes.metaDescription) {
      this.setMetaTags();
    }
  }

  setMetaTags() {
    if (this.metaTitle && this.metaDescription) {
      this.metaService.setTitle(this.metaTitle);
      this.metaService.setDescription(this.metaDescription);
      this.metaService.setCanonicalURL();
      this.metaService.setLanguage();
    }
  }

  ngOnInit() {
    this.setMetaTags();
  }
}
