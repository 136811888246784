import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITimer } from '@betrail-libs/shared/interfaces/interfaces';
import { ALL_COUNTRIES_CODES, generateAlias } from '@betrail-libs/shared/utils';
import { BetrailUxFormsService } from '@betrail/ux';
import { UntilDestroy } from '@ngneat/until-destroy';

type TimerForm = {
  title: FormControl<string>;
  postal_code: FormControl<string>;
  place: FormControl<string>;
  country: FormControl<string>;
  email: FormControl<string>;
  phone: FormControl<string>;
  website: FormControl<string>;
  default_results_page: FormControl<string>;
  dont_exist: FormControl<boolean>;
};

@UntilDestroy()
@Component({
  selector: 'app-edit-timer-dialog',
  templateUrl: './edit-timer-dialog.component.html',
  styleUrls: ['./edit-timer-dialog.component.scss'],
})
export class EditTimerDialogComponent implements OnInit {
  data?: ITimer = inject<ITimer | undefined>(MAT_DIALOG_DATA);
  #dialogRef = inject<MatDialogRef<EditTimerDialogComponent, ITimer | Omit<ITimer, 'id'>>>(MatDialogRef);
  #formService = inject(BetrailUxFormsService);

  countries = ALL_COUNTRIES_CODES;
  timerForm?: FormGroup<TimerForm>;

  ngOnInit() {
    if (!this.timerForm) {
      this.timerForm = this.#formService.buildTimerForm(this.data);
    }
  }

  saveTimer() {
    const defaultData = {
      uid: 0,
      status: 1,
      authorization: '1',
    };
    const alias = this.data?.alias || generateAlias(this.timerForm.value.title, '-');
    const timer = {
      ...defaultData,
      ...this.data,
      ...this.timerForm.value,
      alias,
      dont_exist: (this.timerForm.value.dont_exist === true ? 1 : 0) as 0 | 1,
    };
    this.#dialogRef.close(timer);
  }
}
