import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatLevel',
})
export class FormatLevelPipe implements PipeTransform {
  transform(level: string): string {
    return parseFloat(level).toFixed(2);
  }
}
