import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'bux-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss'],
})
export class SocialLinksComponent implements OnInit {
  url: string;

  constructor(private route: Router, @Inject('appsConfig') private config: { [key: string]: string | boolean },) {}

  @Input() text: string;
  @Input() align: string = '';

  ngOnInit() {
    this.url = this.config.betrail + this.route.url;
  }
}
