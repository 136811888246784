<div
  *ngIf="
    current_runner &&
      current_runner?.ruid &&
      results[(current_runner?.ruid)] &&
      results[(current_runner?.ruid)].length > 0;
    else loading
  "
>
  <h2>
    {{ firstname }}, répondez à ces quelques questions pour nous aider à retrouver vos résultats dans notre base de
    données...
  </h2>
  <p>Promis, ça ne sera pas long.</p>

  <div class="question" *ngIf="results[(current_runner?.ruid)][current_results_offset]; let result">
    Avez-vous couru {{ result?.trail?.trailNamePrefix | transloco }}<em>{{ result?.trail.title }}</em> <br />
    à {{ result?.trail.place }} en
    <ng-container *ngFor="let event of result.events; let i = index"
      ><ng-container *ngIf="i > 0 && i < result.events.length - 1">, </ng-container
      ><ng-container *ngIf="result.events.length > 1 && i == result.events.length - 1"> et </ng-container> {{
      event?.date * 1000 | date: 'yyyy' }}</ng-container
    >
    ?
    <div>
      <button class="email" mat-raised-button (click)="saveAnswer(result, 'yes')">{{ 'YES' | transloco }}</button>
      <button class="email" mat-raised-button (click)="saveAnswer(result, 'no')">{{ 'NO' | transloco }}</button>
      <button class="outline" mat-button (click)="saveAnswer(result, 'dontremember')">
        {{ 'I_DONT_REMEMBER' | transloco }}
      </button>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="loading">{{ 'LOADING' | transloco }}</div>
</ng-template>
