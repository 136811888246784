import { Component, ElementRef, ViewChild } from '@angular/core';
import { ITimer } from '@betrail-libs/shared/interfaces/interfaces';
import { EventService } from '@betrail-libs/trail-data-state';

@Component({
  selector: 'app-timer-logo-block',
  templateUrl: './timer-logo-block.component.html',
  styles: [
    `
      img.timerLogoImg {
        display: block;
        max-width: 80%;
        margin: auto;
        margin-top: 1em;
      }

      .timerLogoButtons {
        margin: 1em 0;
        text-align: center;
      }

      .timerLogo {
        padding: 1em;
        background: gainsboro;
        border-radius: 7px;
        margin-bottom: 1em;
      }
    `,
  ],
})
export class TimerLogoBlockComponent {
  @ViewChild('addImageButton', { static: true }) updatedImage: any = 'Pas encore dimage chargée';

  timer: ITimer | Omit<ITimer, 'id'>;
  showErrorMessage = false;
  errorMessage = '';
  addImageButton: ElementRef;
  addImageButtonDisabled = false;

  constructor(private eventService: EventService) {}

  addImage(file: File): void {
    this.showErrorMessage = false;

    if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
      if (file.size < 2000000) {
        this.addImageButtonDisabled = true;
        this.getBase64(file).then(data => {
          try {
            this.eventService.postDrupalImage(file.name, JSON.stringify(data), {}).subscribe(r => {
              this.updatedImage = r;
              this.addImageButtonDisabled = false;
              // this.formGroup.patchValue({ logo: r.fid });
            });
          } catch (error) {
            this.addImageButtonDisabled = false;
          }
        });
      } else {
        this.showErrorMessage = true;
        this.errorMessage = 'IMAGE_MAX_SIZE_EXCEEDED_ERROR';
        //this.openDialog('Le fichier soumis est trop grand... A vrai dire, il ne peut guère dépasser les 2 Mo.', 'Aie !');
      }
    } else {
      this.showErrorMessage = true;
      this.errorMessage = 'IMAGE_INCORRECT_TYPE_ERROR';
      //this.openDialog('Bon, en fait... seules les images de type JPG, JPEG ou PNG sont autorisées.', 'Oups !');
    }
  }

  removeImage(): void {
    this.updatedImage = '';
  }

  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}
