import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOrganization, ITrail } from '@betrail-libs/shared/interfaces/interfaces';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss', '../common.scss'],
})
export class ConfirmationComponent {
  @Input() trail: ITrail;
  @Input() organization: IOrganization;

  @Output() closeClicked = new EventEmitter();

  close() {
    this.closeClicked.emit();
  }
}
