import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IResult, IOtherResult, ETags } from '@betrail-libs/shared/interfaces/result.model';
import { editResultTag } from '@betrail-libs/trail-data-state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-result-edit-tag-dialog',
  templateUrl: './result-edit-tag-dialog.component.html',
  styleUrls: ['./result-edit-tag-dialog.component.scss'],
})
export class ResultEditTagDialogComponent {
  tags = this.setData();
  selectedTag = new UntypedFormControl(this.data.result?.tag);
  trailInfo: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { result: IResult | IOtherResult; isOtherResult: boolean },
    private dialogRef: MatDialogRef<ResultEditTagDialogComponent>,
    private store: Store,
  ) {}

  setData() {
    if ('status' in this.data.result) {
      this.trailInfo = this.data.result.race.title;
    } else {
      this.trailInfo = this.data.result.title + ' - ' + this.data.result.ev_year;
    }
    const tagsArray = [];
    for (const [name, key] of Object.entries(ETags)) {
      if (key === 'W') {
        tagsArray.push({ key, name: name + ' Way' });
      } else {
        tagsArray.push({ key, name });
      }
    }
    return tagsArray;
  }

  saveTag() {
    const updatedResult = {
      ...this.data.result,
      tag: this.selectedTag.value.length === 1 ? this.selectedTag.value : null,
    };
    this.store.dispatch(new editResultTag(updatedResult, this.data.isOtherResult));
    this.dialogRef.close();
  }
}
