import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Inject, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

@Component({
  selector: 'bux-time-barrier-input',
  template: `
    <div
      class="time-barrier-input-container"
      [formGroup]="form"
      [attr.aria-labelledby]="_formField.getLabelId()"
      (focusin)="onFocusIn()"
      (focusout)="onFocusOut($event)"
    >
      <input
        matInput
        formControlName="time"
        pattern="^[0-9]{1,3}[:.,h][0-9]{2}$"
        [placeholder]="placeholder"
        (input)="handleInput($event)"
        (blur)="onBlur($event)"
        #timeBarrierInput
      />
    </div>
  `,
  providers: [{ provide: MatFormFieldControl, useExisting: TimeBarrierInputComponent }],
})
export class TimeBarrierInputComponent
  implements ControlValueAccessor, MatFormFieldControl<string | number>, OnDestroy
{
  static nextId = 0;
  @HostBinding() id = `time-barrier-input-${TimeBarrierInputComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @ViewChild('timeBarrierInput') timeBarrierInput!: HTMLElement;

  form: FormGroup<{ time: FormControl<string | null> }>;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'time-barrier-input';
  possibleSeparators = [':', '.', ',', 'h'];

  onChange!: (val: string | number | null) => void;
  onTouched!: () => void;

  // if value ends with a single digit after the separator '. or :', add a ending zero
  onBlur = (data: FocusEvent) => {
    if ((data.target as HTMLInputElement).value.match(/(\.|:)\d$/)) {
      (data.target as HTMLInputElement).value = (data.target as HTMLInputElement).value + '0';
      (data.target as HTMLInputElement).dispatchEvent(new InputEvent('input', { data: '0' }));
    }
  };

  get empty(): boolean {
    const {
      value: { time },
    } = this.form;
    return !time;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder = '';

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | number | null {
    if (this.form.valid) {
      const {
        value: { time },
      } = this.form;
      return time ? this.hmStringToSeconds('' + time) : null;
    }
    return null;
  }
  set value(time: string | number | null) {
    const _time = time ? this.secondsToHmTimeString(+time) : null;
    this.form.setValue({ time: _time });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.form.invalid && this.touched;
  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    this.form = formBuilder.group({
      time: ['', [Validators.required]],
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;

      // Adding a custom validator to the ngControl which checks the validity
      // of the custom form field component.
      const validator: ValidatorFn = (/* control: AbstractControl */): ValidationErrors | null => {
        return this.form.valid ? null : { customControl: true };
      };
      if (this.ngControl.control) {
        this.ngControl.control.setValidators([
          validator,
          ...(this.ngControl.control.validator ? [this.ngControl.control.validator] : []),
        ]);
        this.ngControl.control.updateValueAndValidity();
      }
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  onContainerClick(): void {
    this._focusMonitor.focusVia(this.timeBarrierInput, 'program');
  }

  writeValue(time: string | number | null): void {
    this.value = time;
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.time-barrier-input-container');
    if (controlElement) {
      controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
  }

  handleInput(_event: Event): void {
    this.onChange(this.value);
  }

  secondsToHmTimeString(seconds: number): string {
    const minutes = Math.floor((seconds / 60) % 60);
    const hours = Math.floor((seconds / (60 * 60)) % 24);

    const minutesString = minutes.toString().padStart(2, '0');

    return hours + ':' + minutesString;
  }

  hmStringToSeconds(timeString: string): number {
    const parts = timeString.split(/[:.,h]/);
    const hours = +parts[0];
    const minutes = +parts[1];

    return hours * 3600 + minutes * 60;
  }
}
