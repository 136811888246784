import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { AuthStateService } from './services/auth-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[hasRunner]',
})
export class HasRunnerDirective implements OnDestroy {
  isShow = false;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private state: AuthStateService,
  ) {
    this.state
      .getUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        if (user?.runner?.id > 0) {
          if (!this.isShow) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.isShow = true;
          }
        } else {
          this.viewContainer.clear();
          this.isShow = false;
        }
      });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
