<section *ngIf="timerForm" class="timer-form-dialog">
  <header class="timer-title">
    <h2>{{ data?.title || ('NEW_TIMER' | transloco) }}</h2>
  </header>
  <form [formGroup]="timerForm">
    <div class="form-container">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'TIMER_NAME' | transloco }}</mat-label>
        <input matInput [placeholder]="'NAME_OF_THE_TIMER' | transloco" formControlName="title" required />
        <mat-icon class="suffixIcon" matSuffix>timer</mat-icon>
      </mat-form-field>

      <!-- TODO : edit this component to allow logo upload / replacement -->
      <!-- ! the logo should be required => to upload and save, then save the id in the form (field 'logo') -->
      <!-- * don't forget to delete it if the opération is canceled -->
      <app-timer-logo-block></app-timer-logo-block>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'TIMER_POSTAL_CODE' | transloco }}</mat-label>
        <input matInput formControlName="postal_code" required />
        <mat-icon class="suffixIcon" matSuffix>mail</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'TIMER_PLACE' | transloco }}</mat-label>
        <input matInput formControlName="place" required />
        <mat-icon class="suffixIcon" matSuffix>place</mat-icon>
        <mat-error *ngIf="timerForm?.get('place')?.errors?.['pattern']" class="form-error">
          {{ 'ERROR_NUMBER_LOCALITY' | transloco }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'COUNTRY' | transloco }}</mat-label>
        <mat-select formControlName="country" required>
          <mat-option *ngFor="let country of countries" [value]="country"
            >{{ country }} - {{ 'COUNTRY_' + country | transloco }}</mat-option
          >
        </mat-select>
        <mat-icon class="suffixIcon" matSuffix>flag</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'TIMER_EMAIL' | transloco }}</mat-label>
        <input matInput trimInput formControlName="email" />
        <mat-icon class="suffixIcon" matSuffix>alternate_email</mat-icon>
        <mat-error *ngIf="timerForm?.get('email')?.errors?.['pattern']" class="form-error">
          {{ 'ERROR_EMAIL_NOT_VALID' | transloco }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'TIMER_PHONE' | transloco }}</mat-label>
        <input matInput formControlName="phone" />
        <mat-icon class="suffixIcon" matSuffix>phone</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'TIMER_WEBSITE' | transloco }}</mat-label>
        <input matInput formControlName="website" required />
        <mat-icon class="suffixIcon" matSuffix>language</mat-icon>
        <mat-error *ngIf="timerForm?.get('website')?.errors?.['pattern']" class="form-error">
          {{ 'ERROR_LINK_NOT_VALID' | transloco }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'TIMER_DEFAULT_RESULTS_PAGE' | transloco }}</mat-label>
        <input matInput formControlName="default_results_page" />
        <mat-icon class="suffixIcon" matSuffix>format_list_numbered</mat-icon>
        <mat-error *ngIf="timerForm?.get('default_results_page')?.errors?.['pattern']" class="form-error">
          {{ 'ERROR_LINK_NOT_VALID' | transloco }}
        </mat-error>
      </mat-form-field>

      <mat-slide-toggle color="primary" formControlName="dont_exist"
        >{{ 'TIMER_DONT_EXIST' | transloco }}</mat-slide-toggle
      >
    </div>
  </form>
  <footer class="actions-container">
    <button mat-button [mat-dialog-close]="null">{{ 'CANCEL' | transloco }}</button>
    <button class="confirm-button" mat-raised-button [disabled]="!timerForm.valid" (click)="saveTimer()">
      {{ 'SAVE' | transloco }}
    </button>
  </footer>
</section>
