import { Component, Input } from '@angular/core';
import { IPath } from '@betrail-libs/shared/interfaces/path.model';
import { geodistance } from '@betrail-libs/shared/utils';

@Component({
  selector: 'profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.scss'],
})
export class ProfilePreviewComponent {
  smoothing = 0.15; // smoothing ration for the bezier curve
  svgViewBox?: string;
  svgPath?: string;

  @Input() viewBoxOptions = { width: 200, height: 50 };
  @Input() appearance = { color: '#000000', opacity: 0.2 };
  @Input('path') set path(path: IPath | undefined) {
    if (path && path.path && path.path.length > 0) {
      this.svgViewBox = `0 0 ${this.viewBoxOptions.width} ${this.viewBoxOptions.height}`;
      const graphPoints = this.getGraphPointsFromPathElevation(path.path, path.elevation, this.viewBoxOptions);
      this.createLine(graphPoints);
    }
  }

  getGraphPointsFromPathElevation(
    path: number[][],
    elevation: (number | string)[],
    options = { width: 200, height: 50 },
  ) {
    let i = 0;
    let dist = 0;
    let graphPoints: number[][] = [];

    while (i < path.length) {
      // if elevation[i] is not a number we totally skip the point because it is
      // linked to path[i], if we didn't the path / elevation would be out of sync
      if (typeof elevation[i] === 'number') {
        const elev = elevation[i] as number;
        if (path[i] && i > 0) {
          dist += geodistance(path[i - 1][0], path[i - 1][1], path[i][0], path[i][1], 'K') * 1000;
        }
        graphPoints.push([dist, elev]);
      }
      i++;
    }

    const elevationNumberOnly = elevation.filter(el => typeof el === 'number') as number[];
    let minElevation = Math.min(...elevationNumberOnly);
    let maxElevation = Math.max(...elevationNumberOnly);
    let deltaElevation = maxElevation - minElevation;
    const factorDist = options.width / dist;
    const factorEle = options.height / deltaElevation;

    graphPoints.push([dist, minElevation]);
    graphPoints.push([0, minElevation]);

    return graphPoints.map(a => [a[0] * factorDist, options.height - (a[1] - minElevation) * factorEle]);
  }

  createLine(points: number[][]) {
    this.svgPath = this.createSvgPath(points);
  }

  // Render the svg <path> element
  // I:  - points (array): points coordinates
  //     - command (function)
  //       I:  - point (array) [x,y]: current point coordinates
  //           - i (integer): index of 'point' in the array 'a'
  //           - a (array): complete array of points coordinates
  //       O:  - (string) a svg path command
  // O:  - (string): a Svg <path> element
  createSvgPath(points: number[][]) {
    // build the d attributes by looping over the points
    const d = points.reduce(
      (acc, point, i, a) => (i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${this.bezierCommand(point, i, a)}`),
      '',
    );
    return d;
  }

  // Create the bezier curve command
  // I:  - point (array) [x,y]: current point coordinates
  //     - i (integer): index of 'point' in the array 'a'
  //     - a (array): complete array of points coordinates
  // O:  - (string) 'C x2,y2 x1,y1 x,y': SVG cubic bezier C command
  bezierCommand(point: number[], i: number, a: number[][]) {
    // start control point
    const cps = this.controlPoint(a[i - 1], a[i - 2], point);

    // end control point
    const cpe = this.controlPoint(point, a[i - 1], a[i + 1], true);
    return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`;
  }

  controlPoint(current: number[], previous?: number[], next?: number[], reverse = false) {
    // When 'current' is the first or last point of the array
    // 'previous' or 'next' don't exist.
    // Replace with 'current'
    const p = previous || current;
    const n = next || current;

    // Properties of the opposed-line
    const o = this.line(p, n);

    // If is end-control-point, add PI to the angle to go backward
    const angle = o.angle + (reverse ? Math.PI : 0);
    const length = o.length * this.smoothing;

    // The control point position is relative to the current point
    const x = current[0] + Math.cos(angle) * length;
    const y = current[1] + Math.sin(angle) * length;
    return [x, y];
  }

  // Properties of a line
  // I:  - pointA (array) [x,y]: coordinates
  //     - pointB (array) [x,y]: coordinates
  // O:  - (object) { length: l, angle: a }: properties of the line
  line(pointA: number[], pointB: number[]) {
    const lengthX = pointB[0] - pointA[0];
    const lengthY = pointB[1] - pointA[1];
    return {
      length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
      angle: Math.atan2(lengthY, lengthX),
    };
  }
}
