import { Pipe, PipeTransform } from '@angular/core';
import { toHMS } from '@betrail-libs/shared/utils';

@Pipe({
  name: 'toHms',
})
export class ToHmsPipe implements PipeTransform {
  transform(value: number, format?: 'hm' | 'ms' | 'hms'): string {
    return toHMS(value, format || 'hms');
  }
}
