import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'birthdate-select',
  templateUrl: './birthdate-select.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BirthdateSelectComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => BirthdateSelectComponent), multi: true },
  ],
})
export class BirthdateSelectComponent implements ControlValueAccessor {
  days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  years = [1920];

  day?: number;
  month?: number;
  year?: number;

  date?: Date;
  isDisable = false;

  constructor() {
    let date = new Date();
    for (let i = 1920; i < date.getFullYear(); i++) {
      this.years.push(i);
    }
    this.years.reverse();
  }

  propagateChange: any = () => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  propagateTouch: any = () => {};

  registerOnTouched(fn: any): void {}

  validateFn: any = () => {};

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisable = isDisabled;
  }

  writeValue(value?: number | Date | null): void {
    let date: Date | undefined;
    if (value instanceof Date) {
      date = value;
    } else if (value) {
      date = new Date(value);
    }
    if (date) {
      this.date = date;
      this.day = date.getDate();
      this.month = date.getMonth() + 1;
      this.year = date.getFullYear();
    } else {
      this.resetDate();
    }
  }

  changeValue() {
    if (!this.date) {
      this.date = new Date(0);
      this.date.setHours(6);
    }
    if (this.year) {
      this.date.setFullYear(this.year);
    }
    if (this.month) {
      this.date.setMonth(this.month - 1);
    }
    if (this.day) {
      this.date.setDate(this.day);
    }
    this.propagateChange(this.date);
  }

  resetDate() {
    this.day = undefined;
    this.month = undefined;
    this.year = undefined;
    this.date = undefined;
    this.propagateChange(undefined);
  }
}
