import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'bux-date-card',
  templateUrl: './date-card.component.html',
  styleUrls: ['./date-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateCardComponent {
  @Input() date?: number;
  @Input() endDate?: number;
  @Input() verticalDates = false;
  @Input() forceHorizontal = false;
  @Input() currentLang = 'fr';
}
