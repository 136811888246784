<div class="fullFilter">
  <!--<strong>{{ 'FILTER_CUSTOMIZE_FILTER' | transloco }}</strong>-->
  <div fxLayout="column">
    <div>
      <strong>Localisation</strong>
      <search-place
        (placeSelected)="setPlace($event)"
        [defaultText]="summary"
        [availableCountries]="selectedCountries$ | async"
      ></search-place>
    </div>
    <div>
      <ng-container *ngFor="let quickFilter of quickFilters">
        <span
          class="quickFilter"
          (click)="activeQuickFilter(quickFilter.values, quickFilter.title)"
          [class.active]="quickFilter.values === value"
          >{{ quickFilter.title | transloco }}</span
        >
      </ng-container>
      <ng-container *ngIf="userLocation">
        <span
          class="quickFilter"
          (click)="activeQuickFilter(userLocation.coords, userLocation.place)"
          [class.active]="value === userLocation.coords"
          ><!--{{ 'FILTER_NEARBY' | transloco }}-->
          {{ userLocation.place }}</span
        >
      </ng-container>
    </div>
    <!--<mat-form-field>
        <input matInput type="number" appearance="outline" placeholder="Distance" [(ngModel)]="distance" />
      </mat-form-field>-->
  </div>
</div>

<!--<div class="quickFilters">-->
<!--<strong>{{ 'FILTER_QUICK_FILTERS' | transloco }}</strong>-->
<!--</div>-->
<div class="slider">
  <div class="slider-container">
    <b>{{ 'FILTER_GEO_RADIUS' | transloco }}</b> : {{ distance }}km
    <mat-slider
      min="1"
      max="250"
      step="1"
      (change)="setDistance($event.value)"
      [(ngModel)]="distance"
      [thumbLabel]="true"
    ></mat-slider>
  </div>
</div>
