import { Injectable } from '@angular/core';
import { AppParamsState } from '@betrail-libs/app-params-state';
import { ERankingCode, ILevelRank, IRankingParams, IScoreRank } from '@betrail-libs/shared/interfaces/interfaces';
import { RankingOptions } from '@betrail-libs/shared/interfaces/ranking-options.model';
import { EventService } from '@betrail-libs/trail-data-state';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import produce from 'immer';
import { tap } from 'rxjs';
import { RankingsApiService } from './rankings-api.service';
import {
  LoadAllRankingsOptions,
  LoadRankings,
  LoadRankingSummary,
  RemoveSponsorFromRanking,
  ResetGroupRankings,
  SetGroupParams,
  SetLoadingRanking,
  SetRankingCountry,
  SetRankingOffset,
  UpdateRankingOptions,
} from './rankings.action';
import { IRankingsStateModel } from './rankings.model';

@State<IRankingsStateModel>({
  name: 'rankings',
  defaults: {
    data: {
      bts_score: {},
      btu_score: {},
      level: {},
      tc_pts: {},
      uc_pts: {},
    },
    loading: {
      bts_score: {},
      btu_score: {},
      level: {},
      tc_pts: {},
      uc_pts: {},
    },
    offset: {
      bts_score: {},
      btu_score: {},
      level: {},
      tc_pts: {},
      uc_pts: {},
    },
    options: {},
    selectedCountry: {},
  },
})
@Injectable()
export class RankingsState {
  constructor(private api: RankingsApiService, private eventService: EventService, private store: Store) {}

  @Selector()
  static getRanking(state: IRankingsStateModel) {
    return (params: IRankingParams) => {
      const allOrGroupId =
        state.groupParams?.groupId || state.groupParams?.groupId === 0 ? '' + state.groupParams.groupId : 'all';
      if (state.data[params.ranking]?.[params.country]?.[params.type]?.[params.gender]?.[allOrGroupId]) {
        const rankingData = state.data[params.ranking][params.country][params.type][params.gender][allOrGroupId] as
          | { [rankId: number]: ILevelRank }
          | { [rankId: number]: IScoreRank };

        if (params.ranking === ERankingCode.LEVEL) {
          return (Object.values(rankingData) as ILevelRank[])
            .sort((a, b) =>
              (a.runner.display_title || a.runner.title).localeCompare(b.runner.display_title || b.runner.title),
            )
            .sort((a, b) => b.level - a.level)
            .slice(0, params.mode === 'summary' ? 10 : undefined);
        }
        return (Object.values(rankingData) as IScoreRank[])
          .sort((a, b) =>
            (a.runner.display_title || a.runner.title).localeCompare(b.runner.display_title || b.runner.title),
          )
          .sort((a, b) => b[params.ranking] - a[params.ranking])
          .slice(0, params.mode === 'summary' ? 10 : undefined);
      }
      return [];
    };
  }

  @Selector()
  static getIsLoading(state: IRankingsStateModel) {
    return (ranking: ERankingCode, country: string, gender: 'female' | 'scratch') =>
      state.loading[ranking][country + gender];
  }

  @Selector()
  static rankingLoadings(state: IRankingsStateModel) {
    return state.loading;
  }

  @Selector()
  static allRankingsOptions(state: IRankingsStateModel) {
    return Object.values(state.options);
  }

  @Selector([AppParamsState['defaultCountry']])
  static getRankingOptions(state: IRankingsStateModel, defaultCountry: string) {
    return (ranking: ERankingCode, country?: string) => {
      const selectedCountry = country || state.selectedCountry[ranking] || defaultCountry || 'FR';
      return state.options[ranking + selectedCountry];
    };
  }

  @Selector()
  static getRankingOptionsByCountry(state: IRankingsStateModel) {
    return (ranking: ERankingCode, country: string) => state.options[ranking + country];
  }

  @Selector([AppParamsState['defaultCountry']])
  static getRankingCountry(state: IRankingsStateModel, defaultCountry: string) {
    return (ranking: ERankingCode) => state.selectedCountry[ranking] || defaultCountry || 'FR';
  }

  @Selector()
  static selectedRankingCountries(state: IRankingsStateModel) {
    return state.selectedCountry;
  }

  @Selector()
  static groupParams(state: IRankingsStateModel) {
    return state.groupParams;
  }

  @Action(LoadRankingSummary)
  loadRankingSummary(ctx: StateContext<IRankingsStateModel>, action: LoadRankingSummary) {
    const state = ctx.getState();
    const type = action.type || (action.ranking === ERankingCode.LEVEL ? 'ALL' : 'CURRENT');
    const defaultCountry = this.store.selectSnapshot(AppParamsState.defaultCountry) || 'FR';
    let country = action.country || state.selectedCountry[action.ranking];
    if (!country) {
      country = defaultCountry;
      ctx.setState(
        produce(draft => {
          draft.selectedCountry[action.ranking] = country;
        }),
      );
    }

    const groupParams = action.params || state.groupParams;
    const allOrGroupId = groupParams?.groupId || groupParams?.groupId === 0 ? '' + groupParams.groupId : 'all';

    const getFemale =
      Object.keys(state.data[action.ranking][country]?.[type]?.female?.[allOrGroupId] || {}).length === 0;
    const getScratch =
      Object.keys(state.data[action.ranking][country]?.[type]?.scratch?.[allOrGroupId] || {}).length === 0;
    const genderToGet = getFemale && getScratch ? 'all' : getFemale ? 'female' : getScratch ? 'scratch' : undefined;

    if (genderToGet || action.forceReload) {
      this.store.dispatch(new SetLoadingRanking(action.ranking, country, genderToGet));

      return this.api.getRankingSummaries(action.ranking, type, country, genderToGet, groupParams).pipe(
        tap(data => {
          ctx.setState(
            produce(draft => {
              if (!draft.data[action.ranking][country]) {
                draft.data[action.ranking][country] = {};
              }
              if (!draft.data[action.ranking][country][type]) {
                draft.data[action.ranking][country][type] = {
                  female: {},
                  scratch: {},
                };
              }
              for (const gender of Object.keys(data)) {
                const g = gender as 'female' | 'scratch';

                if (!draft.data[action.ranking][country][type][g][allOrGroupId]) {
                  draft.data[action.ranking][country][type][g][allOrGroupId] = {};
                }

                for (const val of data[g]) {
                  draft.data[action.ranking][country][type][g][allOrGroupId][val.id] = val;
                }

                draft.loading[action.ranking][country + g] = false;
              }
            }),
          );
          for (const gender of Object.keys(data)) {
            const g = gender as 'female' | 'scratch';
            this.store.dispatch(new SetRankingOffset(action.ranking, country, g, type, allOrGroupId, data[g].length));
          }
        }),
      );
    }
    return;
  }

  @Action(LoadRankings)
  loadRankings(ctx: StateContext<IRankingsStateModel>, action: LoadRankings) {
    const state = ctx.getState();
    const defaultCountry = this.store.selectSnapshot(AppParamsState.defaultCountry) || 'FR';
    let country = action.country || state.selectedCountry[action.ranking];
    if (!country) {
      country = defaultCountry;
      ctx.setState(
        produce(draft => {
          draft.selectedCountry[action.ranking] = country;
        }),
      );
    }

    const groupParams = action.params || state.groupParams;
    const allOrGroupId = groupParams?.groupId || groupParams?.groupId === 0 ? '' + groupParams.groupId : 'all';

    const offsetKey = country + action.gender + action.type + '_' + allOrGroupId;
    const offset = state.offset[action.ranking]?.[offsetKey] || 0;
    this.store.dispatch(new SetLoadingRanking(action.ranking, country, action.gender));

    return this.api.getAllRankings(action.ranking, offset, action.gender, action.type, country, groupParams).pipe(
      tap(data => {
        ctx.setState(
          produce(draft => {
            if (!draft.data[action.ranking][country]) {
              draft.data[action.ranking][country] = {};
            }
            if (!draft.data[action.ranking][country][action.type]) {
              draft.data[action.ranking][country][action.type] = {
                female: {},
                scratch: {},
              };
            }

            if (!draft.data[action.ranking][country][action.type][action.gender][allOrGroupId]) {
              draft.data[action.ranking][country][action.type][action.gender][allOrGroupId] = {};
            }

            for (const val of data) {
              draft.data[action.ranking][country][action.type][action.gender][allOrGroupId][val.id] = val;
            }
            draft.loading[action.ranking][country + action.gender] = false;
          }),
        );
        this.store.dispatch(
          new SetRankingOffset(action.ranking, country, action.gender, action.type, allOrGroupId, data.length),
        );
      }),
    );
  }

  @Action(SetLoadingRanking)
  setLoadingRanking(ctx: StateContext<IRankingsStateModel>, action: SetLoadingRanking) {
    ctx.setState(
      produce(draft => {
        const genders = action.gender !== 'all' ? [action.gender] : ['female', 'scratch'];
        for (const g of genders) {
          draft.loading[action.ranking][action.country + g] = action.value;
        }
      }),
    );
  }

  @Action(SetRankingCountry)
  setRankingCountry(ctx: StateContext<IRankingsStateModel>, action: SetRankingCountry) {
    ctx.setState(
      produce(draft => {
        draft.selectedCountry[action.ranking] = action.country;
      }),
    );
  }

  @Action(SetRankingOffset)
  setRankingOffset(ctx: StateContext<IRankingsStateModel>, action: SetRankingOffset) {
    ctx.setState(
      produce(draft => {
        const key = action.country + action.gender + action.type + '_' + action.allOrGroupId;
        if (draft.offset[action.ranking][key]) {
          draft.offset[action.ranking][key] += action.offset;
        } else {
          draft.offset[action.ranking][key] = action.offset;
        }
      }),
    );
  }

  @Action(SetGroupParams)
  setGroupParams(ctx: StateContext<IRankingsStateModel>, action: SetGroupParams) {
    ctx.setState(
      produce(draft => {
        draft.selectedCountry['level'] = 'ALL';
        draft.groupParams = action.params;
      }),
    );
  }

  @Action(LoadAllRankingsOptions)
  loadAllRankingsOptions(ctx: StateContext<IRankingsStateModel>) {
    return this.api.getAllRankingsOptions().pipe(
      tap((options: RankingOptions[]) => {
        options?.length > 0 &&
          ctx.setState(
            produce(draft => {
              for (const option of options) {
                if (option.spid) {
                  option.sponsorImage = this.eventService.getSponsorImage(option.spid);
                }
                draft.options[option.rankingType + option.country] = option;
              }
            }),
          );
      }),
    );
  }

  @Action(UpdateRankingOptions)
  updateRankingOptions(ctx: StateContext<IRankingsStateModel>, action: UpdateRankingOptions) {
    return this.api.updateRankingOptions(action.options).pipe(
      tap((options: RankingOptions) => {
        ctx.setState(
          produce(draft => {
            options.sponsorImage = this.eventService.getSponsorImage(options.spid);
            draft.options[action.options.rankingType + action.options.country] = options;
          }),
        );
      }),
    );
  }

  @Action(RemoveSponsorFromRanking)
  removeSponsorFromRanking(ctx: StateContext<IRankingsStateModel>, action: RemoveSponsorFromRanking) {
    return this.api.removeSponsorFromRanking(action.optionId).pipe(
      tap((options: RankingOptions) => {
        ctx.setState(
          produce(draft => {
            draft.options[options.rankingType + options.country] = options;
          }),
        );
      }),
    );
  }

  @Action(ResetGroupRankings)
  resetGroupRanking(ctx: StateContext<IRankingsStateModel>, { groupId }: ResetGroupRankings) {
    ctx.setState(
      produce(draft => {
        for (const ranking in draft.data) {
          for (const country in draft.data[ranking]) {
            for (const type in draft.data[ranking][country]) {
              for (const gender in draft.data[ranking][country][type]) {
                if (groupId in draft.data[ranking][country][type][gender]) {
                  draft.data[ranking][country][type][gender][groupId] = {};
                }
              }
            }
          }
        }
      }),
    );
  }
}
