import { Component, OnInit, Input } from '@angular/core';
import { latlonArrayToXYArray } from '@betrail-libs/shared/utils';

@Component({
  selector: 'path-preview',
  templateUrl: './path-preview.component.html',
  styleUrls: ['./path-preview.component.scss'],
})
export class PathPreviewComponent {
  svgPath: string;

  @Input('path') set path(path) {
    if (path && path.path instanceof Array) {
      this.svgPath = latlonArrayToXYArray(path.path).join(' ');
    }
  }
}
