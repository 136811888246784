import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { NgxsAuthState } from 'libs/front/auth-state/src/ngxs/auth.state';
import { INotif } from '@betrail-libs/shared/interfaces/interfaces';
import { PlatformServiceService } from './platform-service.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MetaService {
  numberOfNotifs: number;
  title: any;
  constructor(
    @Inject(DOCUMENT) private dom,
    @Inject('appsConfig') private config: { [key: string]: string | boolean },
    private titleService: Title,
    private meta: Meta,
    private translationService: TranslocoService,
    private platform: PlatformServiceService,
  ) {
    combineLatest([this.notifs$, this.notifsStatus$])
      .pipe(untilDestroyed(this))
      .subscribe(([notifs, notifsStatus]) => {
        this.numberOfNotifs = notifs.filter(n => notifsStatus[n.id] && notifsStatus[n.id] !== 'read').length;
        this.sendTitle();
      });
  }

  @Select(NgxsAuthState.notifs) notifs$!: Observable<INotif[]>;
  @Select(NgxsAuthState.notifsStatus) notifsStatus$!: Observable<any>;

  domain = this.config['betrail'];

  sendTitle(title = this.title) {
    if (this.numberOfNotifs && this.numberOfNotifs > 0) {
      title = '(' + this.numberOfNotifs + ') ' + title;
      this.titleService.setTitle(title);
    } else {
      this.titleService.setTitle(title);
    }
  }

  setLanguage() {
    this.removeOldTag("meta[http-equiv='Content-Language']");
    this.meta.updateTag({ 'http-equiv': 'Content-Language', content: this.translationService.getActiveLang() });
  }

  setCanonicalURL(url?: string) {
    this.removeOldTag("link[rel='canonical']");
    let canURL = url == undefined ? this.dom.URL : this.domain + url;
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
    this.meta.updateTag(
      {
        property: 'og:url',
        content: canURL,
      },
      `property='og:url'`,
    );
  }

  removeOldCanonical() {
    if (this.platform.isBrowserRendering()) {
      const canonical = document.querySelector("link[rel='canonical']");
      if (canonical) {
        canonical.remove();
      }
    }
  }

  removeOldTag(selector) {
    if (this.platform.isBrowserRendering()) {
      const tag = document.querySelector(selector);
      if (tag) {
        tag.remove();
      }
    }
  }

  setTitleFromArray(stringsToTranslate: string[], addBetrail: boolean = true) {
    const translatedStrings = stringsToTranslate.map(s => this.translationService.translate(s));
    let t = translatedStrings.join('');
    if (addBetrail === true) {
      t = t + ' · Betrail';
    }
    this.title = t;
    this.sendTitle(t);
  }

  setRaceTitle(race, event, trail, subTitle?) {
    let registrationOnBetrailTitle = event.signupData ? ' · ' + this.translationService.translate('REGISTRATIONS') : '';
    let eventName = event?.event_name || trail?.title;
    let title = eventName;
    title += ' ' + new Date(event.date * 1000).getFullYear();
    title += ' · ' + race.distance + 'km';
    if (race.race_name && race.race_name != '') {
      title += ' "' + race.race_name + '"';
    }
    if (subTitle) {
      title += ' · ' + this.translationService.translate(subTitle).toUpperCase();
    }
    title += registrationOnBetrailTitle;
    this.setTitle(title);
  }

  setTitle(title: string, addYear: boolean = false, monthToAdd = undefined) {
    let t = this.translationService.translate(title);

    if (addYear == true) {
      let date = '';
      if (new Date().getMonth() > 5) {
        date = new Date().getFullYear() + '-' + (new Date().getFullYear() + 1);
      } else {
        date = '' + new Date().getFullYear();
      }
      t = t + ' ' + date;
    }
    if (monthToAdd != undefined) {
      const tt = this.translationService.translate(monthToAdd);
      t = t + ' ' + tt + ' ';
    }

    t = t + ' · Betrail';
    this.title = t;
    this.sendTitle(t);

    this.meta.updateTag(
      {
        property: 'og:title',
        content: t,
      },
      `property='og:title'`,
    );

    this.meta.updateTag(
      {
        property: 'og:type',
        content: 'website',
      },
      `property='og:type'`,
    );

    this.meta.updateTag(
      {
        property: 'fb:app_id',
        content: '461551534016215',
      },
      `property='fb:app_id'`,
    );
  }

  setDescription(description: string, monthToAdd = undefined, addYear = false) {
    let t = this.translationService.translate(description);

    let date = '';
    if (addYear == true) {
      if (new Date().getMonth() > 5) {
        date = new Date().getFullYear() + '-' + (new Date().getFullYear() + 1);
      } else {
        date = '' + new Date().getFullYear();
      }
    }
    if (monthToAdd != undefined) {
      const tt = this.translationService.translate(monthToAdd);

      t = tt + ' ' + date + '. ' + t;
    }
    this.meta.removeTag("name='description'");
    this.meta.addTag({ name: 'description', content: t });
    this.meta.updateTag(
      {
        property: 'og:description',
        content: t,
      },
      `property='og:description'`,
    );
  }

  setImage(url) {
    this.meta.updateTag(
      {
        property: 'og:image',
        content: url,
      },
      `property='og:image'`,
    );
  }

  setRobotNoIndex() {
    this.meta.removeTag("name='robots'");
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }

  setRobotIndex() {
    this.meta.removeTag("name='robots'");
    this.meta.addTag({ name: 'robots', content: 'index, fellow' });
  }
}
