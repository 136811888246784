<div class="flex justify-between items-stretch">
  <mat-form-field appearance="fill" class="max-w-[25.5%]">
    <mat-label>{{ 'DAY' | transloco }}</mat-label>
    <mat-select
      required
      [placeholder]="'DAY' | transloco"
      [(ngModel)]="day"
      [disabled]="isDisable"
      (selectionChange)="changeValue()"
    >
      <mat-option *ngFor="let d of days" [value]="d"> {{ d }} </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="max-w-[35.5%]">
    <mat-label>{{ 'MONTH' | transloco }}</mat-label>
    <mat-select
      required
      [placeholder]="'MONTH' | transloco"
      [(ngModel)]="month"
      [disabled]="isDisable"
      (selectionChange)="changeValue()"
    >
      <mat-option *ngFor="let m of months" [value]="m"> {{ 'MONTH_' + m | transloco }} </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="max-w-[30.5%]">
    <mat-label>{{ 'YEAR' | transloco }}</mat-label>
    <mat-select
      required
      [placeholder]="'YEAR' | transloco"
      [disabled]="isDisable"
      [(ngModel)]="year"
      (selectionChange)="changeValue()"
    >
      <mat-option *ngFor="let y of years" [value]="y"> {{ y }} </mat-option>
    </mat-select>
  </mat-form-field>
</div>
