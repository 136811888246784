import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventService } from '@betrail-libs/trail-data-state';

@Component({
  selector: 'account-matching-check',
  templateUrl: './account-matching-check.component.html',
  styleUrls: ['./account-matching-check.component.scss'],
})
export class AccountMatchingCheckComponent {
  results: any = {};
  current_runner: any;
  _runners: any;
  current_step: number = -1;
  current_results_offset: number = 0;
  initial_results_offset: number = 0;
  answers = {};
  max_offset: number = 2;
  results_necessary_to_match: number = 2;

  current_results: any;

  constructor(private eventService: EventService) {}

  @Input() firstname: string;
  @Input('runners') set runners(runners) {
    this._runners = runners;
    if (!runners || this._runners.length == 0) {
      this.runnerFound.emit({ id: 9876543 });
    } else if (this._runners.length < 3) {
      this.max_offset = 2; // on teste max 3 résultats par coureur à chaque passage
    } else if (this._runners.length >= 3 && this._runners.length < 6) {
      this.max_offset = 1; // on teste max 2 résultats par coureur à chaque passage
    } else {
      this.max_offset = 0; // on ne teste qu'un résultat par coureur à chaque passage
    }
    if (runners.length > 0) {
      this.goToNextRunner();
    }
  }
  @Output() runnerFound = new EventEmitter();

  loadNextRunnerResults(runner) {
    const year = new Date().getFullYear() - 1.5;

    //BUG:correct new runner property
    if (runner.has_account !== true) {
      this.eventService.getRunnerResults(runner.ruid ? runner.ruid : runner.nid).subscribe(results => {
        if (results.length > 0) {
          results = results
            .map(r => {
              return { ...r, weight: new Date(r.date * 1000).getFullYear() - year + r.race.distance / 12 };
            })
            .map(result => {
              const trailId = result.race.event.trail.id ? result.race.event.trail.id : result.id;
              if (!this.results[runner.ruid ? runner.ruid : runner.nid]) {
                this.results[runner.ruid ? runner.ruid : runner.nid] = {};
              }
              if (!this.results[runner.ruid ? runner.ruid : runner.nid][trailId]) {
                this.results[runner.ruid ? runner.ruid : runner.nid][trailId] = {
                  trail: { ...result.race.event.trail, trailNamePrefix: this.getTrailNamePrefix(result) },
                  events: [],
                  weight: 0,
                };
              }
              this.results[runner.ruid ? runner.ruid : runner.nid][trailId].events.push(result.race.event);
              this.results[runner.ruid ? runner.ruid : runner.nid][trailId].weight += result.weight;
            });
          if (!this.results[runner.ruid ? runner.ruid : runner.nid]) {
            this.current_step = this.current_step + 1;
            this.goToNextRunner();
          } else {
            this.results[runner.ruid ? runner.ruid : runner.nid] = Object.keys(
              this.results[runner.ruid ? runner.ruid : runner.nid],
            )
              .map(key => this.results[runner.ruid ? runner.ruid : runner.nid][key])
              .sort((a, b) => b.weight - a.weight);
            this.current_step = this.current_step + 1;
            this.current_results_offset = this.initial_results_offset;
            this.current_runner = this._runners[this.current_step];
          }
        } else {
          // delete this runner because he has no result
          this._runners = this._runners.filter(r => r.ruid != runner.nid);
          this.current_step = this.current_step + 1;
          this.goToNextRunner();
        }
      });
    } else {
      // delete this runner because he is already claimed
      this._runners = this._runners.filter(r => r.ruid != runner.nid);
      this.current_step = this.current_step + 1;
      this.goToNextRunner();
    }
  }

  goToNextRunner(nb = 0) {
    nb++;
    if (this._runners.length == 0) {
      this.runnerFound.emit({ id: 9876543 });
    } else if (nb <= this._runners.length) {
      let runner = this._runners[this.current_step + 1];

      if (runner && this.results[runner?.ruid || runner?.nid] == undefined) {
        this.loadNextRunnerResults(runner);
      } else if (
        this.results[runner?.ruid || runner?.nid] != undefined &&
        this.results[runner?.ruid || runner?.nid].length > this.current_results_offset
      ) {
        this.current_step = this.current_step + 1;
        this.current_runner = this._runners[this.current_step];
      } else {
        this.current_step = this.current_step + 1;
        this.goToNextRunner(nb);
      }
    } else {
      this.runnerFound.emit({ id: 9876543 });
    }
  }

  getTrailNamePrefix(r) {
    if (r.race && r.race.event && r.race.event.trail && r.race.event.trail.title) {
      const words = r.race.event.trail.title.toLowerCase().split(/[\s,-]+/);
      if (words[0] == 'trail' || words[words.length - 1] == 'trail' || words[words.length - 2] == 'trail') {
        const firstLetter = words[0].charAt(0);
        if (this.isVowel(firstLetter)) {
          return 'THE_ELIDED';
        }
        return 'THE_SINGULAR';
      } else if (words[0] == 'trails' || words[words.length - 1] == 'trails') {
        return 'THE_PLURAL';
      }
      return '';
    }
  }

  isVowel(letter) {
    const vowels = ['a', 'e', 'i', 'o', 'u', 'y', 'é', 'è', 'ï', 'ê'];
    return vowels.indexOf(letter) > -1;
  }

  nextStep(forceCheckForSameRunner = false) {
    if (this.checkValues() === false) {
      if (
        (this.current_results_offset < this.max_offset || forceCheckForSameRunner === true) &&
        this.results[this.current_runner.ruid ? this.current_runner.ruid : this.current_runner.id].length >
          this.current_results_offset + 1
      ) {
        this.current_results_offset++;
      } else if (this.current_step < this._runners.length - 1) {
        this.current_results_offset = this.initial_results_offset;
        this.goToNextRunner();
      } else if (this._runners.length >= 3 && this.initial_results_offset == 0) {
        // on parcourt à nouveau l'array des coureurs
        this.initial_results_offset = this.max_offset + 1;
        this.current_results_offset = this.initial_results_offset;
        this.max_offset = this.current_results_offset + this.max_offset;
        this.current_step = -1;
        this.goToNextRunner();
      } else {
        this.runnerFound.emit({ id: 9876543 });
      }
    } else {
      this.runnerFound.emit(this.current_runner);
    }
  }

  checkValues() {
    let numberOfRunnerResults =
      this.results[this.current_runner.ruid ? this.current_runner.ruid : this.current_runner.id].length;
    for (let key of Object.keys(this.answers)) {
      if (
        (this.results[key] != undefined && this.answers[key].yes == this.results[key].length) ||
        ((this.answers[key].yes >=
          (numberOfRunnerResults > 1 ? this.results_necessary_to_match : numberOfRunnerResults) ||
          this.answers[key].yes + this.answers[key].dontremember == numberOfRunnerResults) &&
          this.answers[key].yes > this.answers[key].no)
      ) {
        return true;
      }
    }
    return false;
  }

  saveAnswer(result, val) {
    if (!this.answers[result.ruid]) {
      this.answers[result.ruid] = { yes: 0, no: 0, dontremember: 0 };
    }
    this.answers[result.ruid][val]++;
    if (val == 'yes' || val == 'dontremember') {
      this.nextStep(true);
    } else {
      this.nextStep();
    }
  }

  ngOnDestroy() {}
}
