import { ERaceCategory, ERaceType } from '@betrail-libs/shared/interfaces/race.model';

/**
 * Determine the category of a race based on its characteristics.
 *
 * @param {number} distance - The real distance in kilometers.
 * @param {number} elevation - The positive elevation (D+) of the race in meters.
 * @param {number | null} offroad - Percentage of offroad sections (null is treated as 0).
 * @param {number | null} trail - Percentage of trail sections (null is treated as 0).
 * @param {number} refreshments - Number of refreshment stations on the race.
 * @param {string} country - The country ISO2 code where the race take place ("BE", "LU", "NL", etc).
 * @param {ERaceType} race_type - The type of the race ("solo", "duo", "backyard", "walk", "other", etc).
 * @param {number} route_type - Race route type = 0 for a linear race, a number > 0 stand for the number of loops.
 * @param {boolean} white - Indicate if the race is a white trail (true/false).
 * @param {boolean} urban - Indicate if the race is an urban trail (true/false).
 * @returns {ERaceCategory} The race category ("LABEL" | "TRAIL", "NATURE_XL" | "START_TO_TRAIL" | "NATURE" | "NEARLY_CRITERIA" | "OTHER").
 */
export function determineRaceCategory(
  distance: number,
  elevation: number,
  offroad: number | null,
  trail: number | null,
  refreshments: number,
  country: string,
  race_type: ERaceType,
  route_type: number | string,
  white: boolean,
  urban: boolean,
): ERaceCategory {
  // + 1. Handle nullish values for %offroad and %trail: if null, consider 0.
  const offroadPct = offroad ?? 0;
  const trailPct = trail ?? 0;

  // + 2. Define the factor according to the white trail case.
  const whiteTrailFactor = white ? 2 : 1;

  // + 3. Modified distance calculation (Dist_D+)
  const distDPlus = distance + elevation / 100 + (whiteTrailFactor * elevation) / (25 * distance);

  // + 4. Average distance between refreshments.
  const distMoyRefresh = distDPlus / (refreshments + 1);

  // + 5. Calculation of distances for a loop race.
  // If route_type > 1, we split distances by the number of loops, else we keep the same distances.
  const distLoopDPlus = +route_type > 1 ? distDPlus / +route_type : distDPlus;
  const distLoopReal = +route_type > 1 ? distance / +route_type : distance;

  // + 6. Calculation of the nature percentage.
  // If one of the percentages (offroad or trail) is 0 (empty), %nature is 0, else %nature = offroadPct + trailPct.
  const naturePct = offroadPct === 0 || trailPct === 0 ? 0 : offroadPct + trailPct;

  // + 7. Initializing race category to "OTHER".
  let category: ERaceCategory = ERaceCategory.OTHER;

  // + 8. Conditions to determine the category of the race :
  // Rule 1 : If race_type is one of the following, the category is "OTHER".
  const otherRaceTypes = [
    ERaceType.WALK,
    ERaceType.NORDIC_WALK,
    ERaceType.CANICROSS,
    ERaceType.ORIENTEERING,
    ERaceType.OTHER,
  ];
  if (otherRaceTypes.includes(race_type)) {
    category = ERaceCategory.OTHER;
  }
  // Rule 2 : If the modified distance (Dist_D+) is less than 7 km, category is "OTHER".
  else if (distDPlus < 7) {
    category = ERaceCategory.OTHER;
  }
  // Rule 3 : If (%nature >= 140%) and country = BE or LU, depending of the average distance between refreshments :
  else if (naturePct >= 140 && ['BE', 'LU'].includes(country.toUpperCase())) {
    if (distMoyRefresh < 7) {
      // If (Dist_D+ < 21 km) category is "NATURE", else "NATURE XL".
      category = distDPlus < 21 ? ERaceCategory.NATURE : ERaceCategory.NATURE_XL;
    } else if (distMoyRefresh < 12.5) {
      // If (Dist_D+ < 21 km) category is "START-TO-TRAIL", else "TRAIL".
      category = distDPlus < 21 ? ERaceCategory.START_TO_TRAIL : ERaceCategory.TRAIL;
    } else {
      // Other cases : define category from Dist_D+, real distance or loop distance.
      if (distDPlus < 21) {
        category = ERaceCategory.START_TO_TRAIL;
      } else if (distance < 21) {
        category = ERaceCategory.TRAIL;
      } else if (distLoopReal < 21) {
        category = ERaceCategory.TRAIL;
      } else {
        category = ERaceCategory.LABEL;
      }
    }
  }
  // Rule 4 : If (%offroad >= 70%) or (%nature >= 120%), depending of the average distance between refreshments :
  else if (offroadPct >= 70 || naturePct >= 120) {
    if (distMoyRefresh < 7) {
      // If (Dist_D+ < 21 km) category is "NATURE", else "NATURE XL".
      category = distDPlus < 21 ? ERaceCategory.NATURE : ERaceCategory.NATURE_XL;
    } else {
      // If (Dist_D+ < 21 km) category is "START-TO-TRAIL", else "TRAIL".
      category = distDPlus < 21 ? ERaceCategory.START_TO_TRAIL : ERaceCategory.TRAIL;
    }
  }
  // Rule 5 : If (%offroad >= 60%) or (%nature >= 100%).
  else if (offroadPct >= 60 || naturePct >= 100) {
    // If (Dist_D+ < 21 km) category is "NATURE", else "NATURE XL".
    category = distDPlus < 21 ? ERaceCategory.NATURE : ERaceCategory.NATURE_XL;
  }
  // Rule 6 : If (%offroad < 60%) and (%nature < 100%) and (Dist_D+ >= 21), category is "NEARLY_CRITERIA".
  else if (offroadPct < 60 && naturePct < 100 && distDPlus >= 21) {
    category = ERaceCategory.NEARLY_CRITERIA;
  }
  // Rule 7 : If (%offroad < 60%) and (%nature < 100%), category is "OTHER".
  else if (offroadPct < 60 && naturePct < 100) {
    category = ERaceCategory.OTHER;
  }
  // Rule 8 : If (%offroad < 50%) and (%nature < 80%) and (Dist_D+ >= 21) and race is an urban trail, category is "NEARLY_CRITERIA".
  else if (offroadPct < 50 && naturePct < 80 && distDPlus >= 21 && urban) {
    category = ERaceCategory.NEARLY_CRITERIA;
  }
  // Rule 9 : If none of the above conditions are met, category is "OTHER".
  else {
    category = ERaceCategory.OTHER;
  }

  // + 8. Special case of too small loops :
  // If one loop is less than 7 km and the obtained category is not "OTHER", we force the category to "NEARLY_CRITERIA".
  if (distLoopDPlus < 7 && category !== ERaceCategory.OTHER) {
    category = ERaceCategory.NEARLY_CRITERIA;
  }

  // + 9. Downgrade for short KV to avoid them to be wrongly within the criteria :
  // If race is a white trail and distance < 3 km and elevation < 110, the category become "OTHER".
  if (white && distance < 3 && elevation < 110) {
    category = ERaceCategory.OTHER;
  }
  // Else if the race is not a white trail and distance < 3 km and elevation < 175, the category become "OTHER".
  else if (!white && distance < 3 && elevation < 175) {
    category = ERaceCategory.OTHER;
  }
  // Else if the race is a white trail and distance < 8 km and elevation < 650 :
  else if (white && distance < 8 && elevation < 650) {
    if (category === ERaceCategory.NATURE_XL) {
      // If the category is "NATURE XL", we downgrade it to "NATURE".
      category = ERaceCategory.NATURE;
    } else if (category === ERaceCategory.TRAIL || category === ERaceCategory.LABEL) {
      // If the category is "TRAIL" or "LABEL", we downgrade it to "START-TO-TRAIL".
      category = ERaceCategory.START_TO_TRAIL;
    }
  }
  // Else if the race is not a white trail and distance < 6 km and elevation < 900 :
  else if (!white && distance < 6 && elevation < 900) {
    if (category === ERaceCategory.NATURE_XL) {
      // If the category is "NATURE XL", we downgrade it to "NATURE".
      category = ERaceCategory.NATURE;
    } else if (category === ERaceCategory.TRAIL || category === ERaceCategory.LABEL) {
      // If the category is "TRAIL" or "LABEL", we downgrade it to "START-TO-TRAIL".
      category = ERaceCategory.START_TO_TRAIL;
    }
  }

  // + 10. Return the determined category.
  return category;
}
