import { IEvent } from './event.model';
import { ITrail } from './interfaces';
import { IRace } from './race.model';
import { IRunner } from './runner.model';

export interface IResult {
  title: string;
  id: number;
  raid: number;
  race?: IRace;
  ruid: number;
  runner?: IRunner;
  gender: 0 | 1;
  original_infos?: string | null;
  original_position?: number | null;
  original_runner_name?: string | null;
  original_runner_firstname?: string | null;
  original_runner_lastname?: string | null;
  original_gender?: number | null;
  uid: number;
  result_seconds: number;
  result_milliseconds: number;
  result_hms?: string;
  performance?: number | null; // level betrail
  points?: number | null; // depreciated
  pts?: number | null; // actual trailcup points
  rank_scratch?: number | null;
  rank_gender?: number | null;
  finishers?: number;
  position?: number;
  femaleFinishers?: number;
  femalePosition?: number;
  date?: number | null;
  status: number;
  ultra?: number | null;
  category?: string | null;
  country?: string | null;
  modifications?: string | null;
  verif_status?: string | null;
  notification_sent?: string | null;
  created?: number;
  nb_results?: number;
  isOneOfBestScores?: boolean;
  eliteType?: 'world' | 'national';
  tag?: ETags | null;

  // trail?: ITrail;
}

export interface IResultCreation {
  ruid?: number;
  firstname: string;
  lastname: string;
  birthYear?: number;
  gender: 0 | 1;
  nationality?: string;
  raid: number;
  result_seconds: number;
}

export interface IOtherResult {
  id: number;
  uid: number;
  ruid: number;
  runner?: IRunner;
  trid?: number;
  trail?: ITrail;
  evid?: number;
  event?: IEvent;
  raid?: number;
  race?: IRace;
  title?: string | null;
  country?: string | null;
  zipcode?: string | null;
  location?: string | null;
  geo_lat?: number | null;
  geo_lon?: number | null;
  date?: number | null;
  ev_year?: number | null;
  ev_date?: string | null;
  distance?: number | null;
  height_difference?: number | null;
  result_seconds?: number | null;
  result_milliseconds?: number | null;
  race_type?: string | null;
  night_race?: boolean | null;
  result_type?: number | null;
  run_type?: ERunType;
  position?: number | null;
  total_finishers?: number | null;
  distance_dnf?: number | null;
  isOneOfBestScores?: boolean;
  tag?: ETags | null;
}

export enum ETags {
  Pacer = 'P',
  Wrong = 'W',
  Training = 'T',
  Injury = 'I',
}

export enum ERunType {
  NATURE = 'nature_race',
  ROAD = 'road_race',
  TRAIL = 'trail',
  OTHER = 'other',
}
