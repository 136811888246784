import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports : [
    CommonModule
  ],
  providers: [],
})
export class AppParamsModule {}

export { AppParamsService } from './app-params.service';
