import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthStateModule } from '@betrail-libs/auth-state';
import { NgxsModule } from '@ngxs/store';
import { TrailDataState } from 'libs/front/trail-data-state/trail-data.state';
import { RaceStateService } from './services/race-state.service';
import { RegistrationStateService } from './services/registration-state.service';
import { RunnerStateService } from './services/runner-state.service';

@NgModule({
  imports: [CommonModule, AuthStateModule, NgxsModule.forFeature([TrailDataState])],
  providers: [RaceStateService, RegistrationStateService, RunnerStateService],
})
export class TrailDataStateModule {}
