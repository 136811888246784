import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITrail } from '@betrail-libs/shared/interfaces/interfaces';
import { EventService } from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as L from 'leaflet';

@UntilDestroy()
@Component({
  selector: 'app-edit-geolocation-dialog',
  templateUrl: './edit-geolocation-dialog.component.html',
  styles: [
    `
      .center-marker {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 500;
      }
    `,
  ],
})
export class EditGeolocationDialogComponent implements OnInit {
  map: L.Map;
  latitude: number;
  longitude: number;
  saving = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { trail: ITrail },
    private dialogRef: MatDialogRef<EditGeolocationDialogComponent>,
    private eventService: EventService,
  ) {}

  ngOnInit(): void {
    this.initMap(
      this.data.trail.man_lat || this.data.trail.geo_lat,
      this.data.trail.man_lon || this.data.trail.geo_lon,
      7,
    );
  }

  private initMap(initialLat: number, initialLng: number, zoomLevel: number): void {
    // Set initial geo coordinates
    this.latitude = initialLat;
    this.longitude = initialLng;

    // Generate the map
    this.map = L.map('map').setView([initialLat, initialLng], zoomLevel);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors',
    }).addTo(this.map);

    // Define the actual position marker
    const icon = L.icon({
      iconUrl: 'assets/leaflet/map_marker_orange.png',
      iconSize: [24, 32],
      iconAnchor: [12, 32],
    });

    // Add the marker to the map
    L.marker([initialLat, initialLng], { icon }).addTo(this.map);

    // Listen for map movement
    this.map.on('move', () => {
      const center = this.map.getCenter();
      this.latitude = center.lat;
      this.longitude = center.lng;
    });
  }

  saveGeolocation(): void {
    this.saving = true;
    const data = {
      message: 'Modification de la géolocalisation du trail',
      newData: { trail: { ...this.data.trail, man_lat: this.latitude, man_lon: this.longitude } },
      oldData: { trail: this.data.trail },
    };
    this.eventService
      .updateTrailData(data)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.saving = false;
          this.dialogRef.close({ errored: false, result: this.data.trail.alias });
        },
        error: err => {
          this.saving = false;
          this.dialogRef.close({ errored: true, result: JSON.stringify(err) });
        },
      });
  }
}
