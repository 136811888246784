import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UsersService } from '@betrail-libs/auth-state';
import { ILoginCred } from '@betrail-libs/shared/interfaces/auth.model';
import { ITrail } from '@betrail-libs/shared/interfaces/interfaces';

@Component({
  selector: 'app-login-form',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../../common.scss'],
})
export class LoginComponent {
  /** Members **/

  loginForm: UntypedFormGroup;

  /** Inputs **/

  /** Outputs **/

  @Output() loginClicked = new EventEmitter<ILoginCred>();
  @Output() noAccountClicked = new EventEmitter<any>();
  @Output() sendNewPasswordClicked = new EventEmitter<any>();

  /** ViewChilds **/
  @ViewChild('pass', { static: true }) passwordInput: ElementRef;

  passwordToggleText = 'SHOW_PASSWORD';
  passwordToggleIcon = 'visibility_off';
  displayPassword: boolean;
  trail: ITrail;

  /** Constructor **/

  constructor(private formBuilder: UntypedFormBuilder, private usersService: UsersService) {
    this.loginForm = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  /** Page events **/

  /** Click events **/

  displayPasswordToggle() {
    if (this.displayPassword === false) {
      this.passwordInput.nativeElement.type = 'text';
      this.passwordToggleText = 'HIDE_PASSWORD';
      this.passwordToggleIcon = 'visibility';
      this.displayPassword = true;
    } else {
      this.passwordInput.nativeElement.type = 'password';
      this.passwordToggleText = 'SHOW_PASSWORD';
      this.passwordToggleIcon = 'visibility_off';
      this.displayPassword = false;
    }
  }

  login() {
    this.loginClicked.emit(this.buildCredentials());

    // const credentials : LoginCred = {
    //   user : this.loginForm.get('email').value,
    //   password : this.loginForm.get('password').value
    // };
    // this.authenticationService.login(credentials);
  }

  noAccount() {
    this.noAccountClicked.emit();
  }

  sendNewPassword() {
    this.sendNewPasswordClicked.emit();
  }

  /** Private methods **/

  buildCredentials() {
    const credentials: ILoginCred = {
      user: '',
      password: '',
    };

    credentials.user = this.getFormValue('user');
    credentials.password = this.getFormValue('password');

    return credentials;
  }

  getFormValue(name: string): string {
    let value = '';

    if (this.loginForm.get(name)) {
      value = this.loginForm.get(name).value;
    }

    return value;
  }
}
