import { Component, Input, OnInit } from '@angular/core';
import { NgxsAuthState } from '@betrail-libs/auth-state';
import { IUserState } from '@betrail-libs/shared/interfaces/auth.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'banner-displayer',
  templateUrl: './banner-displayer.component.html',
  styleUrls: ['./banner-displayer.component.scss'],
})
export class BannerDisplayerComponent implements OnInit {
  @Select(NgxsAuthState.user) user$!: Observable<IUserState>;

  hideBanner = false;

  @Input() type: 'calendar' | 'race' | 'perso';
  @Input() language: string;

  ngOnInit() {
    this.user$.pipe(untilDestroyed(this)).subscribe(user => {
      this.hideBanner = user?.userProfile?.hide_other_banner ? user.userProfile.hide_other_banner : false;
    });
  }
}
