import { Pipe } from '@angular/core';
import { IOtherResult, IResult } from '@betrail-libs/shared/interfaces/result.model';
import { toHMS } from '@betrail-libs/shared/utils';

@Pipe({
  name: 'getResultInfos',
})
export class GetResultInfosPipe {
  transform(
    result: IResult & IOtherResult,
    toGet: ('name' | 'year' | 'distance' | 'elevation' | 'time' | 'perf' | 'position')[],
  ): string {
    const infos = [];
    toGet.forEach(tg => {
      let info: string | number;
      switch (tg) {
        case 'name':
          info = result.result_hms
            ? result.race.event.event_name
            : result.event
            ? result.event.event_name
            : result.trail
            ? result.trail.title
            : result.title
            ? result.title
            : null;
          break;
        case 'year':
          info = result.ev_year
            ? result.ev_year
            : result.event
            ? result.event.alias
            : result.race
            ? result.race.event
              ? result.race.event.alias
              : null
            : null;
          break;
        case 'distance':
          info = result.race ? result.race.distance + 'km' : result.distance ? result.distance + 'km' : null;
          break;
        case 'elevation':
          info = result.height_difference
            ? result.height_difference + 'm'
            : result.race
            ? result.race.elevation
              ? result.race.elevation + 'm'
              : null
            : null;
          break;
        case 'time':
          info = result.result_seconds ? toHMS(result.result_seconds, 'hms') : null;
          break;
        case 'perf':
          info = result.performance ? result.performance : null;
          break;
        case 'position':
          info =
            (result.femalePosition ? result.femalePosition : result.position) +
            '°/' +
            (result.femaleFinishers
              ? result.femaleFinishers
              : result.total_finishers
              ? result.total_finishers
              : result.finishers);
      }

      if (info) {
        infos.push(info);
      }
    });

    return infos.join(' • ');
  }
}
