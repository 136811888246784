import { inject, Injectable } from '@angular/core';
import { IRunnerEdition, IRunnerExtraInfos } from '@betrail-libs/shared/interfaces/runner.model';
import { Store } from '@ngxs/store';
import { UpdateRunnerById, UpdateRunnerExtraInfos } from '../trail-data.action';
import { TrailDataState } from '../trail-data.state';
import { IGameData } from '@betrail-libs/shared/interfaces/interfaces';

@Injectable({ providedIn: 'root' })
export class RunnerStateService {
  #store = inject(Store);

  selectedRunner$ = this.#store.select(TrailDataState.selectedRunner);

  updateRunner = (ruid: number, data: { runner: IRunnerEdition; gameData?: IGameData }) =>
    this.#store.dispatch(new UpdateRunnerById(ruid, data));

  updateRunnerExtraInfos = (ruid: number, data: IRunnerExtraInfos) =>
    this.#store.dispatch(new UpdateRunnerExtraInfos(ruid, data));
}
