import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'route-name-displayer',
  templateUrl: './route-name-displayer.component.html',
  styleUrls: ['./route-name-displayer.component.scss'],
})
export class RouteNameDisplayerComponent {
  names: string[];

  @Input('name') set name(name) {
    this.names = name.split(' ');
  }
}
