import { ILoginCred } from '@betrail-libs/shared/interfaces/auth.model';
import { IPremiumRefPrice } from '@betrail-libs/shared/interfaces/premium.model';
import { IRunner } from '@betrail-libs/shared/interfaces/runner.model';

export enum AuthActionTypes {
  LOGIN = '[User] Login',
  LOGIN_DIALOG = '[User] Login dialog',
  LOGIN_SUCCEED = '[User] Login complete',
  LOGIN_FAILED = '[User] Login failed',
  CREATE_USER = '[User] create user',
  UPDATE_USER = '[User] update user',
  CREATE_USER_SUCCESS = '[User] create user success',
  CREATE_USER_FAILED = '[User] create user failed',
  LOGOUT = '[User] Logout',
  LOGOUT_SUCCEED = '[User] Logout succeed',
  REFRESH_TOKEN = '[User] Refresh token',
  TOKEN_ERROR = '[User] Token ERROR',
  CONNECT = '[User] Connect',
  RETRIEVE_USER = '[User] Loading user',
  RETRIEVE_PASSWORD = '[User] Retrieve password',
  RETRIEVE_PASSWORD_SUCCESS = '[User] Retrieve password ok',
  RETRIEVE_PASSWORD_FAILED = '[User] Retrieve password failed',
  RETRIEVE_USER_SUCCESS = '[User] Loading user success',
  RETRIEVE_USER_FAILED = '[User] Loading user failed',
  LOAD_USER_NOTIFICATIONS = '[User] Load user notifications',
  UPDATE_NOTIF_STATUS = '[User] Update notification status',
  UPDATE_USER_PROFILE_DATA = '[User] Update user profile data',
  MASQUERADE = '[User] Masquerade user',
  COMPLETE_RUNNER_DATA = '[User] Complete runner data',
  LOAD_PREMIUM_PRICES = '[Premium] Load premium prices',
  LOAD_USER_PREMIUM_DATA = '[Premium] Load user premium data',
  CHANGE_PREMIUM_SUBSCRIPTION_TYPE = '[Premium] Change premium subscription type',
  CANCEL_PREMIUM_AUTO_RENEWAL = '[Premium] Cancel premium subscription auto renewal',
  UPDATE_USER_PASSWORD = '[User] Update user password',
  UPDATE_USER_MAIL_PREFERENCES = '[User] Update user mail preferences',
  UPDATE_USER_LANGUAGE = '[User] Update user language',
  UPDATE_USER_ADDS_PREFERENCES = '[User] Update user adds preferences',
}

export class LoginDialogAction {
  static readonly type = AuthActionTypes.LOGIN_DIALOG;
  constructor(public formType: string, public runner?: IRunner) {}
}

export class LoginAction {
  static readonly type = AuthActionTypes.LOGIN;
  constructor(public loginCredential: ILoginCred, public activateRunner?: boolean) {}
}
export class UpdateCartAction {
  static readonly type = '[Basket] update items';
  constructor(public items: any[]) {}
}

export class LoginSucceedAction {
  static readonly type = AuthActionTypes.LOGIN_SUCCEED;
  constructor(public payload: any) {}
}

export class LoginFailedAction {
  static readonly type = AuthActionTypes.LOGIN_FAILED;
  constructor(public payload?: {}) {}
}

export class LogoutAction {
  static readonly type = AuthActionTypes.LOGOUT;
  public payload: {};
}

export class MasqueradeAction {
  static readonly type = AuthActionTypes.MASQUERADE;
  constructor(public uid: number) {}
}

export class RetrieveAction {
  static readonly type = AuthActionTypes.RETRIEVE_USER;
  constructor(public activateRunner?: boolean) {}
}

export class RetrievePasswordAction {
  static readonly type = AuthActionTypes.RETRIEVE_PASSWORD;
  constructor(public payload: { name: string }) {}
}

export class RetrieveSucceedAction {
  static readonly type = AuthActionTypes.RETRIEVE_USER_SUCCESS;
  constructor(public payload: { body: any }) {}
}

export class UpdateUserAction {
  static readonly type = AuthActionTypes.UPDATE_USER;
  constructor(public payload: { user: any }) {}
}

export class RetrieveFailedAction {
  static readonly type = AuthActionTypes.RETRIEVE_USER_FAILED;
  public payload: {};
}

export class CreateUserAction {
  static readonly type = AuthActionTypes.CREATE_USER;
  constructor(public formData: any) {}
}

export class CreateUserSuccessAction {
  static readonly type = AuthActionTypes.CREATE_USER_SUCCESS;
}

export class CreateUserFailedAction {
  static readonly type = AuthActionTypes.CREATE_USER_FAILED;
  constructor(public error: any) {}
}

export class LoadUserNotificationsAction {
  static readonly type = AuthActionTypes.LOAD_USER_NOTIFICATIONS;
  constructor(public error: any) {}
}

export class UpdateUserProfileData {
  static readonly type = AuthActionTypes.UPDATE_USER_PROFILE_DATA;
  constructor(public userProfile: any) {}
}

export class UpdateNotificationStatusAction {
  static readonly type = AuthActionTypes.UPDATE_NOTIF_STATUS;
  constructor(public noid: number, public status: string) {}
}

export class CompleteAuthRunnerData {
  static readonly type = AuthActionTypes.COMPLETE_RUNNER_DATA;
  constructor(
    public data: {
      firstname: string;
      lastname: string;
      place: string;
      postal_code: string;
      country: string;
      nationality: string;
      gender: 0 | 1;
      birthdate: number;
    },
  ) {}
}

export class LoadPublicPremiumPrices {
  static readonly type = AuthActionTypes.LOAD_PREMIUM_PRICES;
}

export class LoadUserPremiumData {
  static readonly type = AuthActionTypes.LOAD_USER_PREMIUM_DATA;
  constructor(public userId: number) {}
}

export class ChangePremiumSubscriptionType {
  static readonly type = AuthActionTypes.CHANGE_PREMIUM_SUBSCRIPTION_TYPE;
  constructor(public subscriptionId: number, public newPrice: IPremiumRefPrice) {}
}

export class CancelSubscriptionAutoRenewal {
  static readonly type = AuthActionTypes.CANCEL_PREMIUM_AUTO_RENEWAL;
  constructor(public subscriptionId: number) {}
}

export class UpdateUserPassword {
  static readonly type = AuthActionTypes.UPDATE_USER_PASSWORD;
  constructor(public uid: number, public current_pass: string, public new_pass: string) {}
}

export class UpdateUserMailPreferences {
  static readonly type = AuthActionTypes.UPDATE_USER_MAIL_PREFERENCES;
  constructor(
    public uid: number,
    public data: { mail: string; mail_newsletter: boolean; mail_notification: boolean },
  ) {}
}

export class UpdateUserLanguage {
  static readonly type = AuthActionTypes.UPDATE_USER_LANGUAGE;
  constructor(public uid: number, public language: 'fr' | 'nl' | 'en') {}
}

export class UpdateUserAddsPreferences {
  static readonly type = AuthActionTypes.UPDATE_USER_ADDS_PREFERENCES;
  constructor(public uid: number, public hide_other_banner: boolean) {}
}
