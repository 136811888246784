import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { PlatformServiceService } from '../platform-service.service';

@Directive({
  selector: '[ssrOnly]',
})
export class SsrOnlyDirective {
  isShow = false;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private pss: PlatformServiceService,
  ) {
    if (this.pss.isSsr()) {
      if (!this.isShow) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isShow = true;
      }
    } else {
      this.viewContainer.clear();
      this.isShow = false;
    }
  }
}
