<svg
  version="1.1"
  id="Calque_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 100 100"
  xml:space="preserve"
  width="170"
  height="170"
  class="block"
>
  <polyline class="block" fill="transparent" stroke="#e8a30c" stroke-width="2" [attr.points]="svgPath"></polyline>
</svg>
