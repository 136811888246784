import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EUserRole, ITrail } from '@betrail-libs/shared/interfaces/interfaces';
import { ALL_COUNTRIES_CODES } from '@betrail-libs/shared/utils';
import { EventService } from '@betrail-libs/trail-data-state';
import { BetrailUxFormsService } from '../../../betrail-ux-forms.service';
import { AuthStateService } from '@betrail-libs/auth-state';

@Component({
  selector: 'bux-trail-form',
  templateUrl: './trail-form.component.html',
  styleUrls: ['./trail-form.component.scss', '../common-form.scss'],
})
export class TrailFormComponent {
  showErrorMessage: boolean = false;
  errorMessage: string = '';
  trail: ITrail;
  trailFormGroup: UntypedFormGroup;
  addImageButton: ElementRef;
  addImageButtonDisabled: boolean = false;
  isAdmin$ = this.authState.hasRole(EUserRole.Administrator);
  isEncoder$ = this.authState.hasRole(EUserRole.Encoder);
  countries = ALL_COUNTRIES_CODES.map(c => {
    return { key: c, name: 'COUNTRY_' + c };
  });

  @ViewChild('addImageButton', { static: true }) updatedImage: any = 'Pas encore dimage chargée';

  constructor(
    public eventService: EventService,
    private betrailUxFormsService: BetrailUxFormsService,
    private authState: AuthStateService,
  ) {
    this.trailFormGroup = this.betrailUxFormsService.buildTrailForm();
  }

  optionsGPX = [
    {
      text: 'GPX_OPTION_0',
      value: 0,
    },
    {
      text: 'GPX_OPTION_1',
      value: 1,
    },
    {
      text: 'GPX_OPTION_2',
      value: 2,
    },
    {
      text: 'GPX_OPTION_3',
      value: 3,
    },
    {
      text: 'GPX_OPTION_4',
      value: 4,
    },
  ];

  addImage(file: File): void {
    this.showErrorMessage = false;

    if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
      if (file.size < 2000000) {
        this.addImageButtonDisabled = true;
        this.getBase64(file).then(data => {
          try {
            this.eventService.postDrupalImage(file.name, JSON.stringify(data), {}).subscribe(r => {
              this.updatedImage = r;
              this.addImageButtonDisabled = false;
              this.trailFormGroup.patchValue({ image: r.fid });
            });
          } catch (error) {
            this.addImageButtonDisabled = false;
          }
        });
      } else {
        this.showErrorMessage = true;
        this.errorMessage = 'IMAGE_MAX_SIZE_EXCEEDED_ERROR';
        //this.openDialog('Le fichier soumis est trop grand... A vrai dire, il ne peut guère dépasser les 2 Mo.', 'Aie !');
      }
    } else {
      this.showErrorMessage = true;
      this.errorMessage = 'IMAGE_INCORRECT_TYPE_ERROR';
      //this.openDialog('Bon, en fait... seules les images de type JPG, JPEG ou PNG sont autorisées.', 'Oups !');
    }
  }

  removeImage(): void {
    this.updatedImage = '';
  }

  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  watchEmail(email: string) {
    if (!email) return false;
    else if (!email.trim()) return false;
    else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(email.trim())) return false;
    else return true;
  }

  patchFormValues(trail: ITrail) {
    //TODO : move to service
    this.trail = trail;

    this.trailFormGroup.patchValue({
      id: trail.id,
      title: trail.title,
      image: trail.image,
      country: trail.country,
      postal_code: trail.postal_code,
      place: trail.place,
      website: trail.website,
      facebook: trail.facebook,
      organizer: trail.organizer,
      organizer_email: trail.organizer_email,
      organizer_phone: trail.organizer_phone,
      gpx_option: trail.gpx_option,
      closed: trail.closed,
      near_countries: trail.near_countries,
      other_used_countries: trail.other_used_countries,
    });
  }

  patchFormTitle(value) {
    this.trailFormGroup.patchValue({
      title: value,
    });
  }
}
