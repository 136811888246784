<ng-container *ngIf="timers$ | async; let timers">
  <mat-form-field appearance="fill" fxFlex="100" class="timer-selector">
    <mat-label>{{ 'TIMER' | transloco }}</mat-label>
    <mat-select (selectionChange)="setTimer($event)" (openedChange)="openedHasChanged($event)" [value]="defaultValue">
      <mat-option [value]="0">
        <div class="timer-selector-image"></div>
        <div class="timer-container" fxLayout="column" fxLayoutAlign="center">
          <div class="title">{{ 'NONE' | transloco }} / {{ 'DONT_KNOW' | transloco }} / {{'OTHER' | transloco }}</div>
        </div>
      </mat-option>
      <mat-option *ngFor="let timer of timers" [value]="timer.id">
        <div
          *ngIf="timer?.photo && timer?.photo?.max_100_height"
          class="timer-selector-image"
          [style.background]="timer?.photo?.max_100_height | sanitizeStyleUrl"
        ></div>
        <div class="timer-selector-container" fxLayout="column" fxLayoutAlign="center">
          <div class="timer-selector-title">{{ timer.title }} ({{ timer.country }})</div>
          <div *ngIf="subtitlesVisible" class="timer-selector-subtitle">{{ timer.website }}</div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>

<ng-template #defaultImage>
  <div class="image"></div>
</ng-template>
