<ng-container *ngIf="userRegistrations$ | async; let reg">
  <ng-container
    *ngIf="
      race.date >= now &&
      race.betrail_registration !== 1 &&
      (type === 'public' || (type === 'private' && (user$ | async)?.runner?.id > 0))
    "
  >
    <ng-container *ngIf="type === 'public' || (user$ | async)?.runner?.id === +ruid">
      <div class="button-container">
        <ng-container *ngIf="reg[race.id] && reg[race.id].status === 'planned'; else button">
          <button [disabled]="loading" class="outline" mat-button [matMenuTriggerFor]="options">
            <div class="badge"><mat-icon class="icon badge">star</mat-icon></div>
            <ng-container *ngIf="!loading; else loadingText">
              {{ 'INTERESTED' | transloco }} <mat-icon>expand_more</mat-icon>
            </ng-container>
          </button>
        </ng-container>

        <ng-template #button>
          <ng-container *ngIf="race">
            <button
              *hasRunner
              mat-raised-button
              class="raised outline"
              [disabled]="loading"
              (click)="addToCalendar(race)"
            >
              <ng-container *ngIf="!loading; else loadingText">{{ 'ADD_TO_MY_AGENDA' | transloco }}</ng-container>
            </button>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loadingText> ... </ng-template>

<mat-menu #options="matMenu" yPosition="below" [overlapTrigger]="false">
  <button *hasRunner mat-button class="remove-button" (click)="removeFromCalendar(race)">
    {{ 'NO_MORE_INTERESTED' | transloco }}
  </button>
</mat-menu>
