import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bux-premium-button',
  template: `
    <button
      mat-button
      class="!h-8 !w-64 !flex justify-center items-center !text-base !rounded-3xl !bg-gray-200"
      (click)="clicked.emit()"
    >
      <ng-container *ngIf="icon"
        ><mat-icon>{{ icon }}</mat-icon
        >&nbsp;</ng-container
      >{{ text }}&nbsp;<span>P</span>
    </button>
  `,
})
export class PremiumButtonComponent {
  @Input() icon?: string;
  @Input() text = 'NO TEXT PROVIDED';

  @Output() clicked = new EventEmitter<void>();
}
