<div>
  <div class="filter-container" ngClass.lt-sm="small">
    <button mat-icon-button (click)="close()" class="closeButton">
      <mat-icon>close</mat-icon>
    </button>
    <div class="filter-content">
      <h3>{{'FILTER_QUICK_FILTERS' | transloco}}</h3>
      <div class="filter-sort">
        <ng-container *ngIf="type === 'date'">
          <button *ngFor="let year of dates" mat-raised-button (click)="setQuickFilter(type,year)">{{ year }}</button>
          <div>
            <button *ngFor="let dateFilter of dateFilters" mat-raised-button (click)="setQuickFilter(type,dateFilter)">
              {{ dateFilter | transloco }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="type === 'country'">
          <button *ngFor="let country of countries" mat-raised-button (click)="setQuickFilter(type,country.values)">
            {{ country.title | transloco }}
          </button>
        </ng-container>
        <ng-container *ngIf="type === 'category'">
          <button *ngFor="let category of categories" mat-raised-button (click)="setQuickFilter(type,category.values)">
            {{ category.title | transloco }}
          </button>
        </ng-container>
        <ng-container *ngIf="type === 'runType'">
          <button
            *ngFor="let runType of runTypesQuickFilters"
            mat-raised-button
            (click)="setQuickFilter(type,runType.values)"
          >
            {{ "FILTER_TRAIL_TYPE_" + runType.title | transloco }}
          </button>
        </ng-container>
        <ng-container *ngIf="type === 'raceType'">
          <button
            *ngFor="let raceType of raceTypesQuickFilters"
            mat-raised-button
            (click)="setQuickFilter(type,raceType.values)"
          >
            {{ "RACE_TYPE_" + raceType.title | transloco }}
          </button>
        </ng-container>
        <ng-container *ngIf="type === 'distance'">
          <button
            *ngFor="let distance of distanceTypesQuickFilters"
            mat-raised-button
            (click)="setQuickFilter(type,distance.values)"
          >
            {{ distance.title }}
          </button>
        </ng-container>
        <ng-container *ngIf="type === 'elevation'">
          <button
            *ngFor="let elevation of elevationTypesQuickFilters"
            mat-raised-button
            (click)="setQuickFilter(type,elevation.values)"
          >
            {{ elevation.title | transloco }}
          </button>
        </ng-container>
        <ng-container *ngIf="type === 'drop'">
          <button
            *ngFor="let drop of dropTypesQuickFilters"
            mat-raised-button
            (click)="setQuickFilter(type,drop.values)"
          >
            {{ drop.title }}
          </button>
        </ng-container>
      </div>
      <h3>{{'FILTER_CUSTOMIZE_FILTER' | transloco}}</h3>
      <ng-container *ngIf="type === 'date'">
        <div fxLayout="row">
          <div fxFlex="50">{{ 'FROM' | transloco }} {{formatDate(dateStart)}}</div>
          <div fxFlex="50">{{ 'TO' | transloco }} {{formatDate(dateEnd)}}</div>
        </div>
        <div fxLayout="row">
          <div fxFlex="50">
            <mat-calendar
              ref-{{element.name}}
              [startAt]="dateStart"
              [(selected)]="dateStart"
              [minDate]="minDate"
              [maxDate]="maxDate"
            ></mat-calendar>
          </div>
          <div fxFlex="50">
            <mat-calendar
              ref-{{element.name}}
              [startAt]="dateEnd"
              [(selected)]="dateEnd"
              [minDate]="minDate"
              [maxDate]="maxDate"
            ></mat-calendar>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="type === 'country'">
        <div fxLayout="column">
          <mat-slide-toggle
            *ngFor="let country of countriesSelect"
            [checked]="hasBeenSelected(type,country)"
            (change)="changeValueSelect(type,$event,country)"
          >
            {{country}}
          </mat-slide-toggle>
        </div>
      </ng-container>
      <ng-container *ngIf="type === 'category'">
        <div fxLayout="column">
          <ng-container *ngFor="let category of categoriesSelect">
            <div *ngIf="category === 'LABEL_BETRAIL'" class="special-separator">
              <strong>{{ 'FILTER_WITH_LABEL' | transloco }} (Benelux)</strong>
            </div>
            <mat-slide-toggle
              [checked]="hasBeenSelected(type,category)"
              (change)="changeValueSelect(type,$event,category)"
              [class.mt-3]="category === 'LABEL_BETRAIL'"
            >
              {{ category | transloco }}
            </mat-slide-toggle>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="type === 'runType'">
        <div fxLayout="column">
          <mat-slide-toggle
            *ngFor="let runType of runTypes"
            [checked]="hasBeenSelected(type,runType)"
            (change)="changeValueSelect(type,$event,runType)"
          >
            {{ "FILTER_TRAIL_TYPE_" + runType | transloco }}
          </mat-slide-toggle>
        </div>
      </ng-container>
      <ng-container *ngIf="type === 'raceType'">
        <div fxLayout="column">
          <mat-slide-toggle
            *ngFor="let raceType of raceTypes"
            [checked]="hasBeenSelected(type,raceType.values)"
            (change)="changeValueSelect(type,$event,raceType.values)"
          >
            {{ "RACE_TYPE_" + raceType.title | transloco }}
          </mat-slide-toggle>
        </div>
      </ng-container>
      <ng-container *ngIf="type === 'distance' || type === 'elevation' || type === 'drop'">
        <div class="pt-[25px] pb-[15px] px-[40px]">
          <app-custom-range-slider
            [maxValue]="this.data.filterDialogContent === 'distance' ? 250 : this.data.filterDialogContent === 'elevation' ? 120 : 10000"
            [values]="(type === 'distance' ? selectedDistance$ : type === 'elevation' ? selectedElevation$ : selectedDrop$) | async"
            (valueChange)="changeDistanceRange($event)"
          ></app-custom-range-slider>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="filter-footer">
    <button class="left" mat-raised-button color="theme" (click)="disableFilter(type)">
      {{ 'FILTER_DISABLE' | transloco }}
    </button>
    <button class="right" mat-raised-button [disabled]="disableBtn" color="accent" (click)="setFilters(type)">
      {{ 'FILTER_APPLY' | transloco }}
    </button>
  </div>
</div>
