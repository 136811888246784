import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bt-card',
  templateUrl: './bt-card.component.html',
  styleUrls: ['./bt-card.component.scss'],
})
export class BtCardComponent {
  @Input() color = 'blue';
  @Input() backgroundImage;
  @Input() shadowed = true;
  @Input() background = 'white';
}
