<section>
  <header class="text-center">
    <h2 class="text-lg">{{ 'FROM_PREDICTED_MSG_TITLE' | transloco : {year: data.year - 1} }}</h2>
  </header>
  <hr />
  <div class="max-w-[420px] text-center">
    <p class="px-4">
      {{ 'FROM_PREDICTED_MSG_TXT' | transloco : {trail: data.name, thisEdition: data.year - 1, nextEdition: data.year} }}
    </p>
  </div>
  <div class="flex justify-between p-4">
    <button mat-button mat-dialog-close>{{ 'CONTINUE_ON' | transloco }} {{ data.year - 1 }}</button>
    <a
      mat-raised-button
      color="primary"
      class="!no-underline"
      mat-dialog-close
      [routerLink]="['/', 'calendar', 'add', data.alias]"
    >
      <mat-icon>playlist_add</mat-icon><span>&nbsp; {{ 'ADD' | transloco }} {{ data.year }}</span>
    </a>
  </div>
</section>
