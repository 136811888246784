<div
  [class.bt-shadowed]="shadowed"
  class="card bt-rounded {{ color }} background-{{ background }}"
  ngClass.gt-sm="large"
  [style.background]="backgroundImage ? backgroundImage : null "
>
  <div class="card-header">
    <span class="card-header-title {{ color }}">
      <span class="card-header-title-content">
        <ng-content select="[card-title]"></ng-content>
      </span>
    </span>
  </div>
  <div class="card-raw-body">
    <ng-content select="[card-raw-body]"></ng-content>
  </div>
  <div class="card-body">
    <ng-content select="[card-body]"></ng-content>
    <div class="card-footer">
      <ng-content select="[card-footer]"></ng-content>
    </div>
  </div>
</div>
