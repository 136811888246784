import { inject, Injectable } from '@angular/core';
import { IRace } from '@betrail-libs/shared/interfaces/race.model';
import { Store } from '@ngxs/store';
import { CreateBackyardDistances, CreateNewDistance, DeleteRaceById, EditRaceData } from '../trail-data.action';

@Injectable({ providedIn: 'root' })
export class RaceStateService {
  #store = inject(Store);

  createNewDistance = (race: Partial<IRace>) => this.#store.dispatch(new CreateNewDistance(race));

  generateBackyardRaces = (data: { baseRace: Partial<IRace>; laps: number[] }) =>
    this.#store.dispatch(new CreateBackyardDistances(data));

  editRaceData = (race: IRace) => this.#store.dispatch(new EditRaceData(race));

  deleteDistance = (raceId: number) => this.#store.dispatch(new DeleteRaceById(raceId));
}
