import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ERankingCode, ILevelRank, IScoreRank, ITrailCupCountries } from '@betrail-libs/shared/interfaces/interfaces';
import { RankingOptions } from '@betrail-libs/shared/interfaces/ranking-options.model';
import { IResult } from '@betrail-libs/shared/interfaces/result.model';

@Injectable({ providedIn: 'root' })
export class RankingsApiService {
  constructor(@Inject('appsConfig') private config: { nodejsApi: string }, private http: HttpClient) {}

  getRankingSummaries(
    ranking: ERankingCode,
    type: string,
    country: string,
    gender: 'female' | 'scratch' | 'all',
    params?: { userId: number; groupId: number },
  ) {
    const httpParams = params ? { params: new HttpParams({ fromObject: params }) } : {};
    return this.http.get<{ female?: ILevelRank[] | IScoreRank[]; scratch?: ILevelRank[] | IScoreRank[] }>(
      `${this.config.nodejsApi}/score/summary/${ranking}/${gender}/${type}/${country}`,
      httpParams,
    );
  }

  getAllRankings(
    ranking: ERankingCode,
    offset: number,
    gender: 'female' | 'scratch',
    type: string,
    country: string,
    params?: { userId: number; groupId: number },
  ) {
    const httpParams = params ? { params: new HttpParams({ fromObject: params }) } : {};
    return this.http.get<ILevelRank[] | IScoreRank[]>(
      `${this.config.nodejsApi}/score/full/${ranking}/${offset}/${gender}/${type}/${country}`,
      httpParams,
    );
  }

  getAllRankingsOptions() {
    return this.http.get<RankingOptions[]>(`${this.config.nodejsApi}/score/ranking-options/all`);
  }

  updateRankingOptions(options: RankingOptions) {
    return this.http.put<RankingOptions>(
      `${this.config.nodejsApi}/score/ranking-options/update/${options.id}`,
      options,
    );
  }

  removeSponsorFromRanking(optionId: string | number) {
    return this.http.put<RankingOptions>(`${this.config.nodejsApi}/score/ranking-options/remove-sponsor`, { optionId });
  }

  getScoreCountryDetails(rankingId: string | number, type: 'tc_pts' | 'uc_pts', country: string, year: string) {
    return this.http.get<ITrailCupCountries>(
      `${this.config.nodejsApi}/score/countries/${type}/${country}/${year}/${rankingId}`,
    );
  }

  getScoreResultsDetails(
    rankingType: 'level' | 'bts_score' | 'btu_score' | 'tc_pts' | 'uc_pts',
    rankingId: string | number,
    levelType?: string,
    country?: string,
    year?: string,
  ) {
    return this.http.post<IResult[]>(`${this.config.nodejsApi}/score/results/${rankingType}`, {
      rankingId,
      levelType,
      country,
      year,
    });
  }
}
