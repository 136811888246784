import { Component, Input } from '@angular/core';

@Component({
  selector: 'bux-sticky-footer',
  templateUrl: './sticky-footer.component.html',
})
export class StickyFooterComponent {
  @Input() backgroundColor = 'white';
  @Input() textColor = 'black';
}
