import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IRunner } from '@betrail-libs/shared/interfaces/runner.model';
import { ALL_COUNTRIES_CODES, validRunnerBirthdate } from '@betrail-libs/shared/utils';
import { EventService } from '@betrail-libs/trail-data-state';
import { BetrailUxFormsService } from '@betrail/ux/betrail-ux-forms.service';

@Component({
  selector: 'welcome-step-runner-data',
  templateUrl: './welcome-step-runner-data.component.html',
  styleUrls: ['./welcome-step-runner-data.component.scss', '../welcome-steps-common.scss'],
})
export class WelcomeStepRunnerDataComponent {
  _runner: IRunner;
  validBirthdate: boolean;
  constructor(
    private fb: UntypedFormBuilder,
    private eventService: EventService,
    private formsService: BetrailUxFormsService,
  ) {}

  saveButtonDisabled: boolean;
  saveText: string = 'SAVE';

  countries = ALL_COUNTRIES_CODES;

  @Input() formGroup: UntypedFormGroup;
  @Input('runner') set runner(runner: IRunner) {
    this._runner = runner;
    this.validBirthdate = runner.birthdate ? true : false;
    if (runner.birthdate) {
      this.validBirthdate = validRunnerBirthdate(runner.birthdate);
    }
  }
  @Input() isLightUser = false;

  @Output() submitted = new EventEmitter();

  submit() {
    this.submitted.emit(this.formGroup.value);
  }

  disableButton(type: String) {
    if (type == 'save') {
      this.saveButtonDisabled = true;
      this.saveText = 'SAVING';
    }
  }
}
