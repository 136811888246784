import { Component, OnInit } from '@angular/core';
import { formatDate } from '@betrail-libs/shared/utils';

@Component({
  selector: 'weeks-selector',
  templateUrl: './weeks-selector.component.html',
  styleUrls: ['./weeks-selector.component.scss'],
})
export class WeeksSelectorComponent implements OnInit {
  month;
  selectedWeeks: any[] = [];

  date = new Date();

  generateMonthWeeks(date: Date) {
    let weeks = [];
    date.setDate(1);
    date.setHours(12);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    let dateDay = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    let dayOfFirst = date.getDay();
    if (dayOfFirst == 0) {
      dayOfFirst = 7;
    }

    let firstCellDate = date.setDate(dateDay - dayOfFirst + 1);

    let currentDate = new Date(firstCellDate);

    let w = 0;
    while (w < 6) {
      if (w < 4 || currentDate.getDate() > 21) {
        let week: Week = { firstDay: formatDate(currentDate), days: [], selected: false, full: false };
        let i = 0;
        while (i < 7) {
          let currentDateDay = currentDate.getDate();
          if (i == 0) {
            //week.firstDay = currentDate;
          }
          let day = {
            date: currentDate.getDate(),
            currentMonth: currentDate.getMonth() - month == 0,
          };
          week.days.push(day);
          currentDate = new Date(currentDate.setDate(currentDateDay + 1));
          i++;
        }
        if (this.selectedWeeks.indexOf(week.firstDay) > -1) {
          week.selected = true;
        }
        weeks.push(week);
      }
      w++;
    }
    return {
      label: 'MONTH_' + (month + 1),
      month,
      year,
      weeks,
    };
  }

  nextMonth(month) {
    let date = new Date();
    date.setFullYear(month.year);
    date.setMonth(month.month + 1);
    this.month = this.generateMonthWeeks(date);
  }

  lastMonth(month) {
    let date = new Date();
    date.setFullYear(month.year);
    date.setMonth(month.month - 1);
    this.month = this.generateMonthWeeks(date);
  }

  selectWeek(week) {
    if (this.selectedWeeks.indexOf(week.firstDay) === -1) {
      if (week.full === false) {
        this.selectedWeeks.push(week.firstDay);
        week.selected = true;
      }
    } else {
      this.selectedWeeks = this.selectedWeeks.filter(d => d != week.firstDay);
      week.selected = false;
    }
  }

  ngOnInit() {
    this.date = new Date(this.date.setMonth(0));
    this.month = this.generateMonthWeeks(this.date);
  }
}

interface Week {
  firstDay: string;
  days: Day[];
  selected: boolean;
  full: boolean;
}

interface Day {
  date: number;
  currentMonth: boolean;
}
