import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPath } from '@betrail-libs/shared/interfaces/path.model';
import { EventService } from '@betrail-libs/trail-data-state';

@Component({
  selector: 'path-download-warning',
  templateUrl: './path-download-warning.component.html',
  styleUrls: ['./path-download-warning.component.scss'],
})
export class PathDownloadWarningComponent {
  path: IPath;
  baseUrl: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: EventService,
    public dialogRef: MatDialogRef<PathDownloadWarningComponent, any>,
  ) {
    this.path = data.path;
    this.baseUrl = this.eventService.getApiUrl();
  }

  close() {
    this.dialogRef.close();
  }
}
