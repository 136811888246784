export const environment = {
  production: true,
  envName: 'Production',
  betrailUri: '/',
  api: '/api/encodage/',
  hmr: false,
  betrail: '/',
  wsPath: '/api/websocket',
  wsServer: '/',
  nodejsApi: '/api',
  signupApi: '/api/signupapi',
  mangoURL: 'https://api.mangopay.com',
  mangoClientId: 'betrail',
  mock: false,
  websocket: true,
};
