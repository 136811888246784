<div *ngIf="month" fxLayout="column" class="month-container">
  <div class="month-header" fxLayout="column">
    <div class="month-header-title" fxLayout="row" fxLayoutAlign="space-between">
      <button mat-icon-button (click)="lastMonth(month)">
        <mat-icon aria-label="Previous month">keyboard_arrow_left</mat-icon>
      </button>
      <strong>{{ month.label | transloco }} {{ month.year }}</strong>
      <button mat-icon-button (click)="nextMonth(month)">
        <mat-icon aria-label="Next month">keyboard_arrow_right</mat-icon>
      </button>
    </div>
    <div class="month-header-weekdays" fxLayout="row">
      <div class="day-container header">
        <span class="label">{{ 'WD_1' | transloco }}</span>
      </div>
      <div class="day-container header">
        <span class="label">{{ 'WD_2' | transloco }}</span>
      </div>
      <div class="day-container header">
        <span class="label">{{ 'WD_3' | transloco }}</span>
      </div>
      <div class="day-container header">
        <span class="label">{{ 'WD_4' | transloco }}</span>
      </div>
      <div class="day-container header">
        <span class="label">{{ 'WD_5' | transloco }}</span>
      </div>
      <div class="day-container header">
        <span class="label">{{ 'WD_6' | transloco }}</span>
      </div>
      <div class="day-container header">
        <span class="label">{{ 'WD_7' | transloco }}</span>
      </div>
    </div>
  </div>
  <div
    class="week-container"
    *ngFor="let week of month.weeks"
    fxLayout="row"
    (click)="selectWeek(week)"
    [class.selected]="week.selected === true"
    [class.full]="week.full === true"
  >
    <div class="day-container noselect" [class.otherMonth]="day.currentMonth === false" *ngFor="let day of week.days">
      {{ day.date }}
    </div>
  </div>
</div>

<pre>
  {{ selectedWeeks | json }}
</pre>

<pre>
  {{ month | json }}
</pre>
