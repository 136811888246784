<header
  class="bt-page-header {{params.additionalClass}}"
  fxLayout="column"
  fxLayoutAlign="center center"
  [style.marginBottom]="-(params.bottom - 7) + 'em'"
  [style.backgroundImage]="headerBackgroundImage"
>
  <!--  [style.backgroundImage]="this.sanitizer.bypassSecurityTrustResourceUrl(headerBackgroundImage)"-->
  <div
    class="bt-page-header-container {{params.additionalClass}}"
    [style.paddingTop]="params.top + 'em'"
    [style.paddingBottom]="params.bottom + 'em'"
    ngClass.gt-md="large"
    fxLayout="column"
  >
    <div class="bt-page-header-content" fxFlex="100" *ngIf="header === true">
      <h1 ngClass.gt-md="large" class="bt-page-header-title bt-text-shadowed">
        <span class="{{params.titleClass}}">
          <ng-content select="[page-title]"></ng-content>
        </span>
      </h1>
      <div class="bt-page-header-description bt-text-shadowed">
        <p>
          <strong>
            <ng-content select="[page-description]"></ng-content>
          </strong>
        </p>
      </div>
    </div>
  </div>
</header>

<section class="bt-page-content {{params.additionalClass}}">
  <div class="bt-page-content-container {{params.additionalClass}}">
    <ng-content select="[page-content]"></ng-content>
  </div>
</section>
<footer class="bt-page-footer">
  <div class="bt-page-footer-container">
    <ng-content select="[page-footer]"></ng-content>
  </div>
</footer>
