import { Component, Input } from '@angular/core';
import { IEvent } from '@betrail-libs/shared/interfaces/event.model';

@Component({
  selector: 'next-registration-event-block',
  templateUrl: './next-registration-event-block.component.html',
  styleUrls: ['./next-registration-event-block.component.scss'],
})
export class NextRegistrationEventBlockComponent {
  @Input() event: IEvent;
}
