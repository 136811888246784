import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  CancelSubscriptionAutoRenewal,
  ChangePremiumSubscriptionType,
  LoadUserPremiumData,
} from '../ngxs/auth.actions';
import { NgxsAuthState } from '../ngxs/auth.state';

@Injectable({ providedIn: 'root' })
export class PremiumStateService {
  #store = inject(Store);

  premiumData$ = this.#store.select(NgxsAuthState.premiumData);

  premiumPrices$ = this.#store.select(NgxsAuthState.premiumPrices);

  loadedPremiumData = this.#store.selectSnapshot(NgxsAuthState.premiumData);

  loadUserPremiumSubscriptionData = (userId: number) => this.#store.dispatch(new LoadUserPremiumData(userId));

  changePremiumSubscriptionType = (subscriptionId: number, newPrice: any) =>
    this.#store.dispatch(new ChangePremiumSubscriptionType(subscriptionId, newPrice));

  cancelSubscriptionAutoRenewal = (subscriptionId: number) =>
    this.#store.dispatch(new CancelSubscriptionAutoRenewal(subscriptionId));
}
