import { Injectable, inject } from '@angular/core';
import { IPath } from '@betrail-libs/shared/interfaces/path.model';
import { IResult, IResultCreation } from '@betrail-libs/shared/interfaces/result.model';
import { IRunner } from '@betrail-libs/shared/interfaces/runner.model';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable, map, switchMap } from 'rxjs';
import { IEvent } from '../../shared/interfaces/event.model';
import {
  AddUserToOrganization,
  CreateNewResult,
  DeleteRaceById,
  DeleteResultById,
  EditAllowTrackingValue,
  EditRaceData,
  EditResult,
  LoadTrailForAlias,
  LoadUserRunnerRegistrations,
  SelectDistanceAlias,
  SelectDistanceFromQueryParamsOrAlias,
  loadPathsForRaceId,
  updatePathsForRaceId,
} from './trail-data.action';
import { TrailDataState } from './trail-data.state';
import { IRace } from '@betrail-libs/shared/interfaces/race.model';

@Injectable({
  providedIn: 'root',
})
export class TrailDataService {
  #store = inject(Store);

  selectedTrail$ = this.#store.select(TrailDataState.selectedTrail);
  selectedEvent$ = this.#store.select(TrailDataState.selectedEvent);
  selectedRace$ = this.#store.select(TrailDataState.selectedDistance);
  selectedResult$ = this.#store.select(TrailDataState.selectedResult);
  selectedEventRegistrations$ = this.#store.select(TrailDataState.selectedEventRegistrations);
  selectedEventSignupRegistrations$ = this.#store.select(TrailDataState.selectedEventSignupRegistrations);
  runnerOne$ = this.#store.select(TrailDataState.selectedRunner);
  runnerMatchup$ = this.#store.select(TrailDataState.selectedMatchupRunner);
  notifications$ = this.#store.select(TrailDataState.selectNotification);
  predictedEvents$ = this.#store.select(TrailDataState.predictedEvents);
  url$ = this.#store.select(RouterState.url);

  isRaceSignupPage$ = this.url$.pipe(map((u: string) => u.includes('registration')));

  // * OLD METHODS * //

  getRaceById(raceId: number) {
    return this.#store.select(state => state.trailData?.races?.[raceId]);
  }
  // RACES / EVENTS / TRAILS

  @Dispatch()
  selectDistanceAlias = (distanceAlias?: string) => new SelectDistanceAlias(distanceAlias);

  getRaceByIdSnap(raceId: any): any {
    return this.#store.selectSnapshot(state => state.trailData?.races?.[raceId]);
  }

  getFirstDistanceEnabledAliasSnapshot(): any {
    return this.#store.selectSnapshot(state =>
      TrailDataState.selectDefaultDistanceAliasForRegistration(state.trailData),
    );
  }

  geRaceByAlias(distance: any): any {
    return this.#store.select(
      state =>
        state.trailData?.raceAlias?.[
          state.trailData.selectedTrailAlias + state.trailData.selectedEventAlias + distance
        ],
    );
  }

  selectDistanceFromAliasOrQueryParams(select: boolean, alias?: string) {
    return this.#store.dispatch(new SelectDistanceFromQueryParamsOrAlias(select, alias));
  }

  deleteRaceResult(
    resultId: number,
    runnerId?: number,
    raceId?: number,
    data?: { raceTitle: string; resultTitle: string; resultTime: string },
  ) {
    return this.#store.dispatch(new DeleteResultById(resultId, runnerId, raceId, data));
  }

  createRaceResult(data: IResultCreation) {
    return this.#store.dispatch(new CreateNewResult(data));
  }

  editResultData(
    result: IResult,
    edited: { gender?: string; distance?: string; time?: string },
    newDistance: { raid?: string | number; title?: string },
  ) {
    return this.#store.dispatch(new EditResult(result, edited, newDistance));
  }

  getSelectedEventSnapshot(): IEvent {
    return this.#store.selectSnapshot(TrailDataState.selectedEvent);
  }

  getSelectedTrail() {
    return this.#store.select(TrailDataState.selectedTrail);
  }

  getSelectedTrailSnapshot() {
    return this.#store.selectSnapshot(TrailDataState.selectedTrail);
  }

  getRaceSignupInfoSnap(distanceAlias): any {
    let race = Object.values(this.#store.selectSnapshot(TrailDataState.selectRaces)).find(
      race => race.alias === distanceAlias || race.id === distanceAlias,
    );
    return race && race.signupData;
  }

  loadTrailByAlias = (trailAlias: string, storeCache = false) => {
    return this.#store
      .dispatch(new LoadTrailForAlias(trailAlias, storeCache))
      .pipe(switchMap(() => this.#store.select(state => TrailDataState.trailByAlias(state.trailData, trailAlias))));
  };

  @Dispatch()
  dismissNotification(action: any) {
    return action;
  }

  loadPathsForRaceId(raceId: string | number) {
    this.#store.dispatch(new loadPathsForRaceId(raceId));
  }

  updatePathsForRaceId(raceId: string | number, paths: IPath[]) {
    return this.#store.dispatch(new updatePathsForRaceId(raceId, paths));
  }

  // USERS / RUNNERS

  editAllowTrackingValue(ruid: number, value: boolean) {
    this.#store.dispatch(new EditAllowTrackingValue(ruid, value));
  }

  getCurrentUserRunnerSnapshot(): IRunner {
    return this.#store.selectSnapshot(state => TrailDataState.currentUserRunner(state.trailData));
  }

  getCurrentUserRunner(): Observable<IRunner> {
    return this.#store.select(state => TrailDataState.currentUserRunner(state.trailData));
  }

  getCurrentRunnerLastAccess(): Observable<number | undefined> {
    return this.runnerOne$.pipe(map(r => r?.user?.access));
  }

  linkUserWithOrga(orgaId: number, userId: number) {
    this.#store.dispatch(new AddUserToOrganization(orgaId, userId));
  }
}
