import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppParamsService } from '@betrail-libs/app-params-state';
import { AuthStateService, RetrieveSucceedAction } from '@betrail-libs/auth-state';
import { IUserState } from '@betrail-libs/shared/interfaces/auth.model';
import { EUserRole } from '@betrail-libs/shared/interfaces/interfaces';
import { IRunner } from '@betrail-libs/shared/interfaces/runner.model';
import { hasRoleId, DEFAULT_WELCOME_STEPS } from '@betrail-libs/shared/utils';
import { BetrailUxFormsService } from '@betrail/ux/betrail-ux-forms.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { WelcomeDialogComponent } from './welcome-dialog.component';

@Injectable({ providedIn: 'root' })
export class WelcomeDialogService {
  isOpen = false;
  doNotClose = false;
  dialogRef: MatDialogRef<WelcomeDialogComponent, any>;
  mobile = false;
  welcomeSteps = { ...DEFAULT_WELCOME_STEPS };

  constructor(
    mediaMatcher: MediaMatcher,
    public dialog: MatDialog,
    private appParamsService: AppParamsService,
    private authStateService: AuthStateService,
    private formsService: BetrailUxFormsService,
    private action$: Actions,
  ) {
    let user = this.authStateService.getUserSnapshot();
    user?.runner?.id && this.displayWelcomeMessage(user);

    this.mobile = mediaMatcher.matchMedia('(max-width: 600px)').matches;
    mediaMatcher.matchMedia('(max-width: 600px)').onchange = ev => {
      this.mobile = ev.matches;
      if (this.isOpen) {
        this.dialogRef.componentInstance.fullscreen = this.mobile;
      }
    };

    // * opens welcome steps after user is created and retrieved successfully
    this.action$.pipe(ofActionSuccessful(RetrieveSucceedAction)).subscribe(() => {
      let _user = this.authStateService.getUserSnapshot();
      this.displayWelcomeMessage(_user);
    });
  }

  openDialog(formType: string, runner): Observable<any> {
    if (!this.isOpen) {
      this.dialogRef = this.dialog.open(WelcomeDialogComponent, {
        panelClass: 'welcome-dialog',
        height: 'auto',
        minWidth: '350px',
        maxWidth: '100vw',
        minHeight: '550px',
        data: { formType, runner },
      });
      this.isOpen = true;
      this.dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(v => {
          this.isOpen = false;
          this.dialogRef = undefined;
        });
    }
    this.dialogRef.componentInstance.formType = formType;
    this.dialogRef.componentInstance.runner = runner;
    this.dialogRef.componentInstance.fullscreen = this.mobile;
    return this.dialogRef.componentInstance.action;
  }

  closeDialog() {
    if (this.isOpen && !this.doNotClose) {
      this.dialogRef.close();
    }
  }

  forceCloseDialog() {
    this.doNotClose = false;
    this.closeDialog();
  }

  numberOfWelcomeSteps() {
    const nb =
      Object.keys(this.welcomeSteps)
        .map(key => this.welcomeSteps[key])
        .filter(s => s.display === true).length - 2;
    // * -2 is because we don't want to count the first and last steps *
    return nb;
  }

  parseUserParams(user: IUserState) {
    return JSON.parse(user?.userDrupal?.userParams || '{}');
  }

  displayWelcomeMessage(user: IUserState) {
    this.welcomeSteps = { ...DEFAULT_WELCOME_STEPS };
    this.doNotClose = true;
    const userParams = this.parseUserParams(user);
    const isLightUser = user?.roles.filter(role => role.rid === EUserRole.LightUser).length > 0;

    if (userParams && userParams.resultsChecked && userParams.resultsChecked === true) {
      if (this.welcomeSteps.results) {
        this.welcomeSteps.results.display = false;
      }
      if (this.welcomeSteps.resultsClaimingTuto || isLightUser) {
        this.welcomeSteps.resultsClaimingTuto.display = false;
      }
    }

    const appParams = this.appParamsService.getAppParamsSnapshot();

    let diff;
    if (userParams.avatarLater && !isNaN(+userParams.avatarLater)) {
      diff = new Date().getTime() - userParams.avatarLater;
    }
    if (appParams.avatarLater === true || (diff && diff < 7 * 24 * 60 * 60 * 1000) || isLightUser) {
      this.welcomeSteps.avatar.display = false;
    }

    if (appParams.confirmationLater === true || isLightUser) {
      this.welcomeSteps.emailconfirmation.display = false;
    }
    const r = user.runner;
    if (r) {
      let runnerMissingDataForm = this.formsService.buildRunnerMissingDataForm(r);

      if ((runnerMissingDataForm.valid && this.welcomeSteps.identity) || userParams.resultsChecked) {
        this.welcomeSteps.identity.display = false;
      }

      if (!hasRoleId(user, 4) || !hasRoleId(user, 5) || !hasRoleId(user, 7) || !hasRoleId(user, 10)) {
        if (this.welcomeSteps.emailconfirmation) {
          this.welcomeSteps.emailconfirmation.display = false;
        }
      }

      if (r && r.photo && r.photo.id !== undefined) {
        if (this.welcomeSteps.avatar) {
          this.welcomeSteps.avatar.display = false;
        }
      }

      const nbOfSteps = this.numberOfWelcomeSteps();
      if (nbOfSteps > 0) {
        if (!this.isOpen) {
          this.openDialog('welcome', {});
        }
        this.dialogRef.disableClose = true;
        let dialogRef = this.dialogRef;
        let componentInstance = dialogRef.componentInstance;
        this.dialogRef.componentInstance.welcomeSteps = this.welcomeSteps;
        this.dialogRef.componentInstance.formType = 'welcome';
        this.dialogRef.componentInstance.welcomeStep = 'welcome';
        componentInstance._runner = r;
        componentInstance.fillWelcomeForm(r);
      } else {
        this.forceCloseDialog();
      }
    }
  }

  populateRunnerWithUserData(runner: IRunner, user) {
    if (user.runner?.lastname) {
      runner.lastname = user.runner.lastname;
    }
    if (user.runner?.firstname) {
      runner.firstname = user.runner.firstname;
    }
    if (user.runner?.birthdate) {
      let d = new Date(user.runner.birthdate).getTime() / 1000;
      if (!isNaN(d)) {
        runner.birthdate = d;
      }
    }
    if (user.runner?.place) {
      runner.place = user.runner.place;
    }
    if (user.runner?.nationality) {
      runner.nationality = user.runner.nationality;
    }
    if (user.runner?.country) {
      runner.country = user.runner.country;
    }
    if (user.runner?.postal_code) {
      runner.postal_code = user.runner.postal_code;
    }
    return runner;
  }

  enableButton(type) {
    if (this.isOpen) {
      if (this.isOpen) {
        this.dialogRef.componentInstance.enableButton(type);
      }
    }
  }

  displayError(error: string) {
    if (this.isOpen) {
      if (this.isOpen) {
        this.dialogRef.componentInstance.error = error;
      }
    }
  }
}
