import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
})
export class NotificationMessageComponent {
  /* Inputs */
  @Input() type: string;
}
