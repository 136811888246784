import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { IOtherResult, IResult } from '@betrail-libs/shared/interfaces/result.model';

@Pipe({
  name: 'getResultImage',
})
export class GetResultImagePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(
    result: IResult | IOtherResult,
    type?: 'small' | 'medium' | 'large' | 'square',
    otherStyles?: string,
  ): SafeStyle {
    let value: string;

    if ('run_type' in result) {
      switch (result.run_type) {
        case 'road_race':
          value = 'assets/img/Route_NB.jpg';
          break;
        case 'nature_race':
          value = 'assets/img/Chemin_NB.jpg';
          break;
        default:
          value = 'assets/img/Trail_NB.jpg';
      }
    } else if (result?.race?.event?.trail?.hide === 1) {
      value = 'assets/img/Trail_NB.jpg';
    } else {
      const photos = result?.race?.event?.trail?.photo;
      switch (type) {
        case 'small':
          value = photos?.small_image;
          break;
        case 'medium':
          value = photos?.medium_image;
          break;
        case 'large':
          value = photos?.large_image;
          break;
        case 'square':
          value = photos?.square_image;
          break;
        default:
          value = photos?.small_image;
      }
    }

    return this.sanitizer.bypassSecurityTrustStyle(`${otherStyles ? otherStyles + ' ' : ''}url('${value}')`);
  }
}
