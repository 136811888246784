import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterNumberElement } from '../filterElement.model';

@UntilDestroy()
@Component({
  selector: 'app-calendar-filter-number-range',
  templateUrl: './calendar-filter-number.component.html',
  styleUrls: ['./calendar-filter-number.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class CalendarFilterNumberComponent implements OnInit {
  elements: FilterNumberElement[];
  debouncer = new BehaviorSubject<number[]>([]);
  values = new BehaviorSubject<number[]>([]);
  range = new BehaviorSubject<any>({});

  @Input() sliderMaxVal: number;
  @Input() quickFilters;
  @Input() unit!: string;
  @Input() description: string;
  @Input() filteredElements$: Observable<any> = new BehaviorSubject([]);
  @Input() set _values(values) {
    if (values && values[0] != 0 && values[1] != 0) {
      this.values.next([values[0], values[1]]);
    }
  }

  @Output() numberFilter = new EventEmitter();
  @Output() updateSummary = new EventEmitter();
  @Output() apply = new EventEmitter();

  ngOnInit() {
    this.filteredElements$.pipe(untilDestroyed(this)).subscribe(filteredElements => {
      if (filteredElements && filteredElements.length > 0) {
        const values = [+filteredElements[0].value, +filteredElements[filteredElements.length - 1].value];
        this.changeValues(values);
        const range = {
          min: [filteredElements[0].minAndMax[0]],
          max: [filteredElements[filteredElements.length - 1].minAndMax[1]],
        };
        this.range.next(range);
        this.elements = filteredElements;
      }
    });
  }

  getSummary(values, range) {
    let max = values[1];
    if (values[1] == range.max) {
      max = values[1] + '+';
    }
    const summary = values[0] + '-' + max + ' ' + this.unit;
    return summary;
  }

  changeValue(value, elementName) {
    if (value > -1) {
      this.elements.find(e => e.name == elementName).value = value;
      //this.numberFilter.emit(this.elements.map(e => e.value));
      this.changeValues(this.elements.map(e => e.value));
    }
  }

  changeValues(values) {
    if (Math.min(...values) > -1) {
      this.numberFilter.emit(values);
      this.values.next(values);
      this.updateSummary.emit(this.getSummary(values, this.range));
    }
  }

  activeQuickFilter(value, summary) {
    this.changeValues(value);
    this.numberFilter.emit(value);
    this.updateSummary.emit(summary);
    this.apply.emit();
  }
}
