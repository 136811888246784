import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { LoadUserRunnerRegistrations } from '../trail-data.action';

@Injectable({ providedIn: 'root' })
export class RegistrationStateService {
  #store = inject(Store);

  loadRunnerRegistrations = () => this.#store.dispatch(new LoadUserRunnerRegistrations());
}
