import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { APP_CONFIG, AuthStateService } from '@betrail-libs/auth-state';
import { IAppConfig } from '@betrail-libs/shared/interfaces/app-config.model';
import { EUserRole, IGameData } from '@betrail-libs/shared/interfaces/interfaces';
import { IRunner, IRunnerEdition, IRunnerExtraInfos } from '@betrail-libs/shared/interfaces/runner.model';
import { map, switchMap, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RunnerApiService {
  #authState = inject(AuthStateService);
  #config = inject<IAppConfig>(APP_CONFIG);
  #http = inject(HttpClient);

  getRunner = (ruid: string) => {
    return this.#authState.geUserAfterLoading().pipe(
      take(1),
      switchMap(user => {
        if (user?.roles.find(r => r.rid === EUserRole.Administrator)) {
          return this.#http
            .get<{ body: IRunner }>(`${this.#config.nodejsApi}/runner/asAdmin/${ruid}`, { withCredentials: true })
            .pipe(map(r => r.body));
        }
        return this.#http
          .get<{ body: IRunner }>(`${this.#config.nodejsApi}/runner/${ruid}`, { withCredentials: true })
          .pipe(map(r => r.body));
      }),
    );
  };

  updateRunner = (ruid: number, data: { runner: IRunnerEdition; gameData?: IGameData }) =>
    this.#http
      .put<{ body: IRunner }>(`${this.#config.nodejsApi}/runner/${ruid}`, { data }, { withCredentials: true })
      .pipe(map(r => r.body));

  updateRunnerExtraInfos = (ruid: number, data: IRunnerExtraInfos) => {
    return this.#http
      .put<{ body: IRunnerExtraInfos }>(
        `${this.#config.nodejsApi}/runner/extraInfos/${ruid}`,
        { data },
        { withCredentials: true },
      )
      .pipe(map(r => r.body));
  };
}
