import { Component, Input } from '@angular/core';

@Component({
  selector: 'bux-premium-stamp',
  template: `<div
    class="inline-block text-white text-xs bg-premium h-4 w-4 rounded-[50%]"
    [style.transform]="'scale(' + size + ')'"
  >
    P
  </div>`,
})
export class PremiumStampComponent {
  @Input() size = 1;
}
