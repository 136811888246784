import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IOrganization } from '@betrail-libs/shared/interfaces/interfaces';
import { EventService } from '@betrail-libs/trail-data-state';
import { BetrailUxFormsService } from '../../../betrail-ux-forms.service';

@Component({
  selector: 'bux-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['../common-form.scss'],
})
export class OrganizationFormComponent {
  constructor(public eventService: EventService, private betrailUxFormsService: BetrailUxFormsService) {
    this.organizationFormGroup = this.betrailUxFormsService.buildOrganizationForm();
  }

  organizationFormGroup: UntypedFormGroup;
  organization: IOrganization;
  legal_forms = [
    {
      key: 'NATURAL',
      name: 'LEGAL_NATURAL',
    },
    {
      key: 'ORGANIZATION_BE_ASBL',
      name: 'ORGANIZATION_BE_ASBL',
    },
    {
      key: 'ORGANIZATION_FR_LOI_1901',
      name: 'ORGANIZATION_FR_LOI_1901',
    },
    {
      key: 'ORGANIZATION_COMPANY',
      name: 'ORGANIZATION_COMPANY',
    },
    {
      key: 'ORGANIZATION_NO_LEGAL_FORM',
      name: 'ORGANIZATION_NO_LEGAL_FORM',
    },
  ];

  patchFormValues(organization: IOrganization) {
    //TODO : move to service
    this.organization = organization;

    this.organizationFormGroup.patchValue({
      id: organization.id,
      name: organization.name,
      email: organization.email,
      legal_form: organization.legal_form,
      phone: organization.phone,
      mango_user_id: organization.mango_user_id,
      web_site: organization.web_site,
      facebook_page: organization.facebook_page,
      description: organization.description,
    });
  }
}
