import { inject, Injectable } from '@angular/core';
import { ITimer } from '@betrail-libs/shared/interfaces/interfaces';
import { Store } from '@ngxs/store';
import {
  CreateNewTimer,
  LoadAllTimers,
  LoadTimerByAlias,
  LoadTimerById,
  SetDisplayMode,
  SetSelectedCountries,
  UpdateTimer,
} from './timers.action';
import { TimersState } from './timers.state';

@Injectable({ providedIn: 'root' })
export class TimerStateService {
  #store = inject(Store);

  allTimers$ = this.#store.select(TimersState.allTimers);
  filteredTimers$ = this.#store.select(TimersState.filteredTimers);
  selectedTimer$ = this.#store.select(TimersState.selectedTimer);
  selectedCountry$ = this.#store.select(TimersState.selectedCountry);
  displayMode$ = this.#store.select(TimersState.displayMode);

  changeDisplayMode = (mode: 'list' | 'map') => this.#store.dispatch(new SetDisplayMode(mode));

  changeSelectedCountries = (countries: string[]) => this.#store.dispatch(new SetSelectedCountries(countries));

  loadAllTimers = (force = false) => this.#store.dispatch(new LoadAllTimers(force));

  loadTimerByAlias = (alias: string, force = false) => this.#store.dispatch(new LoadTimerByAlias(alias, force));

  loadTimeById = (id: number, force = false) => this.#store.dispatch(new LoadTimerById(id, force));

  selectOrLoadAllTimers = () => {
    if (this.#store.selectSnapshot(TimersState.allTimers).length <= 1) {
      this.loadAllTimers();
    }
    return this.allTimers$;
  };

  createNewTimer = (timer: Omit<ITimer, 'id'>) => this.#store.dispatch(new CreateNewTimer(timer));

  updateTimer = (timer: ITimer) => this.#store.dispatch(new UpdateTimer(timer));
}
