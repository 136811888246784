import { Component, Input } from '@angular/core';
import { IScore } from '@betrail-libs/shared/interfaces/interfaces';
import { IRunner } from '@betrail-libs/shared/interfaces/runner.model';

@Component({
  selector: 'runner-preview-block',
  templateUrl: './runner-preview-block.component.html',
  styleUrls: ['./runner-preview-block.component.scss'],
})
export class RunnerPreviewBlockComponent {
  _runner: IRunner | undefined;
  bestTrailCup: IScore | undefined;

  @Input() set runner(runner: IRunner) {
    this._runner = runner;
    if (runner && runner?.scores?.length > 0) {
      // find greatest tc_pts from runner.score array
      this.bestTrailCup = runner.scores.reduce((prev, current) => {
        return prev.tc_pts > current.tc_pts ? prev : current;
      });
    }
  }
}
