import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { AuthStateService } from './services/auth-state.service';
import { Roles } from './roles';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EUserRole } from '@betrail-libs/shared/interfaces/interfaces';
import { hasRoleId } from '@betrail-libs/shared/utils';

@UntilDestroy()
@Directive({
  selector: '[isOrga]',
})
export class IsOrgaDirective {
  isShow = false;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private state: AuthStateService,
  ) {
    this.state
      .getUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        if (hasRoleId(user, EUserRole.EventOrganizer)) {
          if (!this.isShow) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.isShow = true;
          }
        } else {
          this.viewContainer.clear();
          this.isShow = false;
        }
      });
  }
}
