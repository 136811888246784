import { NgxsAuthState } from 'libs/front/auth-state/src/ngxs/auth.state';
import { AuthStateService } from '@betrail-libs/auth-state';
import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { INotif } from '@betrail-libs/shared/interfaces/interfaces';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss'],
})
export class UserNotificationsComponent implements OnInit, OnDestroy {
  _notifs: INotif[];
  _notifsStatus: any;
  constructor(private authStateService: AuthStateService) {}

  @Output() numberUpdated = new EventEmitter();

  @Select(NgxsAuthState.notifs) notifs$!: Observable<INotif[]>;
  @Select(NgxsAuthState.notifsStatus) notifsStatus$!: Observable<any>;

  @Input() open: boolean;

  ngOnInit() {
    this.notifs$.pipe(untilDestroyed(this)).subscribe(notifs => {
      this._notifs = notifs.sort((a, b) => {
        return new Date(b.created).getTime() - new Date(a.created).getTime();
      });
    });

    this.notifsStatus$.pipe(untilDestroyed(this)).subscribe(notifsStatus => {
      this._notifsStatus = notifsStatus;
      this.numberUpdated.emit(this._notifs.filter(n => notifsStatus[n.id] != 'read').length);
    });
  }

  ngOnDestroy() {}
}
