import { Injectable, inject } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthStateService } from '@betrail-libs/auth-state';
import { EUserRole, ITimer } from '@betrail-libs/shared/interfaces/interfaces';
import { ERaceTimingType, ERaceType, IRace } from '@betrail-libs/shared/interfaces/race.model';
import * as utils from '@betrail-libs/shared/utils';
import { DateTime } from 'luxon';
import { timeBarrierValidator } from './directives/custom-validators.directive';

@Injectable({
  providedIn: 'root',
})
export class BetrailUxFormsService {
  #authState = inject(AuthStateService);
  #fb = inject(FormBuilder);
  #ufb = inject(UntypedFormBuilder);

  buildTrailForm() {
    let trailFormGroup = {
      id: [0],
      title: ['', Validators.required],
      image: [''],
      country: ['', Validators.required],
      postal_code: ['', Validators.required],
      place: ['', Validators.required],
      website: ['', Validators.pattern('^(https?://)?([a-z]+\\.|https?://)(.+)\\.(.+)$')],
      facebook: ['', Validators.pattern('^(https?://)?([a-z]+\\.|https?://)(.+)\\.(.+)$')],
      organizer: [''],
      organizer_email: [''],
      organizer_phone: [''],
      closed: [''],
      gpx_option: [0],
      near_countries: [''],
      other_used_countries: [''],
    };

    return this.#ufb.group(trailFormGroup);
  }

  buildOrganizationForm() {
    let organizationFormGroup = {
      id: [0],
      name: ['', Validators.required],
      email: [''],
      legal_form: ['', Validators.required],
      phone: [''],
      mango_user_id: [''],
      web_site: ['', Validators.pattern('^(https?://)?([a-z]+\\.|https?://)(.+)\\.(.+)$')],
      facebook_page: ['', Validators.pattern('^(https?://)?([a-z]+\\.|https?://)(.+)\\.(.+)$')],
      description: [''],
    };

    return this.#ufb.group(organizationFormGroup);
  }

  buildEventForm() {
    let eventFormGroup = {
      id: [0],
      event_name: [''],
      date: ['', Validators.required],
      end_date: [''],
      geo_lat: [''],
      geo_lon: [''],
      departure_address: [''],
      edition_number: [''],
      rules_url: [''],
      several_days: [''],
      prefill: [true],
      onsite_registration: [false],
      preregistration: [true],
      registration_url: ['', Validators.pattern('^(https?://)?([a-z]+\\.|https?://)(.+)\\.(.+)$')],
      registration_start_date: [],
      registration_end_date: [''],
      out_of_criteria: [''],
      canceled: [''],
      no_next_edition: [''],
      external_registration_allowed: [false],
      onsite_registration_start_time: [''],
      onsite_registration_end_time: [''],
      distances: [1],
      timer: [''],
    };
    utils.LIST_AMENITIES.map(amenity => {
      eventFormGroup[amenity] = [''];
    });

    return this.#ufb.group(eventFormGroup);
  }

  /* buildRaceForm(race?: IRace) {
    let raceFormGroup = {
      // id: [id],
      race_name: [race?.race_name || ''],
      date: [''],
      canceled: [''],


      distance: ['', Validators.required],

      elevation: ['', Validators.required],
      departure_time: [''],
      price: [''],
      offroad: [90, Validators.required],
      trail: [''],
      onsite_price: [''],
      refreshments: ['', Validators.required],
      utmb_points: [''],
      max_runners: [''],
      night: [''],
      trailcup_only: [''],
      offroad_certified: [''],
      altitude_min: [''],
      altitude_max: [''],
      route_type: [1],
      race_type: ['solo'],
      timing_type: ['puces'],
      country: [''],
      official_ranking_url: [''],
      paid: [''],

      comment: [''],
    };
    if (evid !== undefined) {
      raceFormGroup['evid'] = [evid];
    }
    return this.#fb.group(raceFormGroup);
  } */

  buildRaceForm(race?: IRace, prefillForNextEdition = false, isBackyard = false) {
    const isAdmin = this.#authState.hasRoleSnapshot(EUserRole.Administrator);
    const isEncoder = this.#authState.hasRoleSnapshot(EUserRole.Encoder);
    const nowInSec = Math.floor(Date.now() / 1000);

    let raceDate = '';
    if (race?.date && race.date > 0) {
      raceDate = DateTime.fromSeconds(race.date).toFormat('YYYY-mm-dd');
    }

    return this.#fb.group({
      // id: prefillForNextEdition === true ? 0 : race.id,
      race_name: [race?.race_name || ''],
      date: [raceDate, Validators.required],
      canceled: [race?.canceled === 1],
      distance: [race?.distance, Validators.required],
      elevation: [race?.elevation, Validators.required],
      max_runners: [race?.max_runners],
      refreshments: [race?.refreshments, Validators.required],
      departure_time: [race?.departure_time ? utils.toHMS(race.departure_time, 'hm') : ''],
      price: [race?.price],
      night: [race?.night == 1],
      trailcup_only: [race?.trailcup_only === 1],
      offroad: [
        { value: race?.offroad || 90, disabled: !isAdmin && !isEncoder && !prefillForNextEdition },
        Validators.required,
      ],
      trail: [{ value: race?.trail, disabled: race?.trail && !isAdmin && !isEncoder && !prefillForNextEdition }],
      offroad_certified: [race?.offroad_certified === 1],
      altitude_min: [race?.min_altitude],
      altitude_max: [race?.max_altitude],
      time_barrier: [race?.time_barrier /* , timeBarrierValidator() */],
      onsite_price: [race?.onsite_price],
      race_type: [race?.race_type ? race.race_type : isBackyard ? ERaceType.BACKYARD : ERaceType.SOLO],
      timing_type: [race?.timing_type || ERaceTimingType.CHIPS],
      route_type: [race?.route_type ? +race.route_type : 1],
      official_ranking_url: [
        prefillForNextEdition ? '' : race?.official_ranking_url,
        Validators.pattern(utils.REGEX_LINK_VALIDATION),
      ],
      comment: { value: race?.comment, disabled: !isAdmin && !isEncoder && nowInSec > race.date },
    });
  }

  convertRaceFormToRace(
    form: ReturnType<typeof this.buildRaceForm>,
    event_title: string,
  ): Omit<IRace, 'id' | 'evid' | 'uid' | 'betrail_index'> {
    const formValues = form.value;
    const distance = formValues.distance || 0;
    const alias = formValues.distance + 'km';
    const title =
      event_title + ` - ${formValues.distance}km` + (formValues.race_name ? ` | ${formValues.race_name}` : '');
    const flat_distance = Math.round(formValues.distance + formValues.elevation / 100);
    const date = new Date(formValues.date).getTime() / 1000;
    const night = formValues.night ? 1 : (0 as 0 | 1);
    const offroad_certified = formValues.offroad_certified ? 1 : (0 as 0 | 1);
    const trailcup_only = formValues.trailcup_only ? 1 : (0 as 0 | 1);
    const canceled = formValues.canceled ? 1 : (0 as 0 | 1);
    const departure_time = formValues.departure_time ? utils.hmToSeconds(formValues.departure_time) : null;
    const ranking_url = formValues.official_ranking_url?.trim();
    const official_ranking_url = ranking_url && ranking_url !== '' ? utils.removeFbTrackerFromLink(ranking_url) : null;

    return {
      ...formValues,
      distance,
      alias,
      title,
      flat_distance,
      date,
      night,
      offroad_certified,
      trailcup_only,
      canceled,
      departure_time,
      official_ranking_url,
    };
  }

  buildPathForm(id: string | number = 0) {
    let pathFormGroup = {
      id: [id],
      raid: [''],
      uid: [''],
      title: ['', Validators.required],
      type: ['', Validators.required],
      private_sections: [2, Validators.required],
      marked: [0, Validators.required],
      fallback: [0, Validators.required],
      display: ['all', Validators.required],
      downloadable: [1, Validators.required],
      zoomable: [1, Validators.required],
      show_background: [1, Validators.required],
      distance_meters: [''],
      pos_elevation: [''],
      neg_elevation: [''],
      min_altitude: [''],
      max_altitude: [''],
      path: [''],
      elevation: [''],
    };
    return this.#ufb.group(pathFormGroup);
  }

  buildBannerForm(id: string | number = 0) {
    let bannerFormGroup = {
      id: [id],
      page: ['', Validators.required],
      weeks: ['', Validators.required],
    };

    return this.#ufb.group(bannerFormGroup);
  }

  buildTimerForm(timer?: ITimer) {
    let timerFormGroup = {
      title: [timer?.title],
      // logo: [''],
      postal_code: [timer?.postal_code],
      place: [timer?.place, Validators.pattern(utils.REGEX_PLACE_VALIDATION)],
      country: [timer?.country],
      email: [timer?.email, Validators.pattern(utils.REGEX_EMAIL_VALIDATION)],
      phone: [timer?.phone],
      website: [timer?.website, Validators.pattern(utils.REGEX_LINK_VALIDATION)],
      default_results_page: [timer?.default_results_page, Validators.pattern(utils.REGEX_LINK_VALIDATION)],
      dont_exist: [timer?.dont_exist === 1 ? true : false],
    };
    return this.#fb.group(timerFormGroup);
  }

  buildRunnerMissingDataForm(runner, newValues: any = {}) {
    let runnerMissingDataFormGroup = {
      firstname: [
        runner && runner.firstname ? runner.firstname : (newValues && newValues.firstname) || '',
        Validators.required,
      ],
      lastname: [runner && runner.lastname ? runner.lastname : '', Validators.required],
      place: [runner && runner.place ? runner.place : (newValues && newValues.place) || '', Validators.required],
      postal_code: [runner && runner.postal_code ? runner.postal_code : '', Validators.required],
      country: [runner && runner.country ? runner.country : '', Validators.required],
      nationality: [runner && runner.nationality ? runner.nationality : '', Validators.required],
      gender: [
        runner && runner.gender
          ? runner.gender == 1 || runner.gender == '1'
            ? '1'
            : '0'
          : newValues && newValues.gender && newValues.gender == 'FEMALE'
          ? '1'
          : '0',
        Validators.required,
      ],
      birthdate: [
        newValues && newValues.birthdate
          ? newValues.birthdate
          : runner && runner.birthdate
          ? runner.birthdate * 1000
          : '',
        Validators.required,
      ],
    };
    return this.#ufb.group(runnerMissingDataFormGroup);
  }
}
