export const countryConversion: Record<string, { name: string; convertedCode: string; convertedName: string }> = {
  AAR: {
    name: 'Antarctique',
    convertedCode: 'AQ',
    convertedName: 'Antarctique',
  },
  ABW: {
    name: 'Aruba',
    convertedCode: 'AW',
    convertedName: 'Aruba',
  },
  AD: {
    name: 'Andorre',
    convertedCode: 'AD',
    convertedName: 'Andorre',
  },
  AE: {
    name: 'Émirats arabes unis',
    convertedCode: 'AE',
    convertedName: 'Émirats arabes unis',
  },
  AF: {
    name: 'Afghanistan',
    convertedCode: 'AF',
    convertedName: 'Afghanistan',
  },
  AFG: {
    name: 'Afghanistan',
    convertedCode: 'AF',
    convertedName: 'Afghanistan',
  },
  AG: {
    name: 'Antigua-et-Barbuda',
    convertedCode: 'AG',
    convertedName: 'Antigua-et-Barbuda',
  },
  AGO: {
    name: 'Angola',
    convertedCode: 'AO',
    convertedName: 'Angola',
  },
  AHO: {
    name: 'Antilles néerlandaises',
    convertedCode: 'NL',
    convertedName: 'Pays-Bas',
  },
  AI: {
    name: 'Anguilla',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  AIA: {
    name: 'Anguilla',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  AL: {
    name: 'Albanie',
    convertedCode: 'AL',
    convertedName: 'Albanie',
  },
  ALA: {
    name: 'Îles Aland',
    convertedCode: 'FI',
    convertedName: 'Finlande',
  },
  ALB: {
    name: 'Albanie',
    convertedCode: 'AL',
    convertedName: 'Albanie',
  },
  ALG: {
    name: 'Algérie',
    convertedCode: 'DZ',
    convertedName: 'Algérie',
  },
  AM: {
    name: 'Arménie',
    convertedCode: 'AM',
    convertedName: 'Arménie',
  },
  AN: {
    name: 'Antilles néerlandaises',
    convertedCode: 'NL',
    convertedName: 'Pays-Bas',
  },
  AND: {
    name: 'Andorre',
    convertedCode: 'AD',
    convertedName: 'Andorre',
  },
  ANG: {
    name: 'Angola',
    convertedCode: 'AO',
    convertedName: 'Angola',
  },
  ANO: {
    name: 'Angola',
    convertedCode: 'AO',
    convertedName: 'Angola',
  },
  ANT: {
    name: 'Antigua-et-Barbuda',
    convertedCode: 'AG',
    convertedName: 'Antigua-et-Barbuda',
  },
  AO: {
    name: 'Angola',
    convertedCode: 'AO',
    convertedName: 'Angola',
  },
  AQ: {
    name: 'Antarctique',
    convertedCode: 'AQ',
    convertedName: 'Antarctique',
  },
  AR: {
    name: 'Argentine',
    convertedCode: 'AR',
    convertedName: 'Argentine',
  },
  ARE: {
    name: 'Émirats arabes unis',
    convertedCode: 'AE',
    convertedName: 'Émirats arabes unis',
  },
  ARG: {
    name: 'Argentine',
    convertedCode: 'AR',
    convertedName: 'Argentine',
  },
  ARM: {
    name: 'Arménie',
    convertedCode: 'AM',
    convertedName: 'Arménie',
  },
  ARU: {
    name: 'Aruba',
    convertedCode: 'AW',
    convertedName: 'Aruba',
  },
  AS: {
    name: 'Samoa américaines',
    convertedCode: 'AS',
    convertedName: 'Samoa américaines',
  },
  ASA: {
    name: 'Samoa américaines',
    convertedCode: 'AS',
    convertedName: 'Samoa américaines',
  },
  ASM: {
    name: 'Samoa américaines',
    convertedCode: 'AS',
    convertedName: 'Samoa américaines',
  },
  AT: {
    name: 'Autriche',
    convertedCode: 'AT',
    convertedName: 'Autriche',
  },
  ATA: {
    name: 'Antarctique',
    convertedCode: 'AQ',
    convertedName: 'Antarctique',
  },
  ATF: {
    name: 'Terres australes françaises',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  ATG: {
    name: 'Antigua-et-Barbuda',
    convertedCode: 'AG',
    convertedName: 'Antigua-et-Barbuda',
  },
  AU: {
    name: 'Australie',
    convertedCode: 'AU',
    convertedName: 'Australie',
  },
  AUS: {
    name: 'Australie',
    convertedCode: 'AU',
    convertedName: 'Australie',
  },
  AUT: {
    name: 'Autriche',
    convertedCode: 'AT',
    convertedName: 'Autriche',
  },
  AW: {
    name: 'Aruba',
    convertedCode: 'AW',
    convertedName: 'Aruba',
  },
  AX: {
    name: 'Îles Aland',
    convertedCode: 'FI',
    convertedName: 'Finlande',
  },
  AZ: {
    name: 'Azerbaïdjan',
    convertedCode: 'AZ',
    convertedName: 'Azerbaïdjan',
  },
  AZE: {
    name: 'Azerbaïdjan',
    convertedCode: 'AZ',
    convertedName: 'Azerbaïdjan',
  },
  BA: {
    name: 'Bosnie-Herzégovine',
    convertedCode: 'BA',
    convertedName: 'Bosnie-Herzégovine',
  },
  BAH: {
    name: 'Bahamas',
    convertedCode: 'BS',
    convertedName: 'Bahamas',
  },
  BAN: {
    name: 'Bangladesh',
    convertedCode: 'BD',
    convertedName: 'Bangladesh',
  },
  BAR: {
    name: 'Barbade',
    convertedCode: 'BB',
    convertedName: 'Barbade',
  },
  BB: {
    name: 'Barbade',
    convertedCode: 'BB',
    convertedName: 'Barbade',
  },
  BD: {
    name: 'Bangladesh',
    convertedCode: 'BD',
    convertedName: 'Bangladesh',
  },
  BDI: {
    name: 'Burundi',
    convertedCode: 'BI',
    convertedName: 'Burundi',
  },
  BE: {
    name: 'Belgique',
    convertedCode: 'BE',
    convertedName: 'Belgique',
  },
  BEL: {
    name: 'Belgique',
    convertedCode: 'BE',
    convertedName: 'Belgique',
  },
  BEN: {
    name: 'Bénin',
    convertedCode: 'BJ',
    convertedName: 'Bénin',
  },
  BER: {
    name: 'Bermudes',
    convertedCode: 'BM',
    convertedName: 'Bermudes',
  },
  BES: {
    name: 'Bonaire, Saint-Eustache et Saba',
    convertedCode: 'NL',
    convertedName: 'Pays-Bas',
  },
  BF: {
    name: 'Burkina Faso',
    convertedCode: 'BF',
    convertedName: 'Burkina Faso',
  },
  BFA: {
    name: 'Burkina Faso',
    convertedCode: 'BF',
    convertedName: 'Burkina Faso',
  },
  BG: {
    name: 'Bulgarie',
    convertedCode: 'BG',
    convertedName: 'Bulgarie',
  },
  BGD: {
    name: 'Bangladesh',
    convertedCode: 'BD',
    convertedName: 'Bangladesh',
  },
  BGR: {
    name: 'Bulgarie',
    convertedCode: 'BG',
    convertedName: 'Bulgarie',
  },
  BH: {
    name: 'Bahreïn',
    convertedCode: 'BH',
    convertedName: 'Bahreïn',
  },
  BHR: {
    name: 'Bahreïn',
    convertedCode: 'BH',
    convertedName: 'Bahreïn',
  },
  BHS: {
    name: 'Bahamas',
    convertedCode: 'BS',
    convertedName: 'Bahamas',
  },
  BHU: {
    name: 'Bhoutan',
    convertedCode: 'BT',
    convertedName: 'Bhoutan',
  },
  BI: {
    name: 'Burundi',
    convertedCode: 'BI',
    convertedName: 'Burundi',
  },
  BIH: {
    name: 'Bosnie-Herzégovine',
    convertedCode: 'BA',
    convertedName: 'Bosnie-Herzégovine',
  },
  BIR: {
    name: 'Birmanie',
    convertedCode: 'MM',
    convertedName: 'Birmanie',
  },
  BIZ: {
    name: 'Belize',
    convertedCode: 'BZ',
    convertedName: 'Belize',
  },
  BJ: {
    name: 'Bénin',
    convertedCode: 'BJ',
    convertedName: 'Bénin',
  },
  BKF: {
    name: 'Burkina Faso',
    convertedCode: 'BF',
    convertedName: 'Burkina Faso',
  },
  BL: {
    name: 'Saint Barthélemy',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  BLM: {
    name: 'Saint Barthélemy',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  BLR: {
    name: 'Biélorussie',
    convertedCode: 'BY',
    convertedName: 'Biélorussie',
  },
  BLZ: {
    name: 'Belize',
    convertedCode: 'BZ',
    convertedName: 'Belize',
  },
  BM: {
    name: 'Bermudes',
    convertedCode: 'BM',
    convertedName: 'Bermudes',
  },
  BMU: {
    name: 'Bermudes',
    convertedCode: 'BM',
    convertedName: 'Bermudes',
  },
  BN: {
    name: 'Brunei',
    convertedCode: 'BN',
    convertedName: 'Brunei',
  },
  BO: {
    name: 'Bolivie',
    convertedCode: 'BO',
    convertedName: 'Bolivie',
  },
  BOL: {
    name: 'Bolivie',
    convertedCode: 'BO',
    convertedName: 'Bolivie',
  },
  BOS: {
    name: 'Bosnie-Herzégovine',
    convertedCode: 'BA',
    convertedName: 'Bosnie-Herzégovine',
  },
  BOT: {
    name: 'Botswana',
    convertedCode: 'BW',
    convertedName: 'Botswana',
  },
  BQ: {
    name: 'Bonaire, Saint-Eustache et Saba',
    convertedCode: 'NL',
    convertedName: 'Pays-Bas',
  },
  BR: {
    name: 'Brésil',
    convertedCode: 'BR',
    convertedName: 'Brésil',
  },
  BRA: {
    name: 'Brésil',
    convertedCode: 'BR',
    convertedName: 'Brésil',
  },
  BRB: {
    name: 'Barbade',
    convertedCode: 'BB',
    convertedName: 'Barbade',
  },
  BRE: {
    name: 'Brésil',
    convertedCode: 'BR',
    convertedName: 'Brésil',
  },
  BRN: {
    name: 'Bahreïn',
    convertedCode: 'BH',
    convertedName: 'Bahreïn',
  },
  BRU: {
    name: 'Brunei',
    convertedCode: 'BN',
    convertedName: 'Brunei',
  },
  BS: {
    name: 'Bahamas',
    convertedCode: 'BS',
    convertedName: 'Bahamas',
  },
  BSH: {
    name: 'Bosnie-Herzégovine',
    convertedCode: 'BA',
    convertedName: 'Bosnie-Herzégovine',
  },
  BT: {
    name: 'Bhoutan',
    convertedCode: 'BT',
    convertedName: 'Bhoutan',
  },
  BTN: {
    name: 'Bhoutan',
    convertedCode: 'BT',
    convertedName: 'Bhoutan',
  },
  BUL: {
    name: 'Bulgarie',
    convertedCode: 'BG',
    convertedName: 'Bulgarie',
  },
  BUR: {
    name: 'Burkina Faso',
    convertedCode: 'BF',
    convertedName: 'Burkina Faso',
  },
  BV: {
    name: 'Île Bouvet',
    convertedCode: 'NO',
    convertedName: 'Norvège',
  },
  BVI: {
    name: 'Îles Vierges britanniques',
    convertedCode: 'VG',
    convertedName: 'Îles Vierges britanniques',
  },
  BVT: {
    name: 'Île Bouvet',
    convertedCode: 'NO',
    convertedName: 'Norvège',
  },
  BW: {
    name: 'Botswana',
    convertedCode: 'BW',
    convertedName: 'Botswana',
  },
  BWA: {
    name: 'Botswana',
    convertedCode: 'BW',
    convertedName: 'Botswana',
  },
  BY: {
    name: 'Biélorussie',
    convertedCode: 'BY',
    convertedName: 'Biélorussie',
  },
  BZ: {
    name: 'Belize',
    convertedCode: 'BZ',
    convertedName: 'Belize',
  },
  CA: {
    name: 'Canada',
    convertedCode: 'CA',
    convertedName: 'Canada',
  },
  CAF: {
    name: 'République centrafricaine',
    convertedCode: 'CF',
    convertedName: 'République centrafricaine',
  },
  CAM: {
    name: 'Cambodge',
    convertedCode: 'KH',
    convertedName: 'Cambodge',
  },
  CAN: {
    name: 'Canada',
    convertedCode: 'CA',
    convertedName: 'Canada',
  },
  CAY: {
    name: 'Îles Caïmans',
    convertedCode: 'KY',
    convertedName: 'Îles Caïmans',
  },
  CC: {
    name: 'Îles Cocos (Keeling)',
    convertedCode: 'AU',
    convertedName: 'Australie',
  },
  CCK: {
    name: 'Îles Cocos (Keeling)',
    convertedCode: 'AU',
    convertedName: 'Australie',
  },
  CD: {
    name: 'RD Congo',
    convertedCode: 'CD',
    convertedName: 'RD Congo',
  },
  CF: {
    name: 'République centrafricaine',
    convertedCode: 'CF',
    convertedName: 'République centrafricaine',
  },
  CG: {
    name: 'Congo',
    convertedCode: 'CG',
    convertedName: 'Congo',
  },
  CGO: {
    name: 'Congo',
    convertedCode: 'CG',
    convertedName: 'Congo',
  },
  CH: {
    name: 'Suisse',
    convertedCode: 'CH',
    convertedName: 'Suisse',
  },
  CHA: {
    name: 'Tchad',
    convertedCode: 'TD',
    convertedName: 'Tchad',
  },
  CHE: {
    name: 'Suisse',
    convertedCode: 'CH',
    convertedName: 'Suisse',
  },
  CHI: {
    name: 'Chili',
    convertedCode: 'CL',
    convertedName: 'Chili',
  },
  CHL: {
    name: 'Chili',
    convertedCode: 'CL',
    convertedName: 'Chili',
  },
  CHN: {
    name: 'Chine',
    convertedCode: 'CN',
    convertedName: 'Chine',
  },
  CI: {
    name: 'Côte d’Ivoire',
    convertedCode: 'CI',
    convertedName: 'Côte d’Ivoire',
  },
  CIV: {
    name: 'Côte d’Ivoire',
    convertedCode: 'CI',
    convertedName: 'Côte d’Ivoire',
  },
  CK: {
    name: 'Îles Cook',
    convertedCode: 'CK',
    convertedName: 'Îles Cook',
  },
  CKI: {
    name: 'Îles Cook',
    convertedCode: 'CK',
    convertedName: 'Îles Cook',
  },
  CL: {
    name: 'Chili',
    convertedCode: 'CL',
    convertedName: 'Chili',
  },
  CM: {
    name: 'Cameroun',
    convertedCode: 'CM',
    convertedName: 'Cameroun',
  },
  CMR: {
    name: 'Cameroun',
    convertedCode: 'CM',
    convertedName: 'Cameroun',
  },
  CN: {
    name: 'Chine',
    convertedCode: 'CN',
    convertedName: 'Chine',
  },
  CO: {
    name: 'Colombie',
    convertedCode: 'CO',
    convertedName: 'Colombie',
  },
  COD: {
    name: 'RD Congo',
    convertedCode: 'CD',
    convertedName: 'RD Congo',
  },
  COG: {
    name: 'Congo',
    convertedCode: 'CG',
    convertedName: 'Congo',
  },
  COK: {
    name: 'Îles Cook',
    convertedCode: 'CK',
    convertedName: 'Îles Cook',
  },
  COL: {
    name: 'Colombie',
    convertedCode: 'CO',
    convertedName: 'Colombie',
  },
  COM: {
    name: 'Comores',
    convertedCode: 'KM',
    convertedName: 'Comores',
  },
  CPV: {
    name: 'Cap-Vert',
    convertedCode: 'CV',
    convertedName: 'Cap-Vert',
  },
  CR: {
    name: 'Costa Rica',
    convertedCode: 'CR',
    convertedName: 'Costa Rica',
  },
  CRC: {
    name: 'Costa Rica',
    convertedCode: 'CR',
    convertedName: 'Costa Rica',
  },
  CRI: {
    name: 'Costa Rica',
    convertedCode: 'CR',
    convertedName: 'Costa Rica',
  },
  CRO: {
    name: 'Croatie',
    convertedCode: 'HR',
    convertedName: 'Croatie',
  },
  CTA: {
    name: 'République centrafricaine',
    convertedCode: 'CF',
    convertedName: 'République centrafricaine',
  },
  CU: {
    name: 'Cuba',
    convertedCode: 'CU',
    convertedName: 'Cuba',
  },
  CUB: {
    name: 'Cuba',
    convertedCode: 'CU',
    convertedName: 'Cuba',
  },
  CUR: {
    name: 'Curaçao',
    convertedCode: 'NL',
    convertedName: 'Pays-Bas',
  },
  CUW: {
    name: 'Curaçao',
    convertedCode: 'NL',
    convertedName: 'Pays-Bas',
  },
  CV: {
    name: 'Cap-Vert',
    convertedCode: 'CV',
    convertedName: 'Cap-Vert',
  },
  CVD: {
    name: 'Cap-Vert',
    convertedCode: 'CV',
    convertedName: 'Cap-Vert',
  },
  CW: {
    name: 'Curaçao',
    convertedCode: 'NL',
    convertedName: 'Pays-Bas',
  },
  CX: {
    name: 'Île Christmas',
    convertedCode: 'AU',
    convertedName: 'Australie',
  },
  CXR: {
    name: 'Île Christmas',
    convertedCode: 'AU',
    convertedName: 'Australie',
  },
  CY: {
    name: 'Chypre',
    convertedCode: 'CY',
    convertedName: 'Chypre',
  },
  CYM: {
    name: 'Îles Caïmans',
    convertedCode: 'KY',
    convertedName: 'Îles Caïmans',
  },
  CYP: {
    name: 'Chypre',
    convertedCode: 'CY',
    convertedName: 'Chypre',
  },
  CZ: {
    name: 'République tchèque',
    convertedCode: 'CZ',
    convertedName: 'République tchèque',
  },
  CZE: {
    name: 'République tchèque',
    convertedCode: 'CZ',
    convertedName: 'République tchèque',
  },
  DAN: {
    name: 'Danemark',
    convertedCode: 'DK',
    convertedName: 'Danemark',
  },
  DE: {
    name: 'Allemagne',
    convertedCode: 'DE',
    convertedName: 'Allemagne',
  },
  DEN: {
    name: 'Danemark',
    convertedCode: 'DK',
    convertedName: 'Danemark',
  },
  DEU: {
    name: 'Allemagne',
    convertedCode: 'DE',
    convertedName: 'Allemagne',
  },
  DJ: {
    name: 'Djibouti',
    convertedCode: 'DJ',
    convertedName: 'Djibouti',
  },
  DJI: {
    name: 'Djibouti',
    convertedCode: 'DJ',
    convertedName: 'Djibouti',
  },
  DK: {
    name: 'Danemark',
    convertedCode: 'DK',
    convertedName: 'Danemark',
  },
  DM: {
    name: 'Dominique',
    convertedCode: 'DM',
    convertedName: 'Dominique',
  },
  DMA: {
    name: 'Dominique',
    convertedCode: 'DM',
    convertedName: 'Dominique',
  },
  DMN: {
    name: 'Dominique',
    convertedCode: 'DM',
    convertedName: 'Dominique',
  },
  DNK: {
    name: 'Danemark',
    convertedCode: 'DK',
    convertedName: 'Danemark',
  },
  DO: {
    name: 'République dominicaine',
    convertedCode: 'DO',
    convertedName: 'République dominicaine',
  },
  DOM: {
    name: 'République dominicaine',
    convertedCode: 'DO',
    convertedName: 'République dominicaine',
  },
  DZ: {
    name: 'Algérie',
    convertedCode: 'DZ',
    convertedName: 'Algérie',
  },
  DZA: {
    name: 'Algérie',
    convertedCode: 'DZ',
    convertedName: 'Algérie',
  },
  EC: {
    name: 'Équateur',
    convertedCode: 'EC',
    convertedName: 'Équateur',
  },
  ECU: {
    name: 'Équateur',
    convertedCode: 'EC',
    convertedName: 'Équateur',
  },
  EE: {
    name: 'Estonie',
    convertedCode: 'EE',
    convertedName: 'Estonie',
  },
  EG: {
    name: 'Égypte',
    convertedCode: 'EG',
    convertedName: 'Égypte',
  },
  EGY: {
    name: 'Égypte',
    convertedCode: 'EG',
    convertedName: 'Égypte',
  },
  EH: {
    name: 'Sahara occidental',
    convertedCode: 'MA',
    convertedName: 'Maroc',
  },
  ENG: {
    name: 'Angleterre',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  EQG: {
    name: 'Guinée équatoriale',
    convertedCode: 'GQ',
    convertedName: 'Guinée équatoriale',
  },
  ER: {
    name: 'Érythrée',
    convertedCode: 'ER',
    convertedName: 'Érythrée',
  },
  ERI: {
    name: 'Érythrée',
    convertedCode: 'ER',
    convertedName: 'Érythrée',
  },
  ES: {
    name: 'Espagne',
    convertedCode: 'ES',
    convertedName: 'Espagne',
  },
  ESA: {
    name: 'Salvador',
    convertedCode: 'SV',
    convertedName: 'Salvador',
  },
  ESH: {
    name: 'Sahara occidental',
    convertedCode: 'MA',
    convertedName: 'Maroc',
  },
  ESP: {
    name: 'Espagne',
    convertedCode: 'ES',
    convertedName: 'Espagne',
  },
  EST: {
    name: 'Estonie',
    convertedCode: 'EE',
    convertedName: 'Estonie',
  },
  ET: {
    name: 'Éthiopie',
    convertedCode: 'ET',
    convertedName: 'Éthiopie',
  },
  ETH: {
    name: 'Éthiopie',
    convertedCode: 'ET',
    convertedName: 'Éthiopie',
  },
  FAR: {
    name: 'Îles Féroé',
    convertedCode: 'DK',
    convertedName: 'Danemark',
  },
  FGU: {
    name: 'Guyane française',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  FI: {
    name: 'Finlande',
    convertedCode: 'FI',
    convertedName: 'Finlande',
  },
  FIJ: {
    name: 'Fidji',
    convertedCode: 'FJ',
    convertedName: 'Fidji',
  },
  FIN: {
    name: 'Finlande',
    convertedCode: 'FI',
    convertedName: 'Finlande',
  },
  FJ: {
    name: 'Fidji',
    convertedCode: 'FJ',
    convertedName: 'Fidji',
  },
  FJI: {
    name: 'Fidji',
    convertedCode: 'FJ',
    convertedName: 'Fidji',
  },
  FK: {
    name: 'Îles Malouines',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  FLK: {
    name: 'Îles Malouines',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  FM: {
    name: 'Micronésie',
    convertedCode: 'FM',
    convertedName: 'Micronésie',
  },
  FO: {
    name: 'Îles Féroé',
    convertedCode: 'DK',
    convertedName: 'Danemark',
  },
  FR: {
    name: 'France',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  FRA: {
    name: 'France',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  FRO: {
    name: 'Îles Féroé',
    convertedCode: 'DK',
    convertedName: 'Danemark',
  },
  FSM: {
    name: 'Micronésie',
    convertedCode: 'FM',
    convertedName: 'Micronésie',
  },
  GA: {
    name: 'Gabon',
    convertedCode: 'GA',
    convertedName: 'Gabon',
  },
  GAB: {
    name: 'Gabon',
    convertedCode: 'GA',
    convertedName: 'Gabon',
  },
  GAM: {
    name: 'Gambie',
    convertedCode: 'GM',
    convertedName: 'Gambie',
  },
  GB: {
    name: 'Royaume-Uni',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  GBR: {
    name: 'Royaume-Uni',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  GBS: {
    name: 'Guinée-Bissau',
    convertedCode: 'GW',
    convertedName: 'Guinée-Bissau',
  },
  GD: {
    name: 'Grenade',
    convertedCode: 'GD',
    convertedName: 'Grenade',
  },
  GE: {
    name: 'Géorgie',
    convertedCode: 'GE',
    convertedName: 'Géorgie',
  },
  GEO: {
    name: 'Géorgie',
    convertedCode: 'GE',
    convertedName: 'Géorgie',
  },
  GEQ: {
    name: 'Guinée équatoriale',
    convertedCode: 'GQ',
    convertedName: 'Guinée équatoriale',
  },
  GER: {
    name: 'Allemagne',
    convertedCode: 'DE',
    convertedName: 'Allemagne',
  },
  GF: {
    name: 'Guyane française',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  GG: {
    name: 'Guernesey',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  GGY: {
    name: 'Guernesey',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  GH: {
    name: 'Ghana',
    convertedCode: 'GH',
    convertedName: 'Ghana',
  },
  GHA: {
    name: 'Ghana',
    convertedCode: 'GH',
    convertedName: 'Ghana',
  },
  GI: {
    name: 'Gibraltar',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  GIB: {
    name: 'Gibraltar',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  GIN: {
    name: 'Guinée',
    convertedCode: 'GN',
    convertedName: 'Guinée',
  },
  GL: {
    name: 'Groenland',
    convertedCode: 'DK',
    convertedName: 'Danemark',
  },
  GLP: {
    name: 'Guadeloupe',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  GM: {
    name: 'Gambie',
    convertedCode: 'GM',
    convertedName: 'Gambie',
  },
  GMB: {
    name: 'Gambie',
    convertedCode: 'GM',
    convertedName: 'Gambie',
  },
  GN: {
    name: 'Guinée',
    convertedCode: 'GN',
    convertedName: 'Guinée',
  },
  GNB: {
    name: 'Guinée-Bissau',
    convertedCode: 'GW',
    convertedName: 'Guinée-Bissau',
  },
  GNQ: {
    name: 'Guinée équatoriale',
    convertedCode: 'GQ',
    convertedName: 'Guinée équatoriale',
  },
  GP: {
    name: 'Guadeloupe',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  GQ: {
    name: 'Guinée équatoriale',
    convertedCode: 'GQ',
    convertedName: 'Guinée équatoriale',
  },
  GR: {
    name: 'Grèce',
    convertedCode: 'GR',
    convertedName: 'Grèce',
  },
  GRC: {
    name: 'Grèce',
    convertedCode: 'GR',
    convertedName: 'Grèce',
  },
  GRD: {
    name: 'Grenade',
    convertedCode: 'GD',
    convertedName: 'Grenade',
  },
  GRE: {
    name: 'Grèce',
    convertedCode: 'GR',
    convertedName: 'Grèce',
  },
  GRL: {
    name: 'Groenland',
    convertedCode: 'DK',
    convertedName: 'Danemark',
  },
  GRN: {
    name: 'Grenade',
    convertedCode: 'GD',
    convertedName: 'Grenade',
  },
  GS: {
    name: 'Géorgie du Sud et les Îles Sandwich du Sud',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  GT: {
    name: 'Guatemala',
    convertedCode: 'GT',
    convertedName: 'Guatemala',
  },
  GTM: {
    name: 'Guatemala',
    convertedCode: 'GT',
    convertedName: 'Guatemala',
  },
  GU: {
    name: 'Guam',
    convertedCode: 'GU',
    convertedName: 'Guam',
  },
  GUA: {
    name: 'Guatemala',
    convertedCode: 'GT',
    convertedName: 'Guatemala',
  },
  GUD: {
    name: 'Guadeloupe',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  GUE: {
    name: 'Guernesey',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  GUF: {
    name: 'Guyane française',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  GUI: {
    name: 'Guinée',
    convertedCode: 'GN',
    convertedName: 'Guinée',
  },
  GUM: {
    name: 'Guam',
    convertedCode: 'GU',
    convertedName: 'Guam',
  },
  GUY: {
    name: 'Guyana',
    convertedCode: 'GY',
    convertedName: 'Guyana',
  },
  GW: {
    name: 'Guinée-Bissau',
    convertedCode: 'GW',
    convertedName: 'Guinée-Bissau',
  },
  GY: {
    name: 'Guyana',
    convertedCode: 'GY',
    convertedName: 'Guyana',
  },
  HAI: {
    name: 'Haïti',
    convertedCode: 'HT',
    convertedName: 'Haïti',
  },
  HK: {
    name: 'Hong Kong, Chine',
    convertedCode: 'HK',
    convertedName: 'Hong Kong, Chine',
  },
  HKG: {
    name: 'Hong Kong, Chine',
    convertedCode: 'HK',
    convertedName: 'Hong Kong, Chine',
  },
  HM: {
    name: 'Îles Heard et MacDonald',
    convertedCode: 'AU',
    convertedName: 'Australie',
  },
  HMD: {
    name: 'Îles Heard et MacDonald',
    convertedCode: 'AU',
    convertedName: 'Australie',
  },
  HN: {
    name: 'Honduras',
    convertedCode: 'HN',
    convertedName: 'Honduras',
  },
  HND: {
    name: 'Honduras',
    convertedCode: 'HN',
    convertedName: 'Honduras',
  },
  HON: {
    name: 'Honduras',
    convertedCode: 'HN',
    convertedName: 'Honduras',
  },
  HR: {
    name: 'Croatie',
    convertedCode: 'HR',
    convertedName: 'Croatie',
  },
  HRV: {
    name: 'Croatie',
    convertedCode: 'HR',
    convertedName: 'Croatie',
  },
  HT: {
    name: 'Haïti',
    convertedCode: 'HT',
    convertedName: 'Haïti',
  },
  HTI: {
    name: 'Haïti',
    convertedCode: 'HT',
    convertedName: 'Haïti',
  },
  HU: {
    name: 'Hongrie',
    convertedCode: 'HU',
    convertedName: 'Hongrie',
  },
  HUN: {
    name: 'Hongrie',
    convertedCode: 'HU',
    convertedName: 'Hongrie',
  },
  ID: {
    name: 'Indonésie',
    convertedCode: 'ID',
    convertedName: 'Indonésie',
  },
  IDN: {
    name: 'Indonésie',
    convertedCode: 'ID',
    convertedName: 'Indonésie',
  },
  IE: {
    name: 'Irlande',
    convertedCode: 'IE',
    convertedName: 'Irlande',
  },
  IL: {
    name: 'Israël',
    convertedCode: 'IL',
    convertedName: 'Israël',
  },
  IM: {
    name: 'Île de Man',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  IMN: {
    name: 'Île de Man',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  IN: {
    name: 'Inde',
    convertedCode: 'IN',
    convertedName: 'Inde',
  },
  INA: {
    name: 'Indonésie',
    convertedCode: 'ID',
    convertedName: 'Indonésie',
  },
  IND: {
    name: 'Inde',
    convertedCode: 'IN',
    convertedName: 'Inde',
  },
  IO: {
    name: "Territoire britannique de l'océan Indien (Chagos)",
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  IOM: {
    name: 'Île de Man',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  IOT: {
    name: "Territoire britannique de l'océan Indien (Chagos)",
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  IQ: {
    name: 'Irak',
    convertedCode: 'IQ',
    convertedName: 'Irak',
  },
  IR: {
    name: 'Iran',
    convertedCode: 'IR',
    convertedName: 'Iran',
  },
  IRI: {
    name: 'Iran',
    convertedCode: 'IR',
    convertedName: 'Iran',
  },
  IRK: {
    name: 'Irak',
    convertedCode: 'IQ',
    convertedName: 'Irak',
  },
  IRL: {
    name: 'Irlande',
    convertedCode: 'IE',
    convertedName: 'Irlande',
  },
  IRN: {
    name: 'Iran',
    convertedCode: 'IR',
    convertedName: 'Iran',
  },
  IRQ: {
    name: 'Irak',
    convertedCode: 'IQ',
    convertedName: 'Irak',
  },
  IS: {
    name: 'Islande',
    convertedCode: 'IS',
    convertedName: 'Islande',
  },
  ISL: {
    name: 'Islande',
    convertedCode: 'IS',
    convertedName: 'Islande',
  },
  ISR: {
    name: 'Israël',
    convertedCode: 'IL',
    convertedName: 'Israël',
  },
  ISV: {
    name: 'Îles Vierges américaines',
    convertedCode: 'VI',
    convertedName: 'Îles Vierges américaines',
  },
  IT: {
    name: 'Italie',
    convertedCode: 'IT',
    convertedName: 'Italie',
  },
  ITA: {
    name: 'Italie',
    convertedCode: 'IT',
    convertedName: 'Italie',
  },
  IVB: {
    name: 'Îles Vierges britanniques',
    convertedCode: 'VG',
    convertedName: 'Îles Vierges britanniques',
  },
  IVO: {
    name: 'Côte d’Ivoire',
    convertedCode: 'CI',
    convertedName: 'Côte d’Ivoire',
  },
  JAM: {
    name: 'Jamaïque',
    convertedCode: 'JM',
    convertedName: 'Jamaïque',
  },
  JAP: {
    name: 'Japon',
    convertedCode: 'JP',
    convertedName: 'Japon',
  },
  JE: {
    name: 'Jersey',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  JER: {
    name: 'Jersey',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  JEY: {
    name: 'Jersey',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  JM: {
    name: 'Jamaïque',
    convertedCode: 'JM',
    convertedName: 'Jamaïque',
  },
  JO: {
    name: 'Jordanie',
    convertedCode: 'JO',
    convertedName: 'Jordanie',
  },
  JOR: {
    name: 'Jordanie',
    convertedCode: 'JO',
    convertedName: 'Jordanie',
  },
  JP: {
    name: 'Japon',
    convertedCode: 'JP',
    convertedName: 'Japon',
  },
  JPN: {
    name: 'Japon',
    convertedCode: 'JP',
    convertedName: 'Japon',
  },
  KAZ: {
    name: 'Kazakhstan',
    convertedCode: 'KZ',
    convertedName: 'Kazakhstan',
  },
  KE: {
    name: 'Kenya',
    convertedCode: 'KE',
    convertedName: 'Kenya',
  },
  KEN: {
    name: 'Kenya',
    convertedCode: 'KE',
    convertedName: 'Kenya',
  },
  KG: {
    name: 'Kirghizistan',
    convertedCode: 'KG',
    convertedName: 'Kirghizistan',
  },
  KGZ: {
    name: 'Kirghizistan',
    convertedCode: 'KG',
    convertedName: 'Kirghizistan',
  },
  KH: {
    name: 'Cambodge',
    convertedCode: 'KH',
    convertedName: 'Cambodge',
  },
  KHM: {
    name: 'Cambodge',
    convertedCode: 'KH',
    convertedName: 'Cambodge',
  },
  KI: {
    name: 'Kiribati',
    convertedCode: 'KI',
    convertedName: 'Kiribati',
  },
  KIR: {
    name: 'Kiribati',
    convertedCode: 'KI',
    convertedName: 'Kiribati',
  },
  KM: {
    name: 'Comores',
    convertedCode: 'KM',
    convertedName: 'Comores',
  },
  KN: {
    name: 'Saint-Christophe-et-Niévès',
    convertedCode: 'KN',
    convertedName: 'Saint-Christophe-et-Niévès',
  },
  KNA: {
    name: 'Saint-Christophe-et-Niévès',
    convertedCode: 'KN',
    convertedName: 'Saint-Christophe-et-Niévès',
  },
  KOR: {
    name: 'Corée du Sud',
    convertedCode: 'KR',
    convertedName: 'Corée du Sud',
  },
  KOS: {
    name: 'Kosovo',
    convertedCode: 'XK',
    convertedName: 'Kosovo',
  },
  KP: {
    name: 'Corée du Nord',
    convertedCode: 'KP',
    convertedName: 'Corée du Nord',
  },
  KR: {
    name: 'Corée du Sud',
    convertedCode: 'KR',
    convertedName: 'Corée du Sud',
  },
  KSA: {
    name: 'Arabie Saoudite',
    convertedCode: 'SA',
    convertedName: 'Arabie Saoudite',
  },
  KUW: {
    name: 'Koweït',
    convertedCode: 'KW',
    convertedName: 'Koweït',
  },
  KW: {
    name: 'Koweït',
    convertedCode: 'KW',
    convertedName: 'Koweït',
  },
  KWT: {
    name: 'Koweït',
    convertedCode: 'KW',
    convertedName: 'Koweït',
  },
  KY: {
    name: 'Îles Caïmans',
    convertedCode: 'KY',
    convertedName: 'Îles Caïmans',
  },
  KZ: {
    name: 'Kazakhstan',
    convertedCode: 'KZ',
    convertedName: 'Kazakhstan',
  },
  KZK: {
    name: 'Kazakhstan',
    convertedCode: 'KZ',
    convertedName: 'Kazakhstan',
  },
  LA: {
    name: 'Laos',
    convertedCode: 'LA',
    convertedName: 'Laos',
  },
  LAO: {
    name: 'Laos',
    convertedCode: 'LA',
    convertedName: 'Laos',
  },
  LAT: {
    name: 'Lettonie',
    convertedCode: 'LV',
    convertedName: 'Lettonie',
  },
  LB: {
    name: 'Liban',
    convertedCode: 'LB',
    convertedName: 'Liban',
  },
  LBA: {
    name: 'Libye',
    convertedCode: 'LY',
    convertedName: 'Libye',
  },
  LBN: {
    name: 'Liban',
    convertedCode: 'LB',
    convertedName: 'Liban',
  },
  LBR: {
    name: 'Libéria',
    convertedCode: 'LR',
    convertedName: 'Libéria',
  },
  LBY: {
    name: 'Libye',
    convertedCode: 'LY',
    convertedName: 'Libye',
  },
  LC: {
    name: 'Sainte-Lucie',
    convertedCode: 'LC',
    convertedName: 'Sainte-Lucie',
  },
  LCA: {
    name: 'Sainte-Lucie',
    convertedCode: 'LC',
    convertedName: 'Sainte-Lucie',
  },
  LES: {
    name: 'Lesotho',
    convertedCode: 'LS',
    convertedName: 'Lesotho',
  },
  LI: {
    name: 'Liechtenstein',
    convertedCode: 'LI',
    convertedName: 'Liechtenstein',
  },
  LIB: {
    name: 'Liban',
    convertedCode: 'LB',
    convertedName: 'Liban',
  },
  LIE: {
    name: 'Liechtenstein',
    convertedCode: 'LI',
    convertedName: 'Liechtenstein',
  },
  LIT: {
    name: 'Lituanie',
    convertedCode: 'LT',
    convertedName: 'Lituanie',
  },
  LK: {
    name: 'Sri Lanka',
    convertedCode: 'LK',
    convertedName: 'Sri Lanka',
  },
  LKA: {
    name: 'Sri Lanka',
    convertedCode: 'LK',
    convertedName: 'Sri Lanka',
  },
  LR: {
    name: 'Libéria',
    convertedCode: 'LR',
    convertedName: 'Libéria',
  },
  LS: {
    name: 'Lesotho',
    convertedCode: 'LS',
    convertedName: 'Lesotho',
  },
  LSO: {
    name: 'Lesotho',
    convertedCode: 'LS',
    convertedName: 'Lesotho',
  },
  LT: {
    name: 'Lituanie',
    convertedCode: 'LT',
    convertedName: 'Lituanie',
  },
  LTU: {
    name: 'Lituanie',
    convertedCode: 'LT',
    convertedName: 'Lituanie',
  },
  LU: {
    name: 'Luxembourg',
    convertedCode: 'LU',
    convertedName: 'Luxembourg',
  },
  LUX: {
    name: 'Luxembourg',
    convertedCode: 'LU',
    convertedName: 'Luxembourg',
  },
  LV: {
    name: 'Lettonie',
    convertedCode: 'LV',
    convertedName: 'Lettonie',
  },
  LVA: {
    name: 'Lettonie',
    convertedCode: 'LV',
    convertedName: 'Lettonie',
  },
  LY: {
    name: 'Libye',
    convertedCode: 'LY',
    convertedName: 'Libye',
  },
  MA: {
    name: 'Maroc',
    convertedCode: 'MA',
    convertedName: 'Maroc',
  },
  MAC: {
    name: 'Macao',
    convertedCode: 'CN',
    convertedName: 'Chine',
  },
  MAD: {
    name: 'Madagascar',
    convertedCode: 'MG',
    convertedName: 'Madagascar',
  },
  MAF: {
    name: 'Saint Martin',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  MAR: {
    name: 'Maroc',
    convertedCode: 'MA',
    convertedName: 'Maroc',
  },
  MAS: {
    name: 'Malaisie',
    convertedCode: 'MY',
    convertedName: 'Malaisie',
  },
  MAW: {
    name: 'Malawi',
    convertedCode: 'MW',
    convertedName: 'Malawi',
  },
  MAY: {
    name: 'Mayotte',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  MC: {
    name: 'Monaco',
    convertedCode: 'MC',
    convertedName: 'Monaco',
  },
  MCO: {
    name: 'Monaco',
    convertedCode: 'MC',
    convertedName: 'Monaco',
  },
  MD: {
    name: 'Moldavie',
    convertedCode: 'MD',
    convertedName: 'Moldavie',
  },
  MDA: {
    name: 'Moldavie',
    convertedCode: 'MD',
    convertedName: 'Moldavie',
  },
  MDG: {
    name: 'Madagascar',
    convertedCode: 'MG',
    convertedName: 'Madagascar',
  },
  MDV: {
    name: 'Maldives',
    convertedCode: 'MV',
    convertedName: 'Maldives',
  },
  ME: {
    name: 'Monténégro',
    convertedCode: 'ME',
    convertedName: 'Monténégro',
  },
  MEX: {
    name: 'Mexique',
    convertedCode: 'MX',
    convertedName: 'Mexique',
  },
  MF: {
    name: 'Saint Martin',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  MG: {
    name: 'Madagascar',
    convertedCode: 'MG',
    convertedName: 'Madagascar',
  },
  MGL: {
    name: 'Mongolie',
    convertedCode: 'MN',
    convertedName: 'Mongolie',
  },
  MH: {
    name: 'Îles Marshall',
    convertedCode: 'MH',
    convertedName: 'Îles Marshall',
  },
  MHL: {
    name: 'Îles Marshall',
    convertedCode: 'MH',
    convertedName: 'Îles Marshall',
  },
  MK: {
    name: 'Macédoine',
    convertedCode: 'MK',
    convertedName: 'Macédoine',
  },
  MKD: {
    name: 'Macédoine',
    convertedCode: 'MK',
    convertedName: 'Macédoine',
  },
  ML: {
    name: 'Mali',
    convertedCode: 'ML',
    convertedName: 'Mali',
  },
  MLD: {
    name: 'Maldives',
    convertedCode: 'MV',
    convertedName: 'Maldives',
  },
  MLI: {
    name: 'Mali',
    convertedCode: 'ML',
    convertedName: 'Mali',
  },
  MLT: {
    name: 'Malte',
    convertedCode: 'MT',
    convertedName: 'Malte',
  },
  MM: {
    name: 'Birmanie',
    convertedCode: 'MM',
    convertedName: 'Birmanie',
  },
  MMR: {
    name: 'Birmanie',
    convertedCode: 'MM',
    convertedName: 'Birmanie',
  },
  MN: {
    name: 'Mongolie',
    convertedCode: 'MN',
    convertedName: 'Mongolie',
  },
  MNE: {
    name: 'Monténégro',
    convertedCode: 'ME',
    convertedName: 'Monténégro',
  },
  MNG: {
    name: 'Mongolie',
    convertedCode: 'MN',
    convertedName: 'Mongolie',
  },
  MNI: {
    name: 'Îles Mariannes du Nord',
    convertedCode: 'US',
    convertedName: 'États-Unis',
  },
  MNP: {
    name: 'Îles Mariannes du Nord',
    convertedCode: 'US',
    convertedName: 'États-Unis',
  },
  MNT: {
    name: 'Montserrat',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  MO: {
    name: 'Macao',
    convertedCode: 'CN',
    convertedName: 'Chine',
  },
  MOL: {
    name: 'Moldavie',
    convertedCode: 'MD',
    convertedName: 'Moldavie',
  },
  MON: {
    name: 'Monaco',
    convertedCode: 'MC',
    convertedName: 'Monaco',
  },
  MOZ: {
    name: 'Mozambique',
    convertedCode: 'MZ',
    convertedName: 'Mozambique',
  },
  MP: {
    name: 'Îles Mariannes du Nord',
    convertedCode: 'US',
    convertedName: 'États-Unis',
  },
  MQ: {
    name: 'Martinique',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  MR: {
    name: 'Mauritanie',
    convertedCode: 'MR',
    convertedName: 'Mauritanie',
  },
  MRI: {
    name: 'Maurice',
    convertedCode: 'MU',
    convertedName: 'Maurice',
  },
  MRQ: {
    name: 'Martinique',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  MRT: {
    name: 'Mauritanie',
    convertedCode: 'MR',
    convertedName: 'Mauritanie',
  },
  MS: {
    name: 'Montserrat',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  MSH: {
    name: 'Îles Marshall',
    convertedCode: 'MH',
    convertedName: 'Îles Marshall',
  },
  MSR: {
    name: 'Montserrat',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  MT: {
    name: 'Malte',
    convertedCode: 'MT',
    convertedName: 'Malte',
  },
  MTN: {
    name: 'Mauritanie',
    convertedCode: 'MR',
    convertedName: 'Mauritanie',
  },
  MTQ: {
    name: 'Martinique',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  MU: {
    name: 'Maurice',
    convertedCode: 'MU',
    convertedName: 'Maurice',
  },
  MUS: {
    name: 'Maurice',
    convertedCode: 'MU',
    convertedName: 'Maurice',
  },
  MV: {
    name: 'Maldives',
    convertedCode: 'MV',
    convertedName: 'Maldives',
  },
  MW: {
    name: 'Malawi',
    convertedCode: 'MW',
    convertedName: 'Malawi',
  },
  MWI: {
    name: 'Malawi',
    convertedCode: 'MW',
    convertedName: 'Malawi',
  },
  MX: {
    name: 'Mexique',
    convertedCode: 'MX',
    convertedName: 'Mexique',
  },
  MY: {
    name: 'Malaisie',
    convertedCode: 'MY',
    convertedName: 'Malaisie',
  },
  MYA: {
    name: 'Birmanie',
    convertedCode: 'MM',
    convertedName: 'Birmanie',
  },
  MYR: {
    name: 'Birmanie',
    convertedCode: 'MM',
    convertedName: 'Birmanie',
  },
  MYS: {
    name: 'Malaisie',
    convertedCode: 'MY',
    convertedName: 'Malaisie',
  },
  MYT: {
    name: 'Mayotte',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  MZ: {
    name: 'Mozambique',
    convertedCode: 'MZ',
    convertedName: 'Mozambique',
  },
  NA: {
    name: 'Namibie',
    convertedCode: 'NA',
    convertedName: 'Namibie',
  },
  NAM: {
    name: 'Namibie',
    convertedCode: 'NA',
    convertedName: 'Namibie',
  },
  NC: {
    name: 'Nouvelle-Calédonie',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  NCA: {
    name: 'Nicaragua',
    convertedCode: 'NI',
    convertedName: 'Nicaragua',
  },
  NCL: {
    name: 'Nouvelle-Calédonie',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  NE: {
    name: 'Niger',
    convertedCode: 'NE',
    convertedName: 'Niger',
  },
  NED: {
    name: 'Pays-Bas',
    convertedCode: 'NL',
    convertedName: 'Pays-Bas',
  },
  NEP: {
    name: 'Népal',
    convertedCode: 'NP',
    convertedName: 'Népal',
  },
  NER: {
    name: 'Niger',
    convertedCode: 'NE',
    convertedName: 'Niger',
  },
  NF: {
    name: 'Île Norfolk',
    convertedCode: 'AU',
    convertedName: 'Australie',
  },
  NFI: {
    name: 'Île Norfolk',
    convertedCode: 'AU',
    convertedName: 'Australie',
  },
  NFK: {
    name: 'Île Norfolk',
    convertedCode: 'AU',
    convertedName: 'Australie',
  },
  NG: {
    name: 'Nigeria',
    convertedCode: 'NG',
    convertedName: 'Nigeria',
  },
  NGA: {
    name: 'Nigeria',
    convertedCode: 'NG',
    convertedName: 'Nigeria',
  },
  NGR: {
    name: 'Nigeria',
    convertedCode: 'NG',
    convertedName: 'Nigeria',
  },
  NI: {
    name: 'Nicaragua',
    convertedCode: 'NI',
    convertedName: 'Nicaragua',
  },
  NIC: {
    name: 'Nicaragua',
    convertedCode: 'NI',
    convertedName: 'Nicaragua',
  },
  NIG: {
    name: 'Niger',
    convertedCode: 'NE',
    convertedName: 'Niger',
  },
  NIR: {
    name: 'Irlande du Nord',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  NIU: {
    name: 'Niue',
    convertedCode: 'NZ',
    convertedName: 'Nouvelle-Zélande',
  },
  NL: {
    name: 'Pays-Bas',
    convertedCode: 'NL',
    convertedName: 'Pays-Bas',
  },
  NLD: {
    name: 'Pays-Bas',
    convertedCode: 'NL',
    convertedName: 'Pays-Bas',
  },
  NMA: {
    name: 'Îles Mariannes du Nord',
    convertedCode: 'US',
    convertedName: 'États-Unis',
  },
  NMI: {
    name: 'Îles Mariannes du Nord',
    convertedCode: 'US',
    convertedName: 'États-Unis',
  },
  NO: {
    name: 'Norvège',
    convertedCode: 'NO',
    convertedName: 'Norvège',
  },
  NOR: {
    name: 'Norvège',
    convertedCode: 'NO',
    convertedName: 'Norvège',
  },
  NP: {
    name: 'Népal',
    convertedCode: 'NP',
    convertedName: 'Népal',
  },
  NPL: {
    name: 'Népal',
    convertedCode: 'NP',
    convertedName: 'Népal',
  },
  NR: {
    name: 'Nauru',
    convertedCode: 'NR',
    convertedName: 'Nauru',
  },
  NRU: {
    name: 'Nauru',
    convertedCode: 'NR',
    convertedName: 'Nauru',
  },
  NU: {
    name: 'Niue',
    convertedCode: 'NZ',
    convertedName: 'Nouvelle-Zélande',
  },
  NZ: {
    name: 'Nouvelle-Zélande',
    convertedCode: 'NZ',
    convertedName: 'Nouvelle-Zélande',
  },
  NZL: {
    name: 'Nouvelle-Zélande',
    convertedCode: 'NZ',
    convertedName: 'Nouvelle-Zélande',
  },
  OM: {
    name: 'Oman',
    convertedCode: 'OM',
    convertedName: 'Oman',
  },
  OMA: {
    name: 'Oman',
    convertedCode: 'OM',
    convertedName: 'Oman',
  },
  OMN: {
    name: 'Oman',
    convertedCode: 'OM',
    convertedName: 'Oman',
  },
  PA: {
    name: 'Panama',
    convertedCode: 'PA',
    convertedName: 'Panama',
  },
  PAK: {
    name: 'Pakistan',
    convertedCode: 'PK',
    convertedName: 'Pakistan',
  },
  PAN: {
    name: 'Panama',
    convertedCode: 'PA',
    convertedName: 'Panama',
  },
  PAR: {
    name: 'Paraguay',
    convertedCode: 'PY',
    convertedName: 'Paraguay',
  },
  PCN: {
    name: 'Pitcairn',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  PE: {
    name: 'Pérou',
    convertedCode: 'PE',
    convertedName: 'Pérou',
  },
  PER: {
    name: 'Pérou',
    convertedCode: 'PE',
    convertedName: 'Pérou',
  },
  PF: {
    name: 'Polynésie française',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  PG: {
    name: 'Papouasie-Nouvelle-Guinée',
    convertedCode: 'PG',
    convertedName: 'Papouasie-Nouvelle-Guinée',
  },
  PH: {
    name: 'Philippines',
    convertedCode: 'PH',
    convertedName: 'Philippines',
  },
  PHI: {
    name: 'Philippines',
    convertedCode: 'PH',
    convertedName: 'Philippines',
  },
  PHL: {
    name: 'Philippines',
    convertedCode: 'PH',
    convertedName: 'Philippines',
  },
  PK: {
    name: 'Pakistan',
    convertedCode: 'PK',
    convertedName: 'Pakistan',
  },
  PKR: {
    name: 'Corée du Nord',
    convertedCode: 'KP',
    convertedName: 'Corée du Nord',
  },
  PL: {
    name: 'Pologne',
    convertedCode: 'PL',
    convertedName: 'Pologne',
  },
  PLE: {
    name: 'Palestine',
    convertedCode: 'PS',
    convertedName: 'Palestine',
  },
  PLW: {
    name: 'Palaos',
    convertedCode: 'PW',
    convertedName: 'Palaos',
  },
  PM: {
    name: 'Saint-Pierre-et-Miquelon',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  PN: {
    name: 'Pitcairn',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  PNG: {
    name: 'Papouasie-Nouvelle-Guinée',
    convertedCode: 'PG',
    convertedName: 'Papouasie-Nouvelle-Guinée',
  },
  POL: {
    name: 'Pologne',
    convertedCode: 'PL',
    convertedName: 'Pologne',
  },
  POR: {
    name: 'Portugal',
    convertedCode: 'PT',
    convertedName: 'Portugal',
  },
  PR: {
    name: 'Porto Rico',
    convertedCode: 'PR',
    convertedName: 'Porto Rico',
  },
  PRI: {
    name: 'Porto Rico',
    convertedCode: 'PR',
    convertedName: 'Porto Rico',
  },
  PRK: {
    name: 'Corée du Nord',
    convertedCode: 'KP',
    convertedName: 'Corée du Nord',
  },
  PRT: {
    name: 'Portugal',
    convertedCode: 'PT',
    convertedName: 'Portugal',
  },
  PRY: {
    name: 'Paraguay',
    convertedCode: 'PY',
    convertedName: 'Paraguay',
  },
  PS: {
    name: 'Palestine',
    convertedCode: 'PS',
    convertedName: 'Palestine',
  },
  PSE: {
    name: 'Palestine',
    convertedCode: 'PS',
    convertedName: 'Palestine',
  },
  PT: {
    name: 'Portugal',
    convertedCode: 'PT',
    convertedName: 'Portugal',
  },
  PUR: {
    name: 'Porto Rico',
    convertedCode: 'PR',
    convertedName: 'Porto Rico',
  },
  PW: {
    name: 'Palaos',
    convertedCode: 'PW',
    convertedName: 'Palaos',
  },
  PY: {
    name: 'Paraguay',
    convertedCode: 'PY',
    convertedName: 'Paraguay',
  },
  PYF: {
    name: 'Polynésie française',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  QA: {
    name: 'Qatar',
    convertedCode: 'QA',
    convertedName: 'Qatar',
  },
  QAT: {
    name: 'Qatar',
    convertedCode: 'QA',
    convertedName: 'Qatar',
  },
  RE: {
    name: 'Réunion',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  REU: {
    name: 'Réunion',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  RO: {
    name: 'Roumanie',
    convertedCode: 'RO',
    convertedName: 'Roumanie',
  },
  ROM: {
    name: 'Roumanie',
    convertedCode: 'RO',
    convertedName: 'Roumanie',
  },
  ROU: {
    name: 'Roumanie',
    convertedCode: 'RO',
    convertedName: 'Roumanie',
  },
  RS: {
    name: 'Serbie',
    convertedCode: 'RS',
    convertedName: 'Serbie',
  },
  RSA: {
    name: 'Afrique du Sud',
    convertedCode: 'ZA',
    convertedName: 'Afrique du Sud',
  },
  RU: {
    name: 'Russie',
    convertedCode: 'RU',
    convertedName: 'Russie',
  },
  RUS: {
    name: 'Russie',
    convertedCode: 'RU',
    convertedName: 'Russie',
  },
  RW: {
    name: 'Rwanda',
    convertedCode: 'RW',
    convertedName: 'Rwanda',
  },
  RWA: {
    name: 'Rwanda',
    convertedCode: 'RW',
    convertedName: 'Rwanda',
  },
  SA: {
    name: 'Arabie Saoudite',
    convertedCode: 'SA',
    convertedName: 'Arabie Saoudite',
  },
  SAM: {
    name: 'Samoa',
    convertedCode: 'WS',
    convertedName: 'Samoa',
  },
  SAU: {
    name: 'Arabie Saoudite',
    convertedCode: 'SA',
    convertedName: 'Arabie Saoudite',
  },
  SB: {
    name: 'Îles Salomon',
    convertedCode: 'SB',
    convertedName: 'Îles Salomon',
  },
  SBT: {
    name: 'Saint Barthélemy',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  SC: {
    name: 'Seychelles',
    convertedCode: 'SC',
    convertedName: 'Seychelles',
  },
  SCO: {
    name: 'Ecosse',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  SCT: {
    name: 'Ecosse',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  SD: {
    name: 'Soudan',
    convertedCode: 'SD',
    convertedName: 'Soudan',
  },
  SDN: {
    name: 'Soudan',
    convertedCode: 'SD',
    convertedName: 'Soudan',
  },
  SE: {
    name: 'Suède',
    convertedCode: 'SE',
    convertedName: 'Suède',
  },
  SEN: {
    name: 'Sénégal',
    convertedCode: 'SN',
    convertedName: 'Sénégal',
  },
  SER: {
    name: 'Serbie',
    convertedCode: 'RS',
    convertedName: 'Serbie',
  },
  SEY: {
    name: 'Seychelles',
    convertedCode: 'SC',
    convertedName: 'Seychelles',
  },
  SG: {
    name: 'Singapour',
    convertedCode: 'SG',
    convertedName: 'Singapour',
  },
  SGP: {
    name: 'Singapour',
    convertedCode: 'SG',
    convertedName: 'Singapour',
  },
  SGS: {
    name: 'Géorgie du Sud et les Îles Sandwich du Sud',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  SH: {
    name: 'Sainte-Hélène',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  SHN: {
    name: 'Sainte-Hélène',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  SI: {
    name: 'Slovénie',
    convertedCode: 'SI',
    convertedName: 'Slovénie',
  },
  SIN: {
    name: 'Singapour',
    convertedCode: 'SG',
    convertedName: 'Singapour',
  },
  SJ: {
    name: 'Svalbard et Île Jan Mayen',
    convertedCode: 'NO',
    convertedName: 'Norvège',
  },
  SJM: {
    name: 'Svalbard et Île Jan Mayen',
    convertedCode: 'NO',
    convertedName: 'Norvège',
  },
  SK: {
    name: 'Slovaquie',
    convertedCode: 'SK',
    convertedName: 'Slovaquie',
  },
  SKN: {
    name: 'Saint-Christophe-et-Niévès',
    convertedCode: 'KN',
    convertedName: 'Saint-Christophe-et-Niévès',
  },
  SL: {
    name: 'Sierra Leone',
    convertedCode: 'SL',
    convertedName: 'Sierra Leone',
  },
  SLB: {
    name: 'Îles Salomon',
    convertedCode: 'SB',
    convertedName: 'Îles Salomon',
  },
  SLE: {
    name: 'Sierra Leone',
    convertedCode: 'SL',
    convertedName: 'Sierra Leone',
  },
  SLO: {
    name: 'Slovénie',
    convertedCode: 'SI',
    convertedName: 'Slovénie',
  },
  SLV: {
    name: 'Salvador',
    convertedCode: 'SV',
    convertedName: 'Salvador',
  },
  SM: {
    name: 'Saint-Marin',
    convertedCode: 'SM',
    convertedName: 'Saint-Marin',
  },
  SMR: {
    name: 'Saint-Marin',
    convertedCode: 'SM',
    convertedName: 'Saint-Marin',
  },
  SN: {
    name: 'Sénégal',
    convertedCode: 'SN',
    convertedName: 'Sénégal',
  },
  SO: {
    name: 'Somalie',
    convertedCode: 'SO',
    convertedName: 'Somalie',
  },
  SOL: {
    name: 'Îles Salomon',
    convertedCode: 'SB',
    convertedName: 'Îles Salomon',
  },
  SOM: {
    name: 'Somalie',
    convertedCode: 'SO',
    convertedName: 'Somalie',
  },
  SPM: {
    name: 'Saint-Pierre-et-Miquelon',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  SR: {
    name: 'Suriname',
    convertedCode: 'SR',
    convertedName: 'Suriname',
  },
  SRB: {
    name: 'Serbie',
    convertedCode: 'RS',
    convertedName: 'Serbie',
  },
  SRI: {
    name: 'Sri Lanka',
    convertedCode: 'LK',
    convertedName: 'Sri Lanka',
  },
  SS: {
    name: 'Sud Soudan',
    convertedCode: 'SS',
    convertedName: 'Sud Soudan',
  },
  SSD: {
    name: 'Sud Soudan',
    convertedCode: 'SS',
    convertedName: 'Sud Soudan',
  },
  SSU: {
    name: 'Sud Soudan',
    convertedCode: 'SS',
    convertedName: 'Sud Soudan',
  },
  ST: {
    name: 'Sao Tomé-et-Principe',
    convertedCode: 'ST',
    convertedName: 'Sao Tomé-et-Principe',
  },
  STH: {
    name: 'Sainte-Hélène',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  STK: {
    name: 'Saint-Christophe-et-Niévès',
    convertedCode: 'KN',
    convertedName: 'Saint-Christophe-et-Niévès',
  },
  STL: {
    name: 'Sainte-Lucie',
    convertedCode: 'LC',
    convertedName: 'Sainte-Lucie',
  },
  STP: {
    name: 'Sao Tomé-et-Principe',
    convertedCode: 'ST',
    convertedName: 'Sao Tomé-et-Principe',
  },
  STV: {
    name: 'Saint-Vincent-et-les-Grenadines',
    convertedCode: 'VC',
    convertedName: 'Saint-Vincent-et-les-Grenadines',
  },
  SUD: {
    name: 'Soudan',
    convertedCode: 'SD',
    convertedName: 'Soudan',
  },
  SUI: {
    name: 'Suisse',
    convertedCode: 'CH',
    convertedName: 'Suisse',
  },
  SUR: {
    name: 'Suriname',
    convertedCode: 'SR',
    convertedName: 'Suriname',
  },
  SV: {
    name: 'Salvador',
    convertedCode: 'SV',
    convertedName: 'Salvador',
  },
  SVK: {
    name: 'Slovaquie',
    convertedCode: 'SK',
    convertedName: 'Slovaquie',
  },
  SVN: {
    name: 'Slovénie',
    convertedCode: 'SI',
    convertedName: 'Slovénie',
  },
  SWE: {
    name: 'Suède',
    convertedCode: 'SE',
    convertedName: 'Suède',
  },
  SWZ: {
    name: 'Eswatini',
    convertedCode: 'SZ',
    convertedName: 'Eswatini',
  },
  SX: {
    name: 'Sint Maarten',
    convertedCode: 'NL',
    convertedName: 'Pays-Bas',
  },
  SXM: {
    name: 'Sint Maarten',
    convertedCode: 'NL',
    convertedName: 'Pays-Bas',
  },
  SY: {
    name: 'Syrie',
    convertedCode: 'SY',
    convertedName: 'Syrie',
  },
  SYC: {
    name: 'Seychelles',
    convertedCode: 'SC',
    convertedName: 'Seychelles',
  },
  SYR: {
    name: 'Syrie',
    convertedCode: 'SY',
    convertedName: 'Syrie',
  },
  SZ: {
    name: 'Eswatini',
    convertedCode: 'SZ',
    convertedName: 'Eswatini',
  },
  TAH: {
    name: 'Polynésie française',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  TAN: {
    name: 'Tanzanie',
    convertedCode: 'TZ',
    convertedName: 'Tanzanie',
  },
  TC: {
    name: 'Îles Turques-et-Caïques',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  TCA: {
    name: 'Îles Turques-et-Caïques',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  TCD: {
    name: 'Tchad',
    convertedCode: 'TD',
    convertedName: 'Tchad',
  },
  TD: {
    name: 'Tchad',
    convertedCode: 'TD',
    convertedName: 'Tchad',
  },
  TF: {
    name: 'Terres australes françaises',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  TG: {
    name: 'Togo',
    convertedCode: 'TG',
    convertedName: 'Togo',
  },
  TGA: {
    name: 'Tonga',
    convertedCode: 'TO',
    convertedName: 'Tonga',
  },
  TGO: {
    name: 'Togo',
    convertedCode: 'TG',
    convertedName: 'Togo',
  },
  TH: {
    name: 'Thaïlande',
    convertedCode: 'TH',
    convertedName: 'Thaïlande',
  },
  THA: {
    name: 'Thaïlande',
    convertedCode: 'TH',
    convertedName: 'Thaïlande',
  },
  TJ: {
    name: 'Tadjikistan',
    convertedCode: 'TJ',
    convertedName: 'Tadjikistan',
  },
  TJK: {
    name: 'Tadjikistan',
    convertedCode: 'TJ',
    convertedName: 'Tadjikistan',
  },
  TK: {
    name: 'Tokelau',
    convertedCode: 'NZ',
    convertedName: 'Nouvelle-Zélande',
  },
  TKL: {
    name: 'Tokelau',
    convertedCode: 'NZ',
    convertedName: 'Nouvelle-Zélande',
  },
  TKM: {
    name: 'Turkménistan',
    convertedCode: 'TM',
    convertedName: 'Turkménistan',
  },
  TKS: {
    name: 'Îles Turques-et-Caïques',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  TL: {
    name: 'Timor oriental',
    convertedCode: 'TL',
    convertedName: 'Timor oriental',
  },
  TLS: {
    name: 'Timor oriental',
    convertedCode: 'TL',
    convertedName: 'Timor oriental',
  },
  TM: {
    name: 'Turkménistan',
    convertedCode: 'TM',
    convertedName: 'Turkménistan',
  },
  TN: {
    name: 'Tunisie',
    convertedCode: 'TN',
    convertedName: 'Tunisie',
  },
  TO: {
    name: 'Tonga',
    convertedCode: 'TO',
    convertedName: 'Tonga',
  },
  TOG: {
    name: 'Togo',
    convertedCode: 'TG',
    convertedName: 'Togo',
  },
  TON: {
    name: 'Tonga',
    convertedCode: 'TO',
    convertedName: 'Tonga',
  },
  TPE: {
    name: 'Taïwan',
    convertedCode: 'TW',
    convertedName: 'Taïwan',
  },
  TR: {
    name: 'Turquie',
    convertedCode: 'TR',
    convertedName: 'Turquie',
  },
  TRI: {
    name: 'Trinité-et-Tobago',
    convertedCode: 'TT',
    convertedName: 'Trinité-et-Tobago',
  },
  TT: {
    name: 'Trinité-et-Tobago',
    convertedCode: 'TT',
    convertedName: 'Trinité-et-Tobago',
  },
  TTO: {
    name: 'Trinité-et-Tobago',
    convertedCode: 'TT',
    convertedName: 'Trinité-et-Tobago',
  },
  TUN: {
    name: 'Tunisie',
    convertedCode: 'TN',
    convertedName: 'Tunisie',
  },
  TUR: {
    name: 'Turquie',
    convertedCode: 'TR',
    convertedName: 'Turquie',
  },
  TUV: {
    name: 'Tuvalu',
    convertedCode: 'TV',
    convertedName: 'Tuvalu',
  },
  TV: {
    name: 'Tuvalu',
    convertedCode: 'TV',
    convertedName: 'Tuvalu',
  },
  TW: {
    name: 'Taïwan',
    convertedCode: 'TW',
    convertedName: 'Taïwan',
  },
  TWN: {
    name: 'Taïwan',
    convertedCode: 'TW',
    convertedName: 'Taïwan',
  },
  TZ: {
    name: 'Tanzanie',
    convertedCode: 'TZ',
    convertedName: 'Tanzanie',
  },
  TZA: {
    name: 'Tanzanie',
    convertedCode: 'TZ',
    convertedName: 'Tanzanie',
  },
  UA: {
    name: 'Ukraine',
    convertedCode: 'UA',
    convertedName: 'Ukraine',
  },
  UAE: {
    name: 'Émirats arabes unis',
    convertedCode: 'AE',
    convertedName: 'Émirats arabes unis',
  },
  UG: {
    name: 'Ouganda',
    convertedCode: 'UG',
    convertedName: 'Ouganda',
  },
  UGA: {
    name: 'Ouganda',
    convertedCode: 'UG',
    convertedName: 'Ouganda',
  },
  UKR: {
    name: 'Ukraine',
    convertedCode: 'UA',
    convertedName: 'Ukraine',
  },
  UM: {
    name: 'Îles mineures éloignées des États-Unis',
    convertedCode: 'US',
    convertedName: 'États-Unis',
  },
  UMI: {
    name: 'Îles mineures éloignées des États-Unis',
    convertedCode: 'US',
    convertedName: 'États-Unis',
  },
  URU: {
    name: 'Uruguay',
    convertedCode: 'UY',
    convertedName: 'Uruguay',
  },
  URY: {
    name: 'Uruguay',
    convertedCode: 'UY',
    convertedName: 'Uruguay',
  },
  US: {
    name: 'États-Unis',
    convertedCode: 'US',
    convertedName: 'États-Unis',
  },
  USA: {
    name: 'États-Unis',
    convertedCode: 'US',
    convertedName: 'États-Unis',
  },
  UY: {
    name: 'Uruguay',
    convertedCode: 'UY',
    convertedName: 'Uruguay',
  },
  UZ: {
    name: 'Ouzbékistan',
    convertedCode: 'UZ',
    convertedName: 'Ouzbékistan',
  },
  UZB: {
    name: 'Ouzbékistan',
    convertedCode: 'UZ',
    convertedName: 'Ouzbékistan',
  },
  VA: {
    name: 'Vatican',
    convertedCode: 'VA',
    convertedName: 'Vatican',
  },
  VAN: {
    name: 'Vanuatu',
    convertedCode: 'VU',
    convertedName: 'Vanuatu',
  },
  VAT: {
    name: 'Vatican',
    convertedCode: 'VA',
    convertedName: 'Vatican',
  },
  VC: {
    name: 'Saint-Vincent-et-les-Grenadines',
    convertedCode: 'VC',
    convertedName: 'Saint-Vincent-et-les-Grenadines',
  },
  VCT: {
    name: 'Saint-Vincent-et-les-Grenadines',
    convertedCode: 'VC',
    convertedName: 'Saint-Vincent-et-les-Grenadines',
  },
  VE: {
    name: 'Venezuela',
    convertedCode: 'VE',
    convertedName: 'Venezuela',
  },
  VEN: {
    name: 'Venezuela',
    convertedCode: 'VE',
    convertedName: 'Venezuela',
  },
  VG: {
    name: 'Îles Vierges britanniques',
    convertedCode: 'VG',
    convertedName: 'Îles Vierges britanniques',
  },
  VGB: {
    name: 'Îles Vierges britanniques',
    convertedCode: 'VG',
    convertedName: 'Îles Vierges britanniques',
  },
  VI: {
    name: 'Îles Vierges américaines',
    convertedCode: 'VI',
    convertedName: 'Îles Vierges américaines',
  },
  VIE: {
    name: 'Vietnam',
    convertedCode: 'VN',
    convertedName: 'Vietnam',
  },
  VIN: {
    name: 'Saint-Vincent-et-les-Grenadines',
    convertedCode: 'VC',
    convertedName: 'Saint-Vincent-et-les-Grenadines',
  },
  VIR: {
    name: 'Îles Vierges américaines',
    convertedCode: 'VI',
    convertedName: 'Îles Vierges américaines',
  },
  VN: {
    name: 'Vietnam',
    convertedCode: 'VN',
    convertedName: 'Vietnam',
  },
  VNM: {
    name: 'Vietnam',
    convertedCode: 'VN',
    convertedName: 'Vietnam',
  },
  VU: {
    name: 'Vanuatu',
    convertedCode: 'VU',
    convertedName: 'Vanuatu',
  },
  VUT: {
    name: 'Vanuatu',
    convertedCode: 'VU',
    convertedName: 'Vanuatu',
  },
  WAL: {
    name: 'Pays de Galles',
    convertedCode: 'GB',
    convertedName: 'Royaume-Uni',
  },
  WF: {
    name: 'Wallis et Futuna',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  WFU: {
    name: 'Wallis et Futuna',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  WLF: {
    name: 'Wallis et Futuna',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  WS: {
    name: 'Samoa',
    convertedCode: 'WS',
    convertedName: 'Samoa',
  },
  WSM: {
    name: 'Samoa',
    convertedCode: 'WS',
    convertedName: 'Samoa',
  },
  XK: {
    name: 'Kosovo',
    convertedCode: 'XK',
    convertedName: 'Kosovo',
  },
  YE: {
    name: 'Yémen',
    convertedCode: 'YE',
    convertedName: 'Yémen',
  },
  YEM: {
    name: 'Yémen',
    convertedCode: 'YE',
    convertedName: 'Yémen',
  },
  YT: {
    name: 'Mayotte',
    convertedCode: 'FR',
    convertedName: 'France',
  },
  ZA: {
    name: 'Afrique du Sud',
    convertedCode: 'ZA',
    convertedName: 'Afrique du Sud',
  },
  ZAF: {
    name: 'Afrique du Sud',
    convertedCode: 'ZA',
    convertedName: 'Afrique du Sud',
  },
  ZAM: {
    name: 'Zambie',
    convertedCode: 'ZM',
    convertedName: 'Zambie',
  },
  ZBW: {
    name: 'Zimbabwe',
    convertedCode: 'ZW',
    convertedName: 'Zimbabwe',
  },
  ZIM: {
    name: 'Zimbabwe',
    convertedCode: 'ZW',
    convertedName: 'Zimbabwe',
  },
  ZM: {
    name: 'Zambie',
    convertedCode: 'ZM',
    convertedName: 'Zambie',
  },
  ZMB: {
    name: 'Zambie',
    convertedCode: 'ZM',
    convertedName: 'Zambie',
  },
  ZW: {
    name: 'Zimbabwe',
    convertedCode: 'ZW',
    convertedName: 'Zimbabwe',
  },
  ZWE: {
    name: 'Zimbabwe',
    convertedCode: 'ZW',
    convertedName: 'Zimbabwe',
  },
  AFGH: { name: 'Afghanistan', convertedCode: 'AF', convertedName: 'Afghanistan' },
  AFRI: { name: 'Afrique du Sud', convertedCode: 'ZA', convertedName: 'Afrique du Sud' },
  ALBA: { name: 'Albanie', convertedCode: 'AL', convertedName: 'Albanie' },
  ALGE: { name: 'Algérie', convertedCode: 'DZ', convertedName: 'Algérie' },
  ALGÉ: { name: 'Algérie', convertedCode: 'DZ', convertedName: 'Algérie' },
  ALLE: { name: 'Allemagne', convertedCode: 'DE', convertedName: 'Allemagne' },
  ANDO: { name: 'Andorre', convertedCode: 'AD', convertedName: 'Andorre' },
  ANGO: { name: 'Angola', convertedCode: 'AO', convertedName: 'Angola' },
  ANTA: { name: 'Antarctique', convertedCode: 'AQ', convertedName: 'Antarctique' },
  ANTI: { name: 'Antigua-et-Barbuda', convertedCode: 'AG', convertedName: 'Antigua-et-Barbuda' },
  ARAB: { name: 'Arabie Saoudite', convertedCode: 'SA', convertedName: 'Arabie Saoudite' },
  ARGE: { name: 'Argentine', convertedCode: 'AR', convertedName: 'Argentine' },
  ARME: { name: 'Arménie', convertedCode: 'AM', convertedName: 'Arménie' },
  ARMÉ: { name: 'Arménie', convertedCode: 'AM', convertedName: 'Arménie' },
  ARUB: { name: 'Aruba', convertedCode: 'AW', convertedName: 'Aruba' },
  AUST: { name: 'Australie', convertedCode: 'AU', convertedName: 'Australie' },
  AUTR: { name: 'Autriche', convertedCode: 'AT', convertedName: 'Autriche' },
  AZER: { name: 'Azerbaïdjan', convertedCode: 'AZ', convertedName: 'Azerbaïdjan' },
  BAHA: { name: 'Bahamas', convertedCode: 'BS', convertedName: 'Bahamas' },
  BAHR: { name: 'Bahreïn', convertedCode: 'BH', convertedName: 'Bahreïn' },
  BANG: { name: 'Bangladesh', convertedCode: 'BD', convertedName: 'Bangladesh' },
  BARB: { name: 'Barbade', convertedCode: 'BB', convertedName: 'Barbade' },
  BELA: { name: 'Biélorussie', convertedCode: 'BY', convertedName: 'Biélorussie' },
  BELG: { name: 'Belgique', convertedCode: 'BE', convertedName: 'Belgique' },
  BELI: { name: 'Belize', convertedCode: 'BZ', convertedName: 'Belize' },
  BENI: { name: 'Bénin', convertedCode: 'BJ', convertedName: 'Bénin' },
  BÉNI: { name: 'Bénin', convertedCode: 'BJ', convertedName: 'Bénin' },
  BERM: { name: 'Bermudes', convertedCode: 'BM', convertedName: 'Bermudes' },
  BHOU: { name: 'Bhoutan', convertedCode: 'BT', convertedName: 'Bhoutan' },
  BHUT: { name: 'Bhoutan', convertedCode: 'BT', convertedName: 'Bhoutan' },
  BIEL: { name: 'Biélorussie', convertedCode: 'BY', convertedName: 'Biélorussie' },
  BIÉL: { name: 'Biélorussie', convertedCode: 'BY', convertedName: 'Biélorussie' },
  BIRM: { name: 'Birmanie', convertedCode: 'MM', convertedName: 'Birmanie' },
  BOLI: { name: 'Bolivie', convertedCode: 'BO', convertedName: 'Bolivie' },
  BOSN: { name: 'Bosnie-Herzégovine', convertedCode: 'BA', convertedName: 'Bosnie-Herzégovine' },
  BOTS: { name: 'Botswana', convertedCode: 'BW', convertedName: 'Botswana' },
  BRAZ: { name: 'Brésil', convertedCode: 'BR', convertedName: 'Brésil' },
  BRÉS: { name: 'Brésil', convertedCode: 'BR', convertedName: 'Brésil' },
  BRUN: { name: 'Brunei', convertedCode: 'BN', convertedName: 'Brunei' },
  BULG: { name: 'Bulgarie', convertedCode: 'BG', convertedName: 'Bulgarie' },
  BURK: { name: 'Burkina Faso', convertedCode: 'BF', convertedName: 'Burkina Faso' },
  BURU: { name: 'Burundi', convertedCode: 'BI', convertedName: 'Burundi' },
  CAMB: { name: 'Cambodge', convertedCode: 'KH', convertedName: 'Cambodge' },
  CAME: { name: 'Cameroun', convertedCode: 'CM', convertedName: 'Cameroun' },
  CANA: { name: 'Canada', convertedCode: 'CA', convertedName: 'Canada' },
  'CAP-': { name: 'Cap-Vert', convertedCode: 'CV', convertedName: 'Cap-Vert' },
  CAPE: { name: 'Cap-Vert', convertedCode: 'CV', convertedName: 'Cap-Vert' },
  CAYM: { name: 'Îles Caïmans', convertedCode: 'KY', convertedName: 'Îles Caïmans' },
  CENT: { name: 'République centrafricaine', convertedCode: 'CF', convertedName: 'République centrafricaine' },
  CHAD: { name: 'Tchad', convertedCode: 'TD', convertedName: 'Tchad' },
  CHIL: { name: 'Chili', convertedCode: 'CL', convertedName: 'Chili' },
  CHIN: { name: 'Chine', convertedCode: 'CN', convertedName: 'Chine' },
  CHYP: { name: 'Chypre', convertedCode: 'CY', convertedName: 'Chypre' },
  COLO: { name: 'Colombie', convertedCode: 'CO', convertedName: 'Colombie' },
  COMO: { name: 'Comores', convertedCode: 'KM', convertedName: 'Comores' },
  CONG: { name: 'Congo', convertedCode: 'CG', convertedName: 'Congo' },
  COOK: { name: 'Îles Cook', convertedCode: 'CK', convertedName: 'Îles Cook' },
  CORE: { name: 'Corée du Sud', convertedCode: 'KR', convertedName: 'Corée du Sud' },
  CORÉ: { name: 'Corée du Sud', convertedCode: 'KR', convertedName: 'Corée du Sud' },
  COST: { name: 'Costa Rica', convertedCode: 'CR', convertedName: 'Costa Rica' },
  COTE: { name: 'Côte d’Ivoire', convertedCode: 'CI', convertedName: 'Côte d’Ivoire' },
  CÔTE: { name: 'Côte d’Ivoire', convertedCode: 'CI', convertedName: 'Côte d’Ivoire' },
  CROA: { name: 'Croatie', convertedCode: 'HR', convertedName: 'Croatie' },
  CUBA: { name: 'Cuba', convertedCode: 'CU', convertedName: 'Cuba' },
  CYPR: { name: 'Chypre', convertedCode: 'CY', convertedName: 'Chypre' },
  CZEC: { name: 'République tchèque', convertedCode: 'CZ', convertedName: 'République tchèque' },
  DANE: { name: 'Danemark', convertedCode: 'DK', convertedName: 'Danemark' },
  DEMO: { name: 'RD Congo', convertedCode: 'CD', convertedName: 'RD Congo' },
  DENM: { name: 'Danemark', convertedCode: 'DK', convertedName: 'Danemark' },
  DJIB: { name: 'Djibouti', convertedCode: 'DJ', convertedName: 'Djibouti' },
  EAST: { name: 'Timor oriental', convertedCode: 'TL', convertedName: 'Timor oriental' },
  ECUA: { name: 'Équateur', convertedCode: 'EC', convertedName: 'Équateur' },
  EGYP: { name: 'Égypte', convertedCode: 'EG', convertedName: 'Égypte' },
  ÉGYP: { name: 'Égypte', convertedCode: 'EG', convertedName: 'Égypte' },
  EMIR: { name: 'Émirats arabes unis', convertedCode: 'AE', convertedName: 'Émirats arabes unis' },
  ÉMIR: { name: 'Émirats arabes unis', convertedCode: 'AE', convertedName: 'Émirats arabes unis' },
  EQUA: { name: 'Équateur', convertedCode: 'EC', convertedName: 'Équateur' },
  ÉQUA: { name: 'Équateur', convertedCode: 'EC', convertedName: 'Équateur' },
  ERIT: { name: 'Érythrée', convertedCode: 'ER', convertedName: 'Érythrée' },
  ERYT: { name: 'Érythrée', convertedCode: 'ER', convertedName: 'Érythrée' },
  ÉRYT: { name: 'Érythrée', convertedCode: 'ER', convertedName: 'Érythrée' },
  ESPA: { name: 'Espagne', convertedCode: 'ES', convertedName: 'Espagne' },
  ESTO: { name: 'Estonie', convertedCode: 'EE', convertedName: 'Estonie' },
  ESWA: { name: 'Eswatini', convertedCode: 'SZ', convertedName: 'Eswatini' },
  ETAT: { name: 'États-Unis', convertedCode: 'US', convertedName: 'États-Unis' },
  ÉTAT: { name: 'États-Unis', convertedCode: 'US', convertedName: 'États-Unis' },
  ETHI: { name: 'Éthiopie', convertedCode: 'ET', convertedName: 'Éthiopie' },
  ÉTHI: { name: 'Éthiopie', convertedCode: 'ET', convertedName: 'Éthiopie' },
  FIDJ: { name: 'Fidji', convertedCode: 'FJ', convertedName: 'Fidji' },
  FIJI: { name: 'Fidji', convertedCode: 'FJ', convertedName: 'Fidji' },
  FINL: { name: 'Finlande', convertedCode: 'FI', convertedName: 'Finlande' },
  FRAN: { name: 'France', convertedCode: 'FR', convertedName: 'France' },
  GABO: { name: 'Gabon', convertedCode: 'GA', convertedName: 'Gabon' },
  GAMB: { name: 'Gambie', convertedCode: 'GM', convertedName: 'Gambie' },
  GEOR: { name: 'Géorgie', convertedCode: 'GE', convertedName: 'Géorgie' },
  GÉOR: { name: 'Géorgie', convertedCode: 'GE', convertedName: 'Géorgie' },
  GERM: { name: 'Allemagne', convertedCode: 'DE', convertedName: 'Allemagne' },
  GHAN: { name: 'Ghana', convertedCode: 'GH', convertedName: 'Ghana' },
  GREA: { name: 'Royaume-Uni', convertedCode: 'GB', convertedName: 'Royaume-Uni' },
  GREC: { name: 'Grèce', convertedCode: 'GR', convertedName: 'Grèce' },
  GRÈC: { name: 'Grèce', convertedCode: 'GR', convertedName: 'Grèce' },
  GREE: { name: 'Grèce', convertedCode: 'GR', convertedName: 'Grèce' },
  GREN: { name: 'Grenade', convertedCode: 'GD', convertedName: 'Grenade' },
  GUAM: { name: 'Guam', convertedCode: 'GU', convertedName: 'Guam' },
  GUAT: { name: 'Guatemala', convertedCode: 'GT', convertedName: 'Guatemala' },
  GUYA: { name: 'Guyana', convertedCode: 'GY', convertedName: 'Guyana' },
  HAIT: { name: 'Haïti', convertedCode: 'HT', convertedName: 'Haïti' },
  HAÏT: { name: 'Haïti', convertedCode: 'HT', convertedName: 'Haïti' },
  HOND: { name: 'Honduras', convertedCode: 'HN', convertedName: 'Honduras' },
  HUNG: { name: 'Hongrie', convertedCode: 'HU', convertedName: 'Hongrie' },
  ICEL: { name: 'Islande', convertedCode: 'IS', convertedName: 'Islande' },
  INDE: { name: 'Inde', convertedCode: 'IN', convertedName: 'Inde' },
  INDI: { name: 'Inde', convertedCode: 'IN', convertedName: 'Inde' },
  INDO: { name: 'Indonésie', convertedCode: 'ID', convertedName: 'Indonésie' },
  IRAK: { name: 'Irak', convertedCode: 'IQ', convertedName: 'Irak' },
  IRAN: { name: 'Iran', convertedCode: 'IR', convertedName: 'Iran' },
  IRAQ: { name: 'Irak', convertedCode: 'IQ', convertedName: 'Irak' },
  IREL: { name: 'Irlande', convertedCode: 'IE', convertedName: 'Irlande' },
  IRLA: { name: 'Irlande', convertedCode: 'IE', convertedName: 'Irlande' },
  ISLA: { name: 'Islande', convertedCode: 'IS', convertedName: 'Islande' },
  ISRA: { name: 'Israël', convertedCode: 'IL', convertedName: 'Israël' },
  ITAL: { name: 'Italie', convertedCode: 'IT', convertedName: 'Italie' },
  IVOR: { name: 'Côte d’Ivoire', convertedCode: 'CI', convertedName: 'Côte d’Ivoire' },
  JAMA: { name: 'Jamaïque', convertedCode: 'JM', convertedName: 'Jamaïque' },
  JAPA: { name: 'Japon', convertedCode: 'JP', convertedName: 'Japon' },
  JAPO: { name: 'Japon', convertedCode: 'JP', convertedName: 'Japon' },
  JORD: { name: 'Jordanie', convertedCode: 'JO', convertedName: 'Jordanie' },
  KAZA: { name: 'Kazakhstan', convertedCode: 'KZ', convertedName: 'Kazakhstan' },
  KENY: { name: 'Kenya', convertedCode: 'KE', convertedName: 'Kenya' },
  KIRG: { name: 'Kirghizistan', convertedCode: 'KG', convertedName: 'Kirghizistan' },
  KIRI: { name: 'Kiribati', convertedCode: 'KI', convertedName: 'Kiribati' },
  KOSO: { name: 'Kosovo', convertedCode: 'XK', convertedName: 'Kosovo' },
  KOWE: { name: 'Koweït', convertedCode: 'KW', convertedName: 'Koweït' },
  KUWA: { name: 'Koweït', convertedCode: 'KW', convertedName: 'Koweït' },
  KYRG: { name: 'Kirghizistan', convertedCode: 'KG', convertedName: 'Kirghizistan' },
  LAOS: { name: 'Laos', convertedCode: 'LA', convertedName: 'Laos' },
  LATV: { name: 'Lettonie', convertedCode: 'LV', convertedName: 'Lettonie' },
  LEBA: { name: 'Liban', convertedCode: 'LB', convertedName: 'Liban' },
  LESO: { name: 'Lesotho', convertedCode: 'LS', convertedName: 'Lesotho' },
  LETT: { name: 'Lettonie', convertedCode: 'LV', convertedName: 'Lettonie' },
  LIBA: { name: 'Liban', convertedCode: 'LB', convertedName: 'Liban' },
  LIBE: { name: 'Libéria', convertedCode: 'LR', convertedName: 'Libéria' },
  LIBÉ: { name: 'Libéria', convertedCode: 'LR', convertedName: 'Libéria' },
  LIBY: { name: 'Libye', convertedCode: 'LY', convertedName: 'Libye' },
  LIEC: { name: 'Liechtenstein', convertedCode: 'LI', convertedName: 'Liechtenstein' },
  LITH: { name: 'Lituanie', convertedCode: 'LT', convertedName: 'Lituanie' },
  LITU: { name: 'Lituanie', convertedCode: 'LT', convertedName: 'Lituanie' },
  LUXE: { name: 'Luxembourg', convertedCode: 'LU', convertedName: 'Luxembourg' },
  MACE: { name: 'Macédoine', convertedCode: 'MK', convertedName: 'Macédoine' },
  MACÉ: { name: 'Macédoine', convertedCode: 'MK', convertedName: 'Macédoine' },
  MADA: { name: 'Madagascar', convertedCode: 'MG', convertedName: 'Madagascar' },
  MALA: { name: 'Malaisie', convertedCode: 'MY', convertedName: 'Malaisie' },
  MALD: { name: 'Maldives', convertedCode: 'MV', convertedName: 'Maldives' },
  MALI: { name: 'Mali', convertedCode: 'ML', convertedName: 'Mali' },
  MALT: { name: 'Malte', convertedCode: 'MT', convertedName: 'Malte' },
  MARO: { name: 'Maroc', convertedCode: 'MA', convertedName: 'Maroc' },
  MARS: { name: 'Îles Marshall', convertedCode: 'MH', convertedName: 'Îles Marshall' },
  MAUR: { name: 'Maurice', convertedCode: 'MR', convertedName: 'Maurice' },
  MEXI: { name: 'Mexique', convertedCode: 'MX', convertedName: 'Mexique' },
  MICR: { name: 'Micronésie', convertedCode: 'FM', convertedName: 'Micronésie' },
  MOLD: { name: 'Moldavie', convertedCode: 'MD', convertedName: 'Moldavie' },
  MONA: { name: 'Monaco', convertedCode: 'MC', convertedName: 'Monaco' },
  MONG: { name: 'Mongolie', convertedCode: 'MN', convertedName: 'Mongolie' },
  MONT: { name: 'Monténégro', convertedCode: 'ME', convertedName: 'Monténégro' },
  MORO: { name: 'Maroc', convertedCode: 'MA', convertedName: 'Maroc' },
  MOZA: { name: 'Mozambique', convertedCode: 'MZ', convertedName: 'Mozambique' },
  MYAN: { name: 'Birmanie', convertedCode: 'MM', convertedName: 'Birmanie' },
  NAMI: { name: 'Namibie', convertedCode: 'NA', convertedName: 'Namibie' },
  NAUR: { name: 'Nauru', convertedCode: 'NR', convertedName: 'Nauru' },
  NEPA: { name: 'Népal', convertedCode: 'NP', convertedName: 'Népal' },
  NÉPA: { name: 'Népal', convertedCode: 'NP', convertedName: 'Népal' },
  NETH: { name: 'Pays-Bas', convertedCode: 'NL', convertedName: 'Pays-Bas' },
  'NEW ': { name: 'Nouvelle-Zélande', convertedCode: 'NZ', convertedName: 'Nouvelle-Zélande' },
  NICA: { name: 'Nicaragua', convertedCode: 'NI', convertedName: 'Nicaragua' },
  NORT: { name: 'Corée du Nord', convertedCode: 'KP', convertedName: 'Corée du Nord' },
  NORV: { name: 'Norvège', convertedCode: 'NO', convertedName: 'Norvège' },
  NORW: { name: 'Norvège', convertedCode: 'NO', convertedName: 'Norvège' },
  NOUV: { name: 'Nouvelle-Zélande', convertedCode: 'NZ', convertedName: 'Nouvelle-Zélande' },
  OMAN: { name: 'Oman', convertedCode: 'OM', convertedName: 'Oman' },
  OUGA: { name: 'Ouganda', convertedCode: 'UG', convertedName: 'Ouganda' },
  OUZB: { name: 'Ouzbékistan', convertedCode: 'UZ', convertedName: 'Ouzbékistan' },
  PAKI: { name: 'Pakistan', convertedCode: 'PK', convertedName: 'Pakistan' },
  PALA: { name: 'Palaos', convertedCode: 'PW', convertedName: 'Palaos' },
  PALE: { name: 'Palestine', convertedCode: 'PS', convertedName: 'Palestine' },
  PANA: { name: 'Panama', convertedCode: 'PA', convertedName: 'Panama' },
  PAPO: { name: 'Papouasie-Nouvelle-Guinée', convertedCode: 'PG', convertedName: 'Papouasie-Nouvelle-Guinée' },
  PAPU: { name: 'Papouasie-Nouvelle-Guinée', convertedCode: 'PG', convertedName: 'Papouasie-Nouvelle-Guinée' },
  PARA: { name: 'Paraguay', convertedCode: 'PY', convertedName: 'Paraguay' },
  PAYS: { name: 'Pays-Bas', convertedCode: 'NL', convertedName: 'Pays-Bas' },
  PERO: { name: 'Pérou', convertedCode: 'PE', convertedName: 'Pérou' },
  PÉRO: { name: 'Pérou', convertedCode: 'PE', convertedName: 'Pérou' },
  PERU: { name: 'Pérou', convertedCode: 'PE', convertedName: 'Pérou' },
  PHIL: { name: 'Philippines', convertedCode: 'PH', convertedName: 'Philippines' },
  POLA: { name: 'Pologne', convertedCode: 'PL', convertedName: 'Pologne' },
  POLO: { name: 'Pologne', convertedCode: 'PL', convertedName: 'Pologne' },
  PORT: { name: 'Portugal', convertedCode: 'PT', convertedName: 'Portugal' },
  PUER: { name: 'Porto Rico', convertedCode: 'PR', convertedName: 'Porto Rico' },
  QATA: { name: 'Qatar', convertedCode: 'QA', convertedName: 'Qatar' },
  ROMA: { name: 'Roumanie', convertedCode: 'RO', convertedName: 'Roumanie' },
  ROUM: { name: 'Roumanie', convertedCode: 'RO', convertedName: 'Roumanie' },
  ROYA: { name: 'Royaume-Uni', convertedCode: 'GB', convertedName: 'Royaume-Uni' },
  RUSS: { name: 'Russie', convertedCode: 'RU', convertedName: 'Russie' },
  RWAN: { name: 'Rwanda', convertedCode: 'RW', convertedName: 'Rwanda' },
  SALV: { name: 'Salvador', convertedCode: 'SV', convertedName: 'Salvador' },
  SAMO: { name: 'Samoa', convertedCode: 'AS', convertedName: 'Samoa' },
  'SAO ': { name: 'Sao Tomé-et-Principe', convertedCode: 'ST', convertedName: 'Sao Tomé-et-Principe' },
  SAUD: { name: 'Arabie Saoudite', convertedCode: 'SA', convertedName: 'Arabie Saoudite' },
  SENE: { name: 'Sénégal', convertedCode: 'SN', convertedName: 'Sénégal' },
  SÉNÉ: { name: 'Sénégal', convertedCode: 'SN', convertedName: 'Sénégal' },
  SERB: { name: 'Serbie', convertedCode: 'RS', convertedName: 'Serbie' },
  SEYC: { name: 'Seychelles', convertedCode: 'SC', convertedName: 'Seychelles' },
  SIER: { name: 'Sierra Leone', convertedCode: 'SL', convertedName: 'Sierra Leone' },
  SING: { name: 'Singapour', convertedCode: 'SG', convertedName: 'Singapour' },
  SOLO: { name: 'Îles Salomon', convertedCode: 'SB', convertedName: 'Îles Salomon' },
  SOMA: { name: 'Somalie', convertedCode: 'SO', convertedName: 'Somalie' },
  SOUD: { name: 'Soudan', convertedCode: 'SD', convertedName: 'Soudan' },
  SPAI: { name: 'Espagne', convertedCode: 'ES', convertedName: 'Espagne' },
  'SRI ': { name: 'Sri Lanka', convertedCode: 'LK', convertedName: 'Sri Lanka' },
  'SUD ': { name: 'Sud Soudan', convertedCode: 'SS', convertedName: 'Sud Soudan' },
  SUDA: { name: 'Soudan', convertedCode: 'SD', convertedName: 'Soudan' },
  SUÈD: { name: 'Suède', convertedCode: 'SE', convertedName: 'Suède' },
  SUIS: { name: 'Suisse', convertedCode: 'CH', convertedName: 'Suisse' },
  SURI: { name: 'Suriname', convertedCode: 'SR', convertedName: 'Suriname' },
  SWED: { name: 'Suède', convertedCode: 'SE', convertedName: 'Suède' },
  SWIT: { name: 'Suisse', convertedCode: 'CH', convertedName: 'Suisse' },
  SYRI: { name: 'Syrie', convertedCode: 'SY', convertedName: 'Syrie' },
  TADJ: { name: 'Tadjikistan', convertedCode: 'TJ', convertedName: 'Tadjikistan' },
  TAIP: { name: 'Taïwan', convertedCode: 'TW', convertedName: 'Taïwan' },
  TAIW: { name: 'Taïwan', convertedCode: 'TW', convertedName: 'Taïwan' },
  TAÏW: { name: 'Taïwan', convertedCode: 'TW', convertedName: 'Taïwan' },
  TAJI: { name: 'Tadjikistan', convertedCode: 'TJ', convertedName: 'Tadjikistan' },
  TANZ: { name: 'Tanzanie', convertedCode: 'TZ', convertedName: 'Tanzanie' },
  TCHA: { name: 'Tchad', convertedCode: 'TD', convertedName: 'Tchad' },
  TCHE: { name: 'République tchèque', convertedCode: 'CZ', convertedName: 'République tchèque' },
  THAI: { name: 'Thaïlande', convertedCode: 'TH', convertedName: 'Thaïlande' },
  THAÏ: { name: 'Thaïlande', convertedCode: 'TH', convertedName: 'Thaïlande' },
  TIMO: { name: 'Timor oriental', convertedCode: 'TL', convertedName: 'Timor oriental' },
  TOGO: { name: 'Togo', convertedCode: 'TG', convertedName: 'Togo' },
  TONG: { name: 'Tonga', convertedCode: 'TO', convertedName: 'Tonga' },
  TRIN: { name: 'Trinité-et-Tobago', convertedCode: 'TT', convertedName: 'Trinité-et-Tobago' },
  TUNI: { name: 'Tunisie', convertedCode: 'TN', convertedName: 'Tunisie' },
  TURK: { name: 'Turquie', convertedCode: 'TR', convertedName: 'Turquie' },
  TURQ: { name: 'Turquie', convertedCode: 'TR', convertedName: 'Turquie' },
  TUVA: { name: 'Tuvalu', convertedCode: 'TV', convertedName: 'Tuvalu' },
  UGAN: { name: 'Ouganda', convertedCode: 'UG', convertedName: 'Ouganda' },
  UKRA: { name: 'Ukraine', convertedCode: 'UA', convertedName: 'Ukraine' },
  UNIT: { name: 'États-Unis', convertedCode: 'US', convertedName: 'États-Unis' },
  URUG: { name: 'Uruguay', convertedCode: 'UY', convertedName: 'Uruguay' },
  UZBE: { name: 'Ouzbékistan', convertedCode: 'UZ', convertedName: 'Ouzbékistan' },
  VANU: { name: 'Vanuatu', convertedCode: 'VU', convertedName: 'Vanuatu' },
  VATI: { name: 'Vatican', convertedCode: 'VA', convertedName: 'Vatican' },
  VENE: { name: 'Venezuela', convertedCode: 'VE', convertedName: 'Venezuela' },
  VIET: { name: 'Vietnam', convertedCode: 'VN', convertedName: 'Vietnam' },
  VIRG: { name: 'Îles Vierges américaines', convertedCode: 'VI', convertedName: 'Îles Vierges américaines' },
  YEME: { name: 'Yémen', convertedCode: 'YE', convertedName: 'Yémen' },
  YÉME: { name: 'Yémen', convertedCode: 'YE', convertedName: 'Yémen' },
  ZAMB: { name: 'Zambie', convertedCode: 'ZM', convertedName: 'Zambie' },
  ZIMB: { name: 'Zimbabwe', convertedCode: 'ZW', convertedName: 'Zimbabwe' },
};

export const excludedConversionCountry = ['UNIT', 'REPU', 'ILES', 'SAIN'];
