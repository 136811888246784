import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bt-button',
  templateUrl: './bt-button.component.html',
  styleUrls: ['./bt-button.component.scss'],
})
export class BtButtonComponent {
  @Input() type: string = 'button';
  @Input() label: string;
  @Input() style: string = 'plain';
  @Input() color: string = 'orange';
  @Input() size: string = '';
  @Input() href: string = '';
}
