import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStateService } from '@betrail-libs/auth-state';
import { IBasket, IUserState } from '@betrail-libs/shared/interfaces/auth.model';
import { IRunner } from '@betrail-libs/shared/interfaces/runner.model';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MasqueradeService } from '../masquerade/masquerade.service';

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnDestroy, AfterViewChecked {
  auth$ = this.authState.isLogged();
  nbrRegItem$: Observable<number> = this.authState.basket$.pipe(
    map((basket: IBasket) => {
      if (basket) return basket.items.filter(item => item.type !== 'FREE_CHARGE').length;
    }),
  );
  hasCartItem$: Observable<boolean> = this.nbrRegItem$.pipe(map(nbrOfItem => nbrOfItem > 0));

  @Input() user: IUserState;
  @Input() runner: IRunner;
  @Input() isLoading: Observable<boolean>;
  @Output() logout = new EventEmitter();
  @Output() login = new EventEmitter();
  @Output() createAccount = new EventEmitter();
  @Output() toggle = new EventEmitter();

  showTopSearch: boolean = false;
  newNotificationsNumber: number;
  lastOffset: number = 0;
  showBar: boolean = true;
  _user = this.authState.getUser();
  allOrders$ = this.authState.getAllOrders();
  isLoadingUser$ = this.authState.isLoading();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private authState: AuthStateService,
    public translation: TranslocoService,
    public router: Router,
    public masquerade: MasqueradeService,
  ) {}

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  toggleTopSearch() {
    this.showTopSearch = !this.showTopSearch;
  }

  toggleMenu() {
    this.toggle.emit();
  }

  selectLang(language: 'fr' | 'nl' | 'en') {
    this.translation.setActiveLang(language);
  }

  loginAction() {
    this.login.emit();
  }

  setNewNotificationsNumber(number) {
    this.newNotificationsNumber = number;
  }

  ngOnDestroy(): void {}
}
