<div class="runner-teaser mat-elevation-z2" [routerLink]="['/', 'runner', _runner?.alias]">
  <div class="avatar">
    <div class="avatar-content" [style.backgroundImage]="_runner?.photo?.square_image | sanitizeStyleUrl"></div>
  </div>
  <div class="teaser-content">
    <div class="runner-content">
      <h2>{{ _runner?.display_title ? _runner?.display_title : _runner?.title }}</h2>
      <div class="infos_persos">
        <span class="date" *ngIf="_runner?.display_place == true">{{ _runner?.place }}</span>
        <span *ngIf="_runner?.display_age == true && _runner?.display_place == true && _runner?.place && _runner?.age"
          >,
        </span>
        <span class="age" *ngIf="_runner?.display_age === true && _runner?.age">
          {{ _runner?.age }} {{ 'RUNNER_YEARS_OLD' | transloco }}
        </span>
      </div>
      <div class="score">
        <span *ngIf="_runner?.level?.level">Level Betrail : {{ _runner?.level?.level / 100 }}</span><br />
        <span *ngIf="bestTrailCup"
          >Trailcup ({{ bestTrailCup?.country }}) : {{ bestTrailCup?.tc_pts }} pts<br />
          {{ bestTrailCup?.nb_races }} {{ 'RACES_THIS_YEAR' | transloco }}</span
        >
      </div>
    </div>
  </div>
</div>
