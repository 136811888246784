import { ITimer } from '@betrail-libs/shared/interfaces/interfaces';

export class LoadAllTimers {
  static readonly type = '[Timers] Load all timers';
  constructor(public force = false) {}
}

export class LoadTimerByAlias {
  static readonly type = '[Timers] Load timer by alias';
  constructor(public alias: string, public force = false) {}
}

export class LoadTimerById {
  static readonly type = '[Timers] Load timer by id';
  constructor(public id: number, public force = false) {}
}

export class SetDisplayMode {
  static readonly type = '[Timers] Set display type';
  constructor(public mode: 'list' | 'map') {}
}

export class SetSelectedCountries {
  static readonly type = '[Timers] Set selected countries';
  constructor(public countries: string[]) {}
}

export class CreateNewTimer {
  static readonly type = '[Timers] Create new timer';
  constructor(public timer: Omit<ITimer, 'id'>) {}
}

export class UpdateTimer {
  static readonly type = '[Timers] Update timer';
  constructor(public timer: ITimer) {}
}
