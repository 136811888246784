import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToSymbols',
})
export class NumberToSymbolsPipe implements PipeTransform {
  transform(value: number, symbol?: string): string {
    const array = new Array(value);
    return array.fill(symbol || '+').join('');
  }
}
