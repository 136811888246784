import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toAge',
})
export class ToAgePipe implements PipeTransform {
  transform(value: number): number {
    let b = new Date(value * 1000);
    let n = new Date();
    let bY = b.getFullYear();
    let nY = n.getFullYear();
    let bM = b.getMonth();
    let nM = n.getMonth();
    let bD = b.getDate();
    let nD = n.getDate();

    let age = nY - bY;

    if (bM > nM) {
      age--;
    } else if (bM == nM && bD > nD) {
      age--;
    }
    return age;
  }
}
