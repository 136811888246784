<div class="message">
  <h2>Bienvenue !</h2>
  <p>
    Vos inscriptions en ligne directement sur Betrail. C'est gratuit pour vous. Betrail propose une participation libre
    aux coureurs au moment de leur inscription.
  </p>
  <p><strong>Comment ça marche ?</strong></p>
  <ol>
    <li>
      <h3>Préparation du formulaire</h3>
      Vous paramétrez facilement votre formulaire d'inscription : champs du formulaire, tarifs, date d'ouverture et de
      clôture des inscriptions, options de paiement.
    </li>

    <li>
      <h3>Publication en ligne</h3>
      Vous pouvez publier en ligne votre formulaire d'inscription dès que nous avons validé votre compte Organisateur.
      <br />
      LES INSCRIPTIONS SONT OUVERTES ! Les coureurs peuvent s'inscrire à votre course.
    </li>
    <li>
      <h3>Gestion des inscriptions</h3>
      Vous pouvez voir en temps réel la liste des inscrits et modifier les données si nécessaire. Ajoutez facilement une
      inscription ou générez un code de gratuité en quelques secondes.
    </li>
    <li>
      <h3>Exports et versements</h3>
      Vous pouvez à tout moment exporter la liste des inscrits au format de votre chronométreur. Complétez vos
      informations bancaires pour pouvoir demander le versement du montant des inscriptions sur votre compte.
    </li>
  </ol>
</div>
