import { Pipe } from '@angular/core';
import { IOtherResult, IResult } from '@betrail-libs/shared/interfaces/result.model';

@Pipe({
  name: 'rankingPercentage',
})
export class RankingPercentagePipe {
  transform(result: IResult & IOtherResult): string {
    const position = result.femalePosition ? result.femalePosition : result.position;
    const finishers = result.femaleFinishers
      ? result.femaleFinishers
      : result.total_finishers
      ? result.total_finishers
      : result.finishers;
    const ranking = 100 / (finishers / position);

    let rankingStr = '' + ranking;
    if (rankingStr.split('').length > 4 && ranking < 1) {
      rankingStr = rankingStr.split('').splice(0, 4).join('') + '%';
    } else {
      rankingStr = Math.round(ranking) + '%';
    }

    return rankingStr;
  }
}
