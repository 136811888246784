import { Injectable } from '@angular/core';
import {
  ETrackingStatus,
  IEliteLevel,
  INewsFeedGroup,
  INewsFeedParams,
  ITrackedRunner,
  ITrackingGroup,
  ITrackingUser,
} from '@betrail-libs/shared/interfaces/interfaces';
import { IResult } from '@betrail-libs/shared/interfaces/result.model';
import { TRACK_LIMIT_NOT_PREMIUM } from '@betrail-libs/shared/utils';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest, map } from 'rxjs';
import {
  ChangeTrackingOverride,
  HandleGroupRunnerLink,
  HandleTrackingGroupSettings,
  LoadEliteLevels,
  LoadTrackedRunnerResults,
  SetNewsFeedFilter,
  SetUserData,
  SetUserFollowedElites,
  TrackRunner,
  UntrackRunner,
} from './tracking.action';
import { TrackingState } from './tracking.state';

@Injectable({
  providedIn: 'root',
})
export class TrackingStateService {
  @Select(TrackingState.userData) userData$!: Observable<{ uid?: number; ruid?: number; isPremium?: boolean }>;
  @Select(TrackingState.loadingResults) loadingResults$!: Observable<boolean>;
  @Select(TrackingState.trackedResults) trackedResults$!: Observable<IResult[]>;
  @Select(TrackingState.runnerTrackings) runnerTrackings$!: Observable<ITrackedRunner[]>;
  @Select(TrackingState.eliteLevels) eliteLevels$!: Observable<IEliteLevel[]>;
  @Select(TrackingState.elitesTrackings) eliteTrackings$!: Observable<{ nationality: string; elid: number }[]>;
  @Select(TrackingState.trackingGroups) trackingGroups$!: Observable<ITrackingGroup[]>;
  @Select(TrackingState.userTrackings) userTrackings$!: Observable<ITrackingUser[]>;
  @Select(TrackingState.selectedFilter) selectedFilter$!: Observable<INewsFeedParams | undefined>;

  constructor(private store: Store) {}

  setUserData = (
    uid: number,
    ruid: number,
    isPremium: boolean,
    trackedRunners: ITrackedRunner[],
    trackedElites: { elid: number; nationality: string }[],
    trackingGroups: { id: number; name: string; priority: number; runners?: { ruid: number }[] }[],
    userTrackings: ITrackingUser[],
  ) =>
    this.store.dispatch(
      new SetUserData(uid, ruid, isPremium, trackedRunners, trackedElites, trackingGroups, userTrackings),
    );

  hasActiveTracking = () => this.runnerTrackings$.pipe(map(trackings => trackings.length > 0));

  getTrackingOverview = () => this.trackedResults$.pipe(map(results => results.slice(0, 5)));

  trackNewRunner = (uid: number, ruid: number, created_at: Date, isAdmin = false) =>
    this.store.dispatch(new TrackRunner({ uid, ruid, created_at }, isAdmin));

  untrackRunner = (uid: number, ruid: number) => this.store.dispatch(new UntrackRunner({ uid, ruid }));

  loadTrackedRunnerResults = (nb?: number) => this.store.dispatch(new LoadTrackedRunnerResults(nb));

  getTrackingByRuid = (ruid: number) =>
    this.runnerTrackings$.pipe(map(trackings => trackings.find(t => t.ruid === ruid)));

  getIsTrackedSnapshot = (ruid: number) => {
    const trackings = this.store.selectSnapshot(TrackingState.runnerTrackings);
    return trackings.some(t => t.ruid === ruid);
  };

  loadEliteLevels = (forceReload = false) => this.store.dispatch(new LoadEliteLevels(forceReload));

  getElitesData = () =>
    combineLatest([this.eliteLevels$, this.eliteTrackings$]).pipe(
      map(([elites, trackings]) => {
        const elitesByNationality: { [nationality: string]: IEliteLevel[] } = {};
        elites.forEach(e => {
          if (!elitesByNationality[e.nationality]) {
            elitesByNationality[e.nationality] = [];
          }
          elitesByNationality[e.nationality].push(e);
        });
        return { elitesByNationality: Object.values(elitesByNationality), userElites: trackings };
      }),
    );

  getElitesDataSnapshot = () => {
    const elites = this.store.selectSnapshot(TrackingState.eliteLevels);
    const elitesByNationality: { [nationality: string]: IEliteLevel[] } = {};
    elites.forEach(e => {
      if (!elitesByNationality[e.nationality]) {
        elitesByNationality[e.nationality] = [];
      }
      elitesByNationality[e.nationality].push(e);
    });
    const userElites = this.store.selectSnapshot(TrackingState.elitesTrackings);
    return { elitesByNationality: Object.values(elitesByNationality), userElites };
  };

  handleElitesTracking = (levels: { [nationality: string]: number }) =>
    this.store.dispatch(new SetUserFollowedElites(levels));

  handleTrackingGroupSettings = (uid: number, data: INewsFeedGroup[]) =>
    this.store.dispatch(new HandleTrackingGroupSettings(uid, data));

  handleRunnerGroupLink = (uid: number, data: { activate: boolean; ruid: number; tgid: number }) =>
    this.store.dispatch(new HandleGroupRunnerLink(uid, data));

  getNewsFeedFilters = () =>
    combineLatest([this.trackingGroups$, this.eliteTrackings$, this.eliteLevels$, this.userData$]).pipe(
      map(([groups, elites, levels, userData]) => {
        if ((groups.length === 0 && elites.length === 0) || !userData.isPremium) {
          return null;
        } else {
          return {
            groups: [...groups].sort((a, b) => a.priority - b.priority),
            elites: [...elites.map(e => levels.find(l => l.id === e.elid))].sort((a, b) =>
              a.nationality.localeCompare(b.nationality),
            ),
          };
        }
      }),
    );

  setNewsFeedFilter = (filter?: INewsFeedParams) => this.store.dispatch(new SetNewsFeedFilter(filter));

  getIfUserSelfFollow = (runnerId: number) =>
    this.runnerTrackings$.pipe(
      map(trackings => {
        for (const t of trackings) {
          if (t.ruid === runnerId) {
            return true;
          }
        }
        return false;
      }),
    );

  changeTrackingOverrides = (data: { rtid: number; override: boolean }[]) =>
    this.store.dispatch(new ChangeTrackingOverride(data));

  getRunnerIdsFromGroupId = (groupId: number) => {
    if (groupId === 0) {
      const trackings = this.store.selectSnapshot(TrackingState.runnerTrackings);
      return trackings.map(t => t.ruid);
    } else {
      const group = this.store.selectSnapshot(TrackingState.trackingGroups).find(g => g.id === groupId);
      return group?.runners?.map(r => r.ruid) || [];
    }
  };

  getIfUserCanFollowANewRunner = () =>
    combineLatest([this.userData$, this.runnerTrackings$]).pipe(
      map(([userData, trackings]) => {
        const runnerTracked = trackings.filter(t => t.ruid !== userData.ruid).length;
        return userData.isPremium || runnerTracked < TRACK_LIMIT_NOT_PREMIUM;
      }),
    );

  getIfUserCanFollowANewRunnerSnapshot = () => {
    const userData = this.store.selectSnapshot(TrackingState.userData);
    const trackings = this.store.selectSnapshot(TrackingState.runnerTrackings);
    const runnerTracked = trackings.filter(t => t.ruid !== userData.ruid).length;
    return userData.isPremium || runnerTracked < TRACK_LIMIT_NOT_PREMIUM;
  };

  getIfUserHasPausedSubscriptions = () =>
    this.runnerTrackings$.pipe(map(trackings => trackings.some(t => t.status === ETrackingStatus.PAUSED)));
}
