import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bt-simple-card',
  templateUrl: './bt-simple-card.component.html',
  styleUrls: ['./bt-simple-card.component.scss'],
})
export class BtSimpleCardComponent {
  classes: string;
  @Input() header: boolean = true;
  @Input() height: any;
}
