<div *ngIf="formGroup" class="flex flex-col p-[1em]" [formGroup]="formGroup">
  <div class="flex flex-wrap justify-between">
    <input style="display: none" type="text" name="fakeusernamerememberedra" />
    <input style="display: none" type="text" name="fakeemailrememberedra" />
    <input style="display: none" type="password" name="fakepasswordrememberedra" />

    <mat-form-field appearance="fill" class="w-full">
      <mat-label>{{ 'RACE_NAME' | transloco }} ({{ 'OPTIONAL' | transloco }})</mat-label>
      <input
        matInput
        #titleField
        formControlName="race_name"
        autocomplete="new-password"
        [placeholder]="'RACE_TITLE_PLACEHOLDER' | transloco"
      />
      <mat-icon matSuffix>format_quote</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-full">
      <mat-label>{{ 'CHOOSE_A_DAY' | transloco }}</mat-label>
      <mat-select formControlName="date" [disabled]="availableDates.length < 2">
        <mat-option *ngFor="let date of availableDates" [value]="date.value">{{ date.displayValue }}</mat-option>
      </mat-select>
      <mat-icon matSuffix>today</mat-icon>
    </mat-form-field>

    <div *ngIf="verified" class="w-full" [innerHTML]="'RACE_VERIFIED_WARNING' | transloco"></div>

    <mat-slide-toggle
      *ngIf="(isAdmin$ | async) || (isEncoder$ | async) || (showOrgaOptions$ | async)"
      color="primary"
      class="w-full !mb-3"
      >{{ 'RACE_CANCELED' | transloco }}</mat-slide-toggle
    >

    <ng-container *ngIf="!multiple; else oneLoopDistance">
      <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
        <mat-label>{{ 'DISTANCE' | transloco }} ({{ 'RACE_DISTANCE_UNIT' | transloco }})</mat-label>
        <input
          [disabled]="verified"
          #distanceField
          matInput
          type="number"
          step="1"
          placeholder="Ex.: 21"
          formControlName="distance"
          inputmode="numeric"
          pattern="[0-9]*"
          [max]="9999"
          [min]="1"
          digitOnly
        />
        <mat-icon matSuffix>keyboard_tab</mat-icon>
      </mat-form-field>
    </ng-container>
    <ng-template #oneLoopDistance>
      <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
        <mat-label>{{ 'ONE_LOOP_DISTANCE_DECIMAL' | transloco }} ({{ 'RACE_DISTANCE_UNIT' | transloco }})</mat-label>
        <input
          [disabled]="verified"
          #distanceField
          matInput
          type="number"
          step="0.01"
          placeholder="Ex.: 21.52"
          formControlName="distance"
          pattern="[0-9]*(\.[0-9]{0,2})?"
          [max]="999"
          [min]="1"
        />
        <mat-icon matSuffix>keyboard_tab</mat-icon>
      </mat-form-field>
    </ng-template>

    <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
      <mat-label
        >{{ (multiple ? 'ONE_LOOP_ELEVATION' : 'RACE_ELEVATION') | transloco }} ({{ 'RACE_ELEVATION_UNIT' | transloco
        }})</mat-label
      >
      <input
        matInput
        type="number"
        placeholder="Ex.: 510"
        formControlName="elevation"
        inputmode="numeric"
        step="10"
        pattern="[0-9]*"
        [min]="0"
        digitOnly
      />
      <mat-icon matSuffix>show_chart</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
      <mat-label>{{ 'RACE_RACE_TYPE' | transloco }}</mat-label>
      <mat-select formControlName="race_type" [disabled]="multiple">
        <mat-option *ngFor="let type of raceTypes" [value]="type">{{ 'RACE_TYPE_' + type | transloco }}</mat-option>
      </mat-select>
      <mat-icon matSuffix>360</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
      <mat-label>{{ (multiple ? 'ONE_LOOP_REFRESHMENTS' : 'RACE_REFRESHMENTS_EXCEPT_ARRIVAL') | transloco }}</mat-label>
      <input
        matInput
        type="number"
        placeholder="Ex. : 3"
        formControlName="refreshments"
        inputmode="numeric"
        pattern="[0-9]*"
        [max]="99"
        [min]="0"
        digitOnly
      />
      <mat-icon matSuffix>fastfood</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
      <mat-label>{{ 'RACE_DEPARTURE_TIME' | transloco }} ({{ 'RACE_DEPARTURE_TIME_UNIT' | transloco }})</mat-label>
      <input
        matInput
        [ngxTimepicker]="picker"
        placeholder="Ex.: 8:30"
        formControlName="departure_time"
        [format]="24"
        enableKeyboardInput="true"
        minutesGap="5"
        readonly
      />
      <ngx-material-timepicker #picker [theme]="darkTheme"></ngx-material-timepicker>
      <mat-icon matSuffix>access_time</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
      <mat-label>{{ 'RACE_PRICE_MIN' | transloco }}</mat-label>
      <input matInput type="number" step="1.00" placeholder="Ex.: 7.60" formControlName="price" />
      <mat-icon matSuffix>euro_symbol</mat-icon>
    </mat-form-field>

    <mat-slide-toggle color="primary" class="w-full !mb-0" formControlName="night">
      {{ 'RACE_NIGHT' | transloco }}
    </mat-slide-toggle>

    <mat-form-field *ngIf="multiple" appearance="fill" class="w-full my-2">
      <mat-label>{{ 'LAPS_TO_GENERATE' | transloco }}</mat-label>
      <input
        matInput
        placeholder="ex: 1,2,3,4,6,7,8,12,16,17,18,21,24,25,36"
        formControlName="laps"
        pattern="[0-9]+(,[0-9]+)+"
      />
      <mat-icon matSuffix>numbers</mat-icon>
      <mat-hint>( = {{ 'CREATE_MULTIPLE_LOOPS_HINT' | transloco }})</mat-hint>
    </mat-form-field>
  </div>

  <mat-divider class="w-full !my-3"></mat-divider>

  <div class="flex flex-wrap justify-between">
    <div class="flex justify-between w-full" (click)="toggleMore = !toggleMore">
      <strong class="text-sm">{{ 'MORE_DETAILS' | transloco }}</strong>
      <button mat-mini-fab color="primary">
        <mat-icon *ngIf="!toggleMore">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="toggleMore">keyboard_arrow_up</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="toggleMore">
      <mat-slide-toggle *isEncoder color="primary" class="w-full !mb-3" formControlName="trailcup_only">
        {{ 'RACE_TRAILCUP_ONLY' | transloco }}
      </mat-slide-toggle>

      <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
        <mat-label>{{ 'RACE_OFFROAD_ESTIMATED' | transloco }}</mat-label>
        <input
          matInput
          type="number"
          placeholder="Ex.: 76"
          formControlName="offroad"
          inputmode="numeric"
          pattern="[0-9]*"
          max="100"
          min="0"
        />
        <mat-icon matSuffix>nature_people</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
        <mat-label>{{ 'RACE_TRAIL_ESTIMATED' | transloco }}</mat-label>
        <input
          matInput
          type="number"
          placeholder="Ex.: 21"
          formControlName="trail"
          inputmode="numeric"
          pattern="[0-9]*"
          max="100"
          min="0"
        />
        <mat-icon matSuffix>nature_people</mat-icon>
      </mat-form-field>

      <mat-slide-toggle *ngIf="(isAdmin$ | async) || (isEncoder$ | async)" color="primary" class="w-full !mb-3">
        {{ 'RACE_OFFROAD_CERTIFIED' | transloco }}
      </mat-slide-toggle>

      <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
        <mat-label>{{ 'RACE_MIN_ALT' | transloco }} ({{ 'RACE_ALT_UNIT' | transloco }})</mat-label>
        <input
          matInput
          type="number"
          placeholder="Ex. : 800"
          formControlName="altitude_min"
          inputmode="numeric"
          pattern="[0-9]*"
          [max]="99999"
          [min]="0"
          digitOnly
        />
        <mat-icon matSuffix>vertical_align_bottom</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
        <mat-label>{{ 'RACE_MAX_ALT' | transloco }} ({{ 'RACE_ALT_UNIT' | transloco }})</mat-label>
        <input
          matInput
          type="number"
          placeholder="Ex. : 1650"
          formControlName="altitude_max"
          inputmode="numeric"
          pattern="[0-9]*"
          [max]="99999"
          [min]="0"
          digitOnly
        />
        <mat-icon matSuffix>vertical_align_top</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
        <mat-label>{{ 'TIMER_BARRIER' | transloco }}</mat-label>
        <bux-time-barrier-input formControlName="time_barrier" placeholder="Ex. : 8:00"></bux-time-barrier-input>
        <mat-icon matSuffix (click)="test()">info</mat-icon>
        <mat-error *ngIf="formGroup?.get('time_barrier')?.errors?.['pattern']" class="mt-[-2px]">
          Le format n'est pas valide, veuillez respecter le format HH:MM
        </mat-error>
        <mat-error *ngIf="formGroup?.get('time_barrier')?.errors?.['invalid']" class="mt-[-2px]">
          Maximum 999 heures et 59 minutes
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
        <mat-label>{{ 'RACE_PRICE_MAX' | transloco }}</mat-label>
        <input matInput type="number" step="1.00" placeholder="Ex.: 9.00" formControlName="onsite_price" />
        <mat-icon matSuffix>euro_symbol</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
        <mat-label>{{ 'RACE_AVAILABLE_BIBS' | transloco }}</mat-label>
        <input
          matInput
          type="number"
          placeholder="Ex. : 250"
          formControlName="max_runners"
          inputmode="numeric"
          pattern="[0-9]*"
          [max]="9999"
          [min]="1"
          digitOnly
        />
        <mat-icon matSuffix>filter_1</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
        <mat-label>{{ 'RACE_ROUTE_TYPE' | transloco }}</mat-label>
        <mat-select [(value)]="selectedLoopType" [disabled]="multiple">
          <mat-option [value]="0" (click)="handleNbOfLoops(0)">{{ 'RACE_ROUTE_TYPE_0' | transloco }}</mat-option>
          <mat-option [value]="1" (click)="handleNbOfLoops(1)">{{ 'RACE_ROUTE_TYPE_1' | transloco }}</mat-option>
          <mat-option [value]="2" (click)="handleNbOfLoops(2)">{{ 'RACE_ROUTE_TYPE_LASTMAN' | transloco }}</mat-option>
        </mat-select>
        <mat-icon matSuffix>360</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full ng-sm:w-[47.5%]">
        <mat-label>{{ 'RACE_TIMING_TYPE' | transloco }}</mat-label>
        <mat-select formControlName="timing_type">
          <mat-option value="non">{{ 'RACE_TIMING_NONE' | transloco }}</mat-option>
          <mat-option value="puces">{{ 'RACE_TIMING_CHIPS' | transloco }}</mat-option>
          <mat-option value="manuel">{{ 'RACE_TIMING_MANUAL' | transloco }}</mat-option>
          <mat-option value="suivi">{{ 'RACE_TIMING_TRACKING' | transloco }}</mat-option>
          <mat-option value="suivi_gps">{{ 'RACE_TIMING_TRACKING_GPS' | transloco }}</mat-option>
          <mat-option value="no_result">{{ 'RACE_TIMING_NO_RESULT' | transloco }}</mat-option>
        </mat-select>
        <mat-icon matSuffix>timer</mat-icon>
      </mat-form-field>

      <mat-form-field *ngIf="showNumberOfLoop" appearance="fill" class="w-full ng-sm:w-[47.5%]">
        <mat-label>{{ 'NUMBER_OF_LOOP' | transloco }}</mat-label>
        <input matInput type="number" step="1" [min]="0" formControlName="route_type" />
        <mat-icon matSuffix>loop</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{ 'RACE_OFFICIAL_RESULTS_LINK' | transloco }}</mat-label>
        <input matInput placeholder="https://..." formControlName="official_ranking_url" autocomplete="new-password" />
        <mat-icon matSuffix svgIcon="results"></mat-icon>
        <mat-error *ngIf="formGroup?.get('official_ranking_url')?.errors?.['pattern']" class="mt-[-2px]">
          {{ 'ERROR_LINK_NOT_VALID' | transloco }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{ 'COMMENT' | transloco }}</mat-label>
        <textarea
          matInput
          #comment
          placeholder="..."
          formControlName="comment"
          [maxlength]="commentMaxLength"
        ></textarea>
        <mat-hint align="start">{{ 'N_CHARACTER_MAX' | transloco : { n: commentMaxLength } }}</mat-hint>
        <mat-hint align="end">{{ comment.value.length }} / {{ commentMaxLength }}</mat-hint>
      </mat-form-field>
    </ng-container>
  </div>
</div>
