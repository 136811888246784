import { IUserJwt, IUserState } from '@betrail-libs/shared/interfaces/auth.model';
import { IEvent } from '@betrail-libs/shared/interfaces/event.model';
import { EUserRole } from '@betrail-libs/shared/interfaces/interfaces';
import { IOtherResult, IResult } from '@betrail-libs/shared/interfaces/result.model';
import { IRunner } from '@betrail-libs/shared/interfaces/runner.model';
import * as moment from 'moment';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MONTH_STRINGS, MONTHS_FR, VALUES_FEMALE, VALUES_MALE } from './constants';
import {
  ALL_COUNTRIES_ISO2_TO_ISO3,
  BELGIUM_PROVINCES,
  BELGIUM_REGIONS,
  FRANCE_DEPARTMENTS,
  FRANCE_REGIONS,
} from './countries';
import {
  REGEX_AGE,
  REGEX_DATE_YMD,
  REGEX_GENERAL_CATEGORY_GENDER_POSITION,
  REGEX_LASTNAME_FIRSTNAME,
  REGEX_YEAR,
} from './regex';

export function hello() {
  return 'hello world!';
}

export function removeFbTrackerFromLink(link: string) {
  return link.replace(/[?&]fbclid=[^&]+(?=&|$)/g, '');
}

export function formatRunnerDisplayOptions(runner: IRunner, isAdminOrRunnerHimself = false) {
  let r = { ...runner };
  if (r.display_options) {
    r.display_options.split(',').map(option => {
      r['display_' + option] = true;
    });
  }
  delete r.display_options;
  if (r?.display_age && r?.birthdate && typeof +r.birthdate === 'number') {
    r.age = getAge(r.birthdate * 1000);
  }
  !isAdminOrRunnerHimself && delete r.birthdate;
  if (!isAdminOrRunnerHimself && !r?.display_place) {
    delete r.place;
    delete r.postal_code;
  }
  return r;
}

export function getDiscoverPremiumTrail() {
  const now = new Date();
  const thisMonth = now.getMonth();
  const thisDay = now.getDate();
  if (thisMonth > 5 && (thisMonth !== 11 || (thisMonth === 11 && thisDay < 16))) return 'saintelyon';
  else return 'marathon.du.mont-blanc';
}

export function getNowPlusNYears(n) {
  const now = new Date();
  return new Date(now.getFullYear() + n, now.getMonth(), now.getDate(), 0, 0, 1);
}

export function getNbOfTimeSinceStampInSec(stamp: number) {
  const now = Date.now() / 1000;
  const value = now - stamp;
  const year = Math.floor(value / 31536000);
  const month = Math.floor((value % 31536000) / 2628000);
  const day = Math.floor(((value % 31536000) % 2628000) / 86400);
  return { year, month, day };
}

export function getDDMMYYYYStringFromDateObject(date: Date) {
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  return `${day}/${month}/${date.getFullYear()}`;
}

export function toDateStringFromNumber(dateAsNumber) {
  const date = new Date(dateAsNumber);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function geomAverage(numbers: number[]) {
  const filteredNumbers = numbers.filter(nbr => nbr > 0);
  let sum = 0;
  for (const number of filteredNumbers) {
    sum += Math.log(number);
  }

  sum = sum / numbers.length;
  const moyenne = Math.pow(Math.exp(1), sum);

  return moyenne;
}

export function weightedGeomAverage(numbers: { value: number; weight: number }[]) {
  let sumOfNumbers = 0;
  let sumOfWeights = 0;
  for (const info of numbers) {
    if (info.value != 0) {
      sumOfNumbers += Math.log(info.value) * info.weight;
      sumOfWeights += info.weight;
    }
  }

  let sum = sumOfNumbers / sumOfWeights;
  const moyenne = Math.pow(Math.exp(1), sum);

  return moyenne;
}

export function median(values: number[]) {
  if (values.length === 0) return 0;

  values.sort(function (a, b) {
    return a - b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

export function averageScore(results, race) {
  results = results
    .filter(res => !isNaN(res.performance) && !isNaN(compute_race_weight(res, race)))
    .map(res => {
      return { value: res.performance / 100, weight: compute_race_weight(res, race) };
      // POUR LA COURSE DE CHAQUE RESULTAT indice Betrail : res.race.betrail_index, distance course: res.race.distance, dénivelé: res.race.elevation
      // POUR LA COURSE DONT ON CALCULE L'INDICE : race.betrail_index, distance course:race.distance, dénivelé: race.elevation
    });
  const averageScore = weightedGeomAverage(results);

  const sum = results
    .map(res => res.weight)
    .reduce(function (acc, val) {
      return acc + val;
    });
  const cred = Math.pow(sum, 1 / 2);

  return { averageScore: averageScore, runnerCredibility: cred };
}

export function compute_race_weight(res, race) {
  let weight_age;
  let weight_Dplus;
  let weight_index;
  let weight_course;
  if (res.race && race) {
    if (res.race.date && race.betrail_index && res.race.date) {
      weight_age = Math.pow(2, -Math.abs(res.race.date / 31536000 - race.date / 31536000)); // chaque année d'écart fait diviser le poids par 2 --> suppose que la date est en années --> à mettre à l'échelle pour qu'une différence d'une année donne 1
    } else {
      weight_age = 0.5;
    }
    if (res.race.betrail_index && race.betrail_index && res.race.betrail_index !== 0 && race.betrail_index !== 0) {
      weight_index = Math.min(res.race.betrail_index / race.betrail_index, race.betrail_index / res.race.betrail_index); // Si la course est 3x plus longue/plus courte que la course à évaluer, le poids est divisé par 3
    } else {
      weight_index = 1;
    }

    if (res.race.elevation && race.elevation && res.race.elevation !== 0 && race.elevation !== 0) {
      weight_Dplus = Math.max(1 / 8, Math.pow(2, -Math.abs(res.race.elevation - race.elevation) / 2500)); // Le poids est divisé par 2 pour chaque 2500m d'écart entre les deux courses, avec un min de 1/8
    } else {
      weight_Dplus = 0.125;
    }
    weight_course = weight_age * weight_index * weight_Dplus;
  } else {
    weight_course = 0.125;
  }

  return weight_course;
}

export function WithValueOf<T>(observable: Observable<T>, fun): Subscription {
  return observable.pipe(take(1)).subscribe(fun);
}

export function ValueOf<T>(observable: Observable<T>): Promise<T> {
  return firstValueFrom(observable);
}

export function getResultInSec(result: number | string) {
  if (typeof result === 'number' || Number(result)) {
    if (+result < 5) {
      return Number(result) * 86400;
    }
    return Number(result);
  }
  if (result != null && result != undefined) {
    const hms =
      /^(?:(?:([01]?\d|[0-9][0-9])[:h])?([0-5]?\d)(?:[:'m]|min))?([0-5]?\d)?(?:'{2}|s|sec|\")?(?:[.,]([0-9]*))?$/.exec(
        result.toString(),
      );
    return hms ? (+hms[1] || 0) * 60 * 60 + +hms[2] * 60 + +hms[3] : undefined;
  } else {
    return undefined;
  }
}

export function getSexe(sexe) {
  if (!sexe || sexe.length < 0) {
    return 0;
  }

  // tslint:disable-next-line:max-line-length
  const sexes = RegExp(REGEX_GENERAL_CATEGORY_GENDER_POSITION, 'gi').exec(sexe);

  const homme = VALUES_MALE;
  const femme = VALUES_FEMALE;

  if (sexes && homme.has(sexes[1])) {
    return 0;
  } else if (sexes && femme.has(sexes[1])) {
    return 1;
  } else {
    return -1;
  }
}

export function getMonthPeriodString(date: Date, nbMoreMonth = 0, getSortString = false) {
  const m = date.getMonth();
  const n = m + nbMoreMonth;
  if (n < 12) {
    if (getSortString) {
      return `${date.getFullYear()}/${n + 1 < 10 ? '0' + (n + 1) : n + 1}`;
    } else {
      return `${MONTHS_FR[n]} ${date.getFullYear()}`;
    }
  } else {
    if (getSortString) {
      return `${date.getFullYear() + 1}/${n - 11 < 10 ? '0' + (n - 11) : n - 11}`;
    } else {
      return `${MONTHS_FR[n - 12]} ${date.getFullYear() + 1}`;
    }
  }
}

export function formatDate(date) {
  let month: any = date.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  let year = date.getFullYear();
  let day: any = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  return year + '-' + month + '-' + day;
}

export function formatBirthdate(date: Date) {
  return '' + moment().diff(date, 'years') + ' (' + moment(date).format('DD/MM/YYYY') + ')';
}

export function convertBirthdate(date: string, raceDate?: Date) {
  let d = RegExp(REGEX_YEAR).exec(date);
  if (d) {
    return moment(d[0], 'YYYY').toDate();
  }
  d = RegExp(REGEX_AGE).exec(date);
  if (d) {
    return moment(d[0], 'DD/MM/YYYY').toDate();
  }
  d = RegExp(REGEX_DATE_YMD).exec(date);
  if (d) {
    return moment(d[0], 'YYYY/MM/DD').toDate();
  }
  return;
}

export function getSeparatedLastnameFirstname(value: string) {
  if (typeof value !== 'string') {
    return value;
  }
  let lastname, firstname, title;
  value = value + '';
  const splitted = value.split(',');
  if (splitted.length > 1) {
    lastname = splitted[0];
    firstname = splitted[1];
    title = `${lastname} ${firstname}`;
  } else {
    const regex = REGEX_LASTNAME_FIRSTNAME;
    // tslint:disable-next-line:quotemark
    // tslint:disable-next-line:max-line-length

    let names;
    try {
      names = RegExp(regex, 'gm').exec(value);
      if (!names) {
        names = RegExp(regex, 'gmi').exec(value);
      }
    } catch (error) {
      const oldRegex =
        // tslint:disable-next-line:quotemark
        // tslint:disable-next-line:max-line-length
        "((?:v.? ?d.?|van|von|de|du|d|vande|vanden|vander|y|der|del|den|des|le|la|l|ait|saint|olden|op|ter|ten|t|o|mc|mac|m)? ?(?:[ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBNáàâäåãéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn_' -]*)) ((?:[Mm]arie|[Cc]laire|[Aa]nne|[lL]aure|[Jj]ean|[cC]harles|[Pp]ierre|[Ll]ouis|[Yy]ves|[pP]aul|[Jj]acques|[Pp]eter|[Aa]ron|[A-Z]*?[áàâäåãéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn-]*)? ?-?(?!van[W]|von[W]|de[W]|du[W]|d[W]|vande[W]|vanden[W]|vander[W]|y[W]|der[W]|del[W]|den[W]|des[W]|le[W]|la[W]|l[W]|ait[W]|saint[W][W]|olden[W]|op[W]|ter[W]|ten[W]|t[W]|o[W]|mc[W]|mac[W]|m[W])(?:[ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]{0,2}?[áàâäåãéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn_-]+))?$";
      names = RegExp(oldRegex, 'gm').exec(value);
      if (!names) {
        names = RegExp(oldRegex, 'gmi').exec(value);
      }
    }
    if (names && names[1] && names[2]) {
      title = names[1] + ' ' + names[2];
      firstname = names[2];
      lastname = names[1];
    }
  }
  return {
    lastname: lastname?.trim(),
    firstname: firstname?.trim(),
    title: title ? title.trim() : value?.trim(),
  };
}

export function getSeparatedFirstnameLastname(value: string) {
  if (typeof value !== 'string') {
    return value;
  }
  let lastname, firstname, title;
  const splitted = value.split(',');
  if (splitted.length > 1) {
    lastname = splitted[1].trim();
    firstname = splitted[0].trim();
    title = `${lastname} ${firstname}`;
  } else {
    const regex =
      // tslint:disable-next-line:quotemark
      // tslint:disable-next-line:max-line-length
      "^((?:[Mm]arie|[Cc]laire|[Aa]nne|[lL]aure|[Jj]ean|[cC]harles|[Pp]ierre|[Ll]ouis|[Yy]ves|[pP]aul|[Jj]acques|[Pp]eter|[Aa]ron|[A-Z]*?[áàâäåãéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn-]*)? ?-?(?!van[W]|von[W]|de[W]|du[W]|d[W]|vande[W]|vanden[W]|vander[W]|y[W]|der[W]|del[W]|den[W]|des[W]|le[W]|la[W]|l[W]|ait[W]|saint[W][W]|olden[W]|op[W]|ter[W]|ten[W]|t[W]|o[W]|mc[W]|mac[W]|m[W])(?:[ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBN]{0,2}?[áàâäåãéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn_-]+))? ((?:v.? ?d.?|van|von|de|du|d|vande|vanden|vander|y|der|del|den|des|le|la|l|ait|saint|olden|op|ter|ten|t|o|mc|mac|m)? ?(?:[ÁÀÂÄÅÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝÇÑŽÆŒAZERTYUIOPQSDFGHJKLMWXCVBNáàâäåãéèêëíìîïóòôöõúùûüýçñžæœazertyuiopqsdfghjklmwxcvbn_' -]*))$";

    let names = RegExp(regex, 'gm').exec(value);
    if (!names) {
      names = RegExp(regex, 'gmi').exec(value);
    }

    if (names && names[1] && names[2]) {
      title = names[2] + ' ' + names[1];
      firstname = names[1];
      lastname = names[2];
    }
  }

  return {
    lastname: lastname?.trim(),
    firstname: firstname?.trim(),
    title: title ? title.trim() : value?.trim(),
  };
}

export function toTitleCase(str) {
  str = str.replace(/(\w\S*)/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
  if (str.split('-').length > 1) {
    str = str
      .split('-')
      .map(txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
      .join('-');
  }
  return str;
}

export function toHMS(value: number, format: 'hm' | 'ms' | 'hms') {
  value = Math.round(value);
  let s = (value % 3600) % 60;
  let m = ((value - s) % 3600) / 60;
  let h = (value - m * 60 - s) / 3600;

  if (format && format === 'hm') {
    if (h > 9) {
      return h + ':' + ('0' + m).slice(-2);
    } else {
      return '0' + h + ':' + ('0' + m).slice(-2);
    }
  } else if (format && format === 'ms') {
    return m + ':' + ('0' + s).slice(-2);
  } else {
    return h + ':' + ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
  }
}

export function hmToSeconds(hms: string) {
  const t = hms.split(':');
  return +t[0] * 60 * 60 + +t[1] * 60;
}

export function toAge(d1, d2) {
  d2 = d2 || Date.now();
  var diff = d2 - d1;
  return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
}

export function formatNames(names, order: string) {
  const title = names.title.toUpperCase();
  if (names.firstname && names.lastname) {
    const firstname = toTitleCase(names.firstname);
    const lastname = names.lastname.toUpperCase();
    if (order === 'firstname_lastname') {
      return `${firstname}, ${lastname}`;
    } else if (order === 'lastname_firstname') {
      return `${lastname}, ${firstname}`;
    }
  }
  return title;
}

export function getNames(row) {
  let names;
  if (row.lastname && row.firstname) {
    names = {
      lastname: row.lastname,
      firstname: row.firstname,
      title: `${row.lastname} ${row.firstname}`,
    };
  }
  if (row.lastname_firstname) {
    names = getSeparatedLastnameFirstname(row.lastname_firstname);
  } else if (row.firstname_lastname) {
    names = getSeparatedFirstnameLastname(row.firstname_lastname);
  }
  return names;
}

export function toUniqueSanitizedString(str: string): string {
  let string = removeFalseWhitespaces(sanitizeString(str));
  let s = string.toUpperCase().replace(/[- ]/g, '').replace(/ /g, '').replace(/_/g, '');
  return s;
}

export function sanitizeString(str: string): string {
  let string = '' + str;
  return removeDiacritics(string)
    .replace(/ *\([^)]*\) */g, '')
    .replace(/["$&+,;:\?@#|_<>\.«»^\*\(\)%!\/]/g, ' ')
    .replace(' ', ' ') // special space replaced by correct space
    .replace(/\-\-/g, '-')
    .replace(/  /g, ' ')
    .replace(/ \- /g, '-')
    .replace(/\- /g, '-')
    .replace(/ \-/g, '-')
    .trim();
}

export function removeTirets(str: string): string {
  return str.replace(/\-/g, ' ').replace(/\_/g, ' ');
}

export function removeEndingTiret(str: string): string {
  return str.replace(/(.*)(\-)$/g, '$1');
}

export function removeFalseWhitespaces(str: string): string {
  let string = '' + str;
  return string
    .replace(' ', ' ') // special space replaced by correct space
    .replace('--', '-')
    .replace('  ', ' ')
    .replace(' - ', '-')
    .replace('- ', '-')
    .replace(' -', '-')
    .trim();
}

export function removeDiacritics(str: string) {
  const defaultDiacriticsRemovalMap = [
    {
      base: 'A',
      // tslint:disable-next-line:max-line-length
      letters:
        /[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g,
    },
    { base: 'AA', letters: /[\uA732]/g },
    { base: 'AE', letters: /[\u00C6\u01FC\u01E2]/g },
    { base: 'AO', letters: /[\uA734]/g },
    { base: 'AU', letters: /[\uA736]/g },
    { base: 'AV', letters: /[\uA738\uA73A]/g },
    { base: 'AY', letters: /[\uA73C]/g },
    {
      base: 'B',
      letters: /[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g,
    },
    {
      base: 'C',
      letters: /[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g,
    },
    {
      base: 'D',
      letters: /[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g,
    },
    { base: 'DZ', letters: /[\u01F1\u01C4]/g },
    { base: 'Dz', letters: /[\u01F2\u01C5]/g },
    {
      base: 'E',
      // tslint:disable-next-line:max-line-length
      letters:
        /[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g,
    },
    { base: 'F', letters: /[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g },
    {
      base: 'G',
      letters: /[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g,
    },
    {
      base: 'H',
      letters: /[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g,
    },
    {
      base: 'I',
      letters:
        /[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g,
    },
    { base: 'J', letters: /[\u004A\u24BF\uFF2A\u0134\u0248]/g },
    {
      base: 'K',
      letters: /[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g,
    },
    {
      base: 'L',
      letters:
        /[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g,
    },
    { base: 'LJ', letters: /[\u01C7]/g },
    { base: 'Lj', letters: /[\u01C8]/g },
    {
      base: 'M',
      letters: /[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g,
    },
    {
      base: 'N',
      letters: /[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g,
    },
    { base: 'NJ', letters: /[\u01CA]/g },
    { base: 'Nj', letters: /[\u01CB]/g },
    {
      base: 'O',
      // tslint:disable-next-line:max-line-length
      letters:
        /[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g,
    },
    { base: 'OI', letters: /[\u01A2]/g },
    { base: 'OO', letters: /[\uA74E]/g },
    { base: 'OU', letters: /[\u0222]/g },
    {
      base: 'P',
      letters: /[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g,
    },
    { base: 'Q', letters: /[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g },
    {
      base: 'R',
      letters:
        /[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g,
    },
    {
      base: 'S',
      letters:
        /[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g,
    },
    {
      base: 'T',
      letters: /[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g,
    },
    { base: 'TZ', letters: /[\uA728]/g },
    {
      base: 'U',
      // tslint:disable-next-line:max-line-length
      letters:
        /[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g,
    },
    {
      base: 'V',
      letters: /[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g,
    },
    { base: 'VY', letters: /[\uA760]/g },
    {
      base: 'W',
      letters: /[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g,
    },
    { base: 'X', letters: /[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g },
    {
      base: 'Y',
      letters: /[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g,
    },
    {
      base: 'Z',
      letters: /[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g,
    },
    {
      base: 'a',
      // tslint:disable-next-line:max-line-length
      letters:
        /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g,
    },
    { base: 'aa', letters: /[\uA733]/g },
    { base: 'ae', letters: /[\u00E6\u01FD\u01E3]/g },
    { base: 'ao', letters: /[\uA735]/g },
    { base: 'au', letters: /[\uA737]/g },
    { base: 'av', letters: /[\uA739\uA73B]/g },
    { base: 'ay', letters: /[\uA73D]/g },
    {
      base: 'b',
      letters: /[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g,
    },
    {
      base: 'c',
      letters: /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g,
    },
    {
      base: 'd',
      letters: /[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g,
    },
    { base: 'dz', letters: /[\u01F3\u01C6]/g },
    {
      base: 'e',
      // tslint:disable-next-line:max-line-length
      letters:
        /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g,
    },
    { base: 'f', letters: /[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g },
    {
      base: 'g',
      letters: /[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g,
    },
    {
      base: 'h',
      letters: /[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g,
    },
    { base: 'hv', letters: /[\u0195]/g },
    {
      base: 'i',
      letters:
        /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g,
    },
    { base: 'j', letters: /[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g },
    {
      base: 'k',
      letters: /[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g,
    },
    {
      base: 'l',
      letters:
        /[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g,
    },
    { base: 'lj', letters: /[\u01C9]/g },
    {
      base: 'm',
      letters: /[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g,
    },
    {
      base: 'n',
      letters:
        /[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g,
    },
    { base: 'nj', letters: /[\u01CC]/g },
    {
      base: 'o',
      // tslint:disable-next-line:max-line-length
      letters:
        /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g,
    },
    { base: 'oi', letters: /[\u01A3]/g },
    { base: 'ou', letters: /[\u0223]/g },
    { base: 'oo', letters: /[\uA74F]/g },
    {
      base: 'p',
      letters: /[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g,
    },
    { base: 'q', letters: /[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g },
    {
      base: 'r',
      letters:
        /[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g,
    },
    {
      base: 's',
      letters:
        /[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g,
    },
    {
      base: 't',
      letters: /[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g,
    },
    { base: 'tz', letters: /[\uA729]/g },
    {
      base: 'u',
      // tslint:disable-next-line:max-line-length
      letters:
        /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g,
    },
    {
      base: 'v',
      letters: /[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g,
    },
    { base: 'vy', letters: /[\uA761]/g },
    {
      base: 'w',
      letters: /[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g,
    },
    { base: 'x', letters: /[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g },
    {
      base: 'y',
      letters: /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g,
    },
    {
      base: 'z',
      letters: /[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g,
    },
  ];

  for (let i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
    str = str.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base);
  }

  return str;
}

export function getRaceFlatDistance(race) {
  let flatDistance = null;
  if (race.distance) {
    flatDistance = race.distance;
    if (race.elevation) {
      flatDistance += race.elevation / 100;
    }
  }
  return flatDistance;
}

export function populateRacesWithAverageElevationValuesIfMissing(races) {
  const racesWithElevationValues = races.filter(r => !!r.elevation);
  let racesWithoutElevationValues = races.filter(r => !r.elevation);
  if (racesWithoutElevationValues.length > 0) {
    racesWithoutElevationValues = racesWithoutElevationValues.map(r => {
      const elevationValues = racesWithElevationValues.map(rwe => {
        const weight = universalRaceWeight(rwe, r);
        const value = rwe.elevation;
        return { weight, value };
      });
      return { ...r, elevation: weightedGeomAverage(elevationValues) };
    });
  }
  return [...racesWithoutElevationValues, ...racesWithElevationValues];
}

export function populateRacesWithAverageWeatherValuesIfMissing(races) {
  const racesWithWeatherValues = races
    .filter(r => !!r.weather_data)
    .map(r => {
      return { ...r, app_temp: JSON.parse(r.weather_data).app_temp.average };
    });
  let racesWithoutWeatherValues = races.filter(r => !r.weather_data);
  if (racesWithoutWeatherValues.length > 0) {
    racesWithoutWeatherValues = racesWithoutWeatherValues.map(r => {
      const elevationValues = racesWithWeatherValues.map(rwe => {
        const weight = universalRaceWeight(rwe, r);
        const value = rwe.app_temp;
        return { weight, value };
      });
      return { ...r, app_temp: weightedGeomAverage(elevationValues) };
    });
  }
  return [...racesWithoutWeatherValues, ...racesWithWeatherValues];
}

export function universalRaceWeight(race, targetRace) {
  let weight_age;
  let weight_Dplus;
  let weight_index;
  let weight_course;
  if (race && targetRace) {
    if (race.date && targetRace.date) {
      weight_age = Math.pow(2, -Math.abs(race.date / 31536000 - targetRace.date / 31536000)); // chaque année d'écart fait diviser le poids par 2 --> suppose que la date est en années --> à mettre à l'échelle pour qu'une différence d'une année donne 1
    } else {
      weight_age = 0.5;
    }
    if (race.betrail_index && targetRace.betrail_index && race.betrail_index !== 0 && targetRace.betrail_index !== 0) {
      weight_index = Math.min(
        race.betrail_index / targetRace.betrail_index,
        targetRace.betrail_index / race.betrail_index,
      );
    } else {
      weight_index = 1;
    }

    if (race.elevation && targetRace.elevation && race.elevation !== 0 && targetRace.elevation !== 0) {
      weight_Dplus = Math.max(1 / 8, Math.pow(2, -Math.abs(race.elevation - targetRace.elevation) / 2500)); // Le poids est divisé par 2 pour chaque 2500m d'écart entre les deux courses, avec un min de 1/8
    } else {
      weight_Dplus = 0.125;
    }
    weight_course = weight_age * weight_index * weight_index * weight_Dplus; // weight index counts double
  } else {
    weight_course = 0.125;
  }

  return weight_course;
}

export function filterRacesForIndexEstimation(race, races, options) {
  if (options.distanceProximity == 'hard') {
    races = [...races].filter(r => r.distance < race.distance + 2 && r.distance > race.distance - 2);
    if (races.length < 1) {
      races = [...races].filter(r => r.distance < race.distance * 1.2 && r.distance > race.distance * 0.8);
    }
    if (races.length < 1) {
      races = [...races];
    }
  } else if (options.distanceProximity == 'smooth') {
    races = [...races].filter(r => r.distance < race.distance + 5 && r.distance > race.distance - 5);
    if (races.length < 1) {
      races = [...races].filter(r => r.distance < race.distance * 1.5 && r.distance > race.distance * 0.5);
    }
    if (races.length < 1) {
      races = [...races];
    }
  }
  return races;
}

export function normalizeRaceIndexByDistance(race, r, options) {
  if (options.distanceNormalization == 'flatDistance') {
    return r.betrail_index * (race.flat_distance / r.flat_distance);
  } else if (options.distanceNormalization == 'distance') {
    r.betrail_index * (race.distance / r.distance);
  }
  return r.betrail_index;
}

export function normalizeRaceIndexByAppTemp(index, r, options) {
  if (options.tempNormalization == 'none') {
    return index;
  } else {
    const appTempDelta = Math.abs(options.idealAppTemp - r.app_temp);
    return index / (1 + appTempDelta / options.coeff) / (1 - options.idealAppTemp / options.coeff);
  }
}

export function computeEstimatedRaceIndex(race, previousRaces, options) {
  let previousRaces2 = [...previousRaces]
    .filter(r => !!r.betrail_index)
    .map(r => {
      return { ...r, flat_distance: getRaceFlatDistance(r) };
    });

  // filtering for distance proximity
  previousRaces2 = filterRacesForIndexEstimation(race, previousRaces, options);

  if (race.flat_distance !== null && previousRaces2 && previousRaces2.length > 0) {
    previousRaces2 = populateRacesWithAverageElevationValuesIfMissing(previousRaces2);
    previousRaces2 = populateRacesWithAverageWeatherValuesIfMissing(previousRaces2);
    previousRaces2 = previousRaces2.map(r => {
      const weight = universalRaceWeight(r, race);
      const distanceNormalizedIndex = normalizeRaceIndexByDistance(race, r, options);
      const appTempNormalizedIndex = normalizeRaceIndexByAppTemp(distanceNormalizedIndex, r, options);
      const appTempDelta = Math.abs(options.idealAppTemp - r.app_temp);

      return {
        weight,
        value: appTempNormalizedIndex,
        index: r.betrail_index,
        index2: distanceNormalizedIndex,
        index3: appTempNormalizedIndex,
        title: r.title,
        distance: r.distance,
        flat_distance: r.flat_distance,
        app_temp: r.app_temp,
        app_temp_delta: appTempDelta,
      };
    });

    let averageAppTempDelta = geomAverage(previousRaces2.map(r => r.app_temp_delta));

    let estimatedIndex = weightedGeomAverage(
      previousRaces2.map(r => {
        return { weight: r.weight, value: r.value };
      }),
    );

    if (options.tempNormalization !== 'none') {
      estimatedIndex = estimatedIndex / (1 - averageAppTempDelta / options.coeff);
    }

    return estimatedIndex;
  }
  return;
}

export function estimateRaceIndex(r, previousRaces, options) {
  let race = { ...r, betrail_index: 0, flat_distance: getRaceFlatDistance(r) };

  let i = 0;
  let b = false;

  while (i < 20) {
    i++;
    if (b == false) {
      i++;
      let oldIndex = +race.betrail_index;
      race.betrail_index = computeEstimatedRaceIndex(race, previousRaces, options);
      if (Math.abs(oldIndex - race.betrail_index) < 1) {
        b = true;
      }
    }
  }

  return race;
}

/**
 * @deprecated
 *
 * @param r
 * @param runners
 * @returns
 */
export function computeRaceIndex(r, runners) {
  let race = { ...r };
  let index = 0;
  let moyenne = 1;
  let normalizedResults = [];

  normalizedResults = [];
  let newIndex;
  let stop = false;
  let i = 1;
  if (runners) {
    while (stop === false && i < 50) {
      for (const runner of runners) {
        // on prend tous les résultats du coureur, sauf celui à cette course (au cas où il est déjà enregistré)
        const results = runner.results.slice().filter(re => re.raid !== race.id && re.performance != 0); //TODO: filter result with result tag and race type (ex solo only and not tagged)
        if (results.length > 0) {
          if (i > 1 && index != 0) {
            // on calcule la perf selon l'indice temporaire
            const currentPerf = (8640 * index) / runner.TempResult.result;
            runner.TempResult.performance = currentPerf;
            runner.TempResult.date = race.date;
            runner.TempResult.race = race;
            runner.TempResult.race.betrail_index = index;
            results.push(runner.TempResult);
          }

          const avScore = averageScore(results, race);
          const normalizedResult = (avScore.averageScore / 100) * runner.TempResult.result;

          if (
            !isNaN(normalizedResult) &&
            normalizedResult !== 0 &&
            (moyenne == 1 || (normalizedResult >= 0.5 * moyenne && normalizedResult <= 2 * moyenne))
          ) {
            let weight =
              avScore.runnerCredibility *
              Math.pow(2, -10 * (-1 + Math.max(normalizedResult / moyenne, moyenne / normalizedResult)));
            const resultInfo = {
              value: normalizedResult / 1000,
              weight: i > 1 ? weight : 1,
            };
            normalizedResults.push(resultInfo);
          }
        }
      }
      moyenne = weightedGeomAverage(normalizedResults) * 1000;

      newIndex = (100000 * moyenne) / 86400;
      normalizedResults = [];
      if (Math.abs(newIndex - index) <= 0.01) {
        stop = true;
      }
      index = newIndex;
      i++;
    }

    if (isNaN(index)) {
      return 0;
    } else {
      return Math.round(index);
    }
  } else {
    return 'ERROR';
  }
}

export function computeRaceIndexWithRunnerScores(
  r,
  runners: {
    ruid?: number;
    results: { raid?: number; performance: number }[];
    TempResult?: { result: number; performance: number; date: Date; race: any; runnerId: any };
  }[],
) {
  let race = { ...r };
  let index = 0;
  let moyenne = 1;
  let normalizedResults: {
    value: number;
    weight: number;
    runnerId: any;
    averageScore: number;
    runnerCredibility: number;
  }[] = [];

  let newIndex;
  let stop = false;
  let i = 1;
  if (runners) {
    while (stop === false && i < 50) {
      normalizedResults = [];
      for (const runner of runners) {
        // on prend tous les résultats du coureur, sauf celui à cette course (au cas où il est déjà enregistré)
        const results = runner.results.slice().filter(re => re.raid !== race.id && re.performance != 0); //TODO: filter result with result tag and race type (ex solo only and not tagged)
        if (results.length > 0) {
          if (i > 1 && index != 0) {
            // on calcule la perf selon l'indice temporaire
            const currentPerf = (8640 * index) / runner.TempResult.result;
            runner.TempResult.performance = currentPerf;
            runner.TempResult.date = race.date;
            runner.TempResult.race = race;
            runner.TempResult.race.betrail_index = index;
            results.push(runner.TempResult);
          }

          const avScore = averageScore(results, race);
          const normalizedResult = (avScore.averageScore / 100) * runner.TempResult.result;

          if (
            !isNaN(normalizedResult) &&
            normalizedResult !== 0 &&
            (moyenne == 1 || (normalizedResult >= 0.5 * moyenne && normalizedResult <= 2 * moyenne))
          ) {
            // diminution du poids du runner trop éloigné de la moyenne de la course
            let weight =
              avScore.runnerCredibility *
              Math.pow(2, -10 * (-1 + Math.max(normalizedResult / moyenne, moyenne / normalizedResult)));
            const resultInfo = {
              value: normalizedResult / 1000,
              averageScore: avScore.averageScore,
              weight: i > 1 ? weight : 1,
              runnerCredibility: avScore.runnerCredibility,
              runnerId: runner.ruid,
            };
            normalizedResults.push(resultInfo);
          }
        }
      }
      moyenne = weightedGeomAverage(normalizedResults) * 1000;

      newIndex = (100000 * moyenne) / 86400;

      if (Math.abs(newIndex - index) <= 0.01) {
        stop = true;
      }
      index = newIndex;
      i++;
    }

    if (isNaN(index)) {
      return { index: 0, runnerIndexScores: normalizedResults };
    } else {
      return { index: Math.round(index), runnerIndexScores: normalizedResults };
    }
  } else {
    throw new Error('No runners');
  }
}

export function geodistance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

export function toDateString(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

const lowercase = 'abcdefghijklmnopqrstuvwxyz';
const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const numbers = '0123456789';

const all = lowercase + uppercase + numbers;

export function pick(string: string, min, max = undefined) {
  var n,
    chars = '';

  if (typeof max === 'undefined') {
    n = min;
  } else {
    n = min + Math.floor(Math.random() * (max - min));
  }

  for (var i = 0; i < n; i++) {
    chars += string.charAt(Math.floor(Math.random() * string.length));
  }

  return chars;
}

// Credit to @Christoph: http://stackoverflow.com/a/962890/464744
export function shuffle(string: string) {
  var array = string.split('');
  var tmp,
    current,
    top = array.length;

  if (top)
    while (--top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = array[current];
      array[current] = array[top];
      array[top] = tmp;
    }

  return array.join('');
}

export function toRad(number: number) {
  return (number * Math.PI) / 180;
}

export function toDeg(number: number) {
  return number * (180 / Math.PI);
}

export function middlePoint(lat1, lng1, lat2, lng2) {
  //-- Longitude difference
  var dLng = toRad(lng2 - lng1);

  //-- Convert to radians
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);
  lng1 = toRad(lng1);

  var bX = Math.cos(lat2) * Math.cos(dLng);
  var bY = Math.cos(lat2) * Math.sin(dLng);
  var lat3 = Math.atan2(
    Math.sin(lat1) + Math.sin(lat2),
    Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY),
  );
  var lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX);

  //-- Return result
  return [toDeg(lat3), toDeg(lng3)];
}

export function generatePassword() {
  return shuffle(pick(numbers, 1) + pick(lowercase, 1) + pick(uppercase, 1) + pick(all, 3, 7));
}

export function getLastThursday() {
  const now = new Date();
  const today =
    now.getTime() -
    now.getHours() * 60 * 60 * 1000 -
    now.getMinutes() * 60 * 1000 -
    now.getSeconds() * 1000 -
    now.getMilliseconds();
  const lastThursday = new Date(today + (-3 - now.getDay()) * 24 * 60 * 60 * 1000);
  lastThursday.setHours(10, 0, 0);
  return lastThursday;
}

export function datesFromMonthString(monthString) {
  const splited = monthString.split('-');
  if (splited.length == 2 && MONTH_STRINGS[splited[0]]) {
    const year = splited[1];
    const month = MONTH_STRINGS[splited[0]];
    let startDate = new Date();
    startDate.setFullYear(year);
    startDate.setMonth(month - 1);
    startDate.setDate(1);

    let endDate = new Date();
    endDate.setFullYear(year);
    endDate.setMonth(month);
    endDate.setDate(1);

    return {
      start: startDate.getTime(),
      end: endDate.getTime() - 23 * 60 * 60 * 1000,
      summary: 'MONTH_' + MONTH_STRINGS[splited[0]],
    };
  }
  return undefined;
}

export function convertCountryDataToIso2(country) {
  country = removeDiacritics(country).toUpperCase();
  if (country && country.length == 2) {
    return country;
  } else if (country && country.length == 3) {
    return convertIso3ToIso2(country);
  } else if (country && country.length > 3) {
    return convertIso3ToIso2(country.substring(0, 3));
  }
  return undefined;
}

export function convertIso3ToIso2(code) {
  const data = Object.keys(ALL_COUNTRIES_ISO2_TO_ISO3)
    .map(key => {
      return {
        iso2: key,
        iso3: ALL_COUNTRIES_ISO2_TO_ISO3[key],
      };
    })
    .find(d => d.iso3 == code);
  return data && data.iso2 ? data.iso2 : null;
}

export function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function getDepartmentFromUrlAlias(departmentString) {
  const dep = FRANCE_DEPARTMENTS.find(r => r.url_alias === departmentString);
  if (dep) return dep;
  const prov = BELGIUM_PROVINCES.find(p => p.url_alias === departmentString);
  if (prov) return prov;
  return '';
}

export function getDepartmentFromCode(departmentCode) {
  return FRANCE_DEPARTMENTS.find(r => r.code == departmentCode);
}

export function getDepartmentTitleFromCode(code) {
  const dep = FRANCE_DEPARTMENTS.find(r => r.code === code);
  if (dep) {
    return dep.title;
  }
  const prov = BELGIUM_PROVINCES.find(r => r.code === code);
  if (prov) {
    return prov.title;
  }
  return '';
}

export function getDepartmentFromPostalCode(
  postalCode: string,
  country: string,
): { code: string; title: string; region: string; url_alias: string } | undefined {
  if (postalCode && postalCode.length === 5 && country === 'FR') {
    let department = FRANCE_DEPARTMENTS.find(d => d.code == '' + postalCode.substr(0, 2));
    return department;
  }
  if (postalCode && postalCode.length === 4 && country === 'BE') {
    let pc = +postalCode;
    let department = undefined;
    if (pc >= 1000 && pc <= 1299) {
      department = BELGIUM_PROVINCES.find(d => d.code === 'BXL');
    }
    if (pc >= 1300 && pc <= 1499) {
      department = BELGIUM_PROVINCES.find(d => d.code === 'WBR');
    }
    if (pc >= 1500 && pc <= 1999) {
      department = BELGIUM_PROVINCES.find(d => d.code === 'VBR');
    }
    if (pc >= 2000 && pc <= 2999) {
      department = BELGIUM_PROVINCES.find(d => d.code === 'VAN');
    }
    if (pc >= 3000 && pc <= 3499) {
      department = BELGIUM_PROVINCES.find(d => d.code === 'VBR');
    }
    if (pc >= 3500 && pc <= 3999) {
      department = BELGIUM_PROVINCES.find(d => d.code === 'VLI');
    }
    if (pc >= 4000 && pc <= 4999) {
      department = BELGIUM_PROVINCES.find(d => d.code === 'WLI');
    }
    if (pc >= 5000 && pc <= 5999) {
      department = BELGIUM_PROVINCES.find(d => d.code === 'WNA');
    }
    if (pc >= 6000 && pc <= 6599) {
      department = BELGIUM_PROVINCES.find(d => d.code === 'WHA');
    }
    if (pc >= 6600 && pc <= 6999) {
      department = BELGIUM_PROVINCES.find(d => d.code === 'WLX');
    }
    if (pc >= 7000 && pc <= 7999) {
      department = BELGIUM_PROVINCES.find(d => d.code === 'WHA');
    }
    if (pc >= 8000 && pc <= 8999) {
      department = BELGIUM_PROVINCES.find(d => d.code === 'VWV');
    }
    if (pc >= 9000 && pc <= 9999) {
      department = BELGIUM_PROVINCES.find(d => d.code === 'VOV');
    }
    return department;
  }
  return undefined;
}

export function getRegionFromUrlAlias(regionString: string) {
  let reg = FRANCE_REGIONS.find(r => r.url_alias === regionString);
  if (reg) return reg;
  reg = BELGIUM_REGIONS.find(r => r.url_alias === regionString);
  if (reg) return reg;
  return '';
}

export function getRegionByCode(regionCode: string) {
  return FRANCE_REGIONS.find(r => r.code === regionCode);
}

export function getRegionTitleFromCode(regionCode: string) {
  let region = FRANCE_REGIONS.find(r => r.code === regionCode);
  if (region) {
    return region.title;
  }
  region = BELGIUM_REGIONS.find(r => r.code === regionCode);
  if (region) {
    return region.title;
  }
  return '';
}

export function getRegionByTitle(regionTitle: string) {
  return FRANCE_REGIONS.find(r => r.title == regionTitle);
}

export function getAge(d1, d2?) {
  d2 = d2 || new Date();
  const diff = d2.getTime() - new Date(d1).getTime();
  return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
}

export function latlonArrayToXYArray(arr) {
  let xy = arr.map(a => latlonToXY(a));
  let xMin = Math.min(...xy.map(a => a[0]));
  let xMax = Math.max(...xy.map(a => a[0]));
  let deltaX = xMax - xMin;
  let yMin = Math.min(...xy.map(a => a[1]));
  let yMax = Math.max(...xy.map(a => a[1]));
  let deltaY = yMax - yMin;
  let deltaMax = Math.max(deltaX, deltaY);
  let corr = 96 / deltaMax;

  return xy.map(a => {
    const x = (96 - deltaX * corr) / 2 + 2 + (a[0] - xMin) * corr;
    const y = (96 - deltaY * corr) / 2 + 2 + (a[1] - yMin) * corr;
    return [x, y];
  });
}

export function latlonToXY(arr) {
  const mapWidth = 100; //options.mapWidth;
  const mapHeight = 100; //options.mapHeight;

  // get x value
  const x = (arr[1] + 180) * (mapWidth / 360);

  // convert from degrees to radians
  const latRad = (arr[0] * Math.PI) / 180;

  // get y value
  const mercN = Math.log(Math.tan(Math.PI / 4 + latRad / 2));
  const y = mapHeight / 2 - (mapWidth * mercN) / (2 * Math.PI);

  return [x, y];
}

export function userIsOrganizerOfThisAlias(user: IUserState, alias: string) {
  let authAliases: string[] = [];
  let rt = false;
  if (alias && user?.organizations) {
    user.organizations.map(o => {
      if (o) {
        authAliases.push('' + o.id);
        if (o.trails) {
          o.trails.map(t => {
            authAliases.push(t.alias);
            /*if (t.events) {
              t.events.map(e => {
                authAliases.push(e.alias);
              });
            }*/
          });
        }
      }
    });
    if (authAliases.some(authAlias => alias.includes(authAlias))) {
      rt = true;
    }
  }
  return rt;
}

export function isAdmin(user: IUserState): boolean {
  return hasRoleId(user, EUserRole.Administrator);
}

export function isAdminOrEncoder(user: IUserState | IUserJwt): boolean {
  return hasRoleId(user, EUserRole.Administrator) || hasRoleId(user, EUserRole.Encoder);
}

export function capitalize(s: string) {
  if (typeof s !== 'string') return s;
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function calculateDistanceFromCoordinates(path) {
  let i = 0;
  let dist = 0;
  while (i < path.length) {
    if (path[i] && i > 0) {
      dist += geodistance(path[i - 1][0], path[i - 1][1], path[i][0], path[i][1], 'K') * 1000;
    }
    i++;
  }
  return dist;
}

export function calculatePositiveElevationFromElevationPoints(el) {
  let total = 0;
  el = el.filter(e => e && e != 0); // hack to avoid huge elevation because of lack of points
  if (el && el.length > 0) {
    let i = 0;
    while (i < el.length) {
      if (i > 0) {
        if (el[i] > el[i - 1]) {
          total += el[i] - el[i - 1];
        }
      }
      i++;
    }
  }
  return total;
}

export function calculateNegativeElevationFromElevationPoints(el) {
  let total = 0;
  el = el.filter(e => e && e != 0); // hack to avoid huge elevation because of lack of points
  if (el && el.length > 0) {
    let i = 0;
    while (i < el.length) {
      if (i > 0) {
        if (el[i] < el[i - 1]) {
          total += el[i - 1] - el[i];
        }
      }
      i++;
    }
  }
  return total;
}

export function metersToDegrees(meters) {
  // this is approximate (spherical earth, average circumference, not considering altitude, etc.)
  var earth = 40075000; // average circumference in metres at sea level
  return (meters * 360.0) / earth;
}

export function simplifyTrack(points, tol) {
  const tolerance = metersToDegrees(tol);
  var Line = function (p1, p2) {
    this.p1 = p1;
    this.p2 = p2;

    this.distanceToPoint = function (point) {
      // slope
      var m = (this.p2[0] - this.p1[0]) / (this.p2[1] - this.p1[1]),
        // y offset
        b = this.p1[0] - m * this.p1[1],
        d = [];
      // distance to the linear equation
      d.push(Math.abs(point[0] - m * point[1] - b) / Math.sqrt(Math.pow(m, 2) + 1));
      // distance to p1
      d.push(Math.sqrt(Math.pow(point[1] - this.p1[1], 2) + Math.pow(point[0] - this.p1[0], 2)));
      // distance to p2
      d.push(Math.sqrt(Math.pow(point[1] - this.p2[1], 2) + Math.pow(point[0] - this.p2[0], 2)));
      // return the smallest distance
      return d.sort(function (a, b) {
        return a - b; //causes an array to be sorted numerically and ascending
      })[0];
    };
  };

  var douglasPeucker = function (points, tolerance) {
    if (points.length <= 2) {
      return [points[0]];
    }
    var returnPoints = [],
      // make line from start to end
      line = new Line(points[0], points[points.length - 1]),
      // find the largest distance from intermediate poitns to this line
      maxDistance = 0,
      maxDistanceIndex = 0,
      p;
    for (var i = 1; i <= points.length - 2; i++) {
      var distance = line.distanceToPoint(points[i]);
      if (distance > maxDistance) {
        maxDistance = distance;
        maxDistanceIndex = i;
      }
    }
    // check if the max distance is greater than our tollerance allows
    if (maxDistance >= tolerance) {
      p = points[maxDistanceIndex];
      line.distanceToPoint(p, true);
      // include this point in the output
      returnPoints = returnPoints.concat(douglasPeucker(points.slice(0, maxDistanceIndex + 1), tolerance));
      // returnPoints.push( points[maxDistanceIndex] );
      returnPoints = returnPoints.concat(douglasPeucker(points.slice(maxDistanceIndex, points.length), tolerance));
    } else {
      // ditching this point
      p = points[maxDistanceIndex];
      line.distanceToPoint(p, true);
      returnPoints = [points[0]];
    }
    return returnPoints;
  };
  var result = douglasPeucker(points, tolerance);
  // always have to push the very last point on so it doesn't get left off
  result.push(points[points.length - 1]);
  return result;
}

export function formatPathToRace(race, paths) {
  let formattedPaths = [];
  if (paths && paths.length > 0) {
    formattedPaths = paths.map(path => formatPath(path));
  }
  race = { ...race, paths: formattedPaths };
  return race;
}

export function formatPath(path) {
  if (path instanceof Object) {
    const polyline = require('google-polyline');
    if (path.path instanceof Array) {
      return path;
    } else {
      const rawPath = path.path;
      const pa = polyline.decode(path.path);
      const el = polyline.decode(path.elevation).map((e, i) => {
        if (e[0] !== 0) {
          return e[0];
        } else if (i > 0) {
          return path.elevation[i - 1][0];
        }
      });

      return {
        ...path,
        path: pa,
        rawPath,
        elevation: el,
      };
    }
  }
  return path;
}

export function getPositionSupString(position: number): string {
  let lastDigit;

  if (position < 4) {
    lastDigit = position;
  } else {
    lastDigit = parseInt('4' + position.toString().split('').pop());
    if (lastDigit > 43) {
      lastDigit = 44;
    }
  }
  return 'SCORE_POS_' + lastDigit;
}

export function validRunnerBirthdate(birthdate) {
  let bd = new Date();
  bd.setTime(birthdate * 1000 + 10 * 60 * 60 * 1000);
  const month = bd.getMonth();
  const date = bd.getDate();
  if ((month == 0 && date == 1) || (month == 11 && date == 31)) {
    return false;
  }
  return true;
}

export function computeResultDateCoeff(resultDate, raceDate?: number) {
  let dateToCompare = raceDate ? raceDate : new Date().getTime() / 1000;
  let dateDiff = Math.abs((dateToCompare - resultDate) / (60 * 60 * 24 * 365));
  let dateCoeff;
  if (dateDiff < 1) {
    dateCoeff = 1 - Math.pow(dateDiff, 2) / 4;
  } else {
    dateCoeff = (3 / 4) * (1 / Math.pow(2, dateDiff - 1));
  }
  return dateCoeff;
}

export function logX(val, base) {
  return Math.log(val) / Math.log(base);
}

export function ecartType(vals) {
  if (vals && vals.length > 0) {
    let arithAv = vals.reduce((a, b) => a + b, 0) / vals.length;
    let sq = vals.map(v => Math.pow(Math.abs(arithAv - v), 2)).reduce((a, b) => a + b, 0) / vals.length;
    let eT = Math.sqrt(sq);
    return eT;
  }
  return;
}

export function getNormalizedTimeFromRaceIndex(index) {
  let raceNormalizedTime = (index / 100000) * 86400;
  return raceNormalizedTime;
}

export function getEstimatedTimeFromRaceIndexAndPerf(index, perf) {
  let raceNormalizedTime = getNormalizedTimeFromRaceIndex(index);
  let estimatedTime = raceNormalizedTime / (perf / 100);
  return estimatedTime;
}

export function populateRaceWithFlatDistanceAndSteep(sourceRace) {
  let source = { ...sourceRace, elevation: sourceRace.elevation || sourceRace.distance * 40 };
  source.flatDistance = source.distance + source.elevation / 100;
  source.steep = source.elevation / source.distance;
  return source;
}

export function computeLengthSteepCoeff(sourceRace, targetRace) {
  let source = populateRaceWithFlatDistanceAndSteep(sourceRace);
  let target = populateRaceWithFlatDistanceAndSteep(targetRace);

  // length coeff
  let lengthCoeff = Math.max(source.distance / target.distance, target.distance / source.distance, 1.25);

  // steep coeff
  let steepCoeff = Math.max(source.steep / target.steep, target.steep / source.steep, 1.25);

  // lengthSteep coeff = coeff prono
  let lengthSteepCoeff = lengthCoeff * Math.sqrt(steepCoeff);

  return lengthSteepCoeff;
}

export function computeTrackProximity(lengthSteepCoeff) {
  let a = 2.0; // abscisse pivot à 80%
  let b = 0.5; // ordonnée pivto à coeff 2
  let toPercentCoeff = 1.2697;
  let trackProximity;
  if (lengthSteepCoeff < a) {
    trackProximity = 1 - Math.pow(Math.pow(lengthSteepCoeff, logX(1.5, a)) - 1, logX(1 - b, 0.5));
  } else {
    trackProximity = 1 / (lengthSteepCoeff - a + 1 / b);
  }
  return toPercentCoeff * trackProximity;
}

export function splitWords(str: string, removeShortWords = false) {
  if (str.indexOf(' ') > -1 || str.indexOf('-') > -1 || str.indexOf(',') > -1) {
    if (removeShortWords === true) {
      return str.split(/[\s-,]+/).filter(w => w.length > 2);
    }
    return str.split(/[\s-,]+/);
  } else {
    return new Array(str);
  }
}

export function combine(fruits, newFruits, second = 0) {
  let i = 0;
  while (i < fruits.length) {
    const temporaryFruits = fruits.slice();
    temporaryFruits.splice(i, 1);
    if (second > i) {
    } else {
      newFruits.push(temporaryFruits);
    }
    if (temporaryFruits.length > 2) {
      if (second > i) {
      } else {
        combine(temporaryFruits, newFruits, i);
      }
    }
    i++;
  }

  return newFruits;
}

export function getLevenshteinProximity(name1, name2) {
  const levenshtein = require('js-levenshtein');
  const distance = Math.max(levenshtein(name1, name2), levenshtein(name2, name1));
  const length = Math.max(name1.length, name2.length);
  return 100 * (1 - distance / length / 1.5);
}

export function getEudexProximity(name1, name2) {
  var eudex = require('talisman/phonetics/eudex');

  let eu1 = eudex(name1);
  let eu2 = eudex(name2);
  let proximity = Math.round(Math.min(eu1 / eu2, eu2 / eu1) * 10000) / 100;
  if ((proximity > 99 && proximity !== 100) || (proximity == 100 && name1 != name2)) {
    proximity = 99;
  }
  proximity = proximity - Math.abs(eu1 - eu2) / 50000000;
  if (proximity >= 95) {
    proximity = Math.pow(proximity / 100, 100) * 100;
  }
  if (splitWords(name1).length == 2 && splitWords(name2).length == 2 && proximity < 90) {
    if (name1.includes(name2, 0) || name2.includes(name1, 0)) {
      proximity = proximity * 1.1;
    }
  }
  return proximity;
}

export function getJaroWinklerProximity(name1, name2) {
  var jaroDistance = require('jaro-winkler');
  return 100 * Math.max(jaroDistance(name1, name2), jaroDistance(name2, name1));
}

export function getStringsProximity(name1, name2) {
  // TO BE SHURE EUDEX WILL NOT CRASH

  const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}]+/gu);
  name1 = name1.replace(regexForNonAlphaNum, ' ');
  name2 = name2.replace(regexForNonAlphaNum, ' ');

  // VARIOUS STRING PROXIMITY VALLUES
  let lev = getLevenshteinProximity(name1, name2);
  let jaro = getJaroWinklerProximity(name1, name2);
  let eudex = getEudexProximity(name1, name2);
  let proximity = geomAverage([lev, jaro, eudex]);

  // correct eudex generosity (!)
  if (eudex < 50 || eudex >= 99.5) {
    proximity = geomAverage([lev, jaro]);
  }

  // correct eudex && lev proximity if substring
  if (splitWords(name1).length == 2 && splitWords(name2).length == 2 && proximity < 90) {
    if (name1.includes(name2, 0) || name2.includes(name1, 0)) {
      proximity = jaro;
    }
  }

  // limit proximity of non-exact matches
  proximity = Math.pow(proximity / 100, 1.3) * 100;

  return proximity;
}

export function perm(xs) {
  let ret = [];

  if (typeof xs === 'string') ret.push(xs);
  else {
    for (let i = 0; i < xs.length; i = i + 1) {
      let rest = perm(xs.slice(0, i).concat(xs.slice(i + 1)));

      if (!rest.length) {
        ret.push([xs[i]]);
      } else {
        for (let j = 0; j < rest.length; j = j + 1) {
          ret.push([xs[i]].concat(rest[j]));
        }
      }
    }
  }
  return ret;
}

export function computeNamesProximity(names, name) {
  name = removeTirets(sanitizeString(name)).toUpperCase();
  names = names.map(n => removeTirets(sanitizeString(n)).toUpperCase());
  // match exact avec inversion de plusieurs mots
  const mots = splitWords(name);
  let max = 0;
  let maxWords = mots.length;

  const combinaisons = perm(mots).map(w => w.join(' '));

  for (const n of names) {
    if (n == name) {
      max = 100;
    } else {
      let proximity1 = getStringsProximity(n, name);
      if (proximity1 > max) {
        max = proximity1;
      }

      // using the smallest quantity of words
      if (splitWords(n).length < maxWords) {
        maxWords = splitWords(n).length;
      }

      let permutation = perm(splitWords(n));

      // permutation of words less 1 if words quantity is > 2
      if (splitWords(n).length > 2)
        permutation = permutation.concat(permutation.map(arr => arr.slice(0, arr.length - 1)));
      const combinaisons2 = permutation.map(w => w.join(' '));

      for (const nn of combinaisons2) {
        for (const m of combinaisons) {
          let proximity = getStringsProximity(nn, m) - 8 / maxWords;
          // if the two strings have ben inverted
          if (m != name && nn != n && maxWords == 2) {
            proximity = getStringsProximity(nn, m);
          }

          if (proximity > max) {
            max = proximity;
          }
        }
      }
    }
  }
  return max / 100;
}

export function computeBirthdateProximity(date, birthdate) {
  const tolerance = 1;
  const diff = Math.abs(date - birthdate) / (365 * 24 * 60 * 60 * 1000);
  let proximity;
  if (diff <= tolerance) {
    proximity = 1;
  } else {
    proximity = Math.pow(1.05, -(diff - tolerance));
  }

  return proximity;
}

export function computeGeodistanceProximity(distance, runnerAverageDistance = 100) {
  const tolerance = runnerAverageDistance || 100;
  let proximity;
  if (distance <= tolerance) {
    proximity = Math.pow(1.05, -distance / 100);
  } else {
    proximity = Math.pow(1.1, -(distance - tolerance) / 100) - 0.05;
  }

  return 0.6 + 0.4 * proximity;
}

export function computeLevelProximity(level, perf) {
  var coeff = Math.abs(perf - level);

  // case of big lower performance
  if (coeff > 8 && perf < level) {
    // coeff is modified to be near 10 and not so bigger
    coeff = coeff / 10 + 8;
  }

  var a = 5.0; // abscisse pivot à 95%
  var b = 0.9; // ordonnée pivot
  var proximity = 1;

  if (coeff <= 1) {
    proximity = 1;
  } else if (coeff < a) {
    proximity = 1 - Math.pow(Math.pow(coeff, logX(1.5, a)) - 1, logX(1 - b, 0.5));
  } else {
    proximity = 1 / ((coeff - a) / 50 + 1 / b);
  }

  return proximity;
}

export function computeRunnerGlobalLengthSteepCoeffOnRace(results, distance, elevation) {
  let populatedResults = results
    .filter(result => !!result.race && !!result.race.distance) // avoid problem with result orphan of race
    .map(result => {
      // date coeff
      let resultDate = result.race.date;
      let dateCoeff = computeResultDateCoeff(resultDate);

      // lengthSteep coeff = coeff prono
      let lengthSteepCoeff = computeLengthSteepCoeff(result.race, {
        distance: distance,
        elevation: elevation * distance,
      });

      let coeff = Math.pow(result.performance, 2) * dateCoeff;
      let weight = result.performance * coeff;
      let pronoWeight = weight / Math.pow(lengthSteepCoeff, 4);

      return { ...result, coeff, lengthSteepCoeff, weight, pronoWeight: pronoWeight };
    })
    .sort((a, b) => b.pronoWeight - a.pronoWeight);

  let finalResults = [...populatedResults].slice(0, 3);

  let globalLengthSteepCoeff = geomAverage(finalResults.map(r => r.lengthSteepCoeff));

  const level =
    finalResults.map(r => r.pronoWeight * r.performance).reduce((a, b) => a + b, 0) /
    finalResults.map(r => r.pronoWeight).reduce((a, b) => a + b, 0);

  let estimatedPerformance = Math.round(level * 100) / 100;

  return {
    globalLengthSteepCoeff: globalLengthSteepCoeff,
    estimatedByBetrail: estimatedPerformance,
  };
}

export function computeBestEstimatedRunnerLevelOnRace(results, race) {
  if (!race.elevation) {
    race.elevation = race.distance * 40;
  }
  const targetedFlatDistance = race.distance + race.elevation / 100;
  const targetedSteep = race.elevation / race.distance;
  let populatedResults = results
    .filter(result => !!result.race && !!result.race.distance) // avoid problem with result orphan of race
    .map(result => {
      // date coeff
      let resultDate = result.race.date;
      let dateCoeff = computeResultDateCoeff(resultDate, race.date);

      // lengthSteep coeff = coeff prono
      let lengthSteepCoeff = computeLengthSteepCoeff(result.race, race);

      let coeff = Math.pow(result.performance, 2) * dateCoeff;
      let weight = result.performance * coeff;
      let pronoWeight = weight / Math.pow(lengthSteepCoeff, 4);

      return { ...result, coeff, lengthSteepCoeff, weight, pronoWeight: pronoWeight };
    })
    .sort((a, b) => b.pronoWeight - a.pronoWeight);

  let finalResults = [...populatedResults].slice(0, 3);

  const level =
    finalResults.map(r => r.pronoWeight * r.performance).reduce((a, b) => a + b, 0) /
    finalResults.map(r => r.pronoWeight).reduce((a, b) => a + b, 0);

  let globalLengthSteepCoeff = geomAverage(finalResults.map(r => r.lengthSteepCoeff));
  let trackProximity = computeTrackProximity(globalLengthSteepCoeff);

  let estimatedPerformance = Math.round(level * 100) / 100;

  // let regularity = Math.round(100 - 10*ecartType(finalResults.map(r => r.performance)))/100;

  /* const geomAverageLevel = weightedGeomAverage(
    finalResults.map((r) => {
      return { value: r.performance, weight: r.pronoWeight };
    })
  ); */

  let estimatedTime;
  let raceNormalizedTime;
  if (level && level > 0 && race.betrail_index > 0) {
    raceNormalizedTime = (race.betrail_index / 100000) * 86400;
    estimatedTime = getEstimatedTimeFromRaceIndexAndPerf(race.betrail_index, level);
  }

  let regularity = geomAverage(
    finalResults.map(r =>
      Math.pow(Math.min(r.performance / estimatedPerformance, estimatedPerformance / r.performance), 8),
    ),
  );

  return {
    finalResults,
    globalLengthSteepCoeff,
    estimatedPerformance,
    //estimatedTime: Math.round(estimatedTime),
    //raceNormalizedTime,
    trackProximity: Math.round(trackProximity * 100),
    regularity: Math.round(regularity * 100),
    fiability: Math.round(trackProximity * regularity * 100),
  };
}

export function computeRunnerLevel(ruid, results) {
  if (results && results.length > 0) {
    let populatedResults = results
      .filter(result => !!result.race) // avoid problem with result orphan of race
      .map(result => {
        let resultDate = result.race.date;
        let dateCoeff = computeResultDateCoeff(resultDate);
        let coeff = Math.pow(result.performance, 2) * dateCoeff;
        let weight = result.performance * coeff;
        return { ...result, coeff, weight };
      })
      .sort((a, b) => b.weight - a.weight)
      .slice(0, 3);

    const level =
      populatedResults.map(r => r.weight).reduce((a, b) => a + b, 0) /
      populatedResults.map(r => r.coeff).reduce((a, b) => a + b, 0);

    let level_result_reid_1 = null;
    let level_result_reid_2 = null;
    let level_result_reid_3 = null;
    let last_result_date = null;

    if (populatedResults && populatedResults[0]) {
      level_result_reid_1 = populatedResults[0].id;
    }

    if (populatedResults && populatedResults[1]) {
      level_result_reid_2 = populatedResults[1].id;
    }

    if (populatedResults && populatedResults[2]) {
      level_result_reid_3 = populatedResults[2].id;
    }

    last_result_date = populatedResults.sort((a, b) => b.race.date - a.race.date)[0].race.date;

    return {
      ruid,
      level: Math.round(level * 100),
      level_result_reid_1,
      level_result_reid_2,
      level_result_reid_3,
      last_result_date,
    };
  }
}

export function getDrupalFieldData(drupalEntity, fieldName, fieldColumn = 'value') {
  let data;
  if (
    drupalEntity &&
    drupalEntity[fieldName] &&
    drupalEntity[fieldName].und &&
    drupalEntity[fieldName].und[0] &&
    drupalEntity[fieldName].und[0][fieldColumn]
  ) {
    data = drupalEntity[fieldName].und[0][fieldColumn];
  }
  return data;
}

export function lastInstantOfDate(date: Date) {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  return date.getTime();
}

export function firstInstantOfDate(date: Date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.getTime();
}

export function lastInstantOfToday() {
  let today = new Date();
  return lastInstantOfDate(today);
}

export function fistInstantOfToday() {
  let today = new Date();
  return firstInstantOfDate(today);
}

export function hasRoleId(user: IUserState | IUserJwt, roleId: EUserRole) {
  return user?.roles && (user.roles as { rid: number }[]).some(role => role.rid === roleId);
}

export function getRunnerHighlightedResults(results: (IResult | IOtherResult)[]) {
  const races = results.sort((a, b) => (b.date ? b.date : 0) - (a.date ? a.date : 0));
  if (races?.length < 4) {
    return [];
  } else if (races?.length < 11) {
    return races.slice(3);
  } else {
    const twoGreatestTime = results.sort((a, b) => b.result_milliseconds - a.result_milliseconds).slice(0, 2);
    let resultsLeft = results.sort((a, b) => b.result_milliseconds - a.result_milliseconds).slice(2);
    const fiveBestTrailcup = resultsLeft
      .sort((a, b) => ('pts' in b ? b.pts : 0) - ('pts' in a ? a.pts : 0))
      .slice(0, 5);
    resultsLeft = resultsLeft.sort((a, b) => ('pts' in b ? b.pts : 0) - ('pts' in a ? a.pts : 0)).slice(5);
    const threeBestBetrail = resultsLeft
      .sort((a, b) => ('performance' in b ? b.performance : 0) - ('performance' in a ? a.performance : 0))
      .slice(0, 3);
    return twoGreatestTime
      .concat(fiveBestTrailcup)
      .concat(threeBestBetrail)
      .sort((a, b) => (b.date ? b.date : 0) - (a.date ? a.date : 0));
  }
}

export function calculateEventEncodingPriorityCoeff(event: IEvent): number {
  let nbFinishers = 1;
  let greatestDistance = 1;

  const nbDaysUntilDate = Math.abs((+event.predicted_next_date - Date.now() / 1000) / (60 * 60 * 24));
  const diffDate = nbDaysUntilDate >= 7 ? nbDaysUntilDate : 7;

  for (const race of event.races) {
    nbFinishers += race.finishers ?? 0;
    if (+race.distance > greatestDistance) {
      greatestDistance = +race.distance;
    }
  }
  // pays = 4 pour FR ou BE; 2 pour NL, LU, CH, ES, IT, PT, AD, DE; 1
  let coefCountry = 1;
  if (event.country === 'FR' || event.country === 'BE') {
    coefCountry = 4;
  } else if (
    event.country === 'NL' ||
    event.country === 'LU' ||
    event.country === 'CH' ||
    event.country === 'ES' ||
    event.country === 'IT' ||
    event.country === 'PT' ||
    event.country === 'AD' ||
    event.country === 'DE'
  ) {
    coefCountry = 2;
  }

  return (coefCountry * nbFinishers * greatestDistance) / (diffDate * diffDate);
}
