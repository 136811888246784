<div
  *browserOnly
  class="bannerContainer bt-shadowed bt-rounded"
  ngClass.lt-md="xs"
  ngClass.md="medium"
  ngClass.gt-md="large"
>
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <ng-container *ngIf="(slides$ | async)?.length > 0; else bannerBlock">
      <ng-container *ngFor="let slide of slides$ | async">
        <!-- banners for users who selected a default country -->
        <div
          ngxSlickItem
          class="slide"
          *ngIf="
            (slide.lang === currentLang || slide.lang === 'und') && slide.countries.indexOf(defaultCountry$ | async) > -1
          "
        >
          <a rel="sponsored" href="{{ slide.link }}" target="_blank">
            <div class="bgBanner {{ type }}" [ngStyle]="{ 'background-image': 'url(' + slide.url + ')' }"></div>
          </a>
        </div>

        <!-- default banners for users without default country -->
        <div
          ngxSlickItem
          class="slide"
          *ngIf="
            (slide.lang === currentLang || slide.lang === 'und') &&
            (defaultCountry$ | async) === undefined
          "
        >
          <a rel="sponsored" href="{{ slide.link }}" target="_blank">
            <div class="bgBanner {{ type }}" [ngStyle]="{ 'background-image': 'url(' + slide.url + ')' }"></div>
          </a>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #bannerBlock>
      <div class="bgBanner {{ type }} empty"></div>
      <!--<google-ad-banner></google-ad-banner>-->
    </ng-template>
  </ngx-slick-carousel>
</div>
