<ng-container [formGroup]="organizationFormGroup">
  <div>
    <mat-form-field appearance="fill" class="full-width mb-4">
      <mat-label>{{ 'ORGANIZATION_NAME' | transloco }}*</mat-label>
      <mat-icon class="suffixIcon" matSuffix>people</mat-icon>
      <input
        matInput
        #name
        type="text"
        placeholder="{{ 'ORGANIZATION_NAME_EXAMPLE' | transloco }}"
        formControlName="name"
      />
      <mat-hint>{{ 'ORGANIZATION_NAME_EXAMPLE_HINT' | transloco }}</mat-hint>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="fill" fxFlex="100">
      <mat-icon class="suffixIcon" matSuffix>account_balance</mat-icon>
      <mat-label>{{ 'LEGAL_FORM' | transloco }}*</mat-label>
      <mat-select matInput type="text" formControlName="legal_form">
        <ng-container *ngFor="let form of legal_forms">
          <mat-option [value]="form.key">{{ form.name | transloco }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="font-bold mt-4 mb-3">{{ 'CONTACT_DATA' | transloco }}</div>

  <div>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{ 'EMAIL' | transloco }}</mat-label>
      <mat-icon class="suffixIcon" matSuffix>email</mat-icon>
      <input
        trimInput
        matInput
        #email
        type="text"
        placeholder="{{ 'YOUR_EMAIL' | transloco }}"
        formControlName="email"
      />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{ 'PHONE' | transloco }}</mat-label>
      <mat-icon class="suffixIcon" matSuffix>phone</mat-icon>
      <input matInput #email type="text" placeholder="{{ 'YOUR_PHONE' | transloco }}" formControlName="phone" />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{ 'WEB_SITE' | transloco }}</mat-label>
      <mat-icon class="suffixIcon" matSuffix>web</mat-icon>
      <input matInput #email type="text" placeholder="{{ 'YOUR_WEB_SITE' | transloco }}" formControlName="web_site" />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{ 'FACEBOOK_PAGE' | transloco }}</mat-label>
      <mat-icon class="suffixIcon" matSuffix>public</mat-icon>
      <input
        matInput
        #email
        type="text"
        placeholder="{{ 'YOUR_FACEBOOK_PAGE' | transloco }}"
        formControlName="facebook_page"
      />
    </mat-form-field>
  </div>
</ng-container>
