import { Injectable } from '@angular/core';
import {
  ALL_CALENDAR_COUNTRIES,
  ALL_COUNTRIES_CODES,
  COUNTRIES_TRANSLATIONS,
  COVERED_COUNTRIES,
  getDepartmentTitleFromCode,
  getLastThursday,
  getNowPlusNYears,
  getRegionTitleFromCode,
} from '@betrail-libs/shared/utils';
import { TranslocoService } from '@ngneat/transloco';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import produce from 'immer';
import {
  SetAvatarLater,
  SetCalendarCanceled,
  SetCalendarCanceledValue,
  SetCalendarCategory,
  SetCalendarCountry,
  SetCalendarDate,
  SetCalendarDateEnd,
  SetCalendarDateStart,
  SetCalendarDepartment,
  SetCalendarDistance,
  SetCalendarDistanceMax,
  SetCalendarDistanceMin,
  SetCalendarDrop,
  SetCalendarDropMax,
  SetCalendarDropMin,
  SetCalendarElevation,
  SetCalendarElevationMax,
  SetCalendarElevationMin,
  SetCalendarFilterRunType,
  SetCalendarFilterSelectedRunType,
  SetCalendarFilterSummary,
  SetCalendarGeolocation,
  SetCalendarGeolocationCoordinates,
  SetCalendarMonth,
  SetCalendarRegion,
  SetCalendarSelectedCategories,
  SetCalendarSelectedCountries,
  SetCalendarSelectedCountry,
  SetCalendarSelectedDepartment,
  SetCalendarSelectedRegion,
  SetCalendarTitle,
  SetCalendarTitleFilter,
  SetConfirmationLater,
  SetDefaultCountry,
  SetDisplayFixedRaceDistanceSubPageMenu,
  SetResultFilterCategory,
  SetResultFilterCountry,
  SetResultFilterDate,
  SetResultFilterDateEnd,
  SetResultFilterDateStart,
  SetResultFilterDistance,
  SetResultFilterDrop,
  SetResultFilterElevation,
  SetResultFilterRaceType,
  SetResultFilterRunType,
  SetResultFilterSelectedCategory,
  SetResultFilterSelectedCountry,
  SetResultFilterSelectedDistance,
  SetResultFilterSelectedDrop,
  SetResultFilterSelectedElevation,
  SetResultFilterSelectedRaceType,
  SetResultFilterSelectedRunType,
  SetResultSortDate,
  SetResultSortDesc,
  SetResultSortDistance,
  SetResultSortElevation,
  SetResultSortPercentage,
  SetResultSortPerformance,
  SetResultSortPoints,
  SetResultSortPosition,
  SetResultSortRankingCourse,
  SetResultSortTime,
  SetResultSortTrailName,
  SetUserParams,
} from './app-params.action';
import { AppParamsModel } from './app-params.model';

@State<AppParamsModel>({
  name: 'app_params',
  defaults: {
    calendarDistance: false,
    calendarDistanceMin: 0,
    calendarDistanceMax: 0,
    calendarElevation: false,
    calendarElevationMin: 0,
    calendarElevationMax: 0,
    calendarDrop: false,
    calendarDropMin: 0,
    calendarDropMax: 0,
    calendarRunType: false,
    calendarRunTypeFilter: [],
    calendarTitleFilter: '',
    calendarTitle: false,
    calendarCountry: false,
    calendarSelectedCountries: ALL_CALENDAR_COUNTRIES,
    calendarDate: true,
    calendarDateStart: getNowPlusNYears(0),
    calendarDateEnd: getNowPlusNYears(1),
    calendarCategory: false,
    calendarSelectedCategories: ['2', '3', '4', '5'],
    calendarRegion: true,
    calendarSelectedRegion: 'ALL',
    calendarDepartment: false,
    calendarSelectedDepartment: undefined,
    calendarGeolocation: false,
    calendarGeolocationCoordinates: undefined,
    calendarFiltersSummaries: { FILTER_DATE: '12_NEXT_MONTHS' },
    calendarMonth: false,
    defaultCountry: undefined,
    calendarCanceled: false,
    calendarCanceledValue: undefined,
    confirmationLater: false,
    avatarLater: false,
    resultSortDate: true,
    resultSortDistance: false,
    resultSortElevation: false,
    resultSortTime: false,
    resultSortPoints: false,
    resultSortPerformance: false,
    resultSortTrailName: false,
    resultSortPercentage: false,
    resultSortPosition: false,
    resultSortRankingCourse: false,
    resultSortDesc: false,
    resultFilterCountry: false,
    resultFilterSelectedCountry: [],
    resultFilterDistance: false,
    resultFilterSelectedDistance: [],
    resultFilterElevation: false,
    resultFilterSelectedElevation: [],
    resultFilterDrop: false,
    resultFilterSelectedDrop: [],
    resultFilterCategory: false,
    resultFilterSelectedCategory: [],
    resultFilterDate: false,
    resultFilterDateStart: new Date(getLastThursday().getTime() - 365 * 24 * 60 * 60 * 1000),
    resultFilterDateEnd: new Date(),
    resultFilterRunType: false,
    resultFilterSelectedRunType: [],
    resultFilterRaceType: false,
    resultFilterSelectedRaceType: [],
    displayFixedRaceDistanceSubPageMenu: false,
  },
})
@Injectable()
export class AppParamsState {
  constructor(private translate: TranslocoService) {}

  allCountries = ALL_COUNTRIES_CODES;
  otherCountries = ALL_COUNTRIES_CODES.filter(c => !COVERED_COUNTRIES.includes(c));

  @Selector()
  static resultFilterCountry(state: AppParamsModel): boolean {
    return state.resultFilterCountry;
  }

  @Selector()
  static resultFilterSelectedCountry(state: AppParamsModel): string[] {
    return state.resultFilterSelectedCountry;
  }

  @Selector()
  static resultFilterDistance(state: AppParamsModel): boolean {
    return state.resultFilterDistance;
  }

  @Selector()
  static resultFilterSelectedDistance(state: AppParamsModel): number[] {
    return state.resultFilterSelectedDistance;
  }

  @Selector()
  static resultFilterElevation(state: AppParamsModel): boolean {
    return state.resultFilterElevation;
  }

  @Selector()
  static resultFilterSelectedElevation(state: AppParamsModel): number[] {
    return state.resultFilterSelectedElevation;
  }

  @Selector()
  static resultFilterDrop(state: AppParamsModel): boolean {
    return state.resultFilterDrop;
  }

  @Selector()
  static resultFilterSelectedDrop(state: AppParamsModel): number[] {
    return state.resultFilterSelectedDrop;
  }

  @Selector()
  static resultFilterCategory(state: AppParamsModel): boolean {
    return state.resultFilterCategory;
  }

  @Selector()
  static resultFilterSelectedCategory(state: AppParamsModel): string[] {
    return state.resultFilterSelectedCategory;
  }

  @Selector()
  static resultFilterDate(state: AppParamsModel): boolean {
    return state.resultFilterDate;
  }

  @Selector()
  static resultFilterDateStart(state: AppParamsModel): number | Date {
    return state.resultFilterDateStart;
  }

  @Selector()
  static resultFilterDateEnd(state: AppParamsModel): number | Date {
    return state.resultFilterDateEnd;
  }

  @Selector()
  static resultFilterRunType(state: AppParamsModel): boolean {
    return state.resultFilterRunType;
  }

  @Selector()
  static resultFilterSelectedRunType(state: AppParamsModel): string[] {
    return state.resultFilterSelectedRunType;
  }

  @Selector()
  static calendarFilterRunType(state: AppParamsModel): boolean {
    return state.calendarRunType;
  }

  @Selector()
  static calendarFilterSelectedRunType(state: AppParamsModel): string[] {
    return state.calendarRunTypeFilter;
  }

  @Selector()
  static resultFilterRaceType(state: AppParamsModel): boolean {
    return state.resultFilterRaceType;
  }

  @Selector()
  static resultFilterSelectedRaceType(state: AppParamsModel): string[] {
    return state.resultFilterSelectedRaceType;
  }

  @Selector()
  static resultSortDesc(state: AppParamsModel): boolean {
    return state.resultSortDesc;
  }

  @Selector()
  static resultSortDate(state: AppParamsModel): boolean {
    return state.resultSortDate;
  }

  @Selector()
  static resultSortDistance(state: AppParamsModel): boolean {
    return state.resultSortDistance;
  }

  @Selector()
  static resultSortElevation(state: AppParamsModel): boolean {
    return state.resultSortElevation;
  }

  @Selector()
  static resultSortTime(state: AppParamsModel): boolean {
    return state.resultSortTime;
  }

  @Selector()
  static resultSortPoints(state: AppParamsModel): boolean {
    return state.resultSortPoints;
  }

  @Selector()
  static resultSortPerformance(state: AppParamsModel): boolean {
    return state.resultSortPerformance;
  }

  @Selector()
  static resultSortTrailName(state: AppParamsModel): boolean {
    return state.resultSortTrailName;
  }

  @Selector()
  static resultSortPercentage(state: AppParamsModel): boolean {
    return state.resultSortPercentage;
  }

  @Selector()
  static resultSortPosition(state: AppParamsModel): boolean {
    return state.resultSortPosition;
  }

  @Selector()
  static resultSortRankingCourse(state: AppParamsModel): boolean {
    return state.resultSortRankingCourse;
  }

  @Selector()
  static calendarDistance(state: AppParamsModel): boolean {
    return state.calendarDistance;
  }

  @Selector()
  static calendarDistanceMin(state: AppParamsModel): number {
    return state.calendarDistanceMin;
  }

  @Selector()
  static calendarDistanceMax(state: AppParamsModel): number {
    return state.calendarDistanceMax;
  }

  @Selector()
  static calendarElevation(state: AppParamsModel): boolean {
    return state.calendarElevation;
  }

  @Selector()
  static calendarElevationMin(state: AppParamsModel): number {
    return state.calendarElevationMin;
  }

  @Selector()
  static calendarElevationMax(state: AppParamsModel): number {
    return state.calendarElevationMax;
  }

  @Selector()
  static calendarDrop(state: AppParamsModel): boolean {
    return state.calendarDrop;
  }

  @Selector()
  static calendarDropMin(state: AppParamsModel): number {
    return state.calendarDropMin;
  }

  @Selector()
  static calendarDropMax(state: AppParamsModel): number {
    return state.calendarDropMax;
  }

  @Selector()
  static calendarTitleFilter(state: AppParamsModel): string {
    return state.calendarTitleFilter;
  }

  @Selector()
  static calendarTitle(state: AppParamsModel): boolean {
    return state.calendarTitle;
  }

  @Selector()
  static calendarCountry(state: AppParamsModel): boolean {
    return state.calendarCountry;
  }

  @Selector()
  static calendarSelectedCountries(state: AppParamsModel): string[] {
    return state.calendarSelectedCountries;
  }

  @Selector()
  static calendarDate(state: AppParamsModel): boolean {
    return state.calendarDate;
  }

  @Selector()
  static calendarDateStart(state: AppParamsModel): number | Date {
    return state.calendarDateStart;
  }

  @Selector()
  static calendarDateEnd(state: AppParamsModel): number | Date {
    return state.calendarDateEnd;
  }

  @Selector()
  static calendarCategory(state: AppParamsModel): boolean {
    return state.calendarCategory;
  }

  @Selector()
  static calendarSelectedCategories(state: AppParamsModel): string[] {
    return state.calendarSelectedCategories;
  }

  @Selector()
  static calendarCanceledValue(state: AppParamsModel): string[] {
    return state.calendarCanceledValue;
  }

  @Selector()
  static calendarCanceled(state: AppParamsModel): boolean {
    return state.calendarCanceled;
  }

  @Selector()
  static calendarRegion(state: AppParamsModel): boolean {
    return state.calendarRegion;
  }

  @Selector()
  static calendarSelectedRegion(state: AppParamsModel): string {
    return state.calendarSelectedRegion;
  }

  @Selector()
  static calendarDepartment(state: AppParamsModel): boolean {
    return state.calendarDepartment;
  }

  @Selector()
  static calendarSelectedDepartment(state: AppParamsModel): string {
    return state.calendarSelectedDepartment;
  }

  @Selector()
  static calendarGeolocation(state: AppParamsModel): boolean {
    return state.calendarGeolocation;
  }

  @Selector()
  static calendarGeolocationCoordinates(state: AppParamsModel): { lat: number | string; lon: number | string } {
    return state.calendarGeolocationCoordinates;
  }

  @Selector()
  static calendarFiltersSummaries(state: AppParamsModel) {
    let summaries = {};
    Object.keys(state.calendarFiltersSummaries).map(
      key => (summaries[key] = state.calendarFiltersSummaries[key].split(',').map(el => el.trim())),
    );
    return summaries;
  }

  @Selector()
  static calendarMonth(state: AppParamsModel): boolean {
    return state.calendarMonth;
  }

  @Selector()
  static defaultCountry(state: AppParamsModel): string {
    return state.defaultCountry;
  }

  @Selector()
  static avatarLater(state: AppParamsModel): boolean {
    return state.avatarLater;
  }

  @Selector()
  static confirmationLater(state: AppParamsModel): boolean {
    return state.confirmationLater;
  }

  @Selector()
  static displayFixedRaceDistanceSubPageMenu(state: AppParamsModel): boolean {
    return state.displayFixedRaceDistanceSubPageMenu;
  }

  @Action(SetCalendarDistance)
  setCalendarDistance(ctx: StateContext<AppParamsModel>, action: SetCalendarDistance) {
    ctx.patchState({
      calendarDistance: action.calendarDistance,
    });
  }

  @Action(SetCalendarDistanceMin)
  setCalendarDistanceMin(ctx: StateContext<AppParamsModel>, action: SetCalendarDistanceMin) {
    ctx.patchState({
      calendarDistanceMin: action.calendarDistanceMin,
    });
  }

  @Action(SetCalendarDistanceMax)
  setCalendarDistanceMax(ctx: StateContext<AppParamsModel>, action: SetCalendarDistanceMax) {
    ctx.patchState({
      calendarDistanceMax: action.calendarDistanceMax,
    });
  }

  @Action(SetCalendarElevation)
  setCalendarElevation(ctx: StateContext<AppParamsModel>, action: SetCalendarElevation) {
    ctx.patchState({
      calendarElevation: action.calendarElevation,
    });
  }
  @Action(SetCalendarElevationMin)
  setCalendarElevationMin(ctx: StateContext<AppParamsModel>, action: SetCalendarElevationMin) {
    ctx.patchState({
      calendarElevationMin: action.calendarElevationMin,
    });
  }

  @Action(SetCalendarElevationMax)
  setCalendarElevationMax(ctx: StateContext<AppParamsModel>, action: SetCalendarElevationMax) {
    ctx.patchState({
      calendarElevationMax: action.calendarElevationMax,
    });
  }

  @Action(SetCalendarDrop)
  setCalendarDrop(ctx: StateContext<AppParamsModel>, action: SetCalendarDrop) {
    ctx.patchState({
      calendarDrop: action.calendarDrop,
    });
  }
  @Action(SetCalendarDropMin)
  setCalendarDropMin(ctx: StateContext<AppParamsModel>, action: SetCalendarDropMin) {
    ctx.patchState({
      calendarDropMin: action.calendarDropMin,
    });
  }

  @Action(SetCalendarDropMax)
  setCalendarDropMax(ctx: StateContext<AppParamsModel>, action: SetCalendarDropMax) {
    ctx.patchState({
      calendarDropMax: action.calendarDropMax,
    });
  }

  @Action(SetCalendarTitle)
  setCalendarTitle(ctx: StateContext<AppParamsModel>, action: SetCalendarTitle) {
    ctx.patchState({
      calendarTitle: action.calendarTitle,
    });
  }

  @Action(SetCalendarTitleFilter)
  setCalendarTitleFilter(ctx: StateContext<AppParamsModel>, action: SetCalendarTitleFilter) {
    ctx.patchState({
      calendarTitleFilter: action.title,
    });
  }

  @Action(SetCalendarSelectedCountry)
  setCalendarSelectedCountry(ctx: StateContext<AppParamsModel>, action: SetCalendarSelectedCountry) {
    if (action.calendarSelectedCountry) {
      const selectedCountries = this.toCountryCodeArray(action.calendarSelectedCountry);
      let summary;
      if (selectedCountries.length == 1) {
        summary = 'COUNTRY_' + selectedCountries[0];
      } else if (selectedCountries.length == this.allCountries.length) {
        summary = 'COUNTRY_ALL';
      } else if (action.calendarSelectedCountry == 'others') {
        summary = 'COUNTRY_OTHERS';
      } else {
        summary = selectedCountries.join(', ');
      }
      let toPatch: any = {
        calendarCountry: true,
        calendarSelectedCountries: selectedCountries,
        calendarFiltersSummaries: { ...ctx.getState().calendarFiltersSummaries, FILTER_COUNTRY: summary },
      };
      if (summary === 'COUNTRY_FR' || summary === 'COUNTRY_BE') {
        const country = summary === 'COUNTRY_FR' ? 'FR' : 'BE';
        toPatch.calendarFiltersSummaries['FILTER_REGION_' + country] = 'ALL' + (country === 'BE' ? '_FEM' : '');
        toPatch.calendarRegion = true;
      }
      // if going to another specific country
      if (selectedCountries.length != this.allCountries.length) {
        toPatch.calendarGeolocation = false;

        // if not going to FR
        if (selectedCountries.indexOf('FR') < 0 && selectedCountries.indexOf('BE') < 0) {
          toPatch.calendarRegion = false;
          toPatch.calendarDepartment = false;
        }
      }
      ctx.patchState(toPatch);
    }
  }

  @Action(SetCalendarCountry)
  setCalendarCountry(ctx: StateContext<AppParamsModel>, action: SetCalendarCountry) {
    ctx.patchState({
      calendarCountry: action.calendarCountry,
    });
  }

  @Action(SetCalendarMonth)
  setCalendarMonth(ctx: StateContext<AppParamsModel>, action: SetCalendarMonth) {
    if (action.dates) {
      const dates = action.dates;
      const date = new Date();
      date.setTime(dates.start);
      ctx.setState(
        produce(draft => {
          draft.calendarDate = true;
          draft.calendarDateStart = dates.start;
          draft.calendarDateEnd = dates.end;
          if (!draft.calendarFiltersSummaries) {
            draft.calendarFiltersSummaries = {};
          }
          draft.calendarFiltersSummaries.FILTER_DATE =
            this.translate.translate(dates.summary) + ' ' + date.getFullYear();
          draft.calendarMonth = true;
        }),
      );
    }
  }

  @Action(SetCalendarSelectedCountries)
  setCalendarSelectedCountries(ctx: StateContext<AppParamsModel>, action: SetCalendarSelectedCountries) {
    let summary;
    if (action.selectedCountries.length == 1) {
      summary = 'COUNTRY_' + action.selectedCountries[0];
    } else if (action.selectedCountries.length == this.allCountries.length) {
      summary = 'COUNTRY_ALL';
    } else {
      summary = action.selectedCountries.join(', ');
    }
    let toPatch: any = {
      calendarCountry: true,
      calendarSelectedCountries: action.selectedCountries,
      calendarFiltersSummaries: { ...ctx.getState().calendarFiltersSummaries, FILTER_COUNTRY: summary },
    };
    ctx.patchState(toPatch);
  }

  @Action(SetCalendarCanceled)
  setCalendarCanceled(ctx: StateContext<AppParamsModel>, action: SetCalendarCanceled) {
    ctx.patchState({
      calendarCanceled: action.calendarCanceled,
    });
  }

  @Action(SetCalendarCategory)
  setCalendarCategory(ctx: StateContext<AppParamsModel>, action: SetCalendarCategory) {
    ctx.patchState({
      calendarCategory: action.calendarCategory,
    });
  }

  @Action(SetCalendarCanceledValue)
  setCalendarCanceledValue(ctx: StateContext<AppParamsModel>, action: SetCalendarCanceledValue) {
    ctx.patchState({
      calendarCanceledValue: action.canceled,
    });
  }

  @Action(SetCalendarSelectedCategories)
  setCalendarSelectedCategories(ctx: StateContext<AppParamsModel>, action: SetCalendarSelectedCategories) {
    ctx.patchState({
      calendarSelectedCategories: action.selectedCategories,
    });
  }

  @Action(SetCalendarRegion)
  setCalendarRegion(ctx: StateContext<AppParamsModel>, action: SetCalendarRegion) {
    ctx.patchState({
      calendarRegion: action.calendarRegion,
    });
  }

  @Action(SetCalendarDepartment)
  setCalendarDepartment(ctx: StateContext<AppParamsModel>, action: SetCalendarDepartment) {
    ctx.patchState({
      calendarDepartment: action.calendarDepartment,
    });
  }

  @Action(SetCalendarSelectedRegion)
  setCalendarSelectedRegion(ctx: StateContext<AppParamsModel>, action: SetCalendarSelectedRegion) {
    const state = ctx.getState();
    const country = state.calendarSelectedCountries[0];
    ctx.patchState({
      calendarSelectedRegion: action.region,
      calendarFiltersSummaries: {
        ...ctx.getState().calendarFiltersSummaries,
        ['FILTER_REGION_' + country]: action.region === 'ALL' ? 'ALL' : getRegionTitleFromCode(action.region),
      },
    });
  }

  @Action(SetCalendarSelectedDepartment)
  setCalendarSelectedDepartment(ctx: StateContext<AppParamsModel>, action: SetCalendarSelectedDepartment) {
    const state = ctx.getState();
    const country = state.calendarSelectedCountries[0];
    ctx.patchState({
      calendarSelectedDepartment: action.department,
      calendarFiltersSummaries: {
        ...ctx.getState().calendarFiltersSummaries,
        ['FILTER_REGION_' + country]: action.department
          ? getDepartmentTitleFromCode(action.department)
          : ctx.getState().calendarFiltersSummaries['FILTER_REGION_' + country],
      },
    });
  }

  @Action(SetCalendarGeolocation)
  setCalendarGeolocation(ctx: StateContext<AppParamsModel>, action: SetCalendarGeolocation) {
    ctx.patchState({
      calendarGeolocation: action.calendarGeolocation,
    });
  }

  @Action(SetCalendarGeolocationCoordinates)
  setCalendarGeolocationCoordinates(ctx: StateContext<AppParamsModel>, action: SetCalendarGeolocationCoordinates) {
    if (action.calendarGeolocationCoordinates && action.calendarGeolocationCoordinates[2]) {
      ctx.patchState({
        calendarGeolocationCoordinates: action.calendarGeolocationCoordinates,
        calendarFiltersSummaries: {
          ...ctx.getState().calendarFiltersSummaries,
          FILTER_GEOLOCATION: action.calendarGeolocationCoordinates[2],
        },
      });
    } else {
      ctx.patchState({
        calendarGeolocationCoordinates: action.calendarGeolocationCoordinates,
      });
    }
  }

  @Action(SetCalendarDate)
  setCalendarDate(ctx: StateContext<AppParamsModel>, action: SetCalendarDate) {
    ctx.patchState({
      calendarDate: action.calendarDate,
      calendarMonth: false,
    });
  }
  @Action(SetCalendarDateStart)
  SetCalendarDateStart(ctx: StateContext<AppParamsModel>, action: SetCalendarDateStart) {
    ctx.patchState({
      calendarDateStart: action.calendarDateStart,
    });
  }

  @Action(SetCalendarDateEnd)
  SetCalendarDateEnd(ctx: StateContext<AppParamsModel>, action: SetCalendarDateEnd) {
    ctx.patchState({
      calendarDateEnd: action.calendarDateEnd,
    });
  }

  @Action(SetCalendarFilterSummary)
  SetCalendarFilterSummary(ctx: StateContext<AppParamsModel>, action: SetCalendarFilterSummary) {
    ctx.setState(
      produce(draft => {
        draft.calendarFiltersSummaries[action.key] = action.summary;
      }),
    );
  }

  @Action(SetDefaultCountry)
  setDefaultCountry(ctx: StateContext<AppParamsModel>, action: SetDefaultCountry) {
    ctx.patchState({
      defaultCountry: action.defaultCountry,
    });
  }

  @Action(SetConfirmationLater)
  setConfirmationLater(ctx: StateContext<AppParamsModel>, action: SetConfirmationLater) {
    ctx.patchState({
      confirmationLater: action.confirmationLater,
    });
  }

  @Action(SetAvatarLater)
  setAvatarLater(ctx: StateContext<AppParamsModel>, action: SetAvatarLater) {
    ctx.patchState({
      avatarLater: action.avatarLater,
    });
  }

  @Action(SetUserParams)
  setUserParams(ctx: StateContext<AppParamsModel>, action: SetUserParams) {
    if (action.userParams.defaultCountry)
      ctx.patchState({
        defaultCountry: action.userParams.defaultCountry,
      });
  }

  @Action(SetResultSortDate)
  setResultSortDate(ctx: StateContext<AppParamsModel>) {
    ctx.patchState(this.setResultSort('resultSortDate'));
  }

  @Action(SetResultSortDistance)
  setResultSortDistance(ctx: StateContext<AppParamsModel>) {
    ctx.patchState(this.setResultSort('resultSortDistance'));
  }

  @Action(SetResultSortElevation)
  setResultSortElevation(ctx: StateContext<AppParamsModel>) {
    ctx.patchState(this.setResultSort('resultSortElevation'));
  }

  @Action(SetResultSortTime)
  setResultSortTime(ctx: StateContext<AppParamsModel>) {
    ctx.patchState(this.setResultSort('resultSortTime'));
  }

  @Action(SetResultSortPoints)
  setResultSortPoints(ctx: StateContext<AppParamsModel>) {
    ctx.patchState(this.setResultSort('resultSortPoints'));
  }

  @Action(SetResultSortPerformance)
  setResultSortPerformance(ctx: StateContext<AppParamsModel>) {
    ctx.patchState(this.setResultSort('resultSortPerformance'));
  }

  @Action(SetResultSortTrailName)
  setResultSortTrailName(ctx: StateContext<AppParamsModel>) {
    ctx.patchState(this.setResultSort('resultSortTrailName'));
  }

  @Action(SetResultSortPercentage)
  setResultSortPercentage(ctx: StateContext<AppParamsModel>) {
    ctx.patchState(this.setResultSort('resultSortPercentage'));
  }

  @Action(SetResultSortPosition)
  setResultSortPosition(ctx: StateContext<AppParamsModel>) {
    ctx.patchState(this.setResultSort('resultSortPosition'));
  }

  @Action(SetResultSortRankingCourse)
  setResultSortRankingCourse(ctx: StateContext<AppParamsModel>) {
    ctx.patchState(this.setResultSort('resultSortRankingCourse'));
  }

  @Action(SetResultSortDesc)
  setResultSortDesc(ctx: StateContext<AppParamsModel>, action: SetResultSortDesc) {
    ctx.patchState({ resultSortDesc: action.isDesc });
  }

  @Action(SetResultFilterCountry)
  setResultFilterCountry(ctx: StateContext<AppParamsModel>, action: SetResultFilterCountry) {
    ctx.patchState({ resultFilterCountry: action.resultFilterCountry });
  }

  @Action(SetResultFilterSelectedCountry)
  setResultFilterSelectedCountry(ctx: StateContext<AppParamsModel>, action: SetResultFilterSelectedCountry) {
    ctx.patchState({ resultFilterSelectedCountry: action.resultFilterSelectedCountry });
  }

  @Action(SetResultFilterDistance)
  setResultFilterDistance(ctx: StateContext<AppParamsModel>, action: SetResultFilterDistance) {
    ctx.patchState({ resultFilterDistance: action.resultFilterDistance });
  }

  @Action(SetResultFilterSelectedDistance)
  setResultFilterSelectedDistance(ctx: StateContext<AppParamsModel>, action: SetResultFilterSelectedDistance) {
    ctx.patchState({ resultFilterSelectedDistance: action.resultFilterSelectedDistance });
  }

  @Action(SetResultFilterElevation)
  setResultFilterElevation(ctx: StateContext<AppParamsModel>, action: SetResultFilterElevation) {
    ctx.patchState({ resultFilterElevation: action.resultFilterElevation });
  }

  @Action(SetResultFilterSelectedElevation)
  setResultFilterSelectedElevation(ctx: StateContext<AppParamsModel>, action: SetResultFilterSelectedElevation) {
    ctx.patchState({ resultFilterSelectedElevation: action.resultFilterSelectedElevation });
  }

  @Action(SetResultFilterDrop)
  setResultFilterDrop(ctx: StateContext<AppParamsModel>, action: SetResultFilterDrop) {
    ctx.patchState({ resultFilterDrop: action.resultFilterDrop });
  }

  @Action(SetResultFilterSelectedDrop)
  setResultFilterSelectedDrop(ctx: StateContext<AppParamsModel>, action: SetResultFilterSelectedDrop) {
    ctx.patchState({ resultFilterSelectedDrop: action.resultFilterSelectedDrop });
  }

  @Action(SetResultFilterCategory)
  setResultFilterCategory(ctx: StateContext<AppParamsModel>, action: SetResultFilterCategory) {
    ctx.patchState({ resultFilterCategory: action.resultFilterCategory });
  }

  @Action(SetResultFilterSelectedCategory)
  setResultFilterSelectedCategory(ctx: StateContext<AppParamsModel>, action: SetResultFilterSelectedCategory) {
    ctx.patchState({ resultFilterSelectedCategory: action.resultFilterSelectedCategory });
  }

  @Action(SetResultFilterDate)
  setResultFilterDate(ctx: StateContext<AppParamsModel>, action: SetResultFilterDate) {
    ctx.patchState({ resultFilterDate: action.resultFilterDate });
  }

  @Action(SetResultFilterDateStart)
  setResultFilterDateStart(ctx: StateContext<AppParamsModel>, action: SetResultFilterDateStart) {
    ctx.patchState({ resultFilterDateStart: action.resultFilterDateStart });
  }

  @Action(SetResultFilterDateEnd)
  setResultFilterDateEnd(ctx: StateContext<AppParamsModel>, action: SetResultFilterDateEnd) {
    ctx.patchState({ resultFilterDateEnd: action.resultFilterDateEnd });
  }

  @Action(SetResultFilterRunType)
  setResultFilterRunType(ctx: StateContext<AppParamsModel>, action: SetResultFilterRunType) {
    ctx.patchState({ resultFilterRunType: action.resultFilterRunType });
  }

  @Action(SetResultFilterSelectedRunType)
  setResultFilterSelectedRunType(ctx: StateContext<AppParamsModel>, action: SetResultFilterSelectedRunType) {
    ctx.patchState({ resultFilterSelectedRunType: action.resultFilterSelectedRunType });
  }

  @Action(SetCalendarFilterRunType)
  setCalendarFilterRunType(ctx: StateContext<AppParamsModel>, action: SetCalendarFilterRunType) {
    ctx.patchState({ calendarRunType: action.calendarFilterRunType });
  }

  @Action(SetCalendarFilterSelectedRunType)
  setCalendarFilterSelectedRunType(ctx: StateContext<AppParamsModel>, action: SetCalendarFilterSelectedRunType) {
    ctx.patchState({ calendarRunTypeFilter: action.calendarFilterSelectedRunType });
  }

  @Action(SetResultFilterRaceType)
  setResultFilterRaceType(ctx: StateContext<AppParamsModel>, action: SetResultFilterRaceType) {
    ctx.patchState({ resultFilterRaceType: action.resultFilterRaceType });
  }

  @Action(SetResultFilterSelectedRaceType)
  setResultFilterSelectedRaceType(ctx: StateContext<AppParamsModel>, action: SetResultFilterSelectedRaceType) {
    ctx.patchState({ resultFilterSelectedRaceType: action.resultFilterSelectedRaceType });
  }

  @Action(SetDisplayFixedRaceDistanceSubPageMenu)
  setDisplayFixedRaceDistanceSubPageMenu(
    ctx: StateContext<AppParamsModel>,
    action: SetDisplayFixedRaceDistanceSubPageMenu,
  ) {
    ctx.patchState({ displayFixedRaceDistanceSubPageMenu: action.displayFixedRaceDistanceSubPageMenu });
  }

  setResultSort(field) {
    let resultSortParams = {
      resultSortDate: false,
      resultSortDistance: false,
      resultSortElevation: false,
      resultSortTime: false,
      resultSortPoints: false,
      resultSortPerformance: false,
      resultSortTrailName: false,
      resultSortPercentage: false,
      resultSortPosition: false,
      resultSortRankingCourse: false,
    };
    resultSortParams[field] = true;
    return resultSortParams;
  }

  toCountryCodeArray(key) {
    if (COUNTRIES_TRANSLATIONS[key]) {
      return [COUNTRIES_TRANSLATIONS[key]];
    }
    if (key == 'others') {
      return this.otherCountries;
    }
    return this.allCountries;
  }
}
