import { Component, Input, OnInit } from '@angular/core';
import { AuthStateService } from '@betrail-libs/auth-state';
import { IRace } from '@betrail-libs/shared/interfaces/race.model';
import {
  AddUserRunnerRegistration,
  RemoveUserRunnerRegistration,
  TrailDataState,
} from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'add-to-calendar-button',
  templateUrl: './add-to-calendar-button.component.html',
  styleUrls: ['./add-to-calendar-button.component.scss'],
})
export class AddToCalendarButtonComponent implements OnInit {
  now = new Date().getTime() / 1000;
  displayRemoveButton = false;
  loading = false;

  @Select(TrailDataState.selectUserRunnerRegistrations) userRegistrations$!: Observable<any>;
  user$ = this.authState.getUser();

  @Input() race: IRace;
  @Input() type: 'public' | 'private';
  @Input() ruid?: string | number;

  constructor(private store: Store, private authState: AuthStateService) {}

  ngOnInit() {
    this.userRegistrations$.pipe(untilDestroyed(this)).subscribe(() => {
      this.loading = false;
    });
  }

  toggleDisplayRemoveButton() {
    this.displayRemoveButton = !this.displayRemoveButton;
  }

  addToCalendar(race: IRace) {
    this.loading = true;
    this.store.dispatch(new AddUserRunnerRegistration(race));
  }

  removeFromCalendar(race: IRace) {
    this.loading = true;
    this.store.dispatch(new RemoveUserRunnerRegistration(race));
  }
}
