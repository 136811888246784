import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DateTime } from 'luxon';

@Pipe({
  name: 'luxonDate',
})
export class LuxonDatePipe implements PipeTransform {
  constructor(private translation: TranslocoService) {}

  transform(value: number, format?: string, timezone?: string): string {
    return DateTime.fromSeconds(value, { zone: timezone ?? 'Europe/Brussels' })
      .setLocale(this.translation.getActiveLang())
      .toFormat(format ?? 'full');
  }
}
