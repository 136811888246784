import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITrail } from '@betrail-libs/shared/interfaces/interfaces';
import { AuthStateService } from '@betrail-libs/auth-state';

@Component({
  selector: 'app-link-or-create',
  templateUrl: './link-or-create.component.html',
  styleUrls: ['./link-or-create.component.scss', '../common.scss'],
})
export class LinkOrCreateComponent {
  constructor(private authState: AuthStateService) {}

  @Input() trail: ITrail;
  user$ = this.authState.getUser();

  @Output() linkAccountClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() newAccountClicked: EventEmitter<any> = new EventEmitter<any>();

  linkAccount() {
    this.linkAccountClicked.emit();
  }

  newAccount() {
    this.newAccountClicked.emit();
  }
}
