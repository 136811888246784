import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IAppConfig } from '@betrail-libs/shared/interfaces/app-config.model';
import { IUserState } from '@betrail-libs/shared/interfaces/auth.model';
import { APP_CONFIG } from '../config-token';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  #config = inject<IAppConfig>(APP_CONFIG);
  #http = inject(HttpClient);

  login = (user: string, password: string) => {
    if (user === undefined) {
      throw { errorMsg: 'User not defined' };
    }
    if (password === undefined) {
      throw { errorMsg: 'Password not defined' };
    }
    const headers = new HttpHeaders();
    // headers.append('X-Requested-With', 'XMLHttpRequest');
    return this.#http.post<{ status: string }>(
      this.#config.nodejsApi + '/user/login',
      { username: user, password: password },
      { headers: headers, withCredentials: true },
    );
  };

  logout = () =>
    this.#http.post<{ status: string }>(this.#config.nodejsApi + '/user/logout', {}, { withCredentials: true });

  masquerade = (uid: number) =>
    this.#http.post<{ status: string }>(
      this.#config.nodejsApi + '/user/masquerade',
      { uid },
      { withCredentials: true },
    );

  getUserFromSession = (activateRunner?: boolean) =>
    this.#http.get<IUserState>(this.#config.nodejsApi + '/user/profile' + (activateRunner ? '/activate' : ''), {
      withCredentials: true,
    });

  updateUserAddsPreferences = (uid: number, hide_other_banner: boolean) =>
    this.#http.put<{ hide_other_banner: boolean }>(
      `${this.#config.nodejsApi}/user/adds/${uid}`,
      { hide_other_banner },
      { withCredentials: true },
    );

  updateUserLanguage = (uid: number, language: 'fr' | 'nl' | 'en') =>
    this.#http.put<{ language: 'fr' | 'nl' | 'en' }>(
      `${this.#config.nodejsApi}/user/lang/${uid}`,
      { language },
      { withCredentials: true },
    );

  updateUserMailPreferences = (
    uid: number,
    data: { mail: string; mail_newsletter: boolean; mail_notification: boolean },
  ) =>
    this.#http.put<{ mail: string; mail_newsletter: boolean; mail_notification: boolean }>(
      `${this.#config.nodejsApi}/user/mail/${uid}`,
      data,
      { withCredentials: true },
    );

  updateUserPassword = (uid: number, data: { current_pass: string; new_pass: string }) =>
    this.#http.put<{ status: string }>(`${this.#config.nodejsApi}/user/pass/${uid}`, data, { withCredentials: true });
}
