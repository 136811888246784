import { Injectable } from '@angular/core';
import {
  SetResultSortDate,
  SetResultSortDesc,
  SetResultSortDistance,
  SetResultSortElevation,
  SetResultSortPercentage,
  SetResultSortPerformance,
  SetResultSortPoints,
  SetResultSortPosition,
  SetResultSortTime,
  SetResultSortTrailName,
} from '@betrail-libs/app-params-state';
import { Store } from '@ngxs/store';
import { AppParamsModel } from './app-params.model';

interface StateWithAppParams {
  app_params: AppParamsModel;
}

@Injectable({
  providedIn: 'root',
})
export class AppParamsService {
  constructor(private store: Store) {}

  getAppParamsSnapshot = () => this.store.selectSnapshot((state: StateWithAppParams) => state.app_params);

  getDefaultCountryFilter = () => {
    const country = this.getAppParamsSnapshot().defaultCountry;
    if (country === 'BE' || country === 'FR' || country === 'LU' || country === 'NL') {
      return country;
    }
    return 'ALL';
  };

  changeSortOrder = (desc: boolean) => {
    this.store.dispatch(new SetResultSortDesc(desc));
  };

  dispatchSortResults = (dataToSort: string) => {
    switch (dataToSort) {
      case 'POS':
        this.store.dispatch(new SetResultSortPosition());
        break;
      case 'RANK':
        this.store.dispatch(new SetResultSortPercentage());
        break;
      case 'NAME':
        this.store.dispatch(new SetResultSortTrailName());
        break;
      case 'DATE':
        this.store.dispatch(new SetResultSortDate());
        break;
      case 'DIST':
        this.store.dispatch(new SetResultSortDistance());
        break;
      case 'ELEV':
        this.store.dispatch(new SetResultSortElevation());
        break;
      case 'TIME':
        this.store.dispatch(new SetResultSortTime());
        break;
      case 'PTS':
        this.store.dispatch(new SetResultSortPoints());
        break;
      case 'PERF':
        this.store.dispatch(new SetResultSortPerformance());
        break;
      default:
        this.store.dispatch(new SetResultSortDate());
        break;
    }
  };
}
