import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IPath } from '@betrail-libs/shared/interfaces/path.model';
import {
  formatPathToRace,
  getRunnerHighlightedResults,
  LEVEL_RANKING_DISTANCES,
  LEVEL_RANKING_ELEVATIONS,
  toHMS,
} from '@betrail-libs/shared/utils';
import { ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import produce from 'immer';
import { IRegistration } from 'libs/types';
import { of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { IEvent } from '../../shared/interfaces/event.model';
import { IOtherResult, IResult } from '../../shared/interfaces/result.model';
import { IRunner } from '../../shared/interfaces/runner.model';
import { Sponsor } from '../../shared/interfaces/sponsor.model';
import { RaceApiService } from './api/race-api.service';
import { RegistrationApiService } from './api/registration-api.service';
import { RunnerApiService } from './api/runner-api.service';
import { EventService } from './event.service';
import {
  AddSignupData,
  AddUserRunnerRegistration,
  AddUserToOrganization,
  ClaimEvent,
  CreateBackyardDistances,
  CreateNewDistance,
  CreateNewResult,
  CreateNewSponsor,
  DeleteRaceById,
  DeleteResultById,
  DeleteSponsor,
  EditAllowTrackingValue,
  EditRaceData,
  EditResult,
  editResultTag,
  LoadAllSponsors,
  loadCloseFutureEvents,
  loadEvents,
  loadEventsPacket,
  loadFutureEvents,
  LoadLevelByCategoriesForRunnerId,
  loadMatchupRunnerById,
  LoadOrganizationForAlias,
  loadOtherResultsForRaceId,
  loadOtherResultsForRunnerId,
  loadPathForRaceId,
  loadPathsForRaceId,
  loadRegistrationsForEventId,
  loadRegistrationsForRaceId,
  LoadRegistrationsForRunnerId,
  loadResultsForRaceId,
  LoadResultsForRunnerId,
  loadResultsPositionsForRunnerId,
  LoadRunnerById,
  LoadRunnerForAlias,
  LoadRunnersOfSponsor,
  loadScoresPositionsForRunnerId,
  LoadSignupRegistrationForSelectedEvent,
  loadSignupRegistrationsForEventId,
  loadSignupRegistrationsForEventIdWithRunners,
  loadSignupRegistrationsForRaceId,
  LoadSignupRegistrationWithRunner,
  LoadTrailForAlias,
  LoadUserRunnerRegistrations,
  LoadUserSponsors,
  MarkClaimedEvents,
  RemoveSponsorFromUser,
  RemoveUserRunnerRegistration,
  SelectDistanceAlias,
  SelectDistanceFromQueryParamsOrAlias,
  SelectEventAlias,
  SelectOrganizationAlias,
  SelectResultId,
  SelectRunnerAlias,
  SelectTrailAlias,
  SetCurrentUserRunnerId,
  SetSelectedDistanceSubPage,
  SetSelectedType,
  updatePathsForRaceId,
  UpdateRunnerById,
  UpdateRunnerExtraInfos,
  UpdateSponsorData,
} from './trail-data.action';
import { TrailDataModel } from './trail-data.model';

@State<TrailDataModel>({
  name: 'trailData',
  defaults: {
    trails: {},
    events: {},
    races: {},
    paths: {},
    runners: {},
    results: {},
    otherResults: {},
    registrations: {},
    signupRegistrations: {},
    organizations: {},
    sponsors: {},
    userSponsors: [],
    sponsoredRunners: {},
    trailAlias: {},
    eventAlias: {},
    raceAlias: {},
    runnerAlias: {},
    organizationAlias: {},
    resultIdsForDistance: {},
    otherResultIdsForDistance: {},
    resultIdsForRunner: {},
    otherResultIdsForRunner: {},
    registrationIdsForRunner: {},
    registrationIdsForRace: {},
    signupRegistrationIdsForRace: {},
    registrationIdsForEvent: {},
    signupRegistrationIdsForEvent: {},
    userRunnerRegistrations: {},
    selectedTrailAlias: undefined,
    selectedEventAlias: undefined,
    selectedDistanceAlias: undefined,
    selectedRunnerAlias: undefined,
    selectedOrganizationAlias: undefined,
    selectedResultId: undefined,
    selectedType: undefined,
    selectedDistanceSubPage: { route: 'results', title: 'RACE_MENU_RANKING' },
    currentUserRunnerId: undefined,
    selectedMatchupRunnerId: undefined,
    notification: [],
    runnerLevelRankings: {},
  },
})
@Injectable()
export class TrailDataState {
  #eventService = inject(EventService);
  #raceApi = inject(RaceApiService);
  #registrationApi = inject(RegistrationApiService);
  #runnerApi = inject(RunnerApiService);
  #route = inject(ActivatedRoute);
  #store = inject(Store);

  @Selector()
  static selectedResult(state: TrailDataModel) {
    return state.results[TrailDataState.selectSelectedResultId(state)];
  }

  @Selector()
  static selectedMatchupRunner(state: TrailDataModel) {
    return state.runners[state.selectedMatchupRunnerId];
  }

  @Selector()
  static selectedDistanceResults(state: TrailDataModel) {
    return state.resultIdsForDistance[TrailDataState.selectSelectedDistanceId(state)].map(id => state.results[id]);
  }

  @Selector()
  static selectedDistanceOtherResults(state: TrailDataModel) {
    return state.otherResultIdsForDistance[TrailDataState.selectSelectedDistanceId(state)].map(
      id => state.otherResults[id],
    );
  }

  @Selector()
  static selectedDistancePaths(state: TrailDataModel) {
    return Object.keys(state.paths)
      .map(id => state.paths[id])
      .filter((path: IPath) => path.raid === TrailDataState.selectSelectedDistanceId(state)) as IPath[];
  }

  @Selector()
  static selectedDistanceRegistrations(state: TrailDataModel) {
    return state.registrationIdsForRace[TrailDataState.selectSelectedDistanceId(state)].map(
      id => state.registrations[id],
    );
  }

  @Selector()
  static selectedEventRegistrations(state: TrailDataModel) {
    return state.registrationIdsForEvent[TrailDataState.selectSelectedEventId(state)].map(
      id => state.registrations[id],
    );
  }

  @Selector()
  static selectedDistanceSignupRegistrations(state: TrailDataModel) {
    return state.signupRegistrationIdsForRace[TrailDataState.selectSelectedDistanceId(state)].map(
      id => state.signupRegistrations[id],
    );
  }

  @Selector()
  static selectedEventSignupRegistrations(state: TrailDataModel) {
    return state.signupRegistrationIdsForEvent[TrailDataState.selectSelectedEventId(state)].map(
      id => state.signupRegistrations[id],
    );
  }

  @Selector()
  static selectedRunnerResults(state: TrailDataModel) {
    return state.resultIdsForRunner[TrailDataState.selectSelectedRunnerId(state)].map(
      (id: number) => state.results[id],
    ) as IResult[];
  }

  @Selector()
  static selectedRunnerOtherResults(state: TrailDataModel) {
    return state.otherResultIdsForRunner[TrailDataState.selectSelectedRunnerId(state)].map(
      (id: number) => state.otherResults[id],
    ) as IOtherResult[];
  }

  @Selector()
  static selectedRunnerHighlightedRaces(state: TrailDataModel) {
    const results = state.resultIdsForRunner[TrailDataState.selectSelectedRunnerId(state)].map(
      (id: number) => state.results[id],
    ) as IResult[];
    const otherResults = state.otherResultIdsForRunner[TrailDataState.selectSelectedRunnerId(state)].map(
      (id: number) => state.otherResults[id],
    ) as IOtherResult[];
    const runner = state.runners[state.runnerAlias[state.selectedRunnerAlias]];
    if (runner && +runner.is_premium === 1) {
      return getRunnerHighlightedResults([...results, ...otherResults]);
    } else {
      return getRunnerHighlightedResults(results);
    }
  }

  @Selector()
  static selectedRunnerRegistrations(state: TrailDataModel) {
    return state.registrationIdsForRunner[TrailDataState.selectSelectedRunnerId(state)].map(
      (id: number) => state.registrations[id],
    ) as IRegistration[];
  }

  @Selector()
  static currentUserRunner(state: TrailDataModel): IRunner {
    if (!isNaN(state.currentUserRunnerId)) {
      return state.runners[state.currentUserRunnerId];
    } else {
      return;
    }
  }

  @Selector()
  static selectTrails(state: TrailDataModel) {
    return state.trails;
  }

  @Selector()
  static selectSelectedResultId(state: TrailDataModel) {
    return state.selectedResultId;
  }

  @Selector()
  static selectSelectedDistanceId(state: TrailDataModel) {
    return state.raceAlias[state.selectedTrailAlias + state.selectedEventAlias + state.selectedDistanceAlias];
  }

  @Selector()
  static selectSelectedEventId(state: TrailDataModel) {
    return state.eventAlias[state.selectedTrailAlias + state.selectedEventAlias];
  }

  @Selector()
  static selectSelectedTrailId(state: TrailDataModel) {
    return state.trailAlias[state.selectedTrailAlias];
  }

  @Selector()
  static selectSelectedRunnerId(state: TrailDataModel) {
    if (isNaN(parseInt(state.selectedRunnerAlias))) {
      return state.runnerAlias[state.selectedRunnerAlias];
    } else {
      return state.selectedRunnerAlias;
    }
  }

  @Selector()
  static selectSelectedOrganizationId(state: TrailDataModel) {
    return state.organizationAlias[state.selectedOrganizationAlias];
  }

  @Selector()
  @ImmutableSelector()
  static selectedEventSimple(state: TrailDataModel) {
    try {
      const event = state?.events?.[state.eventAlias[state.selectedTrailAlias + state.selectedEventAlias]];
      return { ...event };
    } catch (e) {
      console.error(e);
    }
  }

  @Selector()
  static selectedEvent(state: TrailDataModel) {
    try {
      const event = TrailDataState.selectedEventSimple(state);
      if (event) {
        event.races = (event?.raceIds || []).map((id: number) => state?.races?.[id]);
        event.trail = state?.trails?.[event.trid];
      }
      return event;
    } catch (e) {
      console.error(e);
    }
  }

  @Selector()
  static selectedDistance(state: TrailDataModel) {
    return state.races[
      state.raceAlias[state.selectedTrailAlias + state.selectedEventAlias + state.selectedDistanceAlias]
    ];
  }

  @Selector()
  static selectedOrganization(state: TrailDataModel) {
    return produce(state.organizations[state.organizationAlias[state.selectedOrganizationAlias]], organization => {});
  }

  @Selector()
  static selectedTrail(state: TrailDataModel) {
    return this.trailByAlias(state, state.selectedTrailAlias);
  }

  static trailByAlias(state: TrailDataModel, alias: string) {
    let trail = { ...state.trails[state.trailAlias[alias]] };
    if (trail) {
      trail.events = (trail?.eventIds || []).map((id: number) => state?.events?.[id]);
    }
    return trail;
  }

  @Selector()
  static selectedType(state: TrailDataModel) {
    return state.selectedType;
  }

  @Selector()
  static selectedDistanceSubPage(state: TrailDataModel) {
    return state.selectedDistanceSubPage;
  }

  @Selector()
  static selectedRunner(state: TrailDataModel) {
    if (isNaN(parseInt(state.selectedRunnerAlias))) {
      return state.runners[state.runnerAlias[state.selectedRunnerAlias]];
    } else {
      return state.runners[parseInt(state.selectedRunnerAlias)];
    }
  }

  @Selector()
  static selectedRunnerLevelRankings(state: TrailDataModel) {
    const ruid = isNaN(+state.selectedRunnerAlias)
      ? state.runnerAlias[state.selectedRunnerAlias]
      : +state.selectedRunnerAlias;
    return state.runnerLevelRankings[ruid];
  }

  @Selector()
  static selectEvents(state: TrailDataModel): IEvent[] {
    let allEvents: IEvent[] = [];
    Object.keys(state.events).map((key, index) => {
      allEvents.push(state.events[key]);
    });
    return allEvents
      .map(ev => {
        let event = { ...ev };
        event.races = ev.raceIds.map(raid => state.races[raid]);
        event.trail = state.trails[ev.trid];
        delete event.raceIds;
        return event;
      })
      .sort((a, b) => a.date - b.date);
  }

  @Selector()
  static predictedEvents(state: TrailDataModel): IEvent[] {
    let predictedEvents: IEvent[] = [];
    Object.keys(state.events).map((key, index) => {
      if (
        state.events[key].predicted ||
        state.events[key].prediction_status === 'pending' ||
        state.events[key].prediction_status === 'predicted'
      ) {
        predictedEvents.push(state.events[key]);
      }
    });
    return predictedEvents
      .map(ev => {
        let event = { ...ev };
        event.races = ev.raceIds.map(raid => state.races[raid]);
        event.trail = state.trails[ev.trid];
        delete event.raceIds;
        return event;
      })
      .sort((a, b) => b.predicted_index - a.predicted_index);
  }

  @Selector()
  static selectRaceResults(state: TrailDataModel) {
    return raceId => {
      return Object.keys(state.results)
        .map(id => state.results[id])
        .filter((res: IResult) => res.raid == raceId) as IResult[];
    };
  }

  @Selector()
  static selectRacePaths(state: TrailDataModel) {
    return (raceId: number) => {
      return Object.keys(state.paths)
        .map(id => state.paths[id])
        .filter((path: IPath) => path.raid === raceId) as IPath[];
    };
  }

  @Selector()
  static getRaceById(state: TrailDataModel) {
    return (raceId: number) => state.races[raceId];
  }

  @Selector()
  static selectUserRunnerRegistrations(state: TrailDataModel) {
    return state.userRunnerRegistrations;
  }

  @Action(CreateNewDistance)
  createNewDistance(ctx: StateContext<TrailDataModel>, action: CreateNewDistance) {
    return this.#raceApi.createNewDistance(action.race).pipe(
      tap(race => {
        ctx.setState(
          produce(draft => {
            draft.races[race.id] = race;
            const eventAlias = Object.keys(draft.eventAlias).find(key => draft.eventAlias[key] === race.evid);
            draft.raceAlias[eventAlias + race.alias] = race.id;
            draft.events[race.evid].raceIds.push(race.id);
          }),
        );
      }),
    );
  }

  @Action(CreateBackyardDistances)
  createBackyardDistances(ctx: StateContext<TrailDataModel>, action: CreateBackyardDistances) {
    return this.#raceApi.generateBackyardRaces(action.data).pipe(
      tap(races => {
        ctx.setState(
          produce(draft => {
            for (const race of races) {
              draft.races[race.id] = race;
              const eventAlias = Object.keys(draft.eventAlias).find(key => draft.eventAlias[key] === race.evid);
              draft.raceAlias[eventAlias + race.alias] = race.id;
              draft.events[race.evid].raceIds.push(race.id);
            }
          }),
        );
      }),
    );
  }

  @Action(EditRaceData)
  editRaceData(ctx: StateContext<TrailDataModel>, action: EditRaceData) {
    return this.#raceApi.editRaceData(action.race).pipe(
      tap(race => {
        ctx.setState(
          produce(draft => {
            draft.races[race.id] = { ...race, podium: draft.races[race.id]?.podium };
            const eventAlias = Object.keys(draft.eventAlias).find(key => draft.eventAlias[key] === race.evid);
            draft.raceAlias[eventAlias + race.alias] = race.id;
          }),
        );
      }),
    );
  }

  @Action(DeleteRaceById)
  deleteRaceById(ctx: StateContext<TrailDataModel>, action: DeleteRaceById) {
    return this.#raceApi.deleteRaceData(action.raceId).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: TrailDataModel) => {
            delete draft.races[action.raceId];
            const raceAlias = Object.keys(draft.raceAlias).find(key => draft.raceAlias[key] === action.raceId);
            if (raceAlias) {
              delete draft.raceAlias[raceAlias];
            }
            const eventRace = Object.values(draft.events).find(event => event.raceIds.includes(+action.raceId));
            if (eventRace) {
              eventRace.raceIds.splice(eventRace.raceIds.indexOf(+action.raceId), 1);
            }
          }),
        );
      }),
    );
  }

  @Action(SelectDistanceFromQueryParamsOrAlias)
  selectDistanceFromQueryParamsOrAlias(
    ctx: StateContext<TrailDataModel>,
    action: SelectDistanceFromQueryParamsOrAlias,
  ) {
    const raceAlias = action.select
      ? action.raceAlias || this.#route?.snapshot?.queryParamMap?.get('distance')
      : undefined;
    ctx.patchState({
      selectedDistanceAlias: raceAlias,
    });
    return;
  }

  @Action(SelectResultId)
  selectResultId(ctx: StateContext<TrailDataModel>, action: SelectResultId) {
    ctx.patchState({
      selectedResultId: action.resultId,
      selectedType: 'result',
    });
    return;
  }

  @Action(SelectRunnerAlias)
  selectRunnerAlias(ctx: StateContext<TrailDataModel>, action: SelectRunnerAlias) {
    let actions = [];
    if (ctx.getState().selectedRunnerAlias !== action.runnerAlias) {
      actions.push(new LoadRunnerForAlias(action.runnerAlias));
      ctx.setState(
        produce(draft => {
          draft.selectedRunnerAlias = action.runnerAlias;
          draft.selectedType = 'runner';
        }),
      );

      return this.#store.dispatch(actions);
    }
    return;
  }

  @Action(SelectTrailAlias)
  selectTrailAlias(ctx: StateContext<TrailDataModel>, action: SelectTrailAlias) {
    if (action.trailAlias.indexOf('node%2F') === 0) {
      action.trailAlias = 'node/' + action.trailAlias.substr(7);
    }

    ctx.patchState({
      selectedTrailAlias: action.trailAlias,
      selectedType: 'trail',
    });

    return this.#store.dispatch(new LoadTrailForAlias(action.trailAlias));
  }

  @Action(SelectOrganizationAlias)
  selectOrganizationAlias(ctx: StateContext<TrailDataModel>, action: SelectOrganizationAlias) {
    ctx.patchState({
      selectedOrganizationAlias: action.organizationAlias,
    });
    return this.#store.dispatch(new LoadOrganizationForAlias(action.organizationAlias));
  }

  @Action(LoadSignupRegistrationForSelectedEvent)
  loadSignupRegistrationForSelectedEvent(
    ctx: StateContext<TrailDataModel>,
    action: LoadSignupRegistrationForSelectedEvent,
  ) {
    return this.#store.dispatch(
      new loadSignupRegistrationsForEventIdWithRunners(
        this.#store.selectSnapshot(TrailDataState.selectSelectedTrailId),
        this.#store.selectSnapshot(TrailDataState.selectSelectedEventId),
      ),
    );
  }

  @Action(SelectEventAlias)
  selectEventAlias(ctx: StateContext<TrailDataModel>, action: SelectEventAlias) {
    const eventAlias = action.eventAlias || '2019';

    ctx.patchState({
      selectedEventAlias: eventAlias,
      selectedType: 'event',
    });

    let event = TrailDataState.selectedEvent(ctx.getState());
    if (event && new Date(event.date * 1000) > new Date()) {
      this.#store.dispatch([
        new loadSignupRegistrationsForEventId(
          this.#store.selectSnapshot(TrailDataState.selectSelectedTrailId),
          this.#store.selectSnapshot(TrailDataState.selectSelectedEventId),
        ),
        new loadSignupRegistrationsForEventIdWithRunners(
          this.#store.selectSnapshot(TrailDataState.selectSelectedTrailId),
          this.#store.selectSnapshot(TrailDataState.selectSelectedEventId),
        ),
      ]);
    }
  }

  @Action(MarkClaimedEvents)
  markClaimedEvents(ctx: StateContext<TrailDataModel>, action: MarkClaimedEvents) {
    ctx.setState(
      produce(draft => {
        for (const claim of action.claims) {
          if (draft.events[claim.element_id]) {
            draft.events[claim.element_id].claim = {
              ...claim,
              days: Math.floor((Date.now() - new Date(claim.created_at).getTime()) / (1000 * 60 * 60 * 24)),
            };
          }
        }
      }),
    );
  }

  @Action(ClaimEvent)
  claimEvent(ctx: StateContext<TrailDataModel>, action: ClaimEvent) {
    ctx.setState(
      produce(draft => {
        draft.events[action.claim.element_id].claim = {
          ...action.claim,
          days: Math.floor((Date.now() - new Date(action.claim.created_at).getTime()) / (1000 * 60 * 60 * 24)),
        };
      }),
    );
  }

  @Action(SetSelectedType)
  setType(ctx: StateContext<TrailDataModel>, action: SetSelectedType) {
    ctx.patchState({
      selectedType: action.type,
    });
  }

  @Action(SetSelectedDistanceSubPage)
  setSelectedDistanceSubPage(ctx: StateContext<TrailDataModel>, action: SetSelectedDistanceSubPage) {
    ctx.patchState({
      selectedDistanceSubPage: action.subPageTitle,
    });
  }

  @Action(SetCurrentUserRunnerId)
  setCurrentUserId(ctx: StateContext<TrailDataModel>, action: SetCurrentUserRunnerId) {
    ctx.setState(
      produce(ctx.getState(), draft => {
        draft.currentUserRunnerId = action.id;
        if (action.runner) {
          draft.runners[action.id] = action.runner;
          draft.runnerAlias[action.runner.alias] = action.id;
        }
      }),
    );
    if (!ctx.getState().runners[action.id] && action.id > 0) {
      ctx.dispatch(new LoadRunnerById(action.id));
    }
  }

  @Action(EditAllowTrackingValue)
  editAllowTrackingValue(ctx: StateContext<TrailDataModel>, action: EditAllowTrackingValue) {
    return this.#eventService.changeRunnerAllowTracking(action.runnerId, action.value).pipe(
      tap(() => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            draft.runners[action.runnerId].allow_tracking = action.value ? 1 : 0;
          }),
        );
      }),
    );
  }

  @Action(AddSignupData)
  addSignupData(ctx: StateContext<TrailDataModel>, action: AddSignupData) {
    const trailSignupData = { ...action.trailSignupData };

    if (!!action.trailSignupData) {
      ctx.setState(
        produce(ctx.getState(), draft => {
          /* TRAIL */
          let storeTrail = draft.trails[+trailSignupData._id];
          if (!!storeTrail) {
            draft.trails[storeTrail.id].signupData = trailSignupData;
          }

          /* EVENTS */
          for (let event of trailSignupData.events) {
            let storeEvent = draft.events[+event.id];
            if (!!storeEvent) {
              event = this.formatSignupDates(event);

              draft.events[storeEvent.id].signupData = event;

              for (const route of event.routes) {
                let storeRace = draft.races[+route.id];
                if (!!storeRace) {
                  draft.races[route.id].signupData = route;
                }
              }
            }
          }
        }),
      );
    }
  }

  @Selector()
  static selectRaces(state: TrailDataModel) {
    //TODO find first not disabled
    return state.races;
  }

  @Selector()
  static selectOtherResults(state: TrailDataModel) {
    //TODO find first not disabled
    return state.otherResults;
  }

  @Selector()
  static selectDefaultDistanceAliasForRegistration(state: TrailDataModel) {
    //TODO find first not disabled

    let route = TrailDataState.selectedEvent(state)
      .signupData.routes.sort((route1, route2) => {
        return (
          TrailDataState.selectRaces(state)[route1.id].distance - TrailDataState.selectRaces(state)[route2.id].distance
        );
      })
      .find(route => !route.maxAttendeeReached);
    let routeId =
      TrailDataState.selectRaces(state)[route.id].alias || '' + TrailDataState.selectRaces(state)[route.id].id;
    return (
      routeId ||
      TrailDataState.selectedEvent(state).races[0].alias ||
      '' + TrailDataState.selectedEvent(state).races[0].id
    );
  }

  @Action(SelectDistanceAlias)
  selectDistanceAlias(ctx: StateContext<TrailDataModel>, { distanceAlias }: SelectDistanceAlias) {
    if (distanceAlias === undefined) {
      const state = ctx.getState();
      distanceAlias =
        TrailDataState.selectedEvent(state).races[0].alias || '' + TrailDataState.selectedEvent(state).races[0].id;
      ctx.patchState({
        selectedDistanceAlias: distanceAlias,
        selectedType: 'race',
      });
    } else {
      ctx.patchState({
        selectedDistanceAlias: distanceAlias,
        selectedType: 'race',
      });
      let actions = [];
      actions.push(
        new loadRegistrationsForRaceId(this.#store.selectSnapshot(TrailDataState.selectSelectedDistanceId), 0),
      );

      let event = TrailDataState.selectedEvent(ctx.getState());

      if (!this.#store.selectSnapshot(TrailDataState.selectedDistanceResults)) {
        actions.push(
          // ! was on force = true, was it important ? test and if not, remove it !
          new loadResultsForRaceId(this.#store.selectSnapshot(TrailDataState.selectSelectedDistanceId) /* , true */),
        );
      }

      actions.push(new loadPathsForRaceId(this.#store.selectSnapshot(TrailDataState.selectSelectedDistanceId)));

      return this.#store.dispatch(actions);
    }
  }

  @Action(loadResultsForRaceId)
  loadResultsForRaceId(ctx: StateContext<TrailDataModel>, action: loadResultsForRaceId) {
    const race = ctx.getState().races[action.raceId];
    const date = new Date().getTime();
    const min = date - 5 * 60 * 1000;
    if (race && (action.force || !race.resultsLoaded || race.resultsLoaded < min)) {
      ctx.setState(
        produce(ctx.getState(), draft => {
          draft.races[action.raceId].resultsLoaded = new Date().getTime();
        }),
      );
      this.#store.dispatch(new loadOtherResultsForRaceId(+action.raceId));
      return this.#eventService.getRaceResults(action.raceId).pipe(
        tap(results => {
          ctx.setState(
            produce(ctx.getState(), draft => {
              draft.resultIdsForDistance[action.raceId] = results.map(result => result.id);
              for (const result of results) {
                if (!draft.results[result.id] || action.force) {
                  draft.results[result.id] = result;
                } else {
                  draft.results[result.id].position = result.position;
                  if (result.femalePosition) {
                    draft.results[result.id].femalePosition = result.femalePosition;
                  }
                }
              }
              draft.races[action.raceId].resultsLoaded = new Date().getTime();
            }),
          );
        }),
      );
    } else {
      return true;
    }
  }

  @Action(loadOtherResultsForRaceId)
  loadOtherResultsForRaceId(ctx: StateContext<TrailDataModel>, action: loadOtherResultsForRaceId) {
    let race = ctx.getState().races[action.raceId];
    if (race) {
      ctx.setState(
        produce(ctx.getState(), draft => {
          draft.races[action.raceId].resultsLoaded = new Date().getTime();
        }),
      );

      return this.#eventService.getOtherResultsByRaceId(action.raceId).pipe(
        tap(results => {
          ctx.setState(
            produce(ctx.getState(), draft => {
              draft.otherResultIdsForDistance[action.raceId] = results.map(result => result.id);
              for (const result of results) {
                if (!draft.otherResults[result.id]) {
                  draft.otherResults[result.id] = result;
                } else {
                  draft.otherResults[result.id].position = result.position;
                }
              }
              draft.races[action.raceId].resultsLoaded = new Date().getTime();
            }),
          );
        }),
      );
    } else {
      return true;
    }
  }

  @Action(loadPathsForRaceId)
  loadPathsForRaceId(ctx: StateContext<TrailDataModel>, action: loadPathsForRaceId) {
    if (+action.raceId > 0) {
      return this.#eventService.getRacePaths(action.raceId).pipe(
        tap(paths => {
          ctx.setState(
            produce(ctx.getState(), draft => {
              let race = draft.races[action.raceId];
              let state = ctx.getState();
              draft.races[action.raceId] = formatPathToRace(race, paths);
            }),
          );
        }),
      );
    }
  }
  @Action(updatePathsForRaceId)
  updatePathsForRaceId(ctx: StateContext<TrailDataModel>, action: updatePathsForRaceId) {
    if (+action.raceId > 0) {
      ctx.setState(
        produce(ctx.getState(), draft => {
          let race = draft.races[action.raceId];
          let state = ctx.getState();
          draft.races[action.raceId].paths = action.paths;
        }),
      );
    }
  }

  @Action(loadPathForRaceId)
  loadPathForRaceId(ctx: StateContext<TrailDataModel>, action: loadPathForRaceId) {
    if (+action.raceId > 0) {
      return this.#eventService.getRacePath(action.raceId).pipe(
        tap(path => {
          if (path && path.path) {
            ctx.setState(
              produce(ctx.getState(), draft => {
                let race = draft.races[action.raceId];
                let state = ctx.getState();
                draft.races[action.raceId] = formatPathToRace(race, [path]);
              }),
            );
          }
        }),
      );
    }
  }

  @Action(LoadResultsForRunnerId)
  loadResultsForRunnerId(ctx: StateContext<TrailDataModel>, action: LoadResultsForRunnerId) {
    return this.#eventService.getRunnerResults(action.runnerId).pipe(
      tap(results => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            draft.resultIdsForRunner[action.runnerId] = results.map(result => result.id);
            for (const result of results) {
              if (draft.results[result.id] != undefined) {
                const oldResult = draft.results[result.id];
                draft.results[result.id] = {
                  ...result,
                  position: oldResult.position ? oldResult.position : result.position,
                  femalePosition: oldResult.femalePosition ? oldResult.femalePosition : null,
                };
              } else {
                draft.results[result.id] = result;
              }
            }
          }),
        );
      }),
      switchMap(() =>
        this.#store.dispatch([
          new loadOtherResultsForRunnerId(action.runnerId),
          new loadResultsPositionsForRunnerId(action.runnerId),
        ]),
      ),
    );
  }

  @Action(loadOtherResultsForRunnerId)
  loadOtherResultsForRunnerId(ctx: StateContext<TrailDataModel>, action: loadOtherResultsForRunnerId) {
    return this.#eventService.getOtherResultsByRunnerId(action.runnerId).pipe(
      tap(results => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            draft.otherResultIdsForRunner[action.runnerId] = results.map(result => result.id);
            for (const result of results) {
              if (draft.otherResults[result.id] != undefined) {
                const oldResult = draft.otherResults[result.id];
                draft.otherResults[result.id] = { ...result };
              } else {
                draft.otherResults[result.id] = result;
              }
            }
          }),
        );
      }),
    );
  }

  @Action(DeleteResultById)
  deleteResultById(ctx: StateContext<TrailDataModel>, action: DeleteResultById) {
    return this.#eventService.deleteRaceResult(action.resultId, action.data).pipe(
      tap(() => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            delete draft.results[action.resultId];
            if (action.runnerId) {
              draft.resultIdsForRunner[action.runnerId].splice(
                draft.resultIdsForRunner[action.runnerId].indexOf(action.resultId),
                1,
              );
            } else if (action.raceId) {
              draft.resultIdsForDistance[action.raceId].splice(
                draft.resultIdsForDistance[action.raceId].indexOf(action.resultId),
                1,
              );
            }
          }),
        );
      }),
    );
  }

  @Action(CreateNewResult)
  createNewResult(ctx: StateContext<TrailDataModel>, action: CreateNewResult) {
    return this.#eventService.createRaceResult(action.data).pipe(
      tap(results => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            draft.resultIdsForDistance[action.data.raid] = results.map(result => result.id);
            for (const result of results) {
              if (!draft.results[result.id]) {
                draft.results[result.id] = result;
              } else {
                draft.results[result.id].position = result.position;
                if (result.femalePosition) {
                  draft.results[result.id].femalePosition = result.femalePosition;
                }
              }
            }
            draft.races[action.data.raid].resultsLoaded = new Date().getTime();
          }),
        );
      }),
    );
  }

  @Action(EditResult)
  editResult(ctx: StateContext<TrailDataModel>, action: EditResult) {
    return this.#eventService.editResultData(action).pipe(
      tap(res => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            if (Array.isArray(res)) {
              delete draft.results[action.result.id];
              draft.resultIdsForDistance[action.result.raid].splice(
                draft.resultIdsForDistance[action.result.raid].indexOf(action.result.id),
                1,
              );
              draft.resultIdsForDistance[action.newDistance.raid] = res.map(result => result.id);
              for (const result of res) {
                if (!draft.results[result.id]) {
                  draft.results[result.id] = result;
                } else {
                  draft.results[result.id].position = result.position;
                  if (result.femalePosition) {
                    draft.results[result.id].femalePosition = result.femalePosition;
                  }
                }
              }
              draft.races[action.newDistance.raid].resultsLoaded = new Date().getTime();
            } else {
              draft.results[action.result.id] = res;
            }
          }),
        );
      }),
    );
  }

  @Action(editResultTag)
  editResultTag(ctx: StateContext<TrailDataModel>, action: editResultTag) {
    return this.#eventService.editResultTag(action.result, action.isOtherResult).pipe(
      tap((updatedResult: IResult | IOtherResult) => {
        ctx.setState(
          produce((draft: TrailDataModel) => {
            if (action.isOtherResult) {
              draft.otherResults[action.result.id] = {
                ...draft.otherResults[action.result.id],
                tag: updatedResult.tag,
              };
            } else if ('status' in action.result && 'status' in updatedResult) {
              const fullResult = {
                ...action.result,
                tag: updatedResult.tag,
                result_hms: toHMS(updatedResult.result_seconds, 'hms'),
                performance: parseFloat((updatedResult.performance / 100).toFixed(2)),
              };
              draft.results[action.result.id] = fullResult;
            }
          }),
        );
      }),
    );
  }

  @Action(loadResultsPositionsForRunnerId)
  loadResultsPositionsForRunnerId(ctx: StateContext<TrailDataModel>, action: loadResultsPositionsForRunnerId) {
    return this.#eventService.getRunnerResultsPositions(action.runnerId).pipe(
      tap(resultsPositions => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            ctx.getState().resultIdsForRunner[action.runnerId].map(resultId => {
              let result = { ...ctx.getState().results[resultId] };
              const runner = ctx.getState().runners[action.runnerId];
              let key = 'position';
              let keyFinishers = 'finishers';
              if (runner && runner.gender == 1) {
                key = 'femalePosition';
                keyFinishers = 'femaleFinishers';
              }
              result[key] = resultsPositions[result.id.toString()];
              result[keyFinishers] = resultsPositions[result.id.toString() + '-finishers'] - 1;
              draft.results[resultId] = result;
            });
          }),
        );
      }),
    );
  }

  @Action(loadScoresPositionsForRunnerId)
  loadScoresPositionsForRunnerId(ctx: StateContext<TrailDataModel>, action: loadScoresPositionsForRunnerId) {
    return this.#eventService.getRunnerScoresPositions(action.runnerId).pipe(
      tap(scoresPositions => {
        const runnerScores =
          ctx.getState().runners[ctx.getState().runnerAlias[ctx.getState().selectedRunnerAlias]] &&
          ctx.getState().runners[ctx.getState().runnerAlias[ctx.getState().selectedRunnerAlias]].scores
            ? ctx.getState().runners[ctx.getState().runnerAlias[ctx.getState().selectedRunnerAlias]].scores
            : [];
        ctx.setState(
          produce(ctx.getState(), draft => {
            if (runnerScores.length > 0) {
              for (let i in runnerScores) {
                draft.runners[draft.runnerAlias[draft.selectedRunnerAlias]].scores[i].position = {};
                let q = "'" + runnerScores[i].id + "'";
                let p = "'" + runnerScores[i].id + "total'";
                let r = "'" + runnerScores[i].id + "points'";
                let s = "'" + runnerScores[i].id + "pointstotal'";
                let t = "'" + runnerScores[i].id + "btu'";
                let u = "'" + runnerScores[i].id + "btutotal'";
                let v = "'" + runnerScores[i].id + "ucpoints'";
                let w = "'" + runnerScores[i].id + "ucpointstotal'";
                let x = "'" + runnerScores[i].id + "bts'";
                let y = "'" + runnerScores[i].id + "btstotal'";

                if (scoresPositions && scoresPositions[0] && scoresPositions[0][q]) {
                  draft.runners[draft.runnerAlias[draft.selectedRunnerAlias]].scores[i].position.bt_position =
                    scoresPositions[0][q];
                  draft.runners[draft.runnerAlias[draft.selectedRunnerAlias]].scores[i].position.bt_total =
                    scoresPositions[0][p];
                }
                if (scoresPositions && scoresPositions[0] && scoresPositions[0][r]) {
                  draft.runners[draft.runnerAlias[draft.selectedRunnerAlias]].scores[i].position.tc_position =
                    scoresPositions[0][r];
                  draft.runners[draft.runnerAlias[draft.selectedRunnerAlias]].scores[i].position.tc_total =
                    scoresPositions[0][s];
                }
                if (scoresPositions && scoresPositions[0] && scoresPositions[0][t]) {
                  draft.runners[draft.runnerAlias[draft.selectedRunnerAlias]].scores[i].position.btu_position =
                    scoresPositions[0][t];
                  draft.runners[draft.runnerAlias[draft.selectedRunnerAlias]].scores[i].position.btu_total =
                    scoresPositions[0][u];
                }
                if (scoresPositions && scoresPositions[0] && scoresPositions[0][v]) {
                  draft.runners[draft.runnerAlias[draft.selectedRunnerAlias]].scores[i].position.uc_position =
                    scoresPositions[0][v];
                  draft.runners[draft.runnerAlias[draft.selectedRunnerAlias]].scores[i].position.uc_total =
                    scoresPositions[0][w];
                }
                if (scoresPositions && scoresPositions[0] && scoresPositions[0][x]) {
                  draft.runners[draft.runnerAlias[draft.selectedRunnerAlias]].scores[i].position.bts_position =
                    scoresPositions[0][x];
                  draft.runners[draft.runnerAlias[draft.selectedRunnerAlias]].scores[i].position.bts_total =
                    scoresPositions[0][y];
                }
              }
            }
          }),
        );
      }),
    );
  }

  @Action(LoadRegistrationsForRunnerId)
  loadRegistrationsForRunnerId(ctx: StateContext<TrailDataModel>, action: LoadRegistrationsForRunnerId) {
    return this.#registrationApi.getRunnerRegistrations(action.runnerId).pipe(
      tap(registrations => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            draft.registrationIdsForRunner[action.runnerId] = registrations.map(reg => reg.id);
            for (const registration of registrations) {
              draft.registrations[registration.id] = registration;
            }
          }),
        );
      }),
    );
  }

  @Action(loadRegistrationsForRaceId)
  loadRegistrationsForRaceId(ctx: StateContext<TrailDataModel>, action: loadRegistrationsForRaceId) {
    return this.#registrationApi.getRaceRegistrations(action.raceId, action.offset).pipe(
      tap(registrations => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            draft.registrationIdsForRace[action.raceId] = draft.registrationIdsForRace[action.raceId]
              ? draft.registrationIdsForRace[action.raceId].concat(registrations.map(r => r.id))
              : registrations.map(r => r.id);
            for (const registration of registrations) {
              draft.registrations[registration.id] = registration;
            }
          }),
        );
      }),
    );
  }

  @Action(loadRegistrationsForEventId)
  loadRegistrationsForEventId(ctx: StateContext<TrailDataModel>, action: loadRegistrationsForEventId) {
    return this.#registrationApi.getEventRegistrations(action.eventId, action.offset).pipe(
      tap(registrations => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            draft.registrationIdsForEvent[action.eventId] = draft.registrationIdsForEvent[action.eventId]
              ? draft.registrationIdsForEvent[action.eventId].concat(registrations.map(r => r.id))
              : registrations.map(r => r.id);
            for (const registration of registrations) {
              draft.registrations[registration.id] = registration;
            }
          }),
        );
      }),
    );
  }

  @Action(loadSignupRegistrationsForRaceId)
  loadSignupRegistrationsForRaceId(ctx: StateContext<TrailDataModel>, action: loadSignupRegistrationsForRaceId) {
    if (
      !ctx.getState().signupRegistrationIdsForRace[action.raceId] ||
      ctx.getState().signupRegistrationIdsForRace[action.raceId].length == 0
    ) {
      return this.#eventService.getRaceSignupRegistrations(action.trailId, action.eventId, action.raceId).pipe(
        tap(registrations => {
          ctx.setState(
            produce(ctx.getState(), draft => {
              draft.signupRegistrationIdsForRace[action.raceId] = draft.signupRegistrationIdsForRace[action.raceId]
                ? draft.signupRegistrationIdsForRace[action.raceId].concat(registrations.registrations.map(r => r._id))
                : registrations.registrations.map(r => r._id);
              for (const registration of registrations.registrations) {
                draft.signupRegistrations[registration._id] = registration;
              }
            }),
          );
        }),
      );
    }
  }

  @Action(loadSignupRegistrationsForEventIdWithRunners)
  loadSignupRegistrationsForEventIdWithRunners(
    ctx: StateContext<TrailDataModel>,
    action: loadSignupRegistrationsForEventIdWithRunners,
  ) {
    if (
      !ctx.getState().signupRegistrationIdsForEvent[action.eventId] ||
      ctx.getState().signupRegistrationIdsForEvent[action.eventId].length == 0
    ) {
      return this.#eventService.getEventSignupRegistrations(action.trailId, action.eventId).pipe(
        tap(registrations => {
          ctx.setState(
            produce(ctx.getState(), draft => {
              if (!draft.signupRegistrationIdsForEvent[action.eventId]) {
                draft.signupRegistrationIdsForEvent[action.eventId] = [];
              }
              for (const registration of registrations.registrations) {
                draft.signupRegistrations[registration._id] = registration;
                if (!draft.signupRegistrationIdsForRace[registration.routeId]) {
                  draft.signupRegistrationIdsForRace[registration.routeId] = [];
                }
                if (
                  draft.signupRegistrationIdsForRace[registration.routeId] &&
                  draft.signupRegistrationIdsForRace[registration.routeId].indexOf(registration._id) < 0
                ) {
                  draft.signupRegistrationIdsForRace[registration.routeId].push(registration._id);
                }
                if (
                  draft.signupRegistrationIdsForEvent[action.eventId] &&
                  draft.signupRegistrationIdsForEvent[action.eventId].indexOf(registration._id) < 0
                ) {
                  draft.signupRegistrationIdsForEvent[action.eventId].push(registration._id);
                }
              }
            }),
          );
        }),
        catchError(err => {
          return of(); //silently ignore error
        }),
      );
    }
  }

  @Action(loadSignupRegistrationsForEventId)
  loadSignupRegistrationsForEventId(ctx: StateContext<TrailDataModel>, action: loadSignupRegistrationsForEventId) {
    if (
      !ctx.getState().signupRegistrationIdsForEvent[action.eventId] ||
      ctx.getState().signupRegistrationIdsForEvent[action.eventId].length == 0
    ) {
      return this.#eventService.getEventSignupRegistrations2(action.eventId).pipe(
        tap(registrations => {
          ctx.setState(
            produce(ctx.getState(), draft => {
              if (!draft.signupRegistrationIdsForEvent[action.eventId]) {
                draft.signupRegistrationIdsForEvent[action.eventId] = [];
              }
              for (const registration of registrations.registrations) {
                draft.signupRegistrations[registration._id] = registration;
                if (!draft.signupRegistrationIdsForRace[registration.routeId]) {
                  draft.signupRegistrationIdsForRace[registration.routeId] = [];
                }
                if (
                  draft.signupRegistrationIdsForRace[registration.routeId] &&
                  draft.signupRegistrationIdsForRace[registration.routeId].indexOf(registration._id) < 0
                ) {
                  draft.signupRegistrationIdsForRace[registration.routeId].push(registration._id);
                }
                if (
                  draft.signupRegistrationIdsForEvent[action.eventId] &&
                  draft.signupRegistrationIdsForEvent[action.eventId].indexOf(registration._id) < 0
                ) {
                  draft.signupRegistrationIdsForEvent[action.eventId].push(registration._id);
                }
              }
            }),
          );
        }),
        catchError(err => {
          return of(); //silently ignore error
        }),
      );
    }
  }

  @Action(LoadSignupRegistrationWithRunner)
  loadSignupRegistrationWithRunner(ctx: StateContext<TrailDataModel>, action: LoadSignupRegistrationWithRunner) {
    return this.#eventService.getSignupRegistrationWithRunner(action.regId).pipe(
      tap(data => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            draft.signupRegistrations[action.regId] = data.registration;
          }),
        );
      }),
      catchError(err => {
        return of(); //silently ignore error
      }),
    );
  }

  @Action(LoadRunnerForAlias)
  loadRunnerForAlias(ctx: StateContext<TrailDataModel>, action: LoadRunnerForAlias) {
    return this.#runnerApi.getRunner(action.runnerAlias).pipe(
      tap(runner => {
        ctx.setState(
          produce((draft: TrailDataModel) => {
            draft.runnerAlias[runner.alias] = runner.id;
            draft.runners[runner.id] = runner;
          }),
        );
      }),
      switchMap(runner =>
        ctx.dispatch([
          new LoadResultsForRunnerId(runner.id),
          new LoadLevelByCategoriesForRunnerId(runner.id),
          new LoadRegistrationsForRunnerId(runner.id),
          new LoadUserSponsors(runner.uid),
        ]),
      ),
    );
  }

  @Action(LoadLevelByCategoriesForRunnerId)
  loadLevelByCategoriesForRunnerId(ctx: StateContext<TrailDataModel>, action: LoadLevelByCategoriesForRunnerId) {
    const state = ctx.getState();
    if (!state.runnerLevelRankings[action.runnerId]) {
      const distances = LEVEL_RANKING_DISTANCES;
      const elevations = LEVEL_RANKING_ELEVATIONS;
      return this.#eventService.getRunnerLevelPronoAndCoeff(+action.runnerId, distances, elevations, true).pipe(
        tap(data => {
          ctx.setState(
            produce(ctx.getState(), draft => {
              draft.runnerLevelRankings[action.runnerId] = data;
            }),
          );
        }),
      );
    }
  }

  @Action(AddUserRunnerRegistration)
  addUserRunnerRegistration(ctx: StateContext<TrailDataModel>, action: AddUserRunnerRegistration) {
    return this.#registrationApi.addRunnerRegistration(action.race).pipe(
      tap(data => {
        ctx.dispatch(new LoadUserRunnerRegistrations());
      }),
    );
  }

  @Action(RemoveUserRunnerRegistration)
  removeUserRunnerRegistration(ctx: StateContext<TrailDataModel>, action: RemoveUserRunnerRegistration) {
    return this.#registrationApi.cancelRunnerRegistration(action.race).pipe(
      tap(data => {
        ctx.dispatch(new LoadUserRunnerRegistrations());
      }),
    );
  }

  @Action(LoadRunnerById)
  loadRunnerById(ctx: StateContext<TrailDataModel>, action: LoadRunnerById) {
    return this.#runnerApi.getRunner(action.runnerId).pipe(
      tap(runner => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            draft.runnerAlias[runner.alias] = runner.id;
            draft.runners[runner.id] = runner;
          }),
        );
      }),
    );
  }

  @Action(UpdateRunnerById)
  updateRunnerById(ctx: StateContext<TrailDataModel>, action: UpdateRunnerById) {
    if (action.ruid && action.data) {
      return this.#runnerApi.updateRunner(action.ruid, action.data).pipe(
        tap(runner => {
          ctx.setState(
            produce(draft => {
              draft.runners[runner.id] = runner;
            }),
          );
        }),
      );
    } else {
      console.error('Error while updating runner, no data or ruid');
    }
  }

  @Action(UpdateRunnerExtraInfos)
  updateRunnerExtraInfos(ctx: StateContext<TrailDataModel>, action: UpdateRunnerExtraInfos) {
    if (action.ruid && action.data) {
      return this.#runnerApi.updateRunnerExtraInfos(action.ruid, action.data).pipe(
        tap(infos => {
          ctx.setState(
            produce(draft => {
              draft.runners[action.ruid].extraInfos = infos;
            }),
          );
        }),
      );
    } else {
      console.error('Error while updating runner extra infos, no data or ruid');
    }
  }

  @Action(loadMatchupRunnerById)
  loadMatchupRunnerById(ctx: StateContext<TrailDataModel>, action: loadMatchupRunnerById) {
    if (action.runnerId) {
      return this.#runnerApi.getRunner(String(action.runnerId)).pipe(
        tap(runner => {
          ctx.setState(
            produce(ctx.getState(), draft => {
              draft.runners[runner.id] = runner;
              draft.selectedMatchupRunnerId = runner.id;
            }),
          );
        }),
      );
    } else {
      ctx.setState(
        produce(ctx.getState(), draft => {
          draft.selectedMatchupRunnerId = undefined;
        }),
      );
    }
  }

  @Action(LoadTrailForAlias)
  loadTrailForAlias(ctx: StateContext<TrailDataModel>, action: LoadTrailForAlias) {
    if (!action.storeCache || !ctx.getState().trailAlias[action.trailAlias]) {
      return this.#eventService.getTrail(action.trailAlias).pipe(
        tap(trail => {
          ctx.setState(
            produce(ctx.getState(), draft => {
              draft.trailAlias[trail.alias || 'node/' + trail.id] = trail.id;
              if (trail && trail.events) {
                trail.eventIds = trail.events.sort((a, b) => b.date - a.date).map(e => e.id);
                for (const event of trail.events) {
                  for (const race of event.races) {
                    draft.races[race.id] = formatPathToRace(race, race.paths);
                    draft.raceAlias[
                      (trail.alias || 'node/' + trail.id) +
                        (event.alias || 'node/' + event.id) +
                        (race.alias || 'node/' + race.id)
                    ] = race.id;
                  }
                  draft.eventAlias[(trail.alias || 'node/' + trail.id) + (event.alias || 'node/' + event.id)] =
                    event.id;
                  event.raceIds = event.races
                    .sort((a, b) => b.distance - a.distance)
                    .sort((a, b) => a.date - b.date)
                    .map(race => race.id);
                  delete event.trail;
                  delete event.races;
                  draft.events[event.id] = event;
                }
                delete trail.events;
              }
              draft.trails[trail.id] = trail;
            }),
          );
          this.#store.dispatch(new AddSignupData(trail.signupData));
        }),
      );
    }
  }

  @Action(LoadOrganizationForAlias)
  loadOrganizationForAlias(ctx: StateContext<TrailDataModel>, action: LoadOrganizationForAlias) {
    return this.#eventService.getOrganizationById(action.organizationAlias).pipe(
      tap(organization => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            if (organization) {
              draft.organizationAlias[organization.id] = organization.id;
              draft.organizations[organization.id] = organization;
            }
          }),
        );
      }),
    );
  }

  @Action(AddUserToOrganization)
  addUserToOrganization(ctx: StateContext<TrailDataModel>, action: AddUserToOrganization) {
    return this.#eventService.addUserToOrganization(action.organizationId, action.userId).pipe(
      tap(organization => {
        ctx.setState(
          produce(ctx.getState(), draft => {
            draft.organizationAlias[organization.id] = organization.id;
            draft.organizations[organization.id] = organization;
          }),
        );
        this.#eventService.validateOrganizerAccount(action.userId).subscribe();
      }),
    );
  }

  @Action(loadCloseFutureEvents)
  loadCloseFutureEvents(ctx: StateContext<TrailDataModel>, action: loadCloseFutureEvents) {
    return this.#eventService.closeFutureEvents().pipe(
      tap(data => {
        ctx.setState(produce(ctx.getState(), this.setEvents(data.events)));
      }),
    );
  }

  @Action(loadFutureEvents)
  loadFutureEvents(ctx: StateContext<TrailDataModel>, action: loadFutureEvents) {
    return this.#eventService.futureEvents().pipe(
      tap(data => {
        ctx.setState(produce(ctx.getState(), this.setEvents(data.events)));
      }),
    );
  }

  @Action(loadEvents)
  loadEvents(ctx: StateContext<TrailDataModel>, action: loadEvents) {
    return this.#eventService.allEvents().pipe(
      tap(data => {
        ctx.setState(produce(ctx.getState(), this.setEvents(data.events)));
      }),
    );
  }

  @Action(loadEventsPacket)
  loadEventsPacket(ctx: StateContext<TrailDataModel>, action: loadEventsPacket) {
    if (action.clear === true) {
      ctx.setState(
        produce(draft => {
          draft.events = {};
        }),
      );
    }
    let date = new Date();
    let afterDate = action.afterDate;
    date.setFullYear(date.getFullYear() + 1);
    if (!action.afterDate) {
      afterDate = Date.now();
    }

    return this.#eventService
      .eventsPacket({
        before: date,
        after: afterDate,
        country: action.country || 'all',
        forAdditionPage: action.forAdditionPage,
      })
      .pipe(
        tap(data => {
          ctx.setState(produce(this.setEvents(data.events)));
        }),
      );
  }
  @Action(LoadUserRunnerRegistrations)
  loadUserRunnerRegistrations(ctx: StateContext<TrailDataModel>, action: LoadUserRunnerRegistrations) {
    return this.#registrationApi.getUserRunnerRegistrations().pipe(
      filter(registrations => !!registrations),
      tap(registrations => {
        if (registrations) {
          const ruid = registrations[0] ? registrations[0].ruid : undefined;
          ctx.setState(
            produce(ctx.getState(), draft => {
              registrations.map(reg => {
                draft.userRunnerRegistrations[reg.raid] = reg;
                draft.registrations[reg.id] = reg;
              });
              if (ruid) {
                draft.registrationIdsForRunner[ruid] = registrations.map(reg => reg.id);
              }
            }),
          );
        }
      }),
      catchError(err => {
        return of();
      }),
    );
  }

  removeEvents() {
    return draft => {
      draft.events = {};
    };
  }

  setEvents(events: any[]) {
    return (draft: TrailDataModel) => {
      for (const event of events) {
        if (event.trail) {
          if (event.signupData) {
            event.signupData = this.formatSignupDates(event.signupData);
          }
          for (const race of event.races) {
            draft.races[race.id] = formatPathToRace(race, race.paths);
            const raceAlias =
              '' +
              (event.trail.alias || 'node/' + event.trail.id) +
              (event.alias || 'node/' + event.id) +
              (race.alias || 'node/' + race.id);
            draft.raceAlias[raceAlias] = race.id;
          }
          event.raceIds = event.races
            .sort((a, b) => b.distance - a.distance)
            .sort((a, b) => a.date - b.date)
            .map(race => race.id);
          delete event.races;
          draft.trails[event.trid] = event.trail;

          draft.trailAlias[event.trail.alias || 'node/' + event.trail.id] = event.trid;
          const eventAlias = '' + (event.trail.alias || 'node/' + event.trid) + (event.alias || 'node/' + event.id);
          delete event.trail;
          draft.events[event.id] = event;
          draft.eventAlias[eventAlias] = event.id;
        }
      }
    };
  }

  formatSignupDates(event) {
    // formatting dates
    if (event.signupStartDate) {
      let d = new Date(event.signupStartDate);
      d.setHours(18);
      d.setMinutes(0);
      d.setSeconds(0);
      event = { ...event, signupStartDate: d };
    }

    if (event.signupEndDate) {
      let d = new Date(event.signupEndDate);
      d.setHours(23);
      d.setMinutes(59);
      d.setSeconds(59);
      event = { ...event, signupEndDate: d };
    }
    return event;
  }

  // Sponsors

  @Selector()
  static allSponsors(state: TrailDataModel) {
    return Object.keys(state.sponsors)
      .map(key => state.sponsors[key])
      .sort((a, b) => b.data.id - a.data.id);
  }

  @Selector()
  static selectNotification(state: TrailDataModel) {
    return state.notification;
  }

  @Selector()
  static userSponsors(state: TrailDataModel) {
    if (state.userSponsors.length > 0) {
      return Object.assign([], state.userSponsors);
    } else return [];
  }

  @Selector()
  static sponsoredRunners(state: TrailDataModel) {
    return Object.keys(state.sponsoredRunners).map(key => state.sponsoredRunners[key]);
  }

  @Action(LoadAllSponsors)
  loadAllSponsors(ctx: StateContext<TrailDataModel>) {
    return this.#eventService.getAllSponsors().pipe(
      tap((sponsors: Sponsor[]) => {
        ctx.setState(
          produce((draft: TrailDataModel) => {
            for (const sponsor of sponsors) {
              draft.sponsors[sponsor.data.id] = sponsor;
            }
          }),
        );
      }),
    );
  }

  @Action(CreateNewSponsor)
  createNewSponsor(ctx: StateContext<TrailDataModel>, action: CreateNewSponsor) {
    return this.#eventService.createSponsor(action.sponsor, action.role, action.user).pipe(
      tap(sponsor => {
        ctx.setState(
          produce((draft: TrailDataModel) => {
            let newSponsor = {
              data: {
                id: sponsor.id,
                name: sponsor.name,
                country: sponsor.country,
                link: sponsor.link,
                linkEnabled: sponsor.linkEnabled,
                nbRunnerLinked: 0,
              },
              image: sponsor.picture,
            };
            draft.sponsors[sponsor.id] = newSponsor;
          }),
        );
      }),
    );
  }

  @Action(UpdateSponsorData)
  updateSponsorData(ctx: StateContext<TrailDataModel>, action: UpdateSponsorData) {
    return this.#eventService.updateSponsor(action.sponsor).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: TrailDataModel) => {
            draft.sponsors[action.sponsor.id] = action.sponsor;
          }),
        );
      }),
    );
  }

  @Action(DeleteSponsor)
  deleteSponsor(ctx: StateContext<TrailDataModel>, action: DeleteSponsor) {
    return this.#eventService.deleteSponsor(action.sponsorId).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: TrailDataModel) => {
            delete draft.sponsors[action.sponsorId];
          }),
        );
      }),
    );
  }

  @Action(RemoveSponsorFromUser)
  removeSponsorFromUser(ctx: StateContext<TrailDataModel>, action: RemoveSponsorFromUser) {
    return this.#eventService.removeSponsorFromUser(action.uid, action.spid).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: TrailDataModel) => {
            if (draft.userSponsors.length === 1) {
              draft.userSponsors = [];
            } else {
              draft.userSponsors = draft.userSponsors.filter(s => s.data.id !== action.spid);
            }
          }),
        );
      }),
    );
  }

  @Action(LoadUserSponsors)
  loadUserSponsors(ctx: StateContext<TrailDataModel>, action: LoadUserSponsors) {
    return this.#eventService.getSponsorsOfRunner(action.userId).pipe(
      tap((sponsors: any[]) => {
        ctx.setState(
          produce((draft: TrailDataModel) => {
            draft.userSponsors = [];
            if (sponsors) draft.userSponsors = sponsors;
          }),
        );
      }),
    );
  }

  @Action(LoadRunnersOfSponsor)
  loadRunnersOfSponsor(ctx: StateContext<TrailDataModel>, action: LoadRunnersOfSponsor) {
    return this.#eventService.getRunnersOfSponsor(action.spid).pipe(
      tap((runners: any[]) => {
        ctx.setState(
          produce((draft: TrailDataModel) => {
            draft.sponsoredRunners = {};
            for (const runner of runners) {
              draft.sponsoredRunners[runner.id] = runner;
            }
          }),
        );
      }),
    );
  }
}
