import { IRace } from './race.model';
import { IRunner } from './runner.model';

export interface IRegistration {
  title: string;
  id: number;
  uid: number;
  ruid: number;
  runner?: IRunner;
  raid: number;
  race?: IRace;
  status: ERegistrationStatus;
  created: number;
  updated: number;
}

export enum ERegistrationStatus {
  PLANNED = 'planned',
  REGISTERED = 'registered',
  CANCELLED = 'cancelled',
}

type ItemsType = 'REGISTRATION' | 'TEAM_REGISTRATION';

export interface IItems {
  _id: string;
  type: ItemsType;
}

export interface ISignupRegistration extends IItems {
  _id: string;
  uid: number;
  ruid: number;
  mail: string;
  createdAt: string;
  firstname: string;
  lastname: string;
  birthdate: string;
  country: string;
  gender: number;
  routeId: string;
  status: string;
  address: string;
  paymentStatus?: string;
  paymentMethod?: string;
  bib?: number;
  formData?: any;
  paymentInfo?: any;
  transferInfo?: any;
  extraFields?: any;
  orderId?: string;
  runners?: any[];
  amount?: number;
  race?: {
    date: number;
    id?: number | string;
  };
  routeInfo?: any;
  distanceChangesLogs?: any[];
}
