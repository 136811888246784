import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthStateService } from '@betrail-libs/auth-state';
import { IEvent } from '@betrail-libs/shared/interfaces/event.model';
import { EUserRole } from '@betrail-libs/shared/interfaces/interfaces';
import { ERaceVerificationStatus, IRace } from '@betrail-libs/shared/interfaces/race.model';
import { hasRoleId } from '@betrail-libs/shared/utils';
import { RaceStateService } from '@betrail-libs/trail-data-state';
import { BetrailUxFormsService } from '@betrail/ux';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { DialogService } from '../../../../common/dialog/dialogService';
import { RaceForm } from '../../../../shared/race-form/race-form.component';

@UntilDestroy()
@Component({
  selector: 'app-edit-race-dialog',
  templateUrl: './edit-race-dialog.component.html',
  styleUrls: ['./edit-race-dialog.component.scss'],
})
export class EditRaceDialogComponent implements OnInit {
  #authState = inject(AuthStateService);
  #dialogService = inject(DialogService);
  #dialogRef = inject(MatDialogRef<EditRaceDialogComponent>);
  #formService = inject(BetrailUxFormsService);
  #raceState = inject(RaceStateService);
  #transloco = inject(TranslocoService);
  data = inject<{ event: IEvent; race?: IRace; multiple?: boolean }>(MAT_DIALOG_DATA);

  raceForm?: FormGroup<RaceForm>;
  datesInfos?: { several_days: boolean; dates: number[] };
  formActivated = false;

  isLogged$ = this.#authState.logged$;
  isAdmin$ = this.#authState.hasRole(EUserRole.Administrator);
  isEncoder$ = this.#authState.hasRole(EUserRole.Encoder);
  isOrganizer$ = this.#authState.hasOrgaRight();
  loading$ = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {
    this.raceForm = this.#formService.buildRaceForm(this.data.race, false, this.data.multiple);
    this.datesInfos = this.buildDateInfos(this.data.event);

    combineLatest([this.#authState.getUser(), this.isOrganizer$])
      .pipe(untilDestroyed(this))
      .subscribe(([u, isOrga]) => {
        if (
          hasRoleId(u, EUserRole.Administrator) ||
          hasRoleId(u, EUserRole.Encoder) ||
          (hasRoleId(u, EUserRole.VerifiedOrganizer) && isOrga)
        ) {
          this.formActivated = true;
        }
        const messageNotRequired =
          isOrga || u?.roles.map(r => r.rid).includes(EUserRole.Encoder, EUserRole.Administrator);

        this.raceForm.addControl(
          'message',
          new FormControl(
            isOrga
              ? !this.data.race
                ? this.#transloco.translate('ADDED_BY_ORGANIZER')
                : this.#transloco.translate('EDITED_BY_ORGANIZER')
              : '',
            messageNotRequired ? undefined : Validators.required,
          ),
        );
      });
  }

  buildDateInfos(event: IEvent) {
    const datesInfos = {
      several_days: false,
      dates: [] as number[],
    };
    if (event && event.date) {
      const startDate = moment(event.date * 1000);
      const endDate = event.end_date ? moment(event.end_date * 1000) : moment(event.date * 1000);
      const date = startDate;
      const dates = [] as number[];

      while (date.isBefore(endDate) || date.isSame(endDate)) {
        dates.push(date.valueOf());
        date.add(1, 'days');
      }
      if (!endDate.isSame(startDate, 'day')) {
        datesInfos.several_days = true;
      }
      datesInfos.dates = dates;
    }
    return datesInfos;
  }

  saveDistance() {
    this.loading$.next(true);
    const raceData = this.#formService.convertRaceFormToRace(this.raceForm, this.data.event.title);

    if (!this.data.multiple) {
      if (this.data.race) {
        // = edit race
        this.#raceState
          .editRaceData({ ...this.data.race, ...raceData })
          .pipe(untilDestroyed(this))
          .subscribe({
            complete: () => {
              // TODO : redirect to the new alias route if we are on the previous one
              this.loading$.next(false);
              this.#dialogRef.close();
            },
            error: err => {
              alert('ERROR : ' + JSON.stringify(err));
              this.loading$.next(false);
              this.#dialogRef.close();
            },
          });
      } else {
        // = create race
        this.#raceState
          .createNewDistance({
            ...raceData,
            country: this.data.event.country,
            evid: this.data.event.id,
            status: 1,
            verification_status: ERaceVerificationStatus.PENDING,
          })
          .pipe(untilDestroyed(this))
          .subscribe({
            complete: () => {
              this.loading$.next(false);
              this.#dialogRef.close();
            },
            error: err => {
              alert('ERROR : ' + JSON.stringify(err));
              this.loading$.next(false);
              this.#dialogRef.close();
            },
          });
      }
    } else {
      // = create multiple loops
      const baseRace = {
        ...this.#formService.convertRaceFormToRace(this.raceForm, this.data.event.title),
        country: this.data.event.country,
        evid: this.data.event.id,
        status: 1,
        verification_status: ERaceVerificationStatus.PENDING,
      };
      const laps = this.raceForm.value.laps.split(',').map(l => +l);
      this.#raceState
        .generateBackyardRaces({ baseRace, laps })
        .pipe(untilDestroyed(this))
        .subscribe({
          complete: () => {
            this.loading$.next(false);
            this.#dialogRef.close();
          },
          error: err => {
            alert('ERROR : ' + JSON.stringify(err));
            this.loading$.next(false);
            this.#dialogRef.close();
          },
        });
    }
  }

  removeDistance(hasResults?: boolean) {
    const title = this.#transloco.translate('CONFIRM_DELETE_RACE_TITLE', { distance: this.data.race.distance });
    if (hasResults) {
      const message = this.#transloco.translate('DELETE_RACE_HAS_RESULTS_WARNING');
      this.#dialogService.showAlertDialog(title, message);
    } else {
      const message = this.#transloco.translate('CONFIRM_DELETE_RACE_MESSAGE_NEW', {
        title: this.data.race.title || this.data.event.title || 'TITLE NOT FOUND',
        year: new Date(this.data.race.date * 1000).getFullYear(),
        distance: this.data.race.distance,
      });
      this.#dialogService
        .showConfirmDialog(title, message)
        .pipe(untilDestroyed(this))
        .subscribe((validated?: boolean) => {
          if (validated) {
            this.#raceState.deleteDistance(this.data.race.id);
            this.#dialogRef.close();
          }
        });
    }
  }
}
