import { Directive } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[betrailCustomValidators]',
})
export class CustomValidatorsDirective {}

export function timeBarrierValidator(): ValidatorFn {
  return (control: AbstractControl<string | null | undefined>): ValidationErrors | null => {
    console.log('--------------------------------------');
    console.log('control.value', control.value);
    if (!/^[0-9]{1,3}[:.,h][0-9]{2}$/.test(control.value)) {
      return { pattern: { value: control.value } };
    } else {
      const timeParts = control.value.split(/[:.,h]/);
      console.log('timeParts', timeParts);
      if (+timeParts[0] > 999 || +timeParts[1] > 59) {
        console.log('invalid');
        return { invalid: { value: control.value } };
      } else {
        return null;
      }
    }
  };
}
