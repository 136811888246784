import { Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthStateService } from '@betrail-libs/auth-state';
import { IEvent } from '@betrail-libs/shared/interfaces/event.model';
import { EUserRole } from '@betrail-libs/shared/interfaces/interfaces';
import { toHMS } from '@betrail-libs/shared/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterState } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Observable } from 'rxjs';
import { BetrailUxFormsService } from '../../../betrail-ux-forms.service';

@UntilDestroy()
@Component({
  selector: 'bux-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss', '../common-form.scss'],
  /* providers: [
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { firstDayOfWeek: 1 } },
  ], */
})
export class EventFormComponent implements OnInit, OnDestroy {
  @Select(RouterState.url) url$!: Observable<string>;
  @Input() showLaunchOnlineRegistration: boolean;
  @Output() datesChanged = new EventEmitter<any>();
  @ViewChild('severalDays', { static: true }) severalDays: ElementRef;

  eventFormGroup: FormGroup;
  event: IEvent;
  minDate = new Date(2013, 0, 1);
  toggleMore = false;
  acceptDateChange = true;

  user$ = this.authState.getUser();
  isAdmin$ = this.authState.hasRole(EUserRole.Administrator);
  isEncoder$ = this.authState.hasRole(EUserRole.Encoder);
  showOrgaOptions$ = this.authState.hasOrgaRight();

  constructor(private betrailUxFormsService: BetrailUxFormsService, private authState: AuthStateService) {
    this.eventFormGroup = this.betrailUxFormsService.buildEventForm();
  }

  ngOnInit() {
    this.eventFormGroup
      .get('date')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(dateChanges => {
        this.notifyDatesChanged();
        this.resetEndDateIfNotOnSeveralDays();
      });

    this.eventFormGroup
      .get('end_date')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(endDateChanges => {
        this.notifyDatesChanged(true);
      });

    this.eventFormGroup
      .get('several_days')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(sd => {
        this.notifyDatesChanged();
        this.resetEndDateIfNotOnSeveralDays();
      });
  }

  resetEndDateIfNotOnSeveralDays() {
    if (this.eventFormGroup.get('several_days').value !== true) {
      this.eventFormGroup.patchValue({
        end_date: this.eventFormGroup.get('date').value,
      });
    }
  }

  patchEventName(trailName) {
    this.eventFormGroup.patchValue({ event_name: trailName });
  }

  patchOutOfCriteriaValue(value: boolean) {
    this.eventFormGroup.patchValue({ out_of_criteria: value });
  }

  patchEventTimer(timer) {
    if (timer && timer.id) {
      this.eventFormGroup.patchValue({ timer: timer.id });
    }
  }

  patchFormValues(event: IEvent) {
    //TODO : move to service
    this.event = event;

    if (event.date * 1000 + 12 * 60 * 60 * 1000 < Date.now()) {
      this.acceptDateChange = false;
    }

    this.eventFormGroup.patchValue({
      id: this.event.id,
      event_name: this.event.event_name ? this.event.event_name : event.trail.title,
      geo_lat: this.event.geo_lat,
      geo_lon: this.event.geo_lon,
      departure_address: this.event.departure_address,
      rules_url: this.event.rules_url,
      edition_number: '' + this.event.edition_number,
      //date: new Date(this.event.date * 1000),
      date: DateTime.fromSeconds(this.event.date),
      //end_date: new Date(this.event.end_date * 1000),
      end_date: DateTime.fromSeconds(this.event.end_date),
      several_days: this.event.date != this.event.end_date,
      prefill: true,
      onsite_registration: this.event.onsite_registration,
      preregistration: true, //TODO
      registration_url: this.event.registration_url,
      out_of_criteria: this.event.out_of_criteria,
      canceled: this.event.canceled,
      no_next_edition: this.event.no_next_edition,
      external_registration_allowed: this.event.external_registration_allowed,
      onsite_registration_start_time: this.event.onsite_registration_start_time
        ? toHMS(this.event.onsite_registration_start_time, 'hm')
        : '',
      onsite_registration_end_time: this.event.onsite_registration_end_time
        ? toHMS(this.event.onsite_registration_end_time, 'hm')
        : '',
      registration_start_date: this.event.registration_start_date
        ? new Date(this.event.registration_start_date * 1000 + 12 * 60 * 60 * 1000)
        : '',
      registration_end_date: this.event.registration_start_date
        ? new Date(this.event.registration_end_date * 1000 + 12 * 60 * 60 * 1000)
        : '',
      distances: 1,
      timer: this.event.timer && this.event.timer.id ? this.event.timer.id : '',
    });

    if (this.event.amenities) {
      this.eventFormGroup.patchValue(this.event.amenities);
    }
  }

  toggle() {
    this.toggleMore = !this.toggleMore;
  }

  private notifyDatesChanged(endDateChanged = false) {
    // if the event is organized on more than one day,
    // we must provide the list of days between start and date
    // to give the possibility to the organiser to choose one of these days for each race
    // EDIT: the list of days is now available even if the event is organized on ONE day only

    if (this.eventFormGroup.get('date').value) {
      const startDate = moment(this.eventFormGroup.get('date').value.valueOf());

      let endDate = moment(this.eventFormGroup.get('date').value.valueOf());
      if (this.eventFormGroup.get('end_date').value) {
        endDate = moment(this.eventFormGroup.get('end_date').value.valueOf());
      }

      let newStartDate: moment.Moment | undefined = undefined;
      let newEndDate: moment.Moment | undefined = undefined;

      if (this.eventFormGroup.get('several_days').value) {
        if (endDateChanged) {
          if (endDate.isSame(startDate, 'day') || endDate.isBefore(startDate)) {
            newStartDate = endDate.subtract(1, 'days');
            this.eventFormGroup.patchValue({ date: DateTime.fromMillis(newStartDate.valueOf()) });
          }
        } else {
          if (startDate.isSame(endDate, 'day') || endDate.isBefore(startDate)) {
            newEndDate = startDate.add(1, 'days');
            this.eventFormGroup.patchValue({ end_date: DateTime.fromMillis(newEndDate.valueOf()) });
          }
        }
      }

      let date = moment(this.eventFormGroup.get('date').value.valueOf()),
        dates = [];
      const end = moment(this.eventFormGroup.get('end_date').value.valueOf());

      while (date.isBefore(end) || date.isSame(end, 'day')) {
        dates.push(date.valueOf());
        date.add(1, 'days');
      }

      this.datesChanged.emit({ several_days: true, dates: dates });
    } else {
      this.datesChanged.emit({ several_days: false, dates: [] });
    }
  }

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#424242',
      buttonColor: '#fff',
    },
    dial: {
      dialBackgroundColor: '#555',
    },
    clockFace: {
      clockFaceBackgroundColor: '#555',
      clockHandColor: '#3ca896',
      clockFaceTimeInactiveColor: '#fff',
    },
  };

  ngOnDestroy(): void {}
}
