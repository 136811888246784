/**
 * Replace accented (and some special) characters with their unaccented counterparts,
 * then perform several cleaning steps similar to the original PHP function.
 *
 * @param {string} input The input string.
 * @param {string} [charset='utf-8'] (unused) The character set – kept for compatibility with the PHP signature.
 * @returns {string} The cleaned string.
 */
export function cleanUpString(input: string, charset = 'utf-8'): string {
  let str = input;

  // Mapping of accented/special characters to their replacement.
  const characters: { [key: string]: string } = {
    À: 'a',
    Á: 'a',
    Â: 'a',
    Ä: 'a',
    Ã: 'a',
    Å: 'a',
    à: 'a',
    á: 'a',
    â: 'a',
    ä: 'a',
    '@': 'a',
    Ç: 'c',
    ç: 'c',
    Ð: 'd',
    È: 'e',
    É: 'e',
    Ê: 'e',
    Ë: 'e',
    è: 'e',
    é: 'e',
    ê: 'e',
    ë: 'e',
    '€': 'e',
    Ì: 'i',
    Í: 'i',
    Î: 'i',
    Ï: 'i',
    ì: 'i',
    í: 'i',
    î: 'i',
    ï: 'i',
    Ñ: 'n',
    Ø: 'o',
    Ò: 'o',
    Ó: 'o',
    Ô: 'o',
    Õ: 'o',
    Ö: 'o',
    ò: 'o',
    ó: 'o',
    ô: 'o',
    ö: 'o',
    Ù: 'u',
    Ú: 'u',
    Û: 'u',
    Ü: 'u',
    ù: 'u',
    ú: 'u',
    û: 'u',
    ü: 'u',
    µ: 'u',
    Ý: 'y',
    Œ: 'oe',
    œ: 'oe',
    $: 's',
    Æ: 'a',
    ß: 'ss',
  };

  // --- Step 1: Replace characters based on the mapping ---
  // To avoid problems with special regex characters, we escape each key.
  const escapeRegExp = (s: string) => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const pattern = new RegExp(Object.keys(characters).map(escapeRegExp).join('|'), 'g');
  str = str.replace(pattern, match => characters[match]);

  // --- Step 2: Replace any sequence of non-alphanumeric characters with a hyphen ---
  str = str.replace(/[^A-Za-z0-9]+/g, '-');

  // --- Step 3: Trim hyphens from the beginning and end ---
  str = str.replace(/^-+|-+$/g, '');

  // --- Step 4: Convert to lower-case ---
  str = str.toLowerCase();

  // --- Step 5: Convert to HTML entities ---
  // There is no built-in "htmlentities" in JS.
  // For our purposes, we can implement a minimal conversion.
  function htmlEntities(s: string): string {
    return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    // (Additional characters could be handled if needed.)
  }
  str = htmlEntities(str);

  // --- Step 6: Replace HTML entities for accented characters with their base letter ---
  // For example, convert "&eacute;" to "e".
  str = str.replace(/&([A-Za-z])(?:acute|cedil|caron|circ|grave|orn|ring|slash|th|tilde|uml);/g, '$1');

  // --- Step 7: Replace ligature HTML entities (like "&oelig;") ---
  str = str.replace(/&([A-Za-z]{2})(?:lig);/g, '$1');

  // (The PHP code had a commented-out replacement to remove other entities.)

  // --- Step 8: Replace any remaining non-alphanumeric characters with a space ---
  // In PHP, the pattern [^[:alnum:]] matches anything not a letter or digit.
  str = str.replace(/[^A-Za-z0-9]/g, ' ');

  // --- Step 9: Convert to upper-case ---
  str = str.toUpperCase();

  return str;
}

// * DRUPAL PHP old function for reference :

// Déclaration d'une fonction qui supprime tous les caractères spéciaux et encode en majuscules
/* function betrail_nettoyer_chaine($str, $charset='utf-8')
{
	$caracteres = array(
		'À' => 'a', 'Á' => 'a', 'Â' => 'a', 'Ä' => 'a', 'Ã' => 'a', 'Å' => 'a', 'à' => 'a', 'á' => 'a', 'â' => 'a', 'ä' => 'a', '@' => 'a',
		'Ç' => 'c', 'ç' => 'c', 'Ð' => 'd', 'È' => 'e', 'É' => 'e', 'Ê' => 'e', 'Ë' => 'e', 'è' => 'e', 'é' => 'e', 'ê' => 'e', 'ë' => 'e', '€' => 'e',
		'Ì' => 'i', 'Í' => 'i', 'Î' => 'i', 'Ï' => 'i', 'ì' => 'i', 'í' => 'i', 'î' => 'i', 'ï' => 'i',
		'Ñ' => 'n', 'Ø' => 'o', 'Ò' => 'o', 'Ó' => 'o', 'Ô' => 'o', 'Õ' => 'o', 'Ö' => 'o', 'ò' => 'o', 'ó' => 'o', 'ô' => 'o', 'ö' => 'o',
		'Ù' => 'u', 'Ú' => 'u', 'Û' => 'u', 'Ü' => 'u', 'ù' => 'u', 'ú' => 'u', 'û' => 'u', 'ü' => 'u', 'µ' => 'u', 'Ý' => 'y',
		'Œ' => 'oe', 'œ' => 'oe', '$' => 's', 'Æ' => 'a', 'ß' => 'ss');

	$str = strtr($str, $caracteres);
	//echo $str." > ";
	$str = preg_replace('#[^A-Za-z0-9]+#', '-', $str);
	//echo $str." > ";
	$str = trim($str, '-');
	//echo $str." > ";
	$str = strtolower($str);
	//echo $str." > ";

	$str = htmlentities($str, ENT_NOQUOTES, $charset);
	//echo $str." > ";
    $str = preg_replace('#&([A-Za-z])(?:acute|cedil|caron|circ|grave|orn|ring|slash|th|tilde|uml);#', '\1', $str);
	//echo $str." > ";
    $str = preg_replace('#&([A-Za-z]{2})(?:lig);#', '\1', $str); // pour les ligatures e.g. '&oelig;'
    //$str = preg_replace('#&[^;]+;#', '', $str); // supprime les autres caractères
	//echo $str." > ";
	$str = preg_replace('#[^[:alnum:]]#u', ' ', $str);
	//echo $str." > ";
    $str = strtoupper($str);
	//echo $str." <br /><br /> ";
    return $str;
}
*/
