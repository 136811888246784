<div class="flex-container">
  <div class="step-container" [class.active]="activeIndex == 1">
    <div class="step">1</div>
    <div class="step-label">{{ 'FORM' | transloco }}</div>
  </div>

  <mat-icon>keyboard_arrow_right</mat-icon>

  <div class="step-container" [class.active]="activeIndex == 2">
    <div class="step">2</div>
    <div class="step-label">{{ 'BASKET' | transloco }}</div>
  </div>

  <mat-icon>keyboard_arrow_right</mat-icon>

  <div class="step-container" [class.active]="activeIndex == 3">
    <div class="step">3</div>
    <div class="step-label">{{ 'PAYMENT' | transloco }}</div>
  </div>
</div>
