import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@betrail-libs/auth-state';
import { IAppConfig } from '@betrail-libs/shared/interfaces/app-config.model';
import { IPremiumRefPrice, IPremiumSubData, IPremiumSubscription } from '@betrail-libs/shared/interfaces/premium.model';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PremiumApiService {
  #config = inject<IAppConfig>(APP_CONFIG);
  #http = inject(HttpClient);

  getPublicPremiumPrices = () =>
    this.#http
      .get<{ body: IPremiumRefPrice[] }>(`${this.#config.nodejsApi}/premium/prices/public`)
      .pipe(map(r => r.body));

  getUserPremiumSubscriptionData = (uid: number) =>
    this.#http
      .get<{ body: IPremiumSubData }>(`${this.#config.nodejsApi}/premium/subscription/user/${uid}`, {
        withCredentials: true,
      })
      .pipe(map(r => r.body));

  getAllUserPremiumSubscriptions = (uid: number, scope?: string) =>
    this.#http
      .get<{ body: IPremiumSubscription[] }>(
        `${this.#config.nodejsApi}/premium/subscriptions/${uid}/all` + (scope ? `?scope=${scope}` : ''),
        { withCredentials: true },
      )
      .pipe(map(r => r.body));

  changePremiumSubscriptionType = (subscriptionId: number, newPrice: IPremiumRefPrice) =>
    this.#http
      .put<{ body: IPremiumSubData }>(
        `${this.#config.nodejsApi}/premium/subscription/change-type/${subscriptionId}`,
        { newPrice },
        { withCredentials: true },
      )
      .pipe(map(r => r.body));

  cancelSubscriptionAutoRenewal(subscriptionId: number) {
    return this.#http
      .put<{ body: IPremiumSubscription }>(
        `${this.#config.nodejsApi}/premium/subscription/stop-renewal`,
        { subscriptionId },
        { withCredentials: true },
      )
      .pipe(map(r => r.body));
  }
}
