import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bux-registration-steps',
  templateUrl: './registration-steps.component.html',
  styleUrls: ['./registration-steps.component.scss'],
})
export class RegistrationStepsComponent {
  /** Members **/

  /** Inputs **/
  @Input() activeIndex: number;

  /** Outputs **/

  /** Constructor **/

  constructor() {}

  /** Page events **/

  /** Click events **/

  /** Private methods **/
}
