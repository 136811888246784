import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'last-claimed-runner-block',
  templateUrl: './last-claimed-runner-block.component.html',
  styleUrls: ['./last-claimed-runner-block.component.scss'],
})
export class LastClaimedRunnerBlockComponent implements OnInit {
  @Input() runner;

  diff;
  type: string;

  ngOnInit() {
    if (this.runner && this.runner.account_created) {
      this.getRegistrationDate();
    }
  }

  getRegistrationDate() {
    const accountCreated = moment(this.runner.account_created * 1000);

    this.diff = moment().diff(accountCreated, 'days');
    this.type = this.diff === 1 ? 'DAY' : 'DAYS';

    if (this.diff === 0) {
      this.diff = moment().diff(accountCreated, 'hours');
      this.type = this.diff === 1 ? 'HOUR' : 'HOURS';
    }

    if (this.diff === 0) {
      this.diff = moment().diff(accountCreated, 'minutes');
      this.type = this.diff === 1 ? 'MINUTE' : 'MINUTES';
    }
  }
}
