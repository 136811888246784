import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthStateService } from '@betrail-libs/auth-state';
import { ITrail } from '@betrail-libs/shared/interfaces/interfaces';

@Component({
  selector: 'app-choose-organization-to-link',
  templateUrl: './choose-organization-to-link.component.html',
  styleUrls: ['./choose-organization-to-link.component.scss', '../../common.scss'],
})
export class ChooseOrganizationToLinkComponent {
  constructor(private authState: AuthStateService) {}

  user$ = this.authState.getUser();
  chosenOrganization = undefined;
  trail: ITrail;

  @Output() confirmLinkToOrgaClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() goToCreateOrgaFormClicked: EventEmitter<any> = new EventEmitter<any>();

  confirmLinkToOrga(orga) {
    this.confirmLinkToOrgaClicked.emit(orga);
  }

  cancel() {
    this.cancelClicked.emit();
  }

  goToCreateOrgaForm() {
    this.goToCreateOrgaFormClicked.emit();
  }

  setChosenOrganization(event) {
    this.chosenOrganization = event.value;
  }
}
