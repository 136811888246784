<div
  class="last-runner-container bt-shadowed"
  fxLayout="row wrap"
  fxLayoutAlign="start center"
  [routerLink]="['/', 'runner', runner?.alias, 'overview']"
>
  <div class="image-container min-w-[10em]">
    <div
      class="image mat-elevation-z1"
      fxLayout="column"
      fxLayoutAlign="center center"
      *ngIf="runner?.photo?.square_image"
      [style.background-image]="runner?.photo?.square_image | sanitizeStyleUrl"
      [class.premium-border]="runner?.is_premium"
    ></div>
  </div>
  <div class="runner-details-container">
    <div class="title">
      <h4>
        {{ runner.title }}
        <country-flag [country]="runner.nationality" [size]="18" class="relative top-[-3px]"></country-flag>
      </h4>
    </div>
    <div class="details">
      <span *ngIf="runner.display_age">{{ runner?.age }} {{ 'RUNNER_YEARS_OLD' | transloco }} - </span>
      <span *ngIf="runner.display_place">
        &nbsp;<country-flag [country]="runner.country" [size]="10" class="relative top-[-1px] mr-1"></country-flag> {{
        runner.place | capitalize }} -
      </span>
      <span class="memberFor">{{ 'MEMBER_FOR' | transloco }} {{ diff }} {{ type | transloco }}</span>
    </div>
    <div class="scores">
      <ng-container *ngIf="runner?.level && runner?.level.level > 0">
        {{'LEVEL' | transloco}} : {{ (runner?.level).level / 100 }}
      </ng-container>
      <span class="results" *ngIf="runner?.results && runner?.results?.length > 1; else oneRace">
        · {{ runner?.results?.length }} {{ 'RESULTS' | transloco }}</span
      >
      <ng-template #oneRace>
        <span class="results" *ngIf="runner?.results && runner?.results?.length > 0; else noRace"
          >· {{ runner?.results?.length }} {{ 'RESULT' | transloco }}</span
        >
      </ng-template>
      <ng-template #noRace>
        <span class="no-results"> {{ 'RUNNER_WITHOUT_RESULT' | transloco }} </span>
      </ng-template>
    </div>
  </div>
</div>
