<section class="w-96">
  <header class="text-center">
    <h3>{{ 'EDIT_RESULT_ADMIN' | transloco }}</h3>
  </header>
  <hr />
  <form [formGroup]="resultForm" class="p-3">
    <div class="flex justify-between">
      <mat-form-field appearance="fill" class="w-32">
        <mat-label>{{ 'GENDER' | transloco }}</mat-label>
        <mat-select formControlName="gender">
          <mat-option [value]="0">{{ 'GENDER_M' | transloco }}</mat-option>
          <mat-option [value]="1">{{ 'GENDER_F' | transloco }}</mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="distances$ | async; let distances; else loading">
        <mat-form-field appearance="fill" class="w-40">
          <mat-label>{{ 'DISTANCE' | transloco }}</mat-label>
          <mat-select formControlName="distance">
            <mat-option *ngFor="let race of distances" [value]="race.id" (onSelectionChange)="selectRace($event, race)">
              <span [style.text-decoration]="race.canceled === 1 ? 'line-through' : 'none'">
                {{ race.distance }}km
                <ng-container class="distance-type" *ngIf="race?.race_type && race?.race_type !== eRaceType.SOLO"
                  >{{ 'RACE_TYPE_' + race?.race_type | transloco }}</ng-container
                >
                <ng-container class="distance-type" *ngIf="race?.timing_type === eRaceTimingType.NONE"
                  >{{ 'RACE_NO_TIMING' | transloco }}</ng-container
                >
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-template #loading>
        <div class="w-40 flex justify-center pt-2">
          <mat-spinner [diameter]="40"></mat-spinner>
        </div>
      </ng-template>
    </div>
    <div class="flex justify-between items-center">
      <mat-label class="pb-2">{{ 'RUNNER_TIME' | transloco }}</mat-label>
      <mat-form-field appearance="fill" class="w-16">
        <input matInput type="number" step="1" formControlName="hours" placeholder="hh" min="0" max="999" />
      </mat-form-field>
      :
      <mat-form-field appearance="fill" class="w-16">
        <input matInput type="number" step="1" formControlName="minutes" placeholder="mm" min="0" max="59" />
      </mat-form-field>
      :
      <mat-form-field appearance="fill" class="w-16">
        <input matInput type="number" step="1" formControlName="seconds" placeholder="ss" min="0" max="59" />
      </mat-form-field>
      <mat-icon class="pb-2" matSuffix>av_timer</mat-icon>
    </div>
    <hr />
    <div class="flex justify-between pt-3">
      <button mat-raised-button mat-dialog-close color="warn">{{ 'CANCEL' | transloco }}</button>
      <button
        mat-raised-button
        color="accent"
        class="w-28"
        (click)="saveModifications()"
        [disabled]="resultForm.pristine || saving"
      >
        <ng-container *ngIf="!saving; else loader">{{ 'SAVE' | transloco }}</ng-container>
        <ng-template #loader>
          <mat-spinner [diameter]="20" color="primary" class="m-auto"></mat-spinner>
        </ng-template>
      </button>
    </div>
  </form>
</section>
