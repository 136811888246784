import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthStateService } from '@betrail-libs/auth-state';
import { IEvent } from '@betrail-libs/shared/interfaces/event.model';
import {
  IBestFriendData,
  IGameData,
  IGameGridData,
  ILevel,
  ILevelProno,
} from '@betrail-libs/shared/interfaces/interfaces';
import { IPremiumSubscription } from '@betrail-libs/shared/interfaces/premium.model';
import { IRace } from '@betrail-libs/shared/interfaces/race.model';
import { IRegistration, ISignupRegistration } from '@betrail-libs/shared/interfaces/registration.model';
import { IOtherResult, IResult, IResultCreation } from '@betrail-libs/shared/interfaces/result.model';
import { IRunner } from '@betrail-libs/shared/interfaces/runner.model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { sanitizeString, toDateStringFromNumber } from '../../shared/utils';

@Injectable({ providedIn: 'root' })
export class EventService {
  constructor(
    private http: HttpClient,
    @Inject('appsConfig') private config: any,
    private authService: AuthStateService,
  ) {}

  private handleError(error: HttpErrorResponse) {
    /*if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`);
    }*/
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  getApiUrl() {
    return this.config.nodejsApi;
  }
  // EVENT

  getEvent(id: any): any {
    return this.frenchEncodinEvents().pipe(
      map(events => events.find((event: any) => event.races.find(race => race.id === +id))),
    );
  }

  getEventById(evid) {
    return this.http.get<any>(`${this.config.nodejsApi}/events/${evid}`);
  }

  events = this.http.get<any>(`${this.config.nodejsApi}/events?after=2016-01-01&scope=full`).pipe(map(r => r.body));

  getEventDistances(evid: number | string) {
    return this.http.get<
      {
        id: number;
        distance: number;
        alias: string;
        race_type: string;
        timing_type: string;
        canceled: 0 | 1;
        title: string;
      }[]
    >(`${this.config.nodejsApi}/events/${evid}/distances`);
  }

  frenchEncodinEvents = () => {
    let before = new Date();
    return this.http
      .get<any>(
        `${this.config.nodejsApi}/events?after=2014-01-01&before=${toDateStringFromNumber(
          before,
        )}&scope=full&length=full`,
      )
      .pipe(map(r => r.body));
  };

  closeFutureEvents = () => {
    const now = new Date();
    const next = new Date();
    const closeFuture = new Date(next.setDate(now.getDate() + 15));

    return this.http
      .get<any>(
        `${this.config.nodejsApi}/events?after=${now.getFullYear()}-${
          now.getMonth() + 1
        }-${now.getDate()}&before=${closeFuture.getFullYear()}-${
          closeFuture.getMonth() + 1
        }-${closeFuture.getDate()}&scope=full`,
      )
      .pipe(map(r => r.body));
  };

  getTrailByEventId(eventId) {
    return this.http.get(`${this.config.nodejsApi}/s1/trails/${eventId}`);
  }

  getCalendarOverviewEvents() {
    return this.http
      .get<{ body: { events: IEvent[] } }>(`${this.config.nodejsApi}/events/calendarOverview`)
      .pipe(map(r => r.body.events));
  }

  getNearEvents(lat, lon, trid) {
    return this.http
      .get<any>(`${this.config.nodejsApi}/events/nearEvents?lat=${lat}&lon=${lon}&trid=${trid}`)
      .pipe(map(r => r.body));
  }

  eventsPacket({ after, before, country, forAdditionPage }, max = 'full') {
    return this.http
      .get<any>(
        `${this.config.nodejsApi}/events?after=${toDateStringFromNumber(after)}&before=${toDateStringFromNumber(
          before,
        )}&scope=full&predicted=1&length=${max}&country=${country}&forAddition=${forAdditionPage}`,
      )
      .pipe(map(r => r.body));
  }

  getLastEncodedEvents() {
    return this.http.get<any>(`${this.config.nodejsApi}/events/lastEncoded`).pipe(map(r => r.body.events));
  }

  getLastEncodedEventsByCountry(country: string) {
    return this.http.get<any>(`${this.config.nodejsApi}/events/lastEncoded/${country}`).pipe(map(r => r.body.events));
  }

  getRealLastEncodedEventsByCountry(nbDays: number, country: string) {
    return this.http.get<IEvent[]>(`${this.config.nodejsApi}/events/lastEncoded/${nbDays}/${country}`);
  }

  getNextWithBetrailRegistrationEvents() {
    return this.http
      .get<{ body: { events: IEvent[] } }>(`${this.config.nodejsApi}/events/nextWithBetrailRegistration`)
      .pipe(map(r => r.body.events));
  }

  futureEvents = () => {
    const now = new Date();
    return this.http
      .get<any>(
        `${this.config.nodejsApi}/events?after=${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}&scope=full`,
      )
      .pipe(map(r => r.body));
  };
  allEvents = () => {
    return this.http.get<any>(`${this.config.nodejsApi}/events?scope=full`).pipe(
      shareReplay(1),
      map(r => r.body),
    );
  };

  getIfEventIsPromoted = evid => {
    return this.http.get<any>(`${this.config.nodejsApi}/events/promoted/${evid}`).pipe(map(r => r.body));
  };

  getUnpublishedEditionDates = (trailId: string | number) =>
    this.http.get(`${this.config.nodejsApi}/events/unpublished/${trailId}`);

  getTotalFinishersOfEvent = (eventId: string | number) =>
    this.http.get<{ trail: number; relay: number }>(`${this.config.nodejsApi}/events/finishers/${eventId}`);

  // ORGANIZATION

  createOrganization = newOrganization => this.http.post<any>(`${this.config.nodejsApi}/organization`, newOrganization); //.pipe(map(r => r.body));

  createUserOrganization = newUserOrganization =>
    this.http.post<any>(`${this.config.nodejsApi}/organization/user`, newUserOrganization); //.pipe(map(r => r.body));

  linkOrganizationToTrail = data => this.http.put<any>(`${this.config.nodejsApi}/organization/trail`, data); //.pipe(map(r => r.body));

  updateOrganization = data => this.http.put<any>(`${this.config.nodejsApi}/organization`, data); //.pipe(map(r => r.body));

  getOrgaByUserId = (userId: number) => this.http.get<any>(`${this.config.nodejsApi}/organization/user/${userId}`); //.pipe(map(r => r.body));

  getOrganizationById = orgaId => this.http.get<any>(`${this.config.nodejsApi}/organization/${orgaId}`); //.pipe(map(r => r.body));

  getAllOrganizations = () => this.http.get<any>(`${this.config.nodejsApi}/organization`); //.pipe(map(r => r.body));

  addUserToOrganization = (orgaId, userId) =>
    this.http.put<any>(`${this.config.nodejsApi}/organization/add-user`, { orgaId, userId });

  removeUserFromOrganization = (organizationId: number, userId: number) =>
    this.http.delete(`${this.config.nodejsApi}/organization/${organizationId}/user/${userId}`);

  removeTrailFromOrganization = (trailId: number) =>
    this.http.delete(`${this.config.nodejsApi}/organization/trail/${trailId}/remove`);

  // TRAIL

  postDrupalImage = (filename: string, image: string, addto?: any) =>
    this.http
      .post<any>(`${this.config.nodejsApi}/trail/drupalImage`, { filename, image, addto })
      .pipe(map(r => r.body));

  saveNewEvent = (
    eventInfos: any,
    additionInfos: { isPremium: boolean; isTheOrga: boolean; isFirstEdition: boolean; claimedEventId?: number },
  ) =>
    this.http
      .post<any>(`${this.config.nodejsApi}/trail/newevent`, { eventInfos, additionInfos })
      .pipe(map(r => r.body));

  sendBannerContactFormMessage = data =>
    this.http.post<any>(`${this.config.nodejsApi}/banner/contact`, { data }).pipe(map(r => r.body));

  getTrail = (trailAlias: string) =>
    this.http.get<any>(`${this.config.nodejsApi}/trail/${trailAlias}`).pipe(map(r => r.body));

  // generic function for updating trail, event or race data
  // TODO = replace this crap with dedicated functions
  updateTrailData = (data: object) =>
    this.http.put<any>(`${this.config.nodejsApi}/trail/update`, { data }).pipe(map(r => r.body));

  // RACE

  getRace = (id: number | string) => this.http.get<any>(`${this.config.api}race/${id}`).pipe(map(r => r.body));

  getRaceResults = (id: number | string) =>
    this.http.get<any>(`${this.config.api}race/${id}/results`).pipe(map(r => r.body));

  deleteRaceResult = (
    resultId: number | string,
    data?: { raceTitle: string; resultTitle: string; resultTime: string },
  ) => this.http.delete(`${this.config.nodejsApi}/race/result/${resultId}`, { body: data });

  createRaceResult = (data: IResultCreation) =>
    this.http.post<IResult[]>(`${this.config.nodejsApi}/race/${data.raid}/result/create`, data);

  editResultData = (data: {
    result: IResult;
    edited: { gender?: string; distance?: string; time?: string };
    newDistance: { raid?: string | number; title?: string };
  }) => this.http.put<IResult | IResult[]>(`${this.config.nodejsApi}/race/result`, data);

  getOrgaEmailByRaid = (raid: number) =>
    this.http.get<any>(`${this.config.nodejsApi}/race/orga-mail/${raid}`).pipe(map(r => r.body));

  reComputeRaceIndex = (raid: number | string) => {
    return this.http.get<any>(`${this.config.nodejsApi}/race/${raid}/recompute-index`).pipe(map(r => r.body));
  };

  saveNewIndexRace(race: IRace, newIndex: number | string, type: string) {
    return this.http
      .put<any>(`${this.config.nodejsApi}/race/${race.id}/recompute-index`, { newIndex, race, type })
      .pipe(map(r => r.body));
  }

  getRaceEncoderData = (raid: number | string) => this.http.get<any>(`${this.config.nodejsApi}/race/${raid}/encoder`);
  /*
  estimatedRaceIndex = (raid: number | string) => {
    return this.http.get<any>(`${this.config.nodejsApi}/race/${raid}/estimatedindex`).pipe(map((r) => r.body));
  }; */

  getStats = () =>
    this.http
      .get<{ body: { accounts: number; races: number; results: number; runners: number } }>(
        `${this.config.nodejsApi}/app/stats`,
      )
      .pipe(map(r => r.body));

  postRaceGpxTrack = (filename: string, gpx: string, raid: number, type?: string) =>
    this.http.post<any>(`${this.config.nodejsApi}/race/gpx`, { filename, gpx, raid, type }).pipe(map(r => r.body));

  deleteRaceTrack = (paid: number) =>
    this.http.delete<any>(`${this.config.nodejsApi}/race/gpx/${paid}`).pipe(catchError(this.handleError));

  updateRaceTrack = (paid: number, data: any, isNew: boolean) =>
    this.http.put<any>(`${this.config.nodejsApi}/race/gpx/${paid}`, { data, isNew }).pipe(map(r => r.body));

  sendPathModificationMail = (data: any) =>
    this.http.post<any>(`${this.config.nodejsApi}/race/gpx/mail`, { data }).pipe(map(r => r.body));

  setRaceTrackAsSelected = (paid: number) =>
    this.http.put<any>(`${this.config.nodejsApi}/race/gpx/${paid}/setselected`, {}).pipe(map(r => r.body));

  getRacePaths = (id: number | string) =>
    this.http.get<any>(`${this.config.nodejsApi}/race/${id}/paths`).pipe(map(r => r.body));

  getRacePath = (id: number | string) =>
    this.http.get<any>(`${this.config.nodejsApi}/race/${id}/path`).pipe(map(r => r.body));

  getIfRaceHasPastPath = (id: number | string) =>
    this.http.get<any>(`${this.config.nodejsApi}/race/${id}/path/past`) /* .pipe(map(r => r.body)) */;

  createGpxViewLog = (gpxId: number) =>
    this.http.post(`${this.config.nodejsApi}/race/gpx/create-log`, { gpxId }, { withCredentials: true });

  getRunnerRaceSimulation = (ruid: number, raid: number) =>
    this.http.get<any>(`${this.config.nodejsApi}/runner/${ruid}/level/${raid}`).pipe(map(r => r.body));

  getRunnerRaceSimulationForPerformance = (ruid: number, raid: number, level: number) =>
    this.http.get<any>(`${this.config.nodejsApi}/runner/${ruid}/estimation/${raid}/${level}`).pipe(map(r => r.body));

  getRunnerLevelPronoAndCoeff(ruid: number, distances: number[], elevations: number[], getLevelRankings = false) {
    let body = { distances, elevations, getLevelRankings };
    return this.http
      .post<{ allLevelProno: ILevelProno[][]; levelRankings?: (ILevel | null)[] }>(
        `${this.config.nodejsApi}/runner/${ruid}/levelprono`,
        body,
      )
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  getRunnerMatchupDuels(ruid1: number, ruid2: number) {
    let body = { ruid1, ruid2 };
    return this.http.post<any>(`${this.config.nodejsApi}/runner/matchup/duels`, body).pipe(
      map(res => {
        return res;
      }),
    );
  }

  // RUNNER

  getLastClaimedRunners = () =>
    this.http.get<{ body: IRunner[] }>(`${this.config.nodejsApi}/runner/lastClaimed`).pipe(map(r => r.body));

  changeRunnerAllowTracking = (ruid: number, allowTracking: boolean) =>
    this.http.put<any>(`${this.config.nodejsApi}/runner/tracking/${ruid}`, { data: { value: allowTracking } });

  getRunnerProfilePoints = (ruid: number | string) =>
    this.http.get<any>(`${this.config.nodejsApi}/runner/${ruid}/profile`).pipe(map(r => r.body));

  getRunnerResults = (id: number | string) =>
    this.http.get<any>(`${this.config.nodejsApi}/runner/${id}/results`).pipe(map(r => r.body));

  getRunnerResultsPositions = (id: number | string) =>
    this.http.get<any>(`${this.config.nodejsApi}/runner/${id}/results/positions`).pipe(map(r => r.body));

  getRunnerScoresPositions = (id: number | string) =>
    this.http.get<any>(`${this.config.nodejsApi}/runner/${id}/scores/positions`).pipe(map(r => r.body));

  getRunnerLevelPosition = (id: number | string, nationality: string) =>
    this.http.get<any>(`${this.config.nodejsApi}/runner/level/${id}/position/${nationality}`).pipe(map(r => r.body));

  getRunnerLevelHistory = (ruid: number | string) =>
    this.http.get<any>(`${this.config.nodejsApi}/runner/${ruid}/levelhistory`).pipe(map(r => r.body));

  searchRunner(body) {
    return this.http.post<any>(`${this.config.nodejsApi}/runner/search/full`, body).pipe(
      map(res => {
        return res;
      }),
    );
  }

  getRunnerPotentialResults(id) {
    return this.http.get<any>(`${this.config.nodejsApi}/runner/results/potential/${id}`).pipe(map(r => r.body));
  }

  giveResultToHomonymous(result: IResult, user: { name: string; isAdmin: boolean }, skipSearch = false) {
    let body = {
      result,
      user,
      skipSearch,
    };
    return this.http.post<any>(`${this.config.nodejsApi}/runner/result/tohomonymous`, body).pipe(
      map(res => {
        return res;
      }),
    );
  }

  giveResultToAnonymous(result: IResult, user: { name: string; isAdmin: boolean }) {
    let body = {
      result: result,
      user: user,
    };
    return this.http.post<any>(`${this.config.nodejsApi}/runner/result/toanonymous`, body).pipe(
      map(res => {
        return res;
      }),
    );
  }

  giveResultToAnotherRunner(result: IResult, runner: IRunner, user: { name: string; isAdmin: boolean }) {
    let body = {
      result: result,
      runner: runner,
      user: user,
    };
    return this.http.post<any>(`${this.config.nodejsApi}/runner/result/torunner`, body).pipe(
      map(res => {
        return res;
      }),
    );
  }

  setCheckedResults(runnerId, data, user) {
    return this.http.post<any>(`${this.config.nodejsApi}/runner/results/torunner/${runnerId}`, { data, user }).pipe(
      map(res => {
        return res;
      }),
    );
  }

  searchRunners(value): Observable<{ runner: IRunner; runners: IRunner[]; events: IEvent[] }> {
    value = value.replace('+', '').replace(/'/g, ' '); // remove problematic characters for elastic search
    value = sanitizeString(value);
    if (value.length > 0) {
      return this.http.get<any>(`${this.config.nodejsApi}/runner/search?queryString=${value}`).pipe(
        map(res => {
          const words = (value.match(/ /g) || []).length + 1;
          res.body[0].close_runners = res.body[0].close_runners.filter(r => r._score > 450);
          res.body[1].close_events = res.body[1].close_events.filter(r => r.score / words >= 250);

          if (res.body[1].close_events.length > 0 && res.body[0].close_runners.length > 0) {
            let max_events_score = Math.max.apply(
              Math,
              res.body[1].close_events.map(e => e.score),
            );
            let max_runners_score = Math.max.apply(
              Math,
              res.body[0].close_runners.map(r => {
                return parseInt(r._score) - 500;
              }),
            );
            if (max_runners_score > 90 * max_events_score) {
              res.body[1].close_events = [];
            }
          }
          let allowedTrails = 5;
          let allowedRunners = 7;
          if (res.body[0].close_runners.length < 7) {
            allowedTrails = 12 - res.body[0].close_runners.length;
          }
          if (res.body[1].close_events.length < 5) {
            allowedRunners = 12 - res.body[1].close_events.length;
          }
          return {
            runner: res.body[0].match_runner ? res.body[0].match_runner : null,
            runners: res.body[0].close_runners.slice(0, allowedRunners),
            events: res.body[1].close_events.slice(0, allowedTrails),
          };
        }),
      );
    }
    return of({ runner: undefined, runners: [], events: [] });
  }

  searchPlaces(value) {
    return this.http.get<any>(`${this.config.nodejsApi}/place/search?queryString=${value}`);
  }

  searchMatchPlaces(value) {
    return this.http.get<any>(
      `${this.config.nodejsApi}/place/match?place=${value.location}&postal_code=${value.zipcode}&country=${value.country}`,
    );
  }

  editResultTag(result: IResult | IOtherResult, isOtherResult: boolean) {
    return this.http.put(`${this.config.nodejsApi}/runner/edit-result-tag`, { result, isOtherResult });
  }

  // IMAGE

  getImageStyleUrl = (fid: any, style: string) => {
    return this.http.get<any>(`${this.config.nodejsApi}/image/${fid}/${style}`).pipe(map(r => r.body));
  };

  getBanners(style: 'calendar' | 'race' | 'perso') {
    //return 'Hello '+style;
    return this.http.get<any>(`${this.config.nodejsApi}/image/banner/${style}`).pipe(map(r => r.body));
  }

  // NOTIFICATION

  dismissError = id =>
    this.http.delete<any>(`${this.config.api}/notification/${id}`).pipe(catchError(this.handleError));

  // ARTICLE

  getArticle(id) {
    return this.http.get<any>(`${this.config.nodejsApi}/article/${id}`).pipe(map(r => r.body));
  }

  getArticles(params) {
    return this.http
      .get<any>(`${this.config.nodejsApi}/article/all/${params.lang}/${params.offset}/${params.length}}`)
      .pipe(map(r => r.body));
  }

  // USER

  loadCart(): Observable<any> {
    return this.getNode('/user/cart');
  }

  createOrder(data): Observable<any> {
    return this.postNode('/s1/signup/validateCart', data);
  }
  subscribeToWaitingList(email, routeId, formData): Observable<any> {
    return this.postNode('/s1/signup/subscribeToWaitingList', { email, routeId, formData });
  }

  getWaitingListRegs(eventId): Observable<any> {
    return this.getNode('/s1/signup/waitingList/' + eventId);
  }

  generateAccessCode(id, message): Observable<any> {
    return this.postNode('/s1/signup/authorizeWaitingList/' + id, message);
  }

  checkRegistrationWaitingListCode(code): Observable<any> {
    return this.getNode('/s1/signup/checkRegistrationWaitingListCode/' + code);
  }

  deleteWaitingList(id): Observable<any> {
    return this.deleteNode('/s1/signup/deleteWaitingList/' + id);
  }

  payOrder(data): Observable<any> {
    return this.postNode('/s1/signup/payOrder', data);
  }

  getOrder(orderId, transactionId?): Observable<any> {
    return this.postNode(`/s1/signup/order/${orderId}`, { transactionId });
  }

  getUserOrders(): Observable<any> {
    return this.getNode(`/s1/signup/orders`);
  }
  getSignupOrdersForReport(): Observable<any> {
    return this.getNode(`/s1/signup/allOrders/signup`);
  }

  cancelOrder(orderId): Observable<any> {
    return this.http.delete<any>(`${this.config.nodejsApi}/s1/signup/order/${orderId}`).pipe(map(r => r.body));
  }

  removeFromCart(index): Observable<any> {
    return this.postNode('/s1/signup/removeCartItem', { index });
  }

  updateRegistration(id: string, registration: any) {
    return this.patchNode('/s1/signup/registration/' + id, { registration });
  }

  deleteRegistration(id: string) {
    return this.getNode('/s1/signup/deleteReg/' + id);
  }

  removeRegistration(id: string) {
    return this.getNode('/s1/signup/removeReg/' + id);
  }

  getAllEncoders(): Observable<{ uid: number; id: number; title: string }[]> {
    return this.getNode('/user/encoder/all');
  }

  getOrganizerAccounts(): Observable<any> {
    return this.getNode('/user/orgaAccount');
  }

  validateOrganizerAccount(uid): Observable<any> {
    return this.postNode(`/user/orgaAccount/validate/${uid}`, {}, { observe: 'response' });
  }

  getAllGamificationPointsByUser(period?: { from: number; to: number }) {
    return this.getNode<IGameGridData[]>(`/user/gamification/all`, { params: new HttpParams({ fromObject: period }) });
  }

  giveUserGamificationPoints(gameData: IGameData, forEncoding = false) {
    return this.postNode(`/user/gamification/${gameData.uid}`, { ...gameData, forEncoding });
  }

  // SIGNUP

  loadBeNode(nid): Observable<any> {
    return this.getBetrail('/node/' + nid);
  }

  getUserEvents(): Observable<any> {
    return this.getNode('/direct/views/trails');
  }

  getNextTrail(): Observable<any> {
    return this.getNode('/trails');
  }

  getRegistrationEvent(eventId): Observable<any> {
    return this.getNode('/s1/trails/' + eventId);
  }

  getEventSignupRegistrations2(eventId): Observable<{ registrations: ISignupRegistration[] }> {
    return this.getNode(`/s1/signup/attendee/${eventId}`);
  }

  getEventSignupRegistrations(trailId, eventId): Observable<{ registrations: ISignupRegistration[] }> {
    return this.getNode(`/s1/signup/attendee/${trailId}/${eventId}?getrunners=1`);
  }

  getRaceSignupRegistrations(trailId, eventId, raceId): Observable<{ registrations: ISignupRegistration[] }> {
    return this.getNode(`/s1/signup/attendee/${trailId}/${eventId}/${raceId}?getrunners=1`);
  }

  getSignupRegistration(registrationId: string): Observable<{ registration: ISignupRegistration }> {
    return this.getNode(`/s1/signup/registration/${registrationId}`);
  }

  getSignupRegistrationWithSignupData(
    registrationId: string,
  ): Observable<{ registration: ISignupRegistration; signupData: any }> {
    return this.getNode(`/s1/signup/registration/${registrationId}?getsignupdata=1`);
  }

  getSignupRegistrationWithRunner(registrationId: string): Observable<{ registration: ISignupRegistration }> {
    return this.getNode(`/s1/signup/registration/${registrationId}?getrunner=1`);
  }

  calculateRegistrationLevelProno(registrationIds: Array<any>): Observable<any> {
    return this.postNode(`/s1/signup/registration/updateLevelProno`, { registrationIds });
  }

  postNewTrail(trail): Observable<any> {
    return this.postNode('/trails', trail);
  }

  validateCoupon(coupon): Observable<any> {
    return this.postNode('/s1/signup/coupon', { coupon });
  }

  addToCart(runner): Observable<any> {
    return this.postNode('/s1/signup/addAttendee', Object.assign(runner));
  }

  updateRunnerInfo(runner, paymentMethod, mail): Observable<any> {
    return this.postNode('/s1/signup/attendee', Object.assign({ mail, paymentMethod }, runner));
  }
  updateEvent(trail, id): Observable<any> {
    return this.postNode('/s1/trails/' + id, trail);
  }

  checkoutCC(regCard): Observable<any> {
    return this.postNode('/s1/signup/checkoutCC', regCard);
  }

  logError(error) {
    return this.postNode('/s1/signup/logError', error);
  }

  deleteReg(id) {
    return this.getNode('/s1/signup/deleteREg/' + id);
  }

  makeTransfer(id) {
    return this.postNode('/s1/signup/accept/' + id, {});
  }

  acceptAll() {
    return this.postNode('/s1/signup/accept/all', {});
  }

  makeAllTransfer(eventId) {
    return this.postNode('/s1/signup/accept/' + eventId, {});
  }

  sendMail(mail) {
    return this.postNode('/s1/signup/mail', mail);
  }

  sendDeleteNotification(path, user, info) {
    return this.postNode('/user/deleteGpx/notification', { path, user, info });
  }

  payToOrga(id) {
    return this.getNode(`/s1/signup/payOrg/${id}`);
  }

  // PREMIUM

  getPremiumAdminReport() {
    return this.getNode<any>(`/premium/admin/report`);
  }

  getAllAuthorizedPrices(): Observable<any> {
    return this.getNode<any>(`/premium/prices/authorized`).pipe(map(r => r.body));
  }

  createNewPremiumSubscription(data: { prid: number; uid: number }): Observable<any> {
    return this.postNode(`/premium/subscription/create`, data);
  }

  deletePremiumSubscription(suid: number): Observable<any> {
    return this.deleteNode<any>(`/premium/subscription/${suid}`).pipe(map(r => r.body));
  }

  getSubscriptionById(suid: number): Observable<IPremiumSubscription> {
    return this.getNode<any>(`/premium/subscription/${suid}`).pipe(map(r => r.body));
  }

  private postNode = (uri: string, object, params?) =>
    this.http.post<any>(this.config.nodejsApi + uri, object, {
      withCredentials: true,
      ...params,
    });
  private patchNode = (uri: string, object) =>
    this.http.patch(this.config.nodejsApi + uri, object, {
      withCredentials: true,
    });
  private putNode = <T>(uri: string, object) =>
    this.http.put<T>(this.config.nodejsApi + uri, object, {
      withCredentials: true,
    });
  private getNode<T>(uri: string, options = {}): Observable<T> {
    return this.http.get<T>(this.config.nodejsApi + uri, { withCredentials: true, ...options });
  }
  private deleteNode<T>(uri: string): Observable<T> {
    return this.http.delete<T>(this.config.nodejsApi + uri, { withCredentials: true });
  }
  private getBetrail = (uri: string) =>
    this.http.get(this.config.nodejsApi + 'beapi/' + uri, { withCredentials: true });

  private getEncodage<T>(uri: string, options = {}): Observable<T> {
    return this.http.get<T>(this.config.nodejsApi + uri, { withCredentials: true, ...options });
  }

  loadWallet(eventId: string) {
    return this.getNode('/s1/signup/wallet/' + eventId);
  }

  createEvent(event: any) {
    return this.postNode('/trails', event);
  }

  updatePaymentInfo = (registrationId, paymentInfoId) =>
    this.postNode(`/s1/signup/registration/paymentInfo/${registrationId}`, {
      paymentInfoId,
    });

  updateRunnerInfo2 = (registrationId, runnerInfo) =>
    this.postNode(`/s1/signup/registration/runnerInfo/${registrationId}`, {
      runnerInfo,
    });

  createWaitingListPerson(data): Observable<any> {
    return this.postNode('/s1/signup/waitinglist', { data });
  }

  createOtherResult(otherResult: any): Observable<any> {
    return this.postNode('/otherResults/create', { otherResult });
  }

  getOtherResultsByRunnerId(runnerId: any): Observable<any> {
    return this.getNode('/otherResults/runner/' + runnerId);
  }

  getOtherResultsByRaceId(raceId: any): Observable<any> {
    return this.getNode('/otherResults/race/' + raceId);
  }

  UpdatetOtherResultsById(otherResult: any): Observable<any> {
    return this.putNode('/otherResults', { otherResult });
  }

  deleteOtherResultById(otherResult: any) {
    return this.deleteNode('/otherResults/delete/' + otherResult.id);
  }

  getRaceResultsCount(raid: number): Observable<any> {
    return this.getNode('/race/count/' + raid);
  }

  getRunnerByRuid(ruid: number): Observable<any> {
    return this.getNode('/runner/' + ruid);
  }

  getAllPremiumRunners(): Observable<any> {
    return this.getNode('/runner/premium/all');
  }

  getRunnerBestFriendsByRaceAndEvent(ruid: number) {
    return this.getNode<{ body: { friendsByRace: IBestFriendData[]; friendsByEvent: IBestFriendData[] } }>(
      '/runner/' + ruid + '/best-friends',
    ).pipe(map(res => res.body));
  }

  searchRunnerByFirstnameLastname(firstname: string, lastname: string, birthdate: string): Observable<any> {
    return this.postNode('/runner/search_by_name_and_race', { lastname, firstname, birthdate });
  }

  // SPONSORS

  createSponsor(sponsor: any, role: string, user: any): Observable<any> {
    return this.postNode('/sponsor/new/' + role, { sponsor, user });
  }

  updateSponsor(sponsor: any) {
    return this.putNode('/sponsor/update/' + sponsor.id, sponsor);
  }

  saveSponsorOrder(uid: number | string, order: { spid: number; priority: number }[]) {
    return this.putNode('/sponsor/link/' + uid, order);
  }

  replaceSponsorUsersLinks(oldSpid: number | string, newSpid: number | string) {
    return this.putNode(`/sponsor/${oldSpid}/replace/${newSpid}`, [oldSpid, newSpid]);
  }

  getSponsorById(spid: number | string) {
    return this.getNode('/sponsor/id/' + spid);
  }

  getAllSponsors() {
    return this.getNode('/sponsor/all');
  }

  getRunnersOfSponsor(spid: number | string) {
    return this.getNode('/sponsor/' + spid + '/runners');
  }

  getSponsorsOfRunner(uid: number | string): Observable<any[]> {
    return this.getNode('/sponsor/user/' + uid);
  }

  removeSponsorFromUser(uid: number | string, spid: number | string) {
    return this.deleteNode(`/sponsor/${spid}/remove/${uid}`);
  }

  deleteSponsor(spid: number | string) {
    return this.deleteNode('/sponsor/delete/' + spid);
  }

  getSponsorImage(spid: number | string) {
    return this.getNode(`/sponsor/image/${spid}`, { responseType: 'text' });
  }

  getDashboardEvent(params: any) {
    return this.getNode<any[]>('/encodage-api/dashboard/events', params);
  }

  getEventByRaceId(raceId: string) {
    return this.getNode<any[]>(`/encodage-api/event/${raceId}`);
  }
}
