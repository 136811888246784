import { Component, Input, forwardRef, AfterViewInit, OnChanges, ViewEncapsulation, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl } from '@angular/forms';
import { ITimer } from '@betrail-libs/shared/interfaces/interfaces';
import { EventService } from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { TimerStateService } from '@betrail/app/timers-state';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimerSelectorComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-timer-selector',
  templateUrl: './timer-selector.component.html',
  styleUrls: ['./timer-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class TimerSelectorComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
  #timerState = inject(TimerStateService);

  timers$: Observable<ITimer[]>;
  subtitlesVisible: boolean = false;
  defaultValue: any;

  constructor(private eventService: EventService) {}

  // The field name text . used to set placeholder also if no pH (placeholder) input is given
  @Input() label = '';

  //current form control input. helpful in validating and accessing form control
  @Input() c: UntypedFormControl = new UntypedFormControl();

  // set true if we need not show the asterisk in red color
  @Input() optional: boolean = false;

  //@Input() v:boolean = true; // validation input. if false we will not show error message.

  // errors for the form control will be stored in this array
  errors: Array<any> = ['This field is required'];

  ngOnChanges() {}

  //Lifecycle hook. angular.io for more info
  ngAfterViewInit() {
    // RESET the custom input form control UI when the form control is RESET
    this.c.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.defaultValue = this.c.value;
      // check condition if the form control is RESET
      if (this.c.value == '' || this.c.value == null || this.c.value == undefined) {
        this.innerValue = '';
      }
    });
  }

  //The internal data model for form control value access
  innerValue: any = '';

  // event fired when input value is changed . later propagated up to the form control using the custom value accessor interface
  onChange(e: Event, value: any) {
    //set changed value
    this.innerValue = value;
    // propagate value into form control using control value accessor interface
    this.propagateChange(this.innerValue);

    //reset errors
    this.errors = [];
    //setting, resetting error messages into an array (to loop) and adding the validation messages to show below the field area
    for (var key in this.c.errors) {
      if (this.c.errors.hasOwnProperty(key)) {
        if (key === 'required') {
          this.errors.push('This field is required');
        } else {
          this.errors.push(this.c.errors[key]);
        }
      }
    }
  }

  //get accessor
  get value(): any {
    return this.innerValue;
  }

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }

  //propagate changes into the custom form control
  propagateChange = (_: any) => {};

  //From ControlValueAccessor interface
  writeValue(value: any) {
    this.innerValue = value;
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {}

  ngOnInit() {
    this.timers$ = this.#timerState.selectOrLoadAllTimers();
  }

  setTimer($event) {
    this.subtitlesVisible = false;
    this.onChange($event, $event.value);
  }

  openedHasChanged(value: boolean) {
    this.subtitlesVisible = value;
  }
}
