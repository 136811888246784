import { Pipe } from '@angular/core';
import { IOtherResult, IResult } from '@betrail-libs/shared/interfaces/result.model';

@Pipe({
  name: 'resultRouterLink',
})
export class ResultRouterLinkPipe {
  transform(result: IResult & IOtherResult, page: 'race' | 'result'): string[] {
    if (page === 'race') {
      let trail: string | undefined;
      let event: string | undefined;
      let race: string | undefined;

      if (result.race?.event?.trail?.alias) {
        trail = result.race.event.trail.alias;
      } else if (result?.trail?.alias) {
        trail = result.trail.alias;
      }
      if (result.race?.event?.alias) {
        event = result.race.event.alias;
      } else if (result?.event?.alias) {
        event = result.event.alias;
      }
      if (result.race?.alias) {
        race = result.race.alias;
      } else if (result.race?.id) {
        race = '' + result.race.id;
      }

      if (trail && event && race) {
        return ['/', 'race', trail, event, race];
      } else if (trail && event) {
        return ['/', 'race', trail, event];
      }
    } else {
      let runner: string | undefined;
      let resultId: string | undefined;

      if (result.runner?.alias) {
        runner = result.runner.alias;
      }
      if (result.id && ('pts' in result || 'performance' in result)) {
        resultId = '' + result.id;
      }

      if (runner && resultId) {
        return ['/', 'runner', runner, 'results', resultId];
        // return '/runner/' + runner + '/my-page/results/' + resultId;
      }
    }

    return ['/', 'runner', 'my-page', 'results'];
  }
}
